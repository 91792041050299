import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { CalendarizationForProject } from '@app/calendarization/store/ctrs-calendarization.reducer';
import { IntegerPipe } from '@app/common/pipe/integer.pipe';
import { OneDecimalPipe } from '@app/common/pipe/one-decimal.pipe';

@Component({
  selector: 'app-calendarization-ctr-tooltip',
  templateUrl: './calendarization-ctr-tooltip.component.html',
  styleUrls: ['./calendarization-ctr-tooltip.component.scss'],
  standalone: true,
  imports: [CommonModule, IntegerPipe, OneDecimalPipe],
  providers: [DecimalPipe],
})
export class CalendarizationCtrTooltipComponent {
  constructor(@Inject('ctrData') public ctr: CalendarizationForProject) {}

  public entitiesNames = this.ctr.entities.join(', ');
}
