import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';

import { AppDateAdapter, APP_DATE_FORMATS } from '@app/DateAdapter';
import { SdkCallService } from '@app/sdk-call.service';
import { selectAllActiveBusinessSegments } from '@collections/business-segments/store/business-segments.selectors';
import { selectSegmentPxDsFactory } from '@collections/pxds/store/pxds.selectors';
import {
  deepClone,
  formToServerDateConversion,
  saveImportedDataAs,
} from '@common/utils';

@Component({
  selector: 'app-add-export',
  templateUrl: './add-export.component.html',
  styleUrls: ['./add-export.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class AddExportComponent implements OnInit {
  registerForm: UntypedFormGroup;
  maxDateVal = new Date();
  showError = false;
  selectionForm: UntypedFormGroup;
  roleTypes: any[] = [];
  businessSegment: any;
  pxdArr: any = [];
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  pxdCtrl = new UntypedFormControl();
  ctrList: any;
  ctrIdArr: any = [];
  emptyPxd = false;
  ctrListCopy: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sdkService: SdkCallService,
    private store: Store
  ) {
    this.store
      .select(selectAllActiveBusinessSegments)
      .pipe(take(1))
      .subscribe((data) => {
        this.businessSegment = data;
        this.createSegmentForm(this.businessSegment[0].businessSegmentId);
        this.store
          .select(
            selectSegmentPxDsFactory(this.businessSegment[0].businessSegmentId)
          )
          .pipe(take(1))
          .subscribe((pxddata) => {
            this.ctrList = deepClone(pxddata);
            this.ctrListCopy = deepClone(pxddata);
          });
      });
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      fromDate: ['', Validators.required],
      toDate: ['', [Validators.required]],
    });
  }

  createSegmentForm(id) {
    this.selectionForm = new UntypedFormGroup({});
    this.selectionForm = this.formBuilder.group({
      segmentId: [id],
    });
    this.handleFormChanges();
  }

  handleFormChanges() {
    this.selectionForm
      .get('segmentId')
      .valueChanges.pipe(
        switchMap((businessSegmentId: string) =>
          this.store.select(
            selectSegmentPxDsFactory(parseInt(businessSegmentId, 10))
          )
        )
      )
      .subscribe((pxddata) => {
        this.ctrList = deepClone(pxddata);
        this.ctrListCopy = deepClone(pxddata);
      });
  }

  selected(event): void {
    this.emptyPxd = false;
    this.pxdArr.push(event.option.viewValue);
    const index = this.ctrList.findIndex(
      (x) => x.shortName == event.option.viewValue
    );
    this.ctrIdArr.push({
      id: this.ctrList[index].id,
      name: this.ctrList[index].shortName,
    });
    this.pxdCtrl.setValue(null);
  }

  remove(item: string): void {
    const index = this.pxdArr.indexOf(item);
    const index1 = this.ctrIdArr.findIndex((x) => x.name == this.pxdArr[index]);
    if (index1 != -1) {
      this.ctrIdArr.splice(index, 1);
    }
    if (index >= 0) {
      this.pxdArr.splice(index, 1);
    }
  }

  fieldEdited(event) {
    const val = event.target.value;
    if (val != null && val.length > 0 && val.trim().length > 0) {
      this.ctrList = [];
      this.ctrListCopy.forEach((ctr) => {
        if (ctr.shortName.toLowerCase().startsWith(val.toLowerCase())) {
          this.ctrList.push(ctr);
        }
      });
    } else {
      this.ctrList = this.ctrListCopy;
    }
  }

  add(event): void {
    const input = event.input;
    const value = event.value;

    this.pxdArr.push(value.trim());

    if (input) {
      input.value = '';
    }
    this.pxdCtrl.setValue(null);
  }

  onDownload() {
    if (this.pxdArr.length == 0) {
      this.emptyPxd = true;
      return;
    }
    this.registerForm.markAllAsTouched();
    if (!this.registerForm.valid) {
      return;
    }
    const arr = [];
    this.ctrIdArr.forEach((ctr) => {
      arr.push(ctr.id);
    });
    this.sdkService
      .DownloadAddComplexManualAct(
        formToServerDateConversion(this.registerForm.get('fromDate')?.value),
        formToServerDateConversion(this.registerForm.get('toDate')?.value),
        arr,
        this.selectionForm.get('segmentId').value
      )
      .subscribe((data) => {
        if (!saveImportedDataAs(data)) {
          this.showError = true;
          this.sdkService.isLoaderVisible.next(false);
        }
      });
  }
}
