<div *ngIf="projectFormGroup" [formGroup]="projectFormGroup" class="row">
  <div class="col-12 col-md-7 col-xl-6">
    <mat-card appearance="outlined" class="form-card container">
      <mat-card-title>General information</mat-card-title>
      <mat-card-content>
        <div class="row">
          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label class="form-required" for="project-projectname-control"
              >Project Name</mat-label
            >
            <input
              autocomplete="off"
              formControlName="projectName"
              id="project-projectname-control"
              matInput
              maxlength="60"
              type="text"
            />
            <mat-error *ngIf="getErrors('projectName')?.required">
              Name is required
            </mat-error>
            <mat-error *ngIf="getErrors('projectName')?.maxlength">
              Project name length should not exceed
              {{ getErrors('projectName')?.maxlength.requiredLength }}
              characters
            </mat-error>
            <mat-error
              *ngIf="
                projectFormGroup?.value?.projectName?.length > 0 &&
                getErrors('projectName')?.whitespace
              "
            >
              Please enter a valid project name
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label for="project-mdm-number-control" class="form-required"
              >MDM number</mat-label
            >

            <input
              autocomplete="off"
              formControlName="projectMDMNumber"
              id="project-mdm-number-control"
              matInput
              maxlength="20"
              type="text"
            />

            <mat-error *ngIf="getErrors('projectMDMNumber')?.required">
              MDM number is required
            </mat-error>
            <mat-error *ngIf="getErrors('projectMDMNumber')?.maxlength">
              MDM number length should not exceed
              {{ getErrors('projectMDMNumber')?.maxlength.requiredLength }}
              characters
            </mat-error>
            <mat-error
              *ngIf="
                projectFormGroup?.value?.projectMDMNumber?.length > 0 &&
                getErrors('projectMDMNumber')?.whitespace
              "
            >
              Please enter a valid MDM Number
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label for="project-type-control" class="form-required"
              >Type</mat-label
            >
            <mat-select
              formControlName="projectTypeId"
              id="project-type-control"
            >
              <mat-option
                *ngFor="let projectType of activeProjectTypes$ | async"
                [value]="projectType.projectTypeId"
              >
                {{ projectType.projectTypeName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getErrors('projectTypeId')?.required">
              Project type is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label for="project-workpackage-control"
              >Work Package number</mat-label
            >
            <input
              id="project-workpackage-control"
              appCharacterCounter
              formControlName="workPackageNumber"
              matInput
              maxlength="18"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label for="project-client-id-control" class="form-required"
              >Client</mat-label
            >
            <mat-select
              formControlName="clientId"
              id="project-client-id-control"
            >
              <mat-option
                *ngFor="let client of activeClients$ | async"
                [value]="client.clientId"
              >
                {{ client.clientName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getErrors('clientId')?.required">
              Client is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label for="project-stage-control" class="form-required"
              >Stage CTRs are pricing for</mat-label
            >
            <mat-select
              formControlName="projectStageId"
              id="project-stage-control"
            >
              <mat-option
                *ngFor="let projectStage of activeProjectStages$ | async"
                [value]="projectStage.projectStageId"
              >
                {{ projectStage.projectStageName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getErrors('projectStageId')?.required">
              Project stage is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label for="project-executing-entity-control"
              >Executing entity</mat-label
            >
            <input
              autocomplete="off"
              formControlName="executingEntity"
              id="project-executing-entity-control"
              matInput
              type="text"
            />
            <mat-error
              *ngIf="
                getErrors('executingEntity') &&
                getErrors('executingEntity')?.required
              "
            >
              Executing entity is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label for="project-status-control">Status</mat-label>
            <input
              autocomplete="off"
              formControlName="projectStatus"
              id="project-status-control"
              matInput
              type="text"
            />
            <mat-error *ngIf="getErrors('projectStatus')?.required"
              >Project status is required
            </mat-error>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="col-12 col-sm-6 project-owner-field"
          >
            <mat-label for="project-owner-control">Project Owner</mat-label>
            <div style="display: flex">
              <input
                matInput
                autocomplete="off"
                formControlName="requestorName"
                id="project-owner-control"
                type="text"
              />
              <button
                mat-icon-button
                color="primary"
                matTooltipPosition="below"
                matTooltip="Change Project Owner in “Actions”"
              >
                <mat-icon>info_outline</mat-icon>
              </button>
            </div>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="form-card">
      <mat-card-title>Main dates</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline" class="date-field">
              <mat-label class="project-date form-required-control"
                >Bid due date</mat-label
              >
              <input
                [matDatepicker]="ctrpicker"
                autocomplete="off"
                formControlName="projectBidDate"
                matInput
                placeholder="DD MMM YYYY"
              />
              <mat-datepicker-toggle
                [for]="ctrpicker"
                matSuffix
              ></mat-datepicker-toggle>
              <mat-datepicker #ctrpicker></mat-datepicker>
              <mat-error *ngIf="getErrors('projectBidDate')?.required">
                Bid due date is required
              </mat-error>
              <mat-error
                *ngIf="getErrors('projectBidDate')?.dateError"
                class="invalid-feedback"
              >
                Bid date should be less than project end date.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6">
            <mat-form-field class="date-field" appearance="outline">
              <mat-label class="form-required">Award date</mat-label>
              <input
                [matDatepicker]="projectAwardDatePicker"
                autocomplete="off"
                formControlName="projectAwardDate"
                matInput
                placeholder="DD MMM YYYY"
              />
              <mat-datepicker-toggle
                [for]="projectAwardDatePicker"
                matSuffix
              ></mat-datepicker-toggle>
              <mat-datepicker #projectAwardDatePicker></mat-datepicker>
              <mat-error *ngIf="getErrors('projectAwardDate')?.required">
                Project award date is required
              </mat-error>
              <mat-error
                *ngIf="getErrors('projectAwardDate')?.dateError"
                class="invalid-feedback"
              >
                Project award date should be less than project end date.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6">
            <mat-form-field class="date-field" appearance="outline">
              <mat-label class="form-required">Start date</mat-label>
              <input
                [matDatepicker]="projectStartDatePicker"
                [max]="projectFormGroup.get('projectEndDate').value"
                autocomplete="off"
                formControlName="projectStartDate"
                id="projectStartDate"
                matInput
                placeholder="DD MMM YYYY"
              />
              <mat-datepicker-toggle
                [for]="projectStartDatePicker"
                matSuffix
              ></mat-datepicker-toggle>
              <mat-datepicker #projectStartDatePicker></mat-datepicker>
              <mat-error *ngIf="getErrors('projectStartDate')?.required">
                Start date is required
              </mat-error>
              <mat-error
                *ngIf="getErrors('projectStartDate')?.dateError"
                class="invalid-feedback"
              >
                Project end date cannot occur before project start date
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 column-space">
            <mat-form-field class="date-field" appearance="outline">
              <mat-label class="form-required">End date</mat-label>
              <input
                [matDatepicker]="projectEndDatePicker"
                [min]="projectFormGroup.get('projectStartDate').value"
                autocomplete="off"
                formControlName="projectEndDate"
                matInput
                placeholder="DD MMM YYYY"
              />
              <mat-datepicker-toggle
                [for]="projectEndDatePicker"
                matSuffix
              ></mat-datepicker-toggle>
              <mat-datepicker #projectEndDatePicker></mat-datepicker>
              <mat-error *ngIf="getErrors('projectEndDate')?.required">
                End date is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="duration" appearance="outline">
              <mat-label for="total-duration">Total duration</mat-label>
              <input
                autocomplete="off"
                formControlName="totalDuration"
                id="total-duration"
                matInput
                type="text"
              />
            </mat-form-field>
          </div>

          <mat-form-field
            appearance="outline"
            class="col-6 project-date-control"
          >
            <mat-label>Start date notes</mat-label>
            <textarea
              appCharacterCounter
              formControlName="projectStartDateNotes"
              matInput
              maxlength="800"
            ></textarea>
            <mat-error *ngIf="getErrors('projectStartDateNotes')?.maxlength">
              Project start date notes length should not exceed
              {{ getErrors('projectStartDateNotes')?.maxlength.requiredLength }}
              characters
            </mat-error>

            <mat-error
              *ngIf="getErrors('projectStartDate')?.matDatepickerMin"
              class="invalid-feedback"
            >
              Project start date should be greater than or equal to today's
              date.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-6 project-date">
            <mat-label>End date notes</mat-label>
            <textarea
              appCharacterCounter
              formControlName="projectEndDateNotes"
              matInput
              maxlength="800"
            ></textarea>
            <mat-error *ngIf="getErrors('projectEndDateNotes')?.maxlength">
              Project end date notes length should not exceed
              {{ getErrors('projectEndDateNotes')?.maxlength.requiredLength }}
              characters
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="form-card">
      <mat-card-title>Field information</mat-card-title>
      <mat-card-content>
        <div class="row">
          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label class="form-required">Field location</mat-label>
            <input
              autocomplete="off"
              formControlName="fieldLocation"
              matInput
              type="text"
            />
            <mat-error *ngIf="getErrors('fieldLocation')?.required">
              Field location is required
            </mat-error>
            <mat-error
              *ngIf="
                projectFormGroup?.value?.fieldLocation?.length > 0 &&
                getErrors('fieldLocation')?.whitespace
              "
            >
              Please enter a valid field location
            </mat-error>
            <mat-error *ngIf="getErrors('fieldLocation')?.maxlength">
              Field location length should not exceed
              {{ getErrors('fieldLocation')?.maxlength.requiredLength }}
              characters
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label class="form-required" for="project-water-depth-control"
              >Water depth, m</mat-label
            >
            <input
              autocomplete="off"
              formControlName="waterDepth"
              id="project-water-depth-control"
              matInput
              type="text"
            />
            <mat-error *ngIf="getErrors('waterDepth')?.pattern">
              Water Depth should be a positive number
            </mat-error>
            <mat-error *ngIf="getErrors('waterDepth')?.required">
              Water Depth is required
            </mat-error>
            <mat-error
              *ngIf="
                projectFormGroup?.value?.waterDepth?.length > 0 &&
                getErrors('waterDepth')?.whitespace
              "
            >
              Please enter a valid Water depth
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label>Field location notes</mat-label>
            <textarea
              appCharacterCounter
              formControlName="fieldLocationNotes"
              matInput
              maxlength="800"
            ></textarea>

            <mat-error *ngIf="getErrors('fieldLocationNotes')?.maxlength">
              Field location notes length should not exceed
              {{ getErrors('fieldLocationNotes')?.maxlength.requiredLength }}
              characters
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label>Water depth notes</mat-label>
            <textarea
              appCharacterCounter
              formControlName="waterDepthNotes"
              matInput
              maxlength="800"
            ></textarea>

            <mat-error *ngIf="getErrors('waterDepthNotes')?.maxlength">
              Water depth notes length should not exceed
              {{ getErrors('waterDepthNotes')?.maxlength.requiredLength }}
              characters
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label
              class="form-required"
              for="project-design-pressure-control"
              >Max design pressure, psi</mat-label
            >
            <input
              autocomplete="off"
              formControlName="maxDesignPressure"
              id="project-design-pressure-control"
              matInput
              type="text"
            />
            <mat-error *ngIf="getErrors('maxDesignPressure')?.required">
              Max design pressure is required
            </mat-error>
            <mat-error *ngIf="getErrors('maxDesignPressure')?.pattern">
              Max design pressure should be a positive number
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label
              class="form-required"
              for="project-max-design-temp-control"
              >Max design temperature, °C</mat-label
            >
            <input
              autocomplete="off"
              formControlName="maxDesignTemp"
              id="project-max-design-temp-control"
              matInput
              type="text"
            />
            <mat-error *ngIf="getErrors('maxDesignTemp')?.required">
              Max design temperature is required
            </mat-error>
            <mat-error *ngIf="getErrors('maxDesignTemp')?.pattern">
              Max design temperature should be a positive number
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label>Design pressure notes</mat-label>
            <textarea
              appCharacterCounter
              formControlName="designPressureNotes"
              matInput
              maxlength="800"
            ></textarea>

            <mat-error *ngIf="getErrors('designPressureNotes')?.maxlength">
              Design pressure notes length should not exceed
              {{ getErrors('designPressureNotes')?.maxlength.requiredLength }}
              characters
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label>Design temperature notes</mat-label>
            <textarea
              appCharacterCounter
              formControlName="designTempNotes"
              matInput
              maxlength="800"
            ></textarea>

            <mat-error *ngIf="getErrors('designTempNotes')?.maxlength">
              Design temperature notes length should not exceed
              {{ getErrors('designTempNotes')?.maxlength.requiredLength }}
              characters
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label
              class="form-required"
              for="project-pressure-elevation-control"
              >Pressure elevation</mat-label
            >
            <input
              autocomplete="off"
              formControlName="pressureElevation"
              id="project-pressure-elevation-control"
              matInput
              type="text"
              maxlength="50"
              [matTooltip]="
                'Reference elevation associated to maximum Internal Pressure'
              "
            />

            <mat-error *ngIf="getErrors('pressureElevation')?.maxLength">
              Max 50 characters
            </mat-error>
          </mat-form-field>

          <div style="width: 100%"></div>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label>Engineering phase duration, days</mat-label>
            <input
              [class.is-invalid]="submitted && getErrors('enggPhaseDuration')"
              autocomplete="off"
              formControlName="enggPhaseDuration"
              matInput
              type="text"
            />

            <mat-error *ngIf="getErrors('enggPhaseDuration')?.pattern">
              Engineering phase duration should be a positive number
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label>Operations phase duration, days</mat-label>
            <input
              [class.is-invalid]="submitted && getErrors('opPhaseDuration')"
              autocomplete="off"
              formControlName="opPhaseDuration"
              id="inputOperationPhaseDur"
              matInput
              type="text"
            />
            <mat-error *ngIf="getErrors('opPhaseDuration')?.pattern">
              Operations phase duration should be a positive number
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label>Engineering phase notes</mat-label>
            <textarea
              appCharacterCounter
              formControlName="enggPhaseDurationNotes"
              matInput
              maxlength="800"
            ></textarea>

            <mat-error *ngIf="getErrors('enggPhaseDurationNotes')?.maxlength">
              Engineering phase notes length should not exceed
              {{
                getErrors('enggPhaseDurationNotes')?.maxlength.requiredLength
              }}
              characters
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-sm-6">
            <mat-label>Operations phase notes</mat-label>
            <textarea
              appCharacterCounter
              formControlName="opPhaseDurationNotes"
              matInput
              maxlength="800"
            ></textarea>
            <mat-error *ngIf="getErrors('opPhaseDurationNotes')?.maxlength">
              Operations phase notes length should not exceed
              {{ getErrors('opPhaseDurationNotes')?.maxlength.requiredLength }}
              characters
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card
      appearance="outlined"
      *ngIf="currencies$ | async as currenciesList"
      class="form-card"
    >
      <mat-card-title>Currency rates</mat-card-title>
      <mat-card-content>
        <div class="currency__wrapper">
          <div class="nb-note">
            NB: these rates will be used for ICPO calculations.
          </div>

          <form *ngIf="currencyFormGroup" [formGroup]="currencyFormGroup">
            <table class="currency__table">
              <thead>
                <tr class="currency__row">
                  <th class="currency__header">Name</th>
                  <th class="currency__header">Main project currency</th>
                  <th class="currency__header number">Rate to main currency</th>
                </tr>
              </thead>
              <tbody class="currency__tbody">
                <ng-container
                  *ngFor="
                    let projectCurrencyConfigControl of currencyFormGroup.controls;
                    let currencyIndex = index
                  "
                  [formGroupName]="currencyIndex"
                >
                  <tr
                    *ngIf="
                      projectCurrencyConfigControl.getRawValue() as projectCurrencyConfig
                    "
                    [attr.data-test]="
                      'currency-index-' + currencyIndex + '-row'
                    "
                    [class.td-delete]="projectCurrencyConfig.isDeleted"
                    class="currency__row"
                  >
                    <td
                      class="currency__text fixed-value-label fixed"
                      [attr.data-test]="
                        'currency-index-' +
                        currencyIndex +
                        '-currency-code-label'
                      "
                    >
                      {{ projectCurrencyConfig.currencyCode }}
                    </td>
                    <td class="radio-align">
                      <mat-radio-group formControlName="isDefaultCurrency">
                        <mat-radio-button
                          [attr.data-test]="
                            'currency-index-' +
                            currencyIndex +
                            '-default-currency-check'
                          "
                          [matTooltipDisabled]="
                            !projectCurrencyConfigControl.controls
                              .isDefaultCurrency.disabled
                          "
                          [value]="true"
                          class="bold_header"
                          [matTooltip]="
                            notOwnEntity
                              ? 'This is not your entity you cannot edit default currency'
                              : 'Project already has completed CTRs, default currency cannot be changed'
                          "
                        ></mat-radio-button>
                      </mat-radio-group>
                    </td>
                    <td
                      class="currency__bg rate"
                      [class.fixed]="
                        projectCurrencyConfigControl.controls.fxRate.disabled
                      "
                    >
                      <ng-container
                        [ngSwitch]="projectCurrencyConfig.isDefaultCurrency"
                      >
                        <input
                          *ngSwitchCase="false"
                          (change)="
                            changeCurrencyConfiguration(
                              projectCurrencyConfigControl
                            )
                          "
                          [attr.data-test]="
                            'currency-index-' +
                            currencyIndex +
                            '-default-fx-rate-input'
                          "
                          class="currency__value currency__text currency__bg1 hidden-arrows"
                          formControlName="fxRate"
                          numbersInputGuardDirective
                          type="text"
                          [matTooltipDisabled]="!notOwnEntity"
                          matTooltip="This is not your entity you cannot edit project currency rates"
                        />
                        <span
                          *ngSwitchCase="true"
                          [attr.data-test]="
                            'currency-index-' +
                            currencyIndex +
                            '-default-fx-rate-label'
                          "
                          class="fixed-value-label rate-label"
                          >{{ projectCurrencyConfig.fxRate }}</span
                        >
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>

            <p class="currency__hint">
              PCM or Tender Manager have to confirm these rates.
            </p>

            <div class="error-wrapper">
              <p
                *ngIf="cannotMakeDefaultError"
                class="error__msg"
                data-test="add-currency-error-label"
              >
                You cannot make currency as main project currency until you save
                it.
              </p>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>

    <ul *ngIf="uniqueErrors.length > 0" class="error__list">
      <span
        >The following validation errors occurred while trying to save :</span
      >
      <li *ngFor="let err of uniqueErrors">
        <mat-error *ngIf="err === 'pattern'" class="custom_error">
          Currency should be positive value and should have maximum of 4 decimal
          points
        </mat-error>
      </li>
    </ul>

    <div class="button-container" *ngIf="projectFormGroup && currencyFormGroup">
      <button
        *ngIf="!submitted && !viewPage"
        (click)="onBack()"
        class="btn px-4 button-project buttonCancel cancel"
        data-test="project-edit-cancel"
        mat-stroked-button
        color="primary"
        type="button"
      >
        Cancel
      </button>

      <div class="buttons-right">
        <button
          *ngIf="!notOwnEntity"
          (click)="onSave()"
          [disabled]="isSavingProjectDisabled()"
          color="primary"
          data-test="project-edit-save"
          mat-raised-button
          type="button"
        >
          Save
        </button>

        <button
          *ngIf="!submitted && !viewPage"
          (click)="onSaveAndNext()"
          [disabled]="isSavingProjectDisabled()"
          color="primary"
          data-test="project-edit-save-and-next"
          mat-raised-button
          type="button"
        >
          Save & next
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-5 col-xl-6">
    <app-mdm-project-info
      title="MDM information"
      [mdmNumber]="projectFormGroup.get('projectMDMNumber').value"
    ></app-mdm-project-info>
  </div>
</div>
