import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, shareReplay, take } from 'rxjs/operators';

import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';

@Injectable()
export class UserIsEngineerGuard  {
  private currentInitializedUser$ = this.store
    .select(selectCurrentInitializedUser)
    .pipe(
      filter((v) => !!v),
      shareReplay(1)
    );

  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.currentInitializedUser$.pipe(
      take(1),
      map((user) => {
        if (user.isEngineer) {
          return true;
        }

        return this.router.parseUrl('/unauthorized');
      })
    );
  }
}
