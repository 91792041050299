import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApplicationsEffects } from './store/applications.effects';
import { FEATURE_KEY, reducer } from './store/applications.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([ApplicationsEffects]),
  ],
})
export class ApplicationsCollectionModule {}
