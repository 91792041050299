import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { IGetCurrenciesResponseItem } from '@models/currencies';

export const getCurrenciesAction = createAction(
  '[Collection Currencies] GET /currency',
  contextAwareProps()
);

export const getCurrenciesSuccessAction = createAction(
  '[Collection Currencies Effect] GET /currency SUCCESS',
  contextAwareProps<{ payload: IGetCurrenciesResponseItem[] }>()
);

export const getCurrenciesFailureAction = createAction(
  '[Collection Currencies Effect] GET /currency FAILURE',
  contextAwareProps()
);
