import { Component, Input, OnChanges } from '@angular/core';

import { CtrStatus } from '@common/enums';
import {
  IGetScenarioComparisonResponseItem,
  IGetScenarioComparisonScenarioCTRItem,
} from '@models/scenario-comparison';

@Component({
  selector: 'app-scenario-ctrs-summary',
  templateUrl: './scenario-ctrs-summary.component.html',
  styleUrls: ['./scenario-ctrs-summary.component.scss'],
})
export class ScenarioCtrsSummaryComponent implements OnChanges {
  @Input() ctrTypes: string[];
  @Input() firstColumn: boolean;
  @Input() projectId: number;
  @Input() scenario: IGetScenarioComparisonResponseItem;

  public ctrsByType: IGetScenarioComparisonScenarioCTRItem[];

  public ngOnChanges() {
    this.ctrsByType = this.getScenarioCtrsByType();
  }

  public isCompleted(ctrStatus: CtrStatus) {
    return ctrStatus === CtrStatus.COMPLETED;
  }

  public getTotalGecUtilization(ctr: IGetScenarioComparisonScenarioCTRItem) {
    return ctr.totalGECUtilization.toString();
  }

  private getScenarioCtrsByType(): IGetScenarioComparisonScenarioCTRItem[] {
    const ctrs = [];
    this.ctrTypes.forEach((type) => {
      ctrs.push(this.getCtrPerType(type));
    });
    return ctrs;
  }

  private getCtrPerType(type: string): IGetScenarioComparisonScenarioCTRItem {
    return (
      this.scenario?.scenarioCTRs?.find((x) => x.ctrShortName === type) || null
    );
  }
}
