import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MdmEffects } from './store/mdm.effects';
import { FEATURE_KEY, reducer } from './store/mdm.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([ MdmEffects ]),
  ],
})
export class MdmCollectionModule { }
