import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { MediatorService } from '@app/mediator.service';
import { IMediatorRequest } from '@app/models/backendModel';
import { LiveSaveSnackbarService } from './snackbar/live-save-snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class LiveSavingInterceptorService {
  constructor(
    private http: HttpClient,
    private mediator: MediatorService,
    private liveSaveSnackbarService: LiveSaveSnackbarService
  ) {}

  send<TRequest extends IMediatorRequest, TResponse = void>(
    requestName: string,
    requestBody: TRequest
  ): Observable<TResponse> {
    this.liveSaveSnackbarService.open();
    return this.handleLiveSaveSnackbar(
      this.mediator.send<TRequest, TResponse>(requestName, requestBody)
    );
  }
  public put<T = any>(url: string, body: any, options?: any) {
    this.liveSaveSnackbarService.open();
    return this.handleLiveSaveSnackbar(this.http.put<T>(url, body, options));
  }

  public post<T = any>(url: string, body: any, options?: any) {
    this.liveSaveSnackbarService.open();
    return this.handleLiveSaveSnackbar(this.http.post<T>(url, body, options));
  }

  public get<T = any>(url: string, body: any, options?: any) {
    this.liveSaveSnackbarService.open();
    return this.handleLiveSaveSnackbar(this.http.get<T>(url, options));
  }

  public delete<T = any>(url: string, options?: any) {
    this.liveSaveSnackbarService.open();
    return this.handleLiveSaveSnackbar(this.http.delete<T>(url, options));
  }

  private handleLiveSaveSnackbar<T>(call: Observable<T>) {
    return call.pipe(
      tap(() => this.liveSaveSnackbarService.close(true)),
      catchError((error) => {
        this.liveSaveSnackbarService.close(false);
        return EMPTY;
      })
    );
  }
}
