import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetClientsResponse } from '@app/models/backendModel';

@Injectable({ providedIn: 'root' })
export class ClientsApiService {
  constructor(private httpClient: HttpClient) {}

  public getClients() {
    return this.httpClient.get<GetClientsResponse[]>('{apiUrl}clients');
  }
}
