<h1 mat-dialog-title>{{ actionName }} discipline</h1>

<div [formGroup]="formGroup" class="container" mat-dialog-content>
  <mat-slide-toggle class="active-toggle" formControlName="isActive">
    <span class="toggle-text">Active</span>
  </mat-slide-toggle>
  <div class="row">
    <mat-form-field appearance="outline" class="field col-12">
      <mat-label>Long name</mat-label>
      <input
        appCharacterCounter
        formControlName="disciplineName"
        matInput
        maxlength="50"
      />
      <mat-error
        *ngIf="formGroup.controls.disciplineName.hasError('duplicateName')"
      >
        This name is already used. Provide a different one.
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="outline" class="field col-12">
      <mat-label>Short name</mat-label>
      <input
        appCharacterCounter
        formControlName="disciplineShortName"
        matInput
        maxlength="20"
      />
      <mat-error
        *ngIf="formGroup.controls.disciplineShortName.hasError('duplicateName')"
      >
        This short name is already used. Provide a different one.
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="outline" class="col-4 wbs-code-field">
      <mat-label>WBS Code</mat-label>
      <input
        formControlName="code"
        matInput
        maxlength="3"
        numbersInputGuardDirective
      />
    </mat-form-field>
    <div *ngIf="data.discipline.id" class="wbs-warning">
      Warning: Changing WBS number will affect all existing CTRs. It may break
      connection to actual hours reported in SAP, that are connected to the old
      WBS number.
    </div>
  </div>
  <mat-slide-toggle
    class="global-entity-toggle col-12"
    formControlName="isEntityGlobal"
  >
    <span class="toggle-text">Global discipline</span>
  </mat-slide-toggle>
  <div class="row">
    <div class="col-12 global-entity-section">
      <div class="adnotation">
        When ON, CTRs from all scenarios will be automatically redirected to the
        selected entity (regardless of the executing entity of the scenario).
      </div>
      <mat-form-field
        *ngIf="formGroup.getRawValue().isEntityGlobal"
        appearance="outline"
        class="entity-select"
      >
        <mat-label>Choose entity</mat-label>
        <mat-select formControlName="globalEntityId">
          <mat-option
            *ngFor="let entity of entities$ | async"
            [value]="entity.entityId"
            >{{ entity.enggCenter }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div
      matTooltip="There is at least one CTR that has been already started for one of this discipline's PxDs"
      [matTooltipDisabled]="data.discipline.deletable"
      [matTooltipPosition]="'above'"
      *ngIf="data.discipline.id"
      class="delete-button-section"
    >
      <button
        mat-button
        appearance="flat"
        [disabled]="!data.discipline.deletable"
        [mat-dialog-close]="{ deleted: true }"
        class="delete-button"
      >
        Delete discipline
      </button>
    </div>
  </div>
</div>
<div mat-dialog-actions [align]="'end'">
  <button [mat-dialog-close]="false" color="primary" mat-button>Cancel</button>
  <button
    [disabled]="!formGroup.valid || !(valueChanged$ | async)"
    [mat-dialog-close]="formGroup.getRawValue()"
    color="primary"
    mat-button
  >
    Save
  </button>
</div>
