<div class="export-wrapper">
  <h2 class="content-head">
    Export manual complexities, additional activities, assumptions and
    exclusions
  </h2>
  <div
    class="segment-radio top__segement__margin"
    *ngIf="businessSegment != null"
  >
    <form [formGroup]="selectionForm">
      <span>Segment:</span>
      <mat-radio-group
        aria-label="Select an option"
        formControlName="segmentId"
      >
        <mat-radio-button
          [value]="segment.businessSegmentId"
          [checked]="
            segment.businessSegmentId == selectionForm?.get('segmentId').value
          "
          *ngFor="let segment of businessSegment; let k = index"
          >{{ segment.businessSegmentName }}</mat-radio-button
        >
      </mat-radio-group>
    </form>
  </div>
  <mat-form-field class="example-chip-list mat-chip-field">
    <mat-chip-grid #chipList2>
      <mat-chip-row
        *ngFor="let ctr of pxdArr"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(ctr)"
      >
        {{ ctr }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip-row>
      <input
        matInput
        placeholder="Filter by PxD"
        [matAutocomplete]="auto2"
        [matChipInputFor]="chipList2"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        (input)="fieldEdited($event)"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #auto2="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option *ngFor="let ctr of ctrList" [value]="ctr.shortName">
        {{ ctr.shortName }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="div__flex" [formGroup]="registerForm">
    <div>
      <mat-form-field
        class="inputroot__child right__margin"
        appearance="outline"
      >
        <mat-label class="form-required">From</mat-label>
        <input
          matInput
          [matDatepicker]="ctrpicker"
          autocomplete="off"
          placeholder="YYYY-MM-DD"
          formControlName="fromDate"
          [max]="maxDateVal"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="ctrpicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #ctrpicker></mat-datepicker>
        <mat-error *ngIf="registerForm.controls.fromDate.errors?.required"
          >From date is required
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="inputroot__child" appearance="outline">
        <mat-label class="form-required">To</mat-label>
        <input
          matInput
          [matDatepicker]="ctrpicker1"
          autocomplete="off"
          placeholder="YYYY-MM-DD"
          formControlName="toDate"
          [max]="maxDateVal"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="ctrpicker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #ctrpicker1></mat-datepicker>
        <mat-error *ngIf="registerForm.controls.toDate.errors?.required"
          >To date is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="emptyPxd" class="error">
    <p>You should export select a pxd to download data.</p>
  </div>
  <div *ngIf="showError" class="error">
    <p>
      No data exists for the selected date range. Please choose a different date
      range.
    </p>
  </div>
  <div class="button__download">
    <button
      (click)="onDownload()"
      class="downloadManual"
      color="primary"
      mat-button
    >
      Download
    </button>
  </div>
</div>
