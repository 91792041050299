<div
  class="readonly"
  [class.text]="value?.length > 40"
  *ngIf="disabled; else input"
>
  <label *ngIf="label">{{label}}</label>
  {{showZero ? value : (value | hideZero)}}
</div>

<ng-template #input>
  <input
    (input)="onInput($event.target.value)"
    (change)="onChange($event.target.value)"
    (focus)="onFocus($event)"
    [attr.data-test]="controlId"
    [attr.maxlength]="maxLength || null"
    [disabled]="disabled"
    [id]="controlId"
    [integer]="integer"
    [maxDigitsCount]="maxDigitsCount"
    [ngClass]="additionalInputClasses"
    [numberLimitationOffSwitch]="type === 'text'"
    [placeholder]="placeholder"
    [value]="showZero ? value : (value | hideZero)"
    #input
    autocomplete="off"
    numbersInputGuardDirective
    type="text"
  >
  <label
    [attr.for]="controlId"
    [class.shifted]="input.value"
    *ngIf="label && (input.value || !input.disabled)"
  >{{label}}</label>
  <span
    *ngIf="suffix"
    class="suffix"
  >{{suffix}}</span>
  <span
    [class.hide-on-blur]="counterForFocusedOnly"
    [class.max-length-exceeded]="input.value.length >= maxLength"
    *ngIf="maxLength && input.value.length >= counterMinimumValue"
    class="letter-counter"
  >
    {{input.value.length}}/{{maxLength}}
  </span>
  <div
    *ngIf="error"
    class="error-bar"
  ></div>
</ng-template>