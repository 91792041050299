import { Component, Input } from '@angular/core';

const IMAGE_SERVICE_BASE_URL = `https://ad-image-store-service.${ window.location.hostname.split('.').slice(1).join('.') }`;

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: [ './user-avatar.component.scss' ]
})
export class UserAvatarComponent {
  public avatarUrl: string = null;

  @Input() public set avatar(email: string) {
    this.avatarUrl = `${ IMAGE_SERVICE_BASE_URL }/userimage/${ email }.jpg`
  }

  @Input() public signature: string;
}
