import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { EventLogContext, IGetEventLogResponseItem } from '@models/event-log';

export const getEventLogAction = createAction(
  '[Collection EventLog] GET /:context/:id/log',
  contextAwareProps<{ payload: { context: EventLogContext; id: number } }>()
);

export const getEventLogSuccessAction = createAction(
  '[Collection EventLog Effect] GET /:context/:id/log SUCCESS',
  contextAwareProps<{ payload: { context: EventLogContext; response: IGetEventLogResponseItem[] } }>()
);

export const getEventLogFailureAction = createAction(
  '[Collection EventLog Effect] GET /:context/:id/log FAILURE',
  contextAwareProps<{ payload: { context: EventLogContext, } }>()
);

export const postCommentAction = createAction(
  '[Collection EventLog] POST /:context/:id/message',
  contextAwareProps<{ payload: { context: EventLogContext; id: number; message: string; projectId?: number; scenarioId?: number; scenarioCTRId?: number; parentId?: number; } }>()
);

export const postCommentSuccessAction = createAction(
  '[Collection EventLog Effect] POST /:context/:id/message SUCCESS',
  contextAwareProps<{ payload: { context: EventLogContext; id: number; } }>()
);

export const postCommentFailureAction = createAction(
  '[Collection EventLog Effect] POST /:context/:id/message FAILURE',
  contextAwareProps()
);
