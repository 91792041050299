import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, defer } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  tap,
} from 'rxjs/operators';

import { selectSidenavScenarios } from '@app/layout/sidenav/store/sidenav.selectors';

import { CommonModule, DecimalPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { CalendarizationTableCTRsComponent } from '@app/calendarization/calendarization-table-ctrs/calendarization-table-ctrs.component';
import { ScenarioStatusIconComponent } from '@app/common/scenario-status-icon/scenario-status-icon.component';
import { IScenarioGeneralInfo } from '@app/models/project';

@Component({
  selector: 'app-calendarization',
  templateUrl: './project-calendarization.component.html',
  styleUrls: ['./project-calendarization.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    ScenarioStatusIconComponent,
    CalendarizationTableCTRsComponent,
  ],
  providers: [DecimalPipe],
})
export class ProjectCalendarizationComponent implements OnDestroy {
  constructor(private store: Store, private formBuilder: FormBuilder) {}

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  private componentDestroy$ = new Subject<void>();

  public editModeEnabled = false;

  public scenarioIdFormControl = this.formBuilder.control<number>(null);
  public calendarizationFilterFormGroup = this.formBuilder.group({
    scenarioId: this.scenarioIdFormControl,
  });

  public scenarios$ = this.store.select(selectSidenavScenarios).pipe(
    tap((scenarios) => {
      if (scenarios?.length > 0) {
        this.calendarizationFilterFormGroup.patchValue({
          scenarioId: scenarios[0]?.scenarioId,
        });
      }
    }),
    shareReplay(1)
  );

  public scenarioId$ = this.createFormInputValue('scenarioId').pipe(
    map((value) => parseInt(value, 10))
  );

  private createFormInputValue(formControlName: string): Observable<any> {
    return defer(() =>
      this.calendarizationFilterFormGroup
        .get(formControlName)
        .valueChanges.pipe(
          startWith(
            this.calendarizationFilterFormGroup.get(formControlName).value
          ),
          distinctUntilChanged()
        )
    ).pipe(shareReplay(1));
  }
  public findSelectedScenario(
    scenarios: IScenarioGeneralInfo[]
  ): IScenarioGeneralInfo {
    const scenarioId = this.scenarioIdFormControl.getRawValue();
    return scenarios.find((scenario) => scenario.scenarioId === scenarioId);
  }
}
