import { NgModule } from '@angular/core';
import { DeliverablesCollectionModule } from '@collections/deliverables/deliverables-collection.module';
import { ApplicationsCollectionModule } from './applications/applications-collection.module';
import { AssumptionsAndExclusionsCollectionModule } from './assumptions-and-exclusions/assumptions-and-exclusions-collection.module';
import { BusinessSegmentsCollectionModule } from './business-segments/business-segments-collection.module';
import { ClientsCollectionModule } from './clients/clients-collection.module';
import { CurrenciesCollectionModule } from './currencies/currencies-collection.module';
import { EntitiesCollectionModule } from './entities/entities-collection.module';
import { EquipAssignsCollectionModule } from './equip-assigns/equip-assigns-collection.module';
import { EventLogCollectionModule } from './event-log/event-log-collection.module';
import { FiltersCollectionModule } from './filters/filters-collection.module';
import { MdmCollectionModule } from './mdm/mdm-collection.module';
import { ProjectStagesCollectionModule } from './project-stages/project-stages-collection.module';
import { ProjectStatusesCollectionModule } from './project-statuses/project-statuses-collection.module';
import { ProjectTypesCollectionModule } from './project-types/project-types-collection.module';
import { ProjectsNamesCollectionModule } from './projects-names/projects-names-collection.module';
import { ProjectsCollectionModule } from './projects/projects-collection.module';
import { PxDsCollectionModule } from './pxds/pxds-collection.module';
import { ScenariosSummaryCTRsCollectionModule } from './scenario-summary-ctrs/scenario-summary-ctrs-collection.module';
import { ScenariosCollectionModule } from './scenarios/scenarios-collection.module';
import { UsersCollectionModule } from './users/users-collection.module';

@NgModule({
  imports: [
    ApplicationsCollectionModule,
    AssumptionsAndExclusionsCollectionModule,
    BusinessSegmentsCollectionModule,
    ClientsCollectionModule,
    CurrenciesCollectionModule,
    DeliverablesCollectionModule,
    EntitiesCollectionModule,
    EquipAssignsCollectionModule,
    EventLogCollectionModule,
    FiltersCollectionModule,
    MdmCollectionModule,
    ProjectsCollectionModule,
    ProjectsNamesCollectionModule,
    ProjectStagesCollectionModule,
    ProjectStatusesCollectionModule,
    ProjectTypesCollectionModule,
    PxDsCollectionModule,
    ScenariosCollectionModule,
    ScenariosSummaryCTRsCollectionModule,
    UsersCollectionModule,
  ],
})
export class CollectionsModule {}
