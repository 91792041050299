import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { updateCurrentlyViewedProjectAction } from '@collections/projects/store/projects.actions';
import { RouteDataService } from '@core/route-data.service';

import { ScenariosApiService } from '../scenarios-api.service';

import {
  getScenarioAction,
  getScenarioFailureAction,
  getScenarioSuccessAction,
  putScenarioStatusAction,
  putScenarioStatusFailureAction,
  putScenarioStatusSuccessAction,
} from './scenarios.actions';

@Injectable()
export class ScenariosEffects {
  public getScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getScenarioAction),
      switchMap((action) =>
        this.scenariosApiService.getScenario(action.payload.scenarioId).pipe(
          map((payload) =>
            getScenarioSuccessAction({
              trigger: action,
              context: 'ScenariosEffects::getScenario$',
              payload,
            })
          ),
          catchError(() =>
            of(
              getScenarioFailureAction({
                trigger: action,
                context: 'ScenariosEffects::getScenario$',
              })
            )
          )
        )
      )
    )
  );

  public putScenarioStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putScenarioStatusAction),
      switchMap((action) =>
        this.scenariosApiService
          .putScenarioStatus(
            action.payload.scenarioId,
            action.payload.statusChange
          )
          .pipe(
            map((payload) =>
              putScenarioStatusSuccessAction({
                trigger: action,
                context: 'ScenariosEffects::putScenarioStatus$',
                payload,
              })
            ),
            catchError(() =>
              of(
                putScenarioStatusFailureAction({
                  trigger: action,
                  context: 'ScenariosEffects::putScenarioStatus$',
                })
              )
            )
          )
      )
    )
  );

  public triggerScenarioUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putScenarioStatusSuccessAction, putScenarioStatusFailureAction),
      map((action) =>
        updateCurrentlyViewedProjectAction({
          context: 'ProjectsEffects::triggerScenarioUpdate$',
          trigger: action,
        })
      )
    )
  );

  public updateCurrentViewedScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCurrentlyViewedProjectAction),
      switchMap((action) =>
        this.routeDataService.activatedRoute$.pipe(
          map(({ params: { scenarioId } }) => scenarioId),
          filter((v) => !!v),
          map((scenarioId) =>
            getScenarioAction({
              context: 'ProjectsEffects::updateCurrentViewedProject$',
              trigger: action,
              payload: { scenarioId },
            })
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private scenariosApiService: ScenariosApiService,
    private routeDataService: RouteDataService
  ) {}
}
