import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';

import { ErrorPopUpComponent } from './error-popup.component';

const components = [ErrorPopUpComponent];

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: components,
  exports: components,
})
export class ErrorPopupModule {}
