import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ScenarioChangeStatusRequest } from '@common/enums';
import { IGetScenarioResponse } from '@models/scenario';

@Injectable({ providedIn: 'root' })
export class ScenariosApiService {
  constructor(private httpClient: HttpClient) {}

  public getScenario(scenarioId: number) {
    return this.httpClient.get<IGetScenarioResponse>(
      `{apiUrl}scenarios/${scenarioId}`
    );
  }

  public putScenarioStatus(
    scenarioId: number,
    statusChange: ScenarioChangeStatusRequest
  ) {
    return this.httpClient.put(
      `{apiUrl}scenarios/${scenarioId}/scenarioStatus`,
      statusChange
    );
  }
}
