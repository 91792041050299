import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ChildActivationEnd,
  NavigationEnd,
  Router,
} from '@angular/router';
import { buffer, filter, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteDataService {
  private routeEndEvent$ = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd)
  );

  public routeData$ = this.router.events.pipe(
    filter((e) => e instanceof ChildActivationEnd),
    buffer(this.routeEndEvent$),
    map((events) =>
      events.map((event: ChildActivationEnd) => event.snapshot.firstChild.data)
    ),
    shareReplay(1)
  );

  public accumulatedRouteData$ = this.routeData$.pipe(
    map((data) => data.reduce((r, v) => ({ ...v, ...r }), {})),
    shareReplay(1)
  );

  public activatedRoute$ = this.routeEndEvent$.pipe(
    map(() => this.getActiveRoute(this.router.routerState.root).snapshot),
    shareReplay(1)
  );

  constructor(private router: Router) {
    // catch route initialization
    this.routeData$.subscribe();
  }

  private getActiveRoute(route: ActivatedRoute): ActivatedRoute {
    if (route === null) {
      return null;
    }

    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
