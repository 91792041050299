import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { IPagedResponse } from '@models/pagination';
import {
  IGetSearchProjectsResponse,
  IPutProjectsResponse,
} from '@models/project';
import { ProjectFormData } from '@models/project-form-data';
import { IProjectSearchParams } from '@models/projects';

import { updateCurrentlyViewedProjectAction } from './store/projects.actions';
import { Observable } from 'rxjs';
import { ProjectWithDetailsDto } from '@app/models/backendModel';

@Injectable({ providedIn: 'root' })
export class ProjectsApiService {
  constructor(private httpClient: HttpClient, private store: Store) {}

  public getRequestorProjects(
    searchParams: IProjectSearchParams
  ): Observable<IPagedResponse<IGetSearchProjectsResponse>> {
    const params = this.removeEmptyParams(searchParams);
    return this.httpClient.get<IPagedResponse<IGetSearchProjectsResponse>>(
      '{apiUrl}projects/requestor',
      {
        params,
      }
    );
  }

  public getEngineerProjects(
    searchParams: IProjectSearchParams
  ): Observable<IPagedResponse<IGetSearchProjectsResponse>> {
    const params = this.removeEmptyParams(searchParams);
    return this.httpClient.get<IPagedResponse<IGetSearchProjectsResponse>>(
      '{apiUrl}projects/engineer',
      {
        params,
      }
    );
  }

  public getProject(projectId: number): Observable<ProjectWithDetailsDto> {
    return this.httpClient.get<ProjectWithDetailsDto>(
      `{apiUrl}projects/${projectId}`
    );
  }

  private removeEmptyParams(searchParams: IProjectSearchParams): HttpParams {
    let params = new HttpParams();
    Object.keys(searchParams).forEach(
      (key) =>
        searchParams[key] && (params = params.append(key, searchParams[key]))
    );
    return params;
  }

  public addProject(projectData: ProjectFormData): Observable<number> {
    return this.httpClient.post<number>(`{apiUrl}projects`, projectData).pipe(
      // TODO: convert to effect
      tap(() =>
        this.store.dispatch(
          updateCurrentlyViewedProjectAction({
            context: 'SdkCallService:addProject',
          })
        )
      )
    );
  }

  public putProject(
    projectData: ProjectFormData
  ): Observable<IPutProjectsResponse> {
    return this.httpClient
      .put<IPutProjectsResponse>(`{apiUrl}projects`, projectData)
      .pipe(
        // TODO: convert to effect
        tap(() =>
          this.store.dispatch(
            updateCurrentlyViewedProjectAction({
              context: 'SdkCallService:putProject',
            })
          )
        )
      );
  }
}
