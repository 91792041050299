import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentScrollService {
  private contentOffsetTopSubject$ = new BehaviorSubject<number>(0);

  public contentOffsetTop$ = this.contentOffsetTopSubject$.asObservable();

  public updateContentOffsetTop(offsetTop: number) {
    this.contentOffsetTopSubject$.next(offsetTop);
  }
}
