import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IMdmProjectResponse } from '../mdm-api.service';
import {
  getMdmProjectInfoAction,
  getMdmProjectInfoFailureAction,
  getMdmProjectInfoSuccessAction
} from './mdm.actions';

export const FEATURE_KEY = 'mdm';

export interface IMdmCollectionState
  extends EntityState<IMdmProjectResponse> {
  loaded: boolean;
}

export const mdmEntityAdapter = createEntityAdapter<
  IMdmProjectResponse
>({
  selectId: ({ mdmNumber }) => mdmNumber,
});

export const initialState = mdmEntityAdapter.getInitialState({
  loaded: false,
} as IMdmCollectionState);

export const reducer = createReducer(
  initialState,
  on(getMdmProjectInfoAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getMdmProjectInfoSuccessAction, (state, { payload }) =>
    mdmEntityAdapter.addOne(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getMdmProjectInfoFailureAction, (state) => ({
    ...state,
    loaded: true,
  })),
)
