import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { IGetProjectStagesResponseItem } from '@models/project-stages';

export const getProjectStagesAction = createAction(
  '[Collection ProjectStages] GET /projectStages',
  contextAwareProps()
);

export const getProjectStagesSuccessAction = createAction(
  '[Collection ProjectStages Effect] GET /projectStages SUCCESS',
  contextAwareProps<{ payload: IGetProjectStagesResponseItem[] }>()
);

export const getProjectStagesFailureAction = createAction(
  '[Collection ProjectStages Effect] GET /projectStages FAILURE',
  contextAwareProps()
);
