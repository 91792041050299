<div
  *ngIf="projectId"
  data-test="breadcrumb-project-label"
><span class="label">Project:</span> <span class="value">{{ projectName$ | async }}</span></div>
<div
  *ngIf="scenarioId"
  data-test="breadcrumb-scenario-label"
><span class="label">Scenario:</span> <span class="value">{{ scenarioName$ | async }}</span></div>
<div
  *ngIf="ctrId"
  data-test="breadcrumb-ctr-label"
><span class="label">CTR:</span> <span class="value">{{ ctrName$ | async }}</span></div>
