import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RolesSortDirection } from '@app/common/enums';
import { MaterialModule } from '@app/shared/material.module';
import { BehaviorSubject } from 'rxjs';
import { RolesSortConfiguration } from '../calendarization-table-roles.component';

@Component({
  selector: 'app-roles-sort-button',
  templateUrl: './roles-sort-button.component.html',
  styleUrls: ['./roles-sort-button.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class RolesSortButtonComponent {
  @Input() sortConfiguration$: BehaviorSubject<RolesSortConfiguration>;
  @Input() columnName: string;
  @Output() sortConfigurationChanged =
    new EventEmitter<RolesSortConfiguration>();

  public isSortIconVisible(iconName: string): boolean {
    const sortConfig = this.sortConfiguration$.value;

    switch (this.columnName) {
      case 'entity':
        if (iconName === 'swap_vert') {
          return sortConfig.byEntity === RolesSortDirection.NO_SORT;
        } else if (iconName === 'arrow_downward') {
          return sortConfig.byEntity === RolesSortDirection.DESC;
        } else if (iconName === 'arrow_upward') {
          return sortConfig.byEntity === RolesSortDirection.ASC;
        }
        break;
      case 'roleType':
        if (iconName === 'swap_vert') {
          return sortConfig.byRoleType === RolesSortDirection.NO_SORT;
        } else if (iconName === 'arrow_downward') {
          return sortConfig.byRoleType === RolesSortDirection.DESC;
        } else if (iconName === 'arrow_upward') {
          return sortConfig.byRoleType === RolesSortDirection.ASC;
        }
        break;
      case 'roleName':
        if (iconName === 'swap_vert') {
          return sortConfig.byRoleName === RolesSortDirection.NO_SORT;
        } else if (iconName === 'arrow_downward') {
          return sortConfig.byRoleName === RolesSortDirection.DESC;
        } else if (iconName === 'arrow_upward') {
          return sortConfig.byRoleName === RolesSortDirection.ASC;
        }
        break;
    }

    return false;
  }

  public applySorting(): void {
    const currentConfig = this.sortConfiguration$.value;
    switch (this.columnName) {
      case 'entity':
        currentConfig.byEntity =
          currentConfig.byEntity === RolesSortDirection.ASC
            ? RolesSortDirection.DESC
            : RolesSortDirection.ASC;
        currentConfig.byRoleType = RolesSortDirection.NO_SORT;
        currentConfig.byRoleName = RolesSortDirection.NO_SORT;
        break;
      case 'roleType':
        currentConfig.byRoleType =
          currentConfig.byRoleType === RolesSortDirection.ASC
            ? RolesSortDirection.DESC
            : RolesSortDirection.ASC;
        currentConfig.byEntity = RolesSortDirection.NO_SORT;
        currentConfig.byRoleName = RolesSortDirection.NO_SORT;
        break;
      case 'roleName':
        currentConfig.byRoleName =
          currentConfig.byRoleName === RolesSortDirection.ASC
            ? RolesSortDirection.DESC
            : RolesSortDirection.ASC;
        currentConfig.byEntity = RolesSortDirection.NO_SORT;
        currentConfig.byRoleType = RolesSortDirection.NO_SORT;
        break;
    }

    this.sortConfiguration$.next(currentConfig);
    this.sortConfigurationChanged.emit(currentConfig);
  }
}
