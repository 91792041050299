// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {
  devFeatureFlags,
  sharedEnvironmentConfiguration,
} from './shared-environment-configuration';
import { theme } from './theme';

export const environment = {
  ...sharedEnvironmentConfiguration,
  production: true,
  apiUrl: 'https://ectr-api.dev.services.technipfmc.com/api/',
  socketUrl: 'https://ectr-api.dev.services.technipfmc.com/api/ectrhub',
  gTag: {
    Key: 'G-3BHFPH1ZWZ',
    EnableDebugMode: true,
  },
  ...devFeatureFlags,
  ...theme,
};
