<div class="scope-header">
  <div class="cell">
    <div class="label name">Scope name</div>
    <button
      (click)="openEditScope()"
      [disabled]="tableService.readOnly$ | async"
      [disableRipple]="true"
      class="scope-name"
      data-test="scope-name-label"
      color="primary"
      mat-button
    >
      <span class="order-number">{{ data.order }}. </span
      >{{ data.scopeDisplayName }}
    </button>
  </div>
  <div class="cell code">
    <div class="label">Code</div>
    <div class="value" data-test="scope-code-label">{{ data.code }}</div>
  </div>
  <div class="cell active">
    <div class="label">Active</div>
    <div class="value">
      <mat-icon
        class="material-icons-outlined"
        data-test="scope-is-active-label"
        [class.active]="data.isActive"
        [class.inactive]="!data.isActive"
        >{{ data.isActive ? 'done' : 'cancel_outlined' }}</mat-icon
      >
    </div>
  </div>
  <div class="cell calc-level">
    <div class="label">Calc level</div>
    <div class="value" data-test="scope-calc-level-label">
      {{ data.calcLevel }}
    </div>
  </div>
  <div class="cell unit">
    <div class="label">Unit</div>
    <div class="value" data-test="scope-unit-type-label">
      {{ data.qtyType }}
    </div>
  </div>
  <div class="cell qty-value">
    <div class="label">QTY Value</div>
    <div class="value" data-test="scope-qty-value-label">
      {{ mapQtyValueLabelMap[data.qtyValue] }}
    </div>
  </div>
  <div class="cell cpx-factor">
    <div class="label">CPX Factor</div>
    <div class="value" data-test="scope-cpx-factor-label">
      {{ data.cpxFactor !== 1 ? data.cpxFactor : '' }}
    </div>
  </div>
  <div class="cell apply-client-cpx-factor">
    <div class="label">Apply client CPX factor</div>
    <div class="value" data-test="scope-client-cpx-factor-label">
      {{ data.applyClientCpxFactor ? 'Yes' : '' }}
    </div>
  </div>
  <div class="cell deliverable">
    <div class="label">Deliverable</div>
    <div class="value" data-test="scope-is-deliverable-label">
      {{ data.isDeliverable ? 'Yes' : '' }}
    </div>
  </div>
  <div class="cell entity">
    <div class="label">Entity</div>
    <div class="value" data-test="scope-entity-label">{{ data.entity }}</div>
  </div>
  <div class="cell description">
    <div class="label">Description</div>
    <div class="value" data-test="scope-description-label">
      {{ data.description }}
    </div>
  </div>
  <div class="cell update">
    <div class="label">Updated</div>
    <div class="value" data-test="scope-updated-date-label">
      {{ data.updated | date }}
    </div>
  </div>

  <div class="cell actions">
    <div class="label"></div>
    <div
      class="disabled-button-tooltip-trigger"
      matTooltip="Scope is used in CTR's and cannot be removed."
      [matTooltipDisabled]="
        (tableService.readOnly$ | async) === true || !data.isUsed
      "
    >
      <button
        class="delete small"
        mat-button
        [disabled]="(tableService.readOnly$ | async) === true || data.isUsed"
        (click)="removeScopes()"
        data-test="scope-delete-button"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <div class="disabled-button-tooltip-trigger">
      <button
        class="copy small"
        mat-button
        [disabled]="tableService.readOnly$ | async"
        (click)="openCopyScope()"
        data-test="admin-activities-copy-scope-button"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>

    <div
      class="disabled-button-tooltip-trigger"
      matTooltip="Not enough activities under this block to reorder them"
      [matTooltipDisabled]="(activities$ | async).length > 1"
    >
      <button
        class="order-activities small"
        color="primary"
        mat-stroked-button
        [disabled]="
          (tableService.readOnly$ | async) || (activities$ | async).length < 2
        "
        (click)="openReorderActivities()"
        data-test="admin-activities-reorder-activity-button"
      >
        <mat-icon>reorder</mat-icon>Reorder
      </button>
    </div>

    <button
      class="small"
      color="accent"
      mat-stroked-button
      [disabled]="tableService.readOnly$ | async"
      (click)="openAddActivity()"
      data-test="admin-activities-add-activity-button"
    >
      <mat-icon>add</mat-icon>Activity
    </button>
  </div>
</div>

<tr class="activities-data" *ngIf="tableService.showActivities$ | async">
  <td colspan="13">
    <div class="activities-caption">Activities</div>

    <table aria-label="activities data">
      <thead class="activities-header">
        <tr>
          <th class="name">Activity name</th>
          <th>Code</th>
          <th>Active</th>
          <th>Type</th>
          <th>Eng. hours</th>
          <th>Eng. iter factor</th>
          <th>Dft. hours</th>
          <th>Dft. iter factor</th>
          <th>Unit</th>
          <th>Qty value</th>
          <th>Deliverable</th>
          <th>Description</th>
          <th>Updated</th>
          <th class="actions"></th>
        </tr>
      </thead>
      <app-masterdata-activity
        *ngFor="
          let activity of activities$ | async;
          let index = index;
          trackBy: trackByActivity
        "
        [data]="activity"
        [class.even]="index % 2 === 0"
        [class.odd]="index % 2 === 1"
      >
      </app-masterdata-activity>
    </table>
  </td>
</tr>
