import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { UserDto } from '@app/models/backendModel';
import {
  createUserAction,
  createUserPublicAction,
  createUserSuccessAction,
  identifyCurrentUserAction,
  identifyCurrentUserFailureAction,
  identifyCurrentUserSuccessAction,
  setMsalUserAction,
  UsersActions,
} from './users.actions';
import { SelectAllUsersResult } from './users.selectors';

export const FEATURE_KEY = 'users';

export enum LoadingStateEnum {
  NOT_STARTED = 'NOT_STARTED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

export const gerUserRoleName = (params: {
  isRequestor: boolean;
  isEngineer: boolean;
}): string => {
  if (params.isRequestor) {
    return 'Commercial Requestor';
  }
  if (params.isEngineer) {
    return 'Engineering Package Owner';
  }
  return '';
};

export const mapUserToSelectAllUsersResult = (
  user: UserDto
): SelectAllUsersResult => {
  return {
    roleName: gerUserRoleName(user),
    ...user,
  };
};
export interface IUsersCollectionState extends EntityState<UserDto> {
  listLoadState: LoadingStateEnum;
  currentUserId: number;
  mainUserId: number;
  userInitialized: boolean;
  msalUser: { firstName: string; lastName: string; email: string };
  userAccountCreated: boolean;
  inactiveUsers: SelectAllUsersResult[];
}

export const usersEntityAdapter = createEntityAdapter<UserDto>({
  selectId: (user) => user.userId,
});

export const initialState =
  usersEntityAdapter.getInitialState<IUsersCollectionState>({
    listLoadState: LoadingStateEnum.NOT_STARTED,
    currentUserId: null,
    mainUserId: null,
    userInitialized: false,
    msalUser: null,
    inactiveUsers: [],
    userAccountCreated: false,
    entities: {},
    ids: [],
  });

export const reducer = createReducer(
  initialState,
  on(UsersActions.getInactiveUsersSuccess, (state, { users: payload }) => ({
    ...state,
    inactiveUsers: payload.map(mapUserToSelectAllUsersResult),
  })),
  on(UsersActions.getActiveUsersQuery, (state) => ({
    ...state,
    listLoadState:
      state.listLoadState !== LoadingStateEnum.LOADED
        ? LoadingStateEnum.LOADING
        : LoadingStateEnum.LOADED,
  })),
  on(UsersActions.getActiveUsersQuerySUCCESS, (state, { users: payload }) =>
    usersEntityAdapter.upsertMany(payload, {
      ...state,
      listLoadState: LoadingStateEnum.LOADED,
    })
  ),

  on(UsersActions.getActiveUsersQueryFAILURE, (state) => ({
    ...state,
    listLoadState: LoadingStateEnum.ERROR,
  })),

  on(identifyCurrentUserAction, (state) => ({
    ...state,
    userInitialized: false,
  })),

  on(identifyCurrentUserSuccessAction, (state, { payload }) =>
    usersEntityAdapter.upsertOne(payload.user, {
      ...state,
      currentUserId: payload.user.userId,
      mainUserId: !state.mainUserId ? payload.user.userId : state.mainUserId,
      userInitialized: payload.ready,
    })
  ),
  on(identifyCurrentUserFailureAction, (state) => ({
    ...state,
  })),
  on(setMsalUserAction, (state, action) => ({
    ...state,
    msalUser: action.payload.user,
    userInitialized: true,
  })),
  on(createUserAction, (state) => ({
    ...state,
    userAccountCreated: false,
  })),
  on(createUserPublicAction, (state) => ({
    ...state,
    userAccountCreated: false,
  })),
  on(createUserSuccessAction, (state) => ({
    ...state,
    userAccountCreated: true,
  }))
);
