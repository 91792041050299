import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IFilter } from '@models/filters';

import {
  convertFormValueToFilter,
  filtersByConditionsFormConfiguration,
} from './filters-form.config';
import {
  applyCurrentConditionsFromProjectsAction,
  applyFilterAction,
  applyNewFilterAndCreateAction,
  deleteFilterSuccessAction,
  getFiltersAction,
  getFiltersFailureAction,
  getFiltersSuccessAction,
  removeFilterConditionAction,
  resetCurrentFilterAction,
  setCurrentFilterFromFormDataAction,
  setFilterAsCurrentAction,
  upsertFilterSuccessAction,
  upsertManyFiltersAction,
} from './filters.actions';

export const FEATURE_KEY = 'filters';

export interface IFiltersCollectionState extends EntityState<IFilter> {
  getFiltersSuccess: boolean;
  currentFilter: IFilter;
  formConditionTypeConfiguration: {
    [conditionType: number]: { formControlName: string; nameKey?: string };
  };
}

export const filtersEntityAdapter = createEntityAdapter<IFilter>({
  // names are unique and know even before storeing filter in database
  selectId: ({ name }) => name,
});

export const initialState = filtersEntityAdapter.getInitialState({
  getFiltersSuccess: false,
  currentFilter: null,
  formConditionTypeConfiguration: Object.entries(
    filtersByConditionsFormConfiguration
  ).reduce(
    (r, [id, conditionTypeConfig]) => ({
      ...r,
      [id]: {
        formControlName: conditionTypeConfig.formControlName,
        optionLabelKey: conditionTypeConfig.optionLabelKey || null,
      },
    }),
    {}
  ),
} as IFiltersCollectionState);

export const reducer = createReducer(
  initialState,

  on(upsertFilterSuccessAction, (state, { payload }) =>
    filtersEntityAdapter.upsertOne(payload.filter, { ...state })
  ),
  on(deleteFilterSuccessAction, (state, { payload }) =>
    filtersEntityAdapter.removeOne(payload.filterName, state)
  ),
  on(getFiltersAction, (state) => ({ ...state, getFiltersSuccess: false })),
  on(getFiltersSuccessAction, upsertManyFiltersAction, (state, { payload }) =>
    filtersEntityAdapter.upsertMany(payload.filters, {
      ...state,
      getFiltersSuccess: true,
    })
  ),
  on(getFiltersFailureAction, (state) => ({
    ...state,
    getFiltersSuccess: false,
  })),
  on(removeFilterConditionAction, (state, { payload }) => ({
    ...state,
    currentFilter: {
      ...state.currentFilter,
      name: null,
      conditions: state.currentFilter.conditions
        .map((condition) => {
          if (condition.type === payload.condition.type) {
            return filtersByConditionsFormConfiguration[
              payload.condition.type
            ].removeReducer(condition, payload.condition.value);
          }
          return condition;
        })
        .filter((x) => !!x.value),
    } as IFilter,
  })),
  on(resetCurrentFilterAction, (state) => ({
    ...state,
    saveFilter: false,
    saveFilterName: null,
    currentFilter: {
      name: null,
      isFavourite: false,
      conditions: [],
    } as IFilter,
  })),
  on(applyFilterAction, (state, { payload }) => ({
    ...state,
    currentFilter:
      state.currentFilter !== null
        ? ({
            ...state.currentFilter,
            userId: payload.userId,
          } as IFilter)
        : ({
            isFavourite: false,
            conditions: [],
            userId: payload.userId,
          } as IFilter),
  })),
  on(applyNewFilterAndCreateAction, (state, { payload }) => ({
    ...state,
    currentFilter: {
      ...state.currentFilter,
      userId: payload.userId,
      name: payload.name,
    } as IFilter,
  })),
  on(setFilterAsCurrentAction, (state, action) => ({
    ...state,
    currentFilter: action.payload.filter,
  })),

  on(setCurrentFilterFromFormDataAction, (state, { payload }) => ({
    ...state,
    currentFilter: convertFormValueToFilter(payload.filter),
  })),
  on(applyCurrentConditionsFromProjectsAction, (state, { payload }) => ({
    ...state,
    currentFilter: payload.filter,
  }))
);
