import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserRole } from '@core/store/core.reducer';
import { selectCurrentRouteUserRole } from '@core/store/core.selectors';

import { UserDto } from '@app/models/backendModel';
import {
  FEATURE_KEY,
  gerUserRoleName,
  IUsersCollectionState,
  usersEntityAdapter,
} from './users.reducer';
export interface SelectAllUsersResult extends UserDto {
  roleName: string;
}
export const selectUsersCollectionState =
  createFeatureSelector<IUsersCollectionState>(FEATURE_KEY);

const userSelectors = usersEntityAdapter.getSelectors(
  selectUsersCollectionState
);

export const selectMsalUser = createSelector(
  selectUsersCollectionState,
  ({ msalUser }) => msalUser
);

export const selectUserCreated = createSelector(
  selectUsersCollectionState,
  ({ userAccountCreated }) => userAccountCreated
);
export const selectInactiveUsers = createSelector(
  selectUsersCollectionState,
  (state) => state.inactiveUsers
);
export const selectActiveUsers = createSelector(
  userSelectors.selectAll,
  (users) =>
    users.map<SelectAllUsersResult>((user) => ({
      ...user,
      roleName: gerUserRoleName(user),
    }))
);

export const selectActiveAndInactiveUsers = createSelector(
  selectActiveUsers,
  selectInactiveUsers,
  (activeUsers, inactiveUsers) => activeUsers.concat(inactiveUsers)
);

export const selectUsersMap = createSelector(
  userSelectors.selectEntities,
  (v) => v
);

export const selectAllRequestors = createSelector(selectActiveUsers, (users) =>
  users.filter((user) => user.isRequestor)
);

export const selectAllProjectCreators = createSelector(
  selectActiveAndInactiveUsers,
  (allUsers): SelectAllUsersResult[] =>
    allUsers.filter((user) => user.hasCreatedProjects)
);

export const selectAllProjectCreatorsAndRequestors = createSelector(
  selectActiveAndInactiveUsers,
  (users) => users.filter((user) => user.hasCreatedProjects || user.isRequestor)
);

export const selectAllEngineeringPackageOwners = createSelector(
  selectActiveUsers,
  (users) => users.filter((user) => user.isEngineer)
);

export const selectCtrOwners = createSelector(
  selectActiveAndInactiveUsers,
  (users) =>
    users
      .filter((user) => user.hasActiveCtrs || user.isEngineer)
      .sort((a, b) => a.fullName.localeCompare(b.fullName))
);
export const selectCtrOwnersAsRecord = createSelector(
  selectCtrOwners,
  (ctrOwners) =>
    ctrOwners.reduce((acc, owner) => ({ ...acc, [owner.userId]: owner }), {})
);

export const selectAllRequestorsMap = createSelector(
  selectAllRequestors,
  (requestors) =>
    requestors.reduce(
      (r, requestor) => ({ ...r, [requestor.userId]: requestor }),
      {}
    )
);

export const selectAllEngineeringPackageOwnersMap = createSelector(
  selectAllEngineeringPackageOwners,
  (engineers) =>
    engineers.reduce(
      (r, engineer) => ({ ...r, [engineer.userId]: engineer }),
      {}
    )
);

export const selectAllProjectCreatorsMap = createSelector(
  selectAllProjectCreators,
  (projectCreators) =>
    projectCreators.reduce(
      (r, requestor) => ({ ...r, [requestor.userId]: requestor }),
      {}
    )
);

export const selectAllProjectCreatorsAndRequestorsMap = createSelector(
  selectAllProjectCreatorsAndRequestors,
  (requestors) =>
    requestors.reduce<{ [key: number]: SelectAllUsersResult }>(
      (r, requestor) => ({
        ...r,
        [requestor.userId]: requestor,
      }),
      {}
    )
);

export const selectUsersListLoadedState = createSelector(
  selectUsersCollectionState,
  ({ listLoadState }) => listLoadState
);

export const selectCurrentUserInitialized = createSelector(
  selectUsersCollectionState,
  ({ userInitialized }) => userInitialized
);

export const selectCurrentUserId = createSelector(
  selectUsersCollectionState,
  ({ currentUserId }) => currentUserId
);

export const selectCurrentUser = createSelector(
  selectCurrentUserId,
  selectUsersMap,
  (currentUserId, users) => (currentUserId ? users[currentUserId] : null)
);

export const selectCurrentInitializedUser = createSelector(
  selectCurrentUserId,
  selectUsersMap,
  selectCurrentUserInitialized,
  (currentUserId, users, initialized) =>
    currentUserId && initialized ? users[currentUserId] : null
);

export const selectCurrentBusinessSegmentId = createSelector(
  selectCurrentInitializedUser,
  (currentUser) => (currentUser ? currentUser.businessSegmentId : null)
);

export const selectCurrentUserEntityId = createSelector(
  selectCurrentInitializedUser,
  (currentUser) => (currentUser ? currentUser.entityId : null)
);

export const selectCurrentUserIsAdmin = createSelector(
  selectCurrentInitializedUser,
  (currentUser) => (currentUser ? currentUser.isAdmin : false)
);

export const selectCurrentUserIsRequestor = createSelector(
  selectCurrentInitializedUser,
  (currentUser) => (currentUser ? currentUser.isRequestor : false)
);

export const selectCurrentUserIsEngineer = createSelector(
  selectCurrentInitializedUser,
  (currentUser) => (currentUser ? currentUser.isEngineer : false)
);

export const selectMainUserId = createSelector(
  selectUsersCollectionState,
  ({ mainUserId }) => mainUserId
);

export const selectMainUser = createSelector(
  selectMainUserId,
  selectUsersMap,
  (mainUserId, users) => (mainUserId ? users[mainUserId] : null)
);

export const selectMainAccountIsAdmin = createSelector(
  selectMainUser,
  (mainUser) => (mainUser ? mainUser.isAdmin : false)
);

export const selectUserByEmailFactory = (email: string) =>
  createSelector(
    selectActiveUsers,
    (users) => users.find((user) => user.email === email) || null
  );

export const selectUserByIdFactory = (userId: number) =>
  createSelector(selectUsersMap, (usersMap) => usersMap[userId]);

export const selectShortRoleName = createSelector(
  selectCurrentRouteUserRole,
  selectCurrentInitializedUser,
  (userRoleContext, user) => {
    if (!user) {
      return null;
    }

    if (userRoleContext === UserRole.REQUESTOR && user.isRequestor) {
      return 'Requestor';
    }

    if (userRoleContext === UserRole.ENGINEER && user.isEngineer) {
      return 'Engineering';
    }

    if (user.isRequestor) {
      return 'Requestor';
    }

    if (user.isEngineer) {
      return 'Engineering';
    }

    return 'Other';
  }
);
