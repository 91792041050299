import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { LoadingStateEnum } from '@collections/state-commons';
import { IGetSearchProjectsResponse } from '@models/project';
import { IProjectSearchParams } from '@models/projects';

import { ProjectWithDetailsDto } from '@app/models/backendModel';
import {
  getProjectAction,
  getProjectFailureAction,
  getProjectSuccessAction,
  patchProjectsListFiltersAction,
  resetProjectsListFiltersAction,
  searchProjectsAction,
  searchProjectsFailureAction,
  searchProjectsSuccessAction,
} from './projects.actions';

/**
 * Projects store is populated by projects search requests and get project details request.
 * @see IGetSearchProjectsResponse,
 * It may in some cases cause some missing fields in store if result returned by those endpoint de-synchronizes.
 */
export interface IProjectsCollectionState {
  searchResult: EntityState<IGetSearchProjectsResponse>;
  projectsDetails: EntityState<ProjectWithDetailsDto>;
  /** data for current filters are already available */
  loaded: boolean;
  /** there is pending request for updating search result */
  searching: boolean;
  /** information if project details were already loaded */
  projectLoadingState: { [projectId: number]: LoadingStateEnum };
  /** name of last selected advanced filter */
  filterName: string;
  /** active filters used for filtering projects list */
  filters: IProjectSearchParams;
  totalElements: number;
  /**
   * configuration for available page sizes displayed on projects lists
   */
  availablePageSizes: number[];
}

export const searchProjectsEntityAdapter =
  createEntityAdapter<IGetSearchProjectsResponse>();

export const projectDetailEntityAdapter =
  createEntityAdapter<ProjectWithDetailsDto>();

export const initialState = {
  searchResult: searchProjectsEntityAdapter.getInitialState(),
  projectsDetails: projectDetailEntityAdapter.getInitialState(),
  searching: false,
  loaded: false,
  projectLoadingState: {},
  filterName: null,
  filters: { searchString: '', pageSize: 25 } as IProjectSearchParams,
  totalElements: 0,
  availablePageSizes: [10, 25, 50],
} as IProjectsCollectionState;

export function reduceSearchSuccess(
  state: IProjectsCollectionState,
  payload: IGetSearchProjectsResponse[],
  totalElements: number
) {
  return {
    ...state,
    loaded: true,
    searching: false,
    totalElements,
    searchResult: searchProjectsEntityAdapter.setAll(payload, {
      ...state.searchResult,
    }),
  } as IProjectsCollectionState;
}

export function normalizeFilters(
  filters: IProjectSearchParams,
  payload: IProjectSearchParams
): IProjectSearchParams {
  const modifiedFilters = Object.entries(filters).reduce((r, [key, value]) => {
    if (value !== null && !['filterName'].includes(key)) {
      return { ...r, [key]: value };
    }
    return r;
  }, {});
  return {
    ...modifiedFilters,
    searchString:
      !payload.filterName && !!filters.searchString
        ? filters.searchString.trim()
        : '',
  };
}

export const reducer = createReducer(
  initialState,

  // handle search project list

  on(patchProjectsListFiltersAction, (state, action) => ({
    ...state,
    filterName:
      typeof action.payload.params.filterName === 'undefined'
        ? state.filterName
        : action.payload.params.filterName,
    filters: normalizeFilters(
      { ...state.filters, ...action.payload.params },
      action.payload.params
    ),
    // if filters didn't change we already have correct data
    loaded:
      state.filters.pageNumber === action.payload.params.pageNumber &&
      state.filters.pageSize === action.payload.params.pageSize &&
      state.filters.searchString.trim() ===
        action.payload.params.searchString.trim(),
  })),
  on(resetProjectsListFiltersAction, (state) => ({
    ...state,
    filterName: null,
    filters: {
      searchString: state.filters?.searchString,
      pageSize: state.filters?.pageSize,
    },
  })),
  on(searchProjectsAction, (state) => ({
    ...state,
    searching: true,
  })),
  on(
    searchProjectsSuccessAction,
    (state: IProjectsCollectionState, { payload }) =>
      reduceSearchSuccess(
        state,
        payload.projects.data,
        payload.projects.totalCount
      )
  ),
  on(searchProjectsFailureAction, (state) => ({
    ...state,
    searching: false,
    totalElements: 0,
  })),

  // handle GET project

  on(getProjectAction, (state, { payload }) => ({
    ...state,
    projectLoadingState: {
      ...state.projectLoadingState,
      [payload.projectId]: LoadingStateEnum.LOADING,
    },
  })),
  on(getProjectSuccessAction, (state, { payload }) => ({
    ...state,
    projectLoadingState: {
      ...state.projectLoadingState,
      [payload.project.id]: LoadingStateEnum.LOADED,
    },
    projectsDetails: projectDetailEntityAdapter.setOne(payload.project, {
      ...state.projectsDetails,
    }),
  })),
  on(getProjectFailureAction, (state, { payload }) => ({
    ...state,
    projectLoadingState: {
      ...state.projectLoadingState,
      [payload.projectId]: LoadingStateEnum.ERROR,
    },
  }))
);
