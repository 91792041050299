<ng-container *ngIf="changesList.length > 0; else noChanges">
  <div class="table-scroll-wrapper">
    <table aria-label="change log">
      <thead class="sticky-top">
        <tr>
          <th>PxD</th>
          <th>Block</th>
          <th>Scope</th>
          <th>Activity</th>
          <th>Action</th>
          <th>Field</th>
          <th>Value before</th>
          <th>Value after</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of changesList">
          <td>{{ row.ctrName }}</td>
          <td>
            <a
              *ngIf="
                !readonly &&
                  row.blockCode &&
                  !row.scopeCode &&
                  !row.activityCode;
                else blockName
              "
              (click)="editBlock(row.blockId)"
              >{{ row.blockName }}</a
            >
            <ng-template #blockName>{{ row.blockName }}</ng-template>
          </td>
          <td>
            <a
              *ngIf="
                !readonly && row.scopeCode && !row.activityCode;
                else scopeName
              "
              (click)="editScope(row.scopeId)"
              >{{ row.scopeName }}</a
            >
            <ng-template #scopeName>{{ row.scopeName }}</ng-template>
          </td>
          <td>
            <a
              *ngIf="!readonly && row.activityCode; else activityName"
              (click)="editActivity(row.activityId)"
              >{{ row.activityName }}</a
            >
            <ng-template #activityName>{{ row.activityName }}</ng-template>
          </td>
          <td>{{ row.action }}</td>
          <td>{{ row.field }}</td>
          <td>{{ row.valueBefore }}</td>
          <td>{{ row.valueAfter }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
<ng-template #noChanges>
  <p>There are no changes.</p>
</ng-template>
