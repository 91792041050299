<button mat-button class="sort-button" (click)="applySorting()">
  <mat-icon class="small-icon-no-sort" *ngIf="isSortIconVisible('swap_vert')"
    >swap_vert</mat-icon
  >
  <mat-icon class="small-icon-sort" *ngIf="isSortIconVisible('arrow_downward')"
    >arrow_downward</mat-icon
  >
  <mat-icon class="small-icon-sort" *ngIf="isSortIconVisible('arrow_upward')">
    arrow_upward</mat-icon
  >
</button>
