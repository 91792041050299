import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IGetScenarioComparisonResponseItem,
  IProjectScenarioComparison,
} from '@models/scenario-comparison';

@Injectable({ providedIn: 'root' })
export class ScenarioComparisonApiService {
  constructor(private httpClient: HttpClient) { }

  public getScenarioComparison(
    projectId: number
  ): Observable<IProjectScenarioComparison> {
    return this.httpClient
      .get<IGetScenarioComparisonResponseItem[]>(
        `{apiUrl}projects/${ projectId }/summary/scenarioComparisons`
      )
      .pipe(
        map((scenarioComparisonItems) => {
          return {
            projectId,
            scenarioComparisonItems: scenarioComparisonItems,
          } as any as IProjectScenarioComparison;
        })
      );
  }
}
