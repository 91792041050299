import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UnsavedChangesGuard } from '@scenario/unsaved-changes.guard';

import { RequestorProjectResolver } from '@collections/projects/requestor-project-resolver.service';
import { UserRole } from '@core/store/core.reducer';

import { CreateProjectComponent } from './create-project/create-project.component';
import { ProjectCalendarizationComponent } from './project-calendarization/project-calendarization.component';
import { ProjectSummaryBaseComponent } from './project-summary/project-summary-base.component';
import { ProjectComponent } from './project/project.component';

const routes: Routes = [
  {
    path: '',
    data: {
      requiredUserRole: UserRole.REQUESTOR,
      projectView: true,
      showNewProjectSidenav: true,
    },
    component: ProjectComponent,
    children: [
      {
        path: 'create',
        component: CreateProjectComponent,
        data: {
          pageTitle: 'New Project',
          projectView: true,
        },
        canDeactivate: [UnsavedChangesGuard],
      },
    ],
  },
  {
    path: ':projectId',
    component: ProjectComponent,
    data: {
      pageTitle: 'Project',
      requiredUserRole: UserRole.REQUESTOR,
      activityLogVisible: true,
      activityLogContext: {
        name: 'projects',
        keyName: 'projectId',
        routeParamName: 'projectId',
      },
    },
    resolve: { project: RequestorProjectResolver },
    children: [
      {
        path: 'edit',
        component: CreateProjectComponent,
        data: {
          pageTitle: 'Project',
          projectView: true,
        },
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: 'calendarization',
        component: ProjectCalendarizationComponent,
        data: {
          pageTitle: 'Calendarization',
          projectView: true,
        },
      },
      {
        path: 'summary',
        component: ProjectSummaryBaseComponent,
        data: {
          pageTitle: 'Project Summary',
          projectView: true,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [UnsavedChangesGuard],
})
export class ProjectRoutingModule {}
