import { createAction, createActionGroup, props } from '@ngrx/store';

import {
  CalendarizationDto,
  UpdateStartDateCommand,
} from '@app/models/backendModel';
import { contextAwareProps } from '@core/utils/context-aware-props';
import { CalendarizationForProject } from './ctrs-calendarization.reducer';

export const CtrsCalendarizationActions = createActionGroup({
  source: 'Ctrs Calendarization',
  events: {
    'Reload calendarization for scenario ctr': props<{
      scenarioCtrId: number;
    }>(),
    'Reload calendarization for scenario ctr success':
      props<CalendarizationForProject>(),
  },
});

export const getCtrsCalendarizationAction = createAction(
  '[CTRs Calendarization] GET /scenario/:scenarioId/calendarization',
  contextAwareProps<{ payload: { scenarioId: number } }>()
);

export const getCtrsCalendarizationSuccessAction = createAction(
  '[CTRs Calendarization Effect] GET /scenario/:scenarioId/calendarization SUCCESS',
  contextAwareProps<{ payload: CalendarizationForProject[] }>()
);

export const getCtrsCalendarizationFailureAction = createAction(
  '[CTRs Calendarization Effect] GET /scenario/:scenarioId/calendarization FAILURE',
  contextAwareProps()
);

export const updateScenarioCtrStartDatesAction = createAction(
  '[CTRs Calendarization] PUT /scenarios/:scenarioId/ctrStartDate',
  contextAwareProps<{
    payload: UpdateStartDateCommand;
  }>()
);

export const updateScenarioCtrStartDatesSuccessAction = createAction(
  '[CTRs Calendarization Effect] PUT /scenarios/:scenarioId/ctrStartDate SUCCESS',
  contextAwareProps<{
    context: string;
    updatedCtrsData: CalendarizationDto[];
  }>()
);
