import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numbersInputGuardDirective]',
})
export class NumbersInputGuardDirective {
  @Input() integer: boolean;
  @Input() maxValue: number;
  @Input() maxDigitsCount: number;
  @Input() numberLimitationOffSwitch: boolean;

  @HostListener('keydown', ['$event'])
  onInput(event: KeyboardEvent): void {
    // TODO: handle autocompletion with text value change
    if (this.numberLimitationOffSwitch || !event.key) {
      return;
    }

    const inputValue = (event.target as HTMLInputElement).value;
    const pressedKey = event.key;
    const { selectionStart, selectionEnd } = event.target as HTMLInputElement;
    const temp = inputValue.split('');
    temp.splice(selectionStart, selectionEnd - selectionStart, pressedKey);
    const newValue = temp.join('');
    const regex = this.integer
      ? new RegExp(/\d|Backspace|Delete|Arrow|Tab|-/)
      : new RegExp(/\d|\.|Backspace|Delete|Arrow|Tab|-/);
    const keysAllowedOnMaxDigitsRegex = new RegExp(
      /Backspace|Delete|Arrow|Tab/
    );
    if (
      !pressedKey.match(regex) ||
      (pressedKey === '.' && (inputValue.includes('.') || !inputValue)) ||
      (pressedKey === '-' &&
        (inputValue.includes('-') ||
          (event.target as HTMLInputElement).selectionStart !== 0)) ||
      (this.maxValue && parseInt(newValue, 10) > this.maxValue) ||
      (this.maxDigitsCount &&
        this.checkExcessiveDigitsCount(
          newValue,
          pressedKey,
          keysAllowedOnMaxDigitsRegex
        ))
    ) {
      event.preventDefault();
    }
  }

  private checkExcessiveDigitsCount(
    inputValue,
    pressedKey,
    keysAllowedOnMaxDigitsRegex
  ): boolean {
    const digitsCountExceeded =
      this.countDigits(inputValue) > this.maxDigitsCount;
    const pressedKeyIsForbidden = !keysAllowedOnMaxDigitsRegex.test(pressedKey);
    return digitsCountExceeded && pressedKeyIsForbidden;
  }

  private countDigits(value): number {
    if (value === 0) {
      return 1;
    }
    return Math.floor(Math.log10(Math.abs(value))) + 1;
  }
}
