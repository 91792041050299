import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_KEY,
  ICurrenciesCollectionState,
  currenciesEntityAdapter,
} from './currencies.reducer';

export const selectCurrenciesCollectionState =
  createFeatureSelector<ICurrenciesCollectionState>(FEATURE_KEY);

const { selectAll, selectEntities } = currenciesEntityAdapter.getSelectors(
  selectCurrenciesCollectionState
);

export const selectAllCurrencies = createSelector(selectAll, (v = []) => v);

export const selectCurrenciesMap = createSelector(
  selectEntities,
  (v = {}) => v
);
