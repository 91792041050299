import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ScenarioSummaryCTRsEffects } from './store/scenario-summary-ctrs.effects';
import { FEATURE_KEY, reducer } from './store/scenario-summary-ctrs.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([ ScenarioSummaryCTRsEffects ]),
  ],
})
export class ScenariosSummaryCTRsCollectionModule { }
