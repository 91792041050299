import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';
import { RouteDataService } from '@core/route-data.service';

import { selectMainUser } from './collections/users/store/users.selectors';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly routerDataService: RouteDataService,
    private readonly store: Store
  ) {}

  async load() {
    combineLatest([
      this.store.select(selectMainUser),
      this.store.select(selectCurrentInitializedUser),
    ])
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        switchMap(([mainUser, currentUser]) => {
          const mainUserName = mainUser ? mainUser.email : 'unauthenticated';

          this.googleAnalyticsService.set('userId', mainUserName);
          this.googleAnalyticsService.set('user', mainUserName);

          return this.routerDataService.activatedRoute$;
        }),
        debounceTime(500)
      )
      .subscribe((route) => {
        this.googleAnalyticsService.set('page', location.pathname);
        this.googleAnalyticsService.pageView(
          location.pathname,
          route.data.pageTitle
        );
      });
  }
}
