import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IGetEntitiesResponseItem } from '@models/entities';

import {
  getEntitiesAction,
  getEntitiesFailureAction,
  getEntitiesSuccessAction,
} from './entities.actions';

export const FEATURE_KEY = 'entities';

export interface IEntitiesCollectionState
  extends EntityState<IGetEntitiesResponseItem> {
  loaded: boolean;
}

export const entitiesEntityAdapter =
  createEntityAdapter<IGetEntitiesResponseItem>({
    selectId: ({ entityId }) => entityId,
  });

export const initialState = entitiesEntityAdapter.getInitialState({
  loaded: false,
} as IEntitiesCollectionState);

export const reducer = createReducer(
  initialState,
  on(getEntitiesAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getEntitiesSuccessAction, (state, { payload }) =>
    entitiesEntityAdapter.upsertMany(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getEntitiesFailureAction, (state) => ({
    ...state,
    loaded: true,
  }))
);
