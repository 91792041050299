import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NumbersInputGuardModule } from '@common/directives/numbers-input-guard.module';
import { CommonPipesModule } from '@common/pipe/common-pipes.module';
import { MaterialModule } from '@shared/material.module';

import { TableCellInputComponent } from './table-cell-input/table-cell-input.component';
import { TableCellSelectComponent } from './table-cell-select/table-cell-select.component';
import { TableCellSwitchComponent } from './table-cell-switch/table-cell-switch.component';
import { TableCellTextareaComponent } from './table-cell-textarea/table-cell-textarea.component';

const components = [
  TableCellInputComponent,
  TableCellSelectComponent,
  TableCellSwitchComponent,
  TableCellTextareaComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    CommonPipesModule,
    FormsModule,
    MaterialModule,
    NumbersInputGuardModule,
  ],
  exports: components,
})
export class TableModule {}
