<div mat-dialog-title data-test="order-dialog-title">
  <h2>{{ title }}</h2>

  <div class="breadcrumbs">
    <span class="crumb" *ngFor="let crumb of breadCrumbs">{{ crumb }}</span>
  </div>

  <p data-test="order-dialog-info">
    Drag and drop to change order.<br />
    Inactive elements stay visible only in admin. They are not visible or
    selectable while building CTRs.
  </p>

  <span>{{ elementTypeLabel }}:</span>
</div>
<mat-dialog-content>
  <div class="order-list-container">
    <table
      aria-label="blocks order list"
      cdkDropList
      cdkDropListOrientation="vertical"
      (cdkDropListDropped)="reorder($event)"
    >
      <tr
        class="order-list-item"
        [class.inactive]="item.isActive === false"
        [attr.data-test]="'block-order-element-' + item.code"
        cdkDrag
        cdkDragLockAxis="y"
        *ngFor="let item of list; trackBy: trackById"
      >
        <td class="code" [attr.data-test]="'block-order-code-' + item.code">
          {{ item.code }}
        </td>
        <td class="label">{{ item.label }}</td>
        <td class="inactive">{{ item.isActive ? '' : '(inactive)' }}</td>
      </tr>
    </table>
  </div>
</mat-dialog-content>
<div class="button-right" mat-dialog-actions [align]="'end'">
  <button
    [mat-dialog-close]="false"
    color="primary"
    data-test="order-dialog-close-button"
    mat-button
  >
    Cancel
  </button>

  <button
    [disabled]="!canSave"
    [mat-dialog-close]="list"
    color="primary"
    data-test="order-dialog-save-button"
    mat-button
  >
    {{ actionLabel }}
  </button>
</div>
