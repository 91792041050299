import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { selectAdminScopesByBlockIdFactory } from '@admin/admin-activities/store/admin-activities.selectors';

@Injectable()
export class ScopeDisplayNameValidator implements AsyncValidator {
  private blockId$ = new ReplaySubject<number>(1);

  private scopeId$ = new ReplaySubject<number>(1);

  constructor(private store: Store) {}

  public validate(
    control: AbstractControl
  ): Promise<ValidationErrors> | Observable<ValidationErrors> {
    const requestedName = control.value;

    return this.blockId$.pipe(
      switchMap((blockId) =>
        this.store.select(selectAdminScopesByBlockIdFactory(blockId))
      ),
      withLatestFrom(this.scopeId$),
      map(([blocks, scopeOwnId]) =>
        blocks
          .filter(({ scopeId }) => scopeId !== scopeOwnId)
          .map(({ scopeDisplayName }) => scopeDisplayName)
      ),
      map((scopeNames) =>
        scopeNames
          .map((s) => s.trim().toLowerCase())
          .includes(requestedName.trim().toLowerCase())
      ),
      map((duplicate) => (duplicate ? { duplicateName: true } : null)),
      take(1)
    );
  }

  public setBlockId(value: number) {
    this.blockId$.next(value);
  }

  public setScopeId(value: number) {
    this.scopeId$.next(value);
  }
}
