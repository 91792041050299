import { FteDistributionForMatrix } from '@app/models/backendModel';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function getCtrTotalDistributedFTEs(
  fteDistribution: FteDistributionForMatrix[]
): number {
  const newTotalFte = fteDistribution.reduce((acc, curr) => {
    curr.ftePeriods.forEach((period) => {
      acc += period.fteInPeriod;
    });
    return acc;
  }, 0);
  return newTotalFte;
}
export function getOverestimatedFTEsForRole(
  fteDistribution: FteDistributionForMatrix[],
  expectedTotal: number
): number {
  const result =
    Math.round(
      (getCtrTotalDistributedFTEs(fteDistribution) - expectedTotal) * 10
    ) / 10;
  return result;
}
export function getOverestimatedFTEsDescription(
  fteDistribution: FteDistributionForMatrix[],
  expectedTotal: number
): string {
  const overEstimated = getOverestimatedFTEsForRole(
    fteDistribution,
    expectedTotal
  );
  if (overEstimated === 0) {
    return null;
  }
  return overEstimated > 0
    ? 'Over ' + overEstimated + ' FTEs'
    : 'Left ' + overEstimated * -1 + ' FTEs';
}
export function totalAmountIsCorrect(expectedTotal): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as {
      fteDistribution: FteDistributionForMatrix[];
    };
    const overEstimated = getOverestimatedFTEsDescription(
      value.fteDistribution,
      expectedTotal
    );
    if (overEstimated == null) {
      return null;
    }
    return {
      overEstimated: overEstimated,
    };
  };
}
