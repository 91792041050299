import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import {
  IGetAdminActivitiesAutocompletionDataResponse,
  IGetAdminScopesAutocompletionDataResponse,
} from '@models/admin';
import { AdminActivity } from '@models/admin-activity';
import { AdminBlock, AdminBlockRequestData } from '@models/admin-block';
import { AdminScope } from '@models/admin-scope';
import { OrderableItem } from '@models/orderable-item';

type AdminApiErrorResponse = any;

// ==== Masterdata ====

export const exportMasterDataAction = createAction(
  '[Collection AdminActivities] GET /masterdata/export',
  contextAwareProps()
);

export const exportMasterDataSuccessAction = createAction(
  '[Collection AdminActivities Effect] GET /masterdata/export SUCCESS',
  contextAwareProps()
);

export const exportMasterDataFailureAction = createAction(
  '[Collection AdminActivities Effect] GET /masterdata/export FAILURE',
  contextAwareProps()
);

export const reloadMasterdataAction = createAction(
  '[AdminActivities Effect] reload previously loaded masterdata',
  contextAwareProps()
);

// ==== Activities ====

export const openAddActivityAction = createAction(
  '[AdminActivities] open add activity dialog',
  contextAwareProps<{
    payload: { scopeId: number };
  }>()
);

export const openEditActivityAction = createAction(
  '[AdminActivities] open edit activity dialog',
  contextAwareProps<{ payload: { activityId: number } }>()
);

export const openCopyActivityAction = createAction(
  '[AdminActivities] open copy activity dialog',
  contextAwareProps<{ payload: { activityId: number } }>()
);

export const openReorderScopeActivitiesAction = createAction(
  '[AdminActivities] open order scopes activities dialog',
  contextAwareProps<{ payload: { scopeId: number } }>()
);

export const requestRemoveActivityAction = createAction(
  '[AdminActivities] request removing activity',
  contextAwareProps<{ payload: AdminActivity }>()
);

// Activity GET List

export const getAdminBlockActivitiesAction = createAction(
  '[AdminActivities] GET /admin/block/:blockId/activity',
  contextAwareProps<{ payload: { blockId: number } }>()
);

export const getAdminBlockActivitiesSuccessAction = createAction(
  '[AdminActivities Effect] GET /admin/block/:blockId/activity SUCCESS',
  contextAwareProps<{ payload: AdminActivity[] }>()
);

export const getAdminBlockActivitiesFailureAction = createAction(
  '[AdminActivities Effect] GET /admin/block/:blockId/activity FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Activity GET details

export const getAdminActivityAction = createAction(
  '[AdminActivities] GET /masterdata/activity/:activityId',
  contextAwareProps<{ payload: { activityId: number } }>()
);

export const getAdminActivitySuccessAction = createAction(
  '[AdminActivities Effect] GET /masterdata/activity/:activityId SUCCESS',
  contextAwareProps<{ payload: AdminActivity }>()
);

export const getAdminActivityFailureAction = createAction(
  '[AdminActivities Effect] GET /masterdata/activity/:activityId FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Activity ADD

export const addActivityAction = createAction(
  '[Collection AdminActivities] POST /masterdata/activity',
  contextAwareProps<{ payload: AdminActivity }>()
);

export const addActivitySuccessAction = createAction(
  '[Collection AdminActivities Effect] POST /masterdata/activity SUCCESS',
  contextAwareProps<{ payload: AdminActivity }>()
);

export const addActivityFailureAction = createAction(
  '[Collection AdminActivities Effect] POST /masterdata/activity FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Activity UPDATE

export const updateActivityAction = createAction(
  '[AdminActivities] PUT /masterdata/activity/:activityId',
  contextAwareProps<{
    payload: AdminActivity;
  }>()
);

export const updateActivitySuccessAction = createAction(
  '[AdminActivities Effect] PUT /masterdata/activity/:activityId SUCCESS',
  contextAwareProps<{ payload: AdminActivity }>()
);

export const updateActivityFailureAction = createAction(
  '[AdminActivities Effect] PUT /masterdata/activity/:activityId FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Activity DELETE

export const deleteAdminActivityAction = createAction(
  '[AdminActivities Effect] DELETE /masterdata/activity/:activityId',
  contextAwareProps<{ payload: { activityId: number } }>()
);

export const deleteAdminActivitySuccessAction = createAction(
  '[AdminActivities Effect] DELETE /masterdata/activity/:activityId SUCCESS',
  contextAwareProps<{ payload: { activityId: number } }>()
);

export const deleteAdminActivityFailureAction = createAction(
  '[AdminActivities Effect] DELETE /masterdata/activity/:activityId FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Activity GET autocompletion

export const getActivitiesAutocompletionDataAction = createAction(
  '[AdminActivities] GET /admin/activityData',
  contextAwareProps()
);

export const getActivitiesAutocompletionDataSuccessAction = createAction(
  '[AdminActivities Effect] GET /admin/activityData SUCCESS',
  contextAwareProps<{
    payload: IGetAdminActivitiesAutocompletionDataResponse;
  }>()
);

export const getActivitiesAutocompletionDataFailureAction = createAction(
  '[AdminActivities Effect] GET /admin/activityData FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Activity PATCH order

export const updateScopeActivitiesOrderAction = createAction(
  '[AdminActivities Effect] PATCH /masterdata/activities/order',
  contextAwareProps<{
    payload: { scopeId: number; activities: OrderableItem[] };
  }>()
);

export const updateScopeActivitiesOrderSuccessAction = createAction(
  '[AdminActivities Effect] PATCH /masterdata/activities/order SUCCESS',
  contextAwareProps<{
    payload: { scopeId: number; activities: OrderableItem[] };
  }>()
);

export const updateScopeActivitiesOrderFailureAction = createAction(
  '[AdminActivities Effect] PATCH /masterdata/activities/order FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// ==== Scopes ====

export const openAddScopeAction = createAction(
  '[AdminActivities] open add scope dialog',
  contextAwareProps<{
    payload: { blockId: number };
  }>()
);

export const openEditScopeAction = createAction(
  '[AdminActivities] open edit scope dialog',
  contextAwareProps<{ payload: { scopeId: number } }>()
);

export const openCopyScopeAction = createAction(
  '[AdminActivities] open copy scope dialog',
  contextAwareProps<{ payload: { scopeId: number } }>()
);

export const openReorderBlockScopesAction = createAction(
  '[AdminActivities] open order block scopes dialog',
  contextAwareProps<{ payload: { blockId: number } }>()
);

export const requestRemoveScopeAction = createAction(
  '[AdminActivities] request removing scope',
  contextAwareProps<{ payload: AdminScope }>()
);

// Scopes GET details

export const getAdminScopeAction = createAction(
  '[AdminActivities] GET /masterdata/scope/:scopeId',
  contextAwareProps<{ payload: { scopeId: number } }>()
);

export const getAdminScopeSuccessAction = createAction(
  '[AdminActivities Effect] GET /masterdata/scope/:scopeId SUCCESS',
  contextAwareProps<{ payload: AdminScope }>()
);

export const getAdminScopeFailureAction = createAction(
  '[AdminActivities Effect] GET /masterdata/scope/:scopeId FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Scopes GET list

export const getAdminBlockScopesAction = createAction(
  '[AdminActivities] GET /masterdata/block/:blockId scopes',
  contextAwareProps<{ payload: { blockId: number } }>()
);

export const getAdminBlockScopesSuccessAction = createAction(
  '[AdminActivities Effect] GET /masterdata/block/:blockId scopes SUCCESS',
  contextAwareProps<{ payload: AdminScope[] }>()
);

export const getAdminBlockScopesFailureAction = createAction(
  '[AdminActivities Effect] GET /masterdata/block/:blockId scopes FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Scope ADD

export const addScopeAction = createAction(
  '[Collection AdminActivities] POST /masterdata/scope',
  contextAwareProps<{ payload: AdminScope }>()
);

export const addScopeSuccessAction = createAction(
  '[Collection AdminActivities Effect] POST /masterdata/scope SUCCESS',
  contextAwareProps<{ payload: AdminScope }>()
);

export const addScopeFailureAction = createAction(
  '[Collection AdminActivities Effect] POST /masterdata/scope FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Scope UPDATE

export const updateScopeAction = createAction(
  '[AdminActivities] PUT /masterdata/scope/:scopeId',
  contextAwareProps<{
    payload: AdminScope;
  }>()
);

export const updateScopeSuccessAction = createAction(
  '[AdminActivities Effect] PUT /masterdata/scope/:scopeId SUCCESS',
  contextAwareProps<{ payload: AdminScope }>()
);

export const updateScopeFailureAction = createAction(
  '[AdminActivities Effect] PUT /masterdata/scope/:scopeId FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Scope DELETE

export const deleteAdminScopeAction = createAction(
  '[AdminActivities Effect] DELETE /masterdata/scope/:scopeId',
  contextAwareProps<{ payload: { scopeId: number } }>()
);

export const deleteAdminScopeSuccessAction = createAction(
  '[AdminActivities Effect] DELETE /masterdata/scope/:scopeId SUCCESS',
  contextAwareProps<{ payload: { scopeId: number } }>()
);

export const deleteAdminScopeFailureAction = createAction(
  '[AdminActivities Effect] DELETE /masterdata/scope/:scopeId FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Scopes GET autocompletion

export const getScopesAutocompletionDataAction = createAction(
  '[AdminActivities] GET /admin/scopeData',
  contextAwareProps()
);

export const getScopesAutocompletionDataSuccessAction = createAction(
  '[AdminActivities Effect] GET /admin/scopeData SUCCESS',
  contextAwareProps<{
    payload: IGetAdminScopesAutocompletionDataResponse;
  }>()
);

export const getScopesAutocompletionDataFailureAction = createAction(
  '[AdminActivities Effect] GET /admin/scopeData FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Scopes PATCH order

export const updateBlockScopesOrderAction = createAction(
  '[AdminActivities Effect] PATCH /masterdata/scopes/order',
  contextAwareProps<{ payload: { blockId: number; scopes: OrderableItem[] } }>()
);

export const updateBlockScopesOrderSuccessAction = createAction(
  '[AdminActivities Effect] PATCH /masterdata/scopes/order SUCCESS',
  contextAwareProps<{ payload: { blockId: number; scopes: OrderableItem[] } }>()
);

export const updateBlockScopesOrderFailureAction = createAction(
  '[AdminActivities Effect] PATCH /masterdata/scopes/order FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// ==== Blocks ====

export const openAddBlockAction = createAction(
  '[AdminActivities] open add block dialog',
  contextAwareProps<{
    payload: {
      ctrId: number;
    };
  }>()
);

export const openEditBlockAction = createAction(
  '[AdminActivities] open edit block dialog',
  contextAwareProps<{ payload: { blockId: number } }>()
);

export const openCopyBlockAction = createAction(
  '[AdminActivities] open copy block dialog',
  contextAwareProps<{ payload: { blockId: number } }>()
);

export const openReorderPxdBlocksAction = createAction(
  '[AdminActivities] open order pxd blocks dialog',
  contextAwareProps<{ payload: { ctrId: number } }>()
);

export const requestRemoveBlockAction = createAction(
  '[AdminActivities] request removing block',
  contextAwareProps<{ payload: AdminBlock }>()
);

// Blocks GET details

export const getAdminBlockAction = createAction(
  '[AdminActivities] GET /masterdata/block/:blockId',
  contextAwareProps<{ payload: { blockId: number } }>()
);

export const getAdminBlockSuccessAction = createAction(
  '[AdminActivities Effect] GET /masterdata/block/:blockId SUCCESS',
  contextAwareProps<{ payload: AdminBlock }>()
);

export const getAdminBlockFailureAction = createAction(
  '[AdminActivities Effect] GET /masterdata/block/:blockId FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Blocks GET List

export const getAdminPxDBlocksAction = createAction(
  '[AdminActivities] GET /admin/blocks/product/:productId/discipline/:disciplineId',
  contextAwareProps<{ payload: { productId: number; disciplineId: number } }>()
);

export const getAdminPxDBlocksSuccessAction = createAction(
  '[AdminActivities Effect] GET /admin/blocks/product/:productId/discipline/:disciplineId SUCCESS',
  contextAwareProps<{ payload: AdminBlock[] }>()
);

export const getAdminPxDBlocksFailureAction = createAction(
  '[AdminActivities Effect] GET /admin/blocks/product/:productId/discipline/:disciplineId FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Block ADD

export const addBlockAction = createAction(
  '[Collection AdminActivities] POST /masterdata/block',
  contextAwareProps<{ payload: AdminBlockRequestData }>()
);

export const addBlockSuccessAction = createAction(
  '[Collection AdminActivities Effect] POST /masterdata/block SUCCESS',
  contextAwareProps<{ payload: AdminBlock }>()
);

export const addBlockFailureAction = createAction(
  '[Collection AdminActivities Effect] POST /masterdata/block FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Block UPDATE

export const updateBlockAction = createAction(
  '[AdminActivities] PUT /masterdata/block/:blockId',
  contextAwareProps<{
    payload: AdminBlockRequestData;
  }>()
);

export const updateBlockSuccessAction = createAction(
  '[AdminActivities Effect] PUT /masterdata/block/:blockId SUCCESS',
  contextAwareProps<{ payload: AdminBlock }>()
);

export const updateBlockFailureAction = createAction(
  '[AdminActivities Effect] PUT /masterdata/block/:blockId FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Block DELETE

export const deleteAdminBlockAction = createAction(
  '[AdminActivities Effect] DELETE /masterdata/block/:blockId',
  contextAwareProps<{ payload: { blockId: number } }>()
);

export const deleteAdminBlockSuccessAction = createAction(
  '[AdminActivities Effect] DELETE /masterdata/block/:blockId SUCCESS',
  contextAwareProps<{ payload: { blockId: number } }>()
);

export const deleteAdminBlockFailureAction = createAction(
  '[AdminActivities Effect] DELETE /masterdata/block/:blockId FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);

// Block PATCH order

export const updatePxDBlocksOrderAction = createAction(
  '[AdminActivities Effect] PATCH /masterdata/blocks/order',
  contextAwareProps<{
    payload: {
      ctrId: number;
      blocks: OrderableItem[];
    };
  }>()
);

export const updatePxDBlocksOrderSuccessAction = createAction(
  '[AdminActivities Effect] PATCH /masterdata/blocks/order SUCCESS',
  contextAwareProps<{
    payload: {
      productId: number;
      disciplineId: number;
      blocks: OrderableItem[];
    };
  }>()
);

export const updatePxDBlocksOrderFailureAction = createAction(
  '[AdminActivities Effect] PATCH /masterdata/blocks/order FAILURE',
  contextAwareProps<{ payload: AdminApiErrorResponse }>()
);
