<p class="page-name">
  eCTR
</p>
<strong>You cannot access this CTR. Possible reasons are:</strong>
<ul>
  <li>You have no access to this CTR</li>
  <li>Product or Discipline has been disabled, so CTR has been removed</li>
  <li>You have wrong link</li>
</ul>
If you believe it's a bug please <a [routerLink]="'/contact'">contact us.</a>
<p>
  Go back to <a [routerLink]="'/'">homepage</a>.
</p>
<p>
  Check <a [routerLink]="'/userguide'">user guide</a>.
</p>
