import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IGetProjectTypesResponseItem } from '@models/project-types';

@Injectable({ providedIn: 'root' })
export class ProjectTypesApiService {
  constructor(private httpClient: HttpClient) {}

  public getProjectTypes() {
    return this.httpClient.get<IGetProjectTypesResponseItem[]>(
      '{apiUrl}projectTypes'
    );
  }
}
