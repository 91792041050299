<ng-container
  *ngIf="scenarioCTR"
  [ngTemplateOutlet]="showCtrOwners ? ctrOwnerCell : ctrCell"
></ng-container>

<ng-template #ctrOwnerCell>
  <ng-container *ngIf="scenarioCTR.ctrOwner">
    <span class="status-label" data-test="pxd-cell-owner-name">{{
      scenarioCTR.ctrOwner === currentUserFullName
        ? 'Your CTR'
        : scenarioCTR?.ctrOwner
    }}</span>

    <div
      class="cell-action disabled-button-tooltip-trigger"
      matTooltip="Changing CTR owner is disabled for cancelled CTRs"
      [matTooltipDisabled]="scenarioCTR.ctrStatus !== CtrStatus.CANCELLED"
      *ngIf="interactive"
    >
      <button
        class="show-on-cell-hover"
        mat-icon-button
        [disabled]="scenarioCTR.ctrStatus === CtrStatus.CANCELLED"
        matTooltip="Edit CTR Owner"
        [matTooltipDisabled]="scenarioCTR.ctrStatus === CtrStatus.CANCELLED"
        data-test="pxd-cell-edit-owner-button"
        (click)="openCTROwnerEdit(); $event.stopPropagation()"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #ctrCell>
  <span class="status-label" data-test="pxd-cell-status-label">{{
    scenarioCTR.ctrStatus
  }}</span>
  <span
    class="revision-label"
    *ngIf="scenarioCTR.revisionNumber > 1"
    data-test="pxd-cell-revision-label"
    >Revision {{ scenarioCTR.revisionNumber }}</span
  >

  <div class="cell-action">
    <div
      class="disabled-button-tooltip-trigger"
      *ngIf="interactive && scenarioCTR.ctrStatus === CtrStatus.COMPLETED"
    >
      <button
        mat-icon-button
        matTooltip="Request revision"
        data-test="pxd-cell-request-ctr-revision-button"
        (click)="requestRevision(); $event.stopPropagation()"
      >
        <mat-icon>autorenew</mat-icon>
      </button>
    </div>

    <div
      class="disabled-button-tooltip-trigger"
      *ngIf="interactive && scenarioCTR.ctrStatus === CtrStatus.CANCELLED"
    >
      <button
        mat-icon-button
        class="small"
        matTooltip="Recover CTR"
        data-test="pxd-cell-recover-ctr-button"
        (click)="recoverCTR(); $event.stopPropagation()"
      >
        <mat-icon>replay</mat-icon>
      </button>
    </div>

    <div
      class="disabled-button-tooltip-trigger"
      *ngIf="
        cancelable &&
        [
          CtrStatus.NOT_STARTED,
          CtrStatus.IN_PROGRESS,
          CtrStatus.COMPLETED
        ].includes(scenarioCTR.ctrStatus)
      "
    >
      <button
        mat-icon-button
        class="mat-icon-button"
        matTooltip="Cancel CTR"
        data-test="pxd-cell-cancel-ctr-button"
        (click)="cancelCTR(); $event.stopPropagation()"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
