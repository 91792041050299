<div class="block-header">
  <div class="cell name">
    <div class="label name">Block name</div>
    <button
      (click)="openBlockEdit()"
      [disabled]="tableService.readOnly$ | async"
      [disableRipple]="true"
      class="block-name"
      data-test="block-name-label"
      color="primary"
      mat-button
    >
      <span class="order-number">{{ data.order }}. </span
      >{{ data.blockDisplayName }}
    </button>
  </div>
  <div class="cell code">
    <div class="label">Code</div>
    <div class="value" data-test="block-code-label">{{ data.blockCode }}</div>
  </div>
  <div class="cell cto">
    <div class="label">CTO Type</div>
    <div class="value">{{ data.ctoType }}</div>
  </div>
  <div class="cell active">
    <div class="label">Active</div>
    <div class="value">
      <mat-icon
        class="material-icons-outlined"
        [class.active]="data.isActive"
        [class.inactive]="!data.isActive"
        data-test="is-active-block-label"
        >{{ data.isActive ? 'done' : 'cancel_outlined' }}</mat-icon
      >
    </div>
  </div>
  <div class="cell update">
    <div class="label">Updated</div>
    <div class="value" data-test="updated-date-block-label">
      {{ data.lastUpdateDate | date }}
    </div>
  </div>

  <div class="cell actions">
    <div
      class="disabled-button-tooltip-trigger"
      matTooltip="Block is used in CTR's and cannot be removed."
      [matTooltipDisabled]="
        (tableService.readOnly$ | async) === true || !data.isUsed
      "
    >
      <button
        class="delete small"
        mat-button
        [disabled]="(tableService.readOnly$ | async) === true || data.isUsed"
        (click)="removeBlock()"
        data-test="admin-activities-remove-block-button"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <div class="disabled-button-tooltip-trigger">
      <button
        class="copy small"
        mat-button
        [disabled]="tableService.readOnly$ | async"
        (click)="openCopyBlock()"
        data-test="admin-activities-copy-block-button"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>

    <div
      class="disabled-button-tooltip-trigger"
      matTooltip="Not enough scopes under this block to reorder them"
      [matTooltipDisabled]="(scopes$ | async).length > 1"
    >
      <button
        class="order-scopes small"
        color="primary"
        mat-stroked-button
        [disabled]="
          (tableService.readOnly$ | async) || (scopes$ | async).length < 2
        "
        (click)="openReorderScopes()"
        data-test="admin-activities-reorder-scope-button"
      >
        <mat-icon>reorder</mat-icon>
        Reorder
      </button>
    </div>

    <div class="disabled-button-tooltip-trigger">
      <button
        class="small"
        color="accent"
        mat-stroked-button
        [disabled]="tableService.readOnly$ | async"
        (click)="openAddScope()"
        data-test="admin-activities-add-scope-button"
      >
        <mat-icon>add</mat-icon>
        Scope
      </button>
    </div>
  </div>
</div>

<tr class="scopes-data" *ngIf="tableService.showScopes$ | async">
  <td colspan="6">
    <table aria-label="Scopes data">
      <thead class="scope-header-labels">
        <tr>
          <th class="name">Scope name</th>
          <th>Code</th>
          <th>Active</th>
          <th>Calc level</th>
          <th>Unit</th>
          <th>QTY Value</th>
          <th>CPX Factor</th>
          <th>Apply client CPX factor</th>
          <th>Deliverable</th>
          <th>Entity</th>
          <th>Description</th>
          <th>Updated</th>
          <th class="actions">Actions</th>
        </tr>
      </thead>

      <app-masterdata-scope
        *ngFor="
          let scope of scopes$ | async;
          let index = index;
          trackBy: trackByScope
        "
        [data]="scope"
      ></app-masterdata-scope>
    </table>
  </td>
</tr>
