export enum CtrStatus {
  SELECTED = 'CTR selected',
  NOT_STARTED = 'Not started',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  ON_HOLD = 'On hold',
  BLANK = '',
}

export enum UserCtrRole {
  OWNER = 'Owner',
  SUPPORTER = 'Supporter',
  VIEWER = 'Viewer',
}

export enum CollaborationStatus {
  NO_ACTION = 1,
  ACCEPTED = 2,
  REJECTED = 4,
  REQUESTED = 8,
  REQUESTED_UPDATE = 16,
  SELECTED = -1,
  SELECTED_FOR_UPDATE = -2,
  NoAction = 1,
  Accepted = 2,
  Resolved = 3,
  Rejected = 4,
  Requested = 8,
  RequestedUpdate = 16,
  Pending = 24,
  Unresolved = 28,
}

export enum EngineeringRoleType {
  DRAFTING = 'Drafting',
  ENGINEERING = 'Engineering',
}

export enum ProjectStatusEnum {
  NOT_STARTED = 'Not started',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export enum ScenarioStatus {
  NOT_STARTED = 'Not started',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  ON_HOLD = 'On hold',
}

export enum ScenarioChangeStatusRequest {
  PUT_ON_HOLD = 1,
  RESUME = 2,
  CANCEL = 3,
}

export enum ConditionType {
  Requestor = 0,
  Entity = 1,
  Client = 2,
  ProjectType = 3,
  ProjectStage = 4,
  ProjectStatus = 5,
  CreatedAfter = 6,
  CreatedBefore = 7,
  ScenarioEntity = 8,
  PxD = 9,
  CtrStatus = 10,
  CtrOwner = 11,
  DelegatedFrom = 12,
  DelegatedTo = 13,
  Project = 14,
  UserIsOwner = 15,
  UserIsSupporter = 16,
  DelegatedToUser = 17,
  DelegatedFromUser = 18,
  CtrDueDate = 19,
  CtrEntity = 20,
  SupportingEntity = 21,
  Supporter = 22,
}

export enum SortType {
  CreatedNewest = 0,
  CreatedOldest = 1,
  LastUpdatedNewest = 2,
  LastUpdatedOldest = 3,
  BidDateNewest = 4,
  BidDateOldest = 5,
  CTRRequestedNewest = 6,
  CTRRequestedOldest = 7,
  CTRLastUpdatedNewest = 8,
  CTRLastUpdatedOldest = 9,
  CTRDueDateNewest = 10,
  CTRDueDateOldest = 11,
}

export enum CtrDueDatePeriods {
  THIS_WEEK = 'This week',
  THIS_MONTH = 'This month',
  NEXT_WEEK = 'Next week',
  NEXT_MONTH = 'Next month',
}

export enum BlockCtoType {
  NA = 'NA',
  DS = 'DS',
  CTO = 'CTO',
}

export enum RolesSortDirection {
  ASC = 1,
  DESC = -1,
  NO_SORT = 0,
}
