import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { IGetScenarioComparisonResponseItem } from '@models/scenario-comparison';

// tslint:disable:use-component-view-encapsulation
@Component({
  selector: 'app-scenario-comparison-column',
  templateUrl: './scenario-comparison-column.component.html',
  styleUrls: ['./scenario-comparison-column.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScenarioComparisonColumnComponent implements OnInit {
  @Input() projectId: number;
  @Input() scenario: IGetScenarioComparisonResponseItem;
  @Input() ctrTypes: string[];
  @Input() allProjectScenarios: IGetScenarioComparisonResponseItem[];

  public selectedScenario: IGetScenarioComparisonResponseItem;

  public ngOnInit(): void {
    this.selectedScenario = this.scenario;
  }
}
