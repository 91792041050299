import { CalendarizationDto } from '@app/models/backendModel';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  CtrsCalendarizationActions,
  getCtrsCalendarizationAction,
  getCtrsCalendarizationFailureAction,
  getCtrsCalendarizationSuccessAction,
  updateScenarioCtrStartDatesAction,
  updateScenarioCtrStartDatesSuccessAction,
} from './ctrs-calendarization.actions';

export const CTRs_FEATURE_KEY = 'CTRsCalendarization';
export interface CalendarizationForProject extends CalendarizationDto {
  startDateAsDate: Date;
  endDateAsDate: Date;
  totalFte: number;
  totalHours: number;
}
export interface ICalendarizationCollectionState
  extends EntityState<CalendarizationForProject> {
  loaded: boolean;
}

export const calendarizationEntityAdapter =
  createEntityAdapter<CalendarizationForProject>({
    selectId: ({ scenarioCTRId }) => scenarioCTRId,
  });

export const initialState = calendarizationEntityAdapter.getInitialState({
  loaded: false,
} as ICalendarizationCollectionState);

export const reducer = createReducer(
  initialState,
  on(getCtrsCalendarizationAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getCtrsCalendarizationSuccessAction, (state, { payload }) =>
    calendarizationEntityAdapter.setAll(
      payload.map((x) => ({
        ...x,
      })),
      {
        ...state,
        loaded: true,
      }
    )
  ),
  on(getCtrsCalendarizationFailureAction, (state) => ({
    ...state,
    loaded: true,
  })),
  on(updateScenarioCtrStartDatesAction, (state) => ({
    ...state,
    loaded: true,
  })),
  on(
    updateScenarioCtrStartDatesSuccessAction,
    (state, { updatedCtrsData }: { updatedCtrsData: CalendarizationDto[] }) => {
      const updates = updatedCtrsData.map((ctr) => ({
        id: ctr.scenarioCTRId,
        changes: ctr,
      }));

      return calendarizationEntityAdapter.updateMany(updates, {
        ...state,
        loaded: true,
      });
    }
  ),

  on(
    CtrsCalendarizationActions.reloadCalendarizationForScenarioCtrSuccess,
    (state, props) => {
      return calendarizationEntityAdapter.upsertOne(props, {
        ...state,
        loaded: true,
      });
    }
  )
);
