import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { LockOwnerState, LockService } from '@core/lock/lock.service';
import { IPxD } from '@models/pxd';

import { getAdminPxDBlocksAction } from './store/admin-activities.actions';
import {
  selectAdminBlocksByPxDFactory,
  selectAllAdminActivities,
  selectAllAdminScopes,
} from './store/admin-activities.selectors';

const masterdataShowScopeStorageKey = 'masterdata:showScopes';
const masterdataShowActivitiesStorageKey = 'masterdata:showActivities';

@Injectable()
export class MasterdataTableService implements OnDestroy {
  public readOnly$ = this.lockService.lockOwnerState$.pipe(
    map((lockOwnerState) => lockOwnerState !== LockOwnerState.OWNER),
    shareReplay(1)
  );

  public showScopes$ = new BehaviorSubject<boolean>(
    JSON.parse(localStorage.getItem(masterdataShowScopeStorageKey)) || true
  );

  public showActivities$ = new BehaviorSubject<boolean>(
    JSON.parse(localStorage.getItem(masterdataShowActivitiesStorageKey)) ||
      false
  );

  private selectedPxD$ = new BehaviorSubject<IPxD>(null);

  public selectedPxDBlocks$ = this.selectedPxD$.pipe(
    switchMap((pxd) =>
      pxd ? this.store.select(selectAdminBlocksByPxDFactory(pxd.id)) : []
    ),
    shareReplay(1)
  );

  private filterSubject$ = new BehaviorSubject<number[]>([]);

  public filter$ = this.filterSubject$.asObservable();

  public filteredBlocks$ = combineLatest([
    this.selectedPxDBlocks$,
    this.filterSubject$,
  ]).pipe(
    map(([blocks, filter]) =>
      blocks.filter(({ blockId }) => filter.includes(blockId))
    ),
    shareReplay(1)
  );

  public filteredBlocksResults$ = combineLatest([
    this.filterSubject$,
    this.store.select(selectAllAdminScopes),
    this.store.select(selectAllAdminActivities),
  ]).pipe(map(this.countFiltered), shareReplay(1));

  private subscription = new Subscription();

  constructor(private lockService: LockService, private store: Store) {
    this.subscription.add(
      this.showScopes$.subscribe((value) => {
        localStorage.setItem(
          masterdataShowScopeStorageKey,
          JSON.stringify(value)
        );
      })
    );
    this.subscription.add(
      this.showActivities$.subscribe((value) => {
        localStorage.setItem(
          masterdataShowActivitiesStorageKey,
          JSON.stringify(value)
        );
      })
    );
  }

  public selectPxD(pxd: IPxD) {
    if (this.selectedPxD$.value?.id !== pxd.id) {
      this.store.dispatch(
        getAdminPxDBlocksAction({
          context: 'MasterdataTableService::selectPxD',
          payload: {
            productId: pxd.productId,
            disciplineId: pxd.disciplineId,
          },
        })
      );

      this.filterSubject$.next([]);
      this.selectedPxD$.next(pxd);
    }
  }

  public toggleAllBlocks({ checked }) {
    if (checked) {
      this.selectedPxDBlocks$.pipe(take(1)).subscribe((blocks) => {
        this.filterSubject$.next(blocks.map(({ blockId }) => blockId));
      });
    } else {
      this.filterSubject$.next([]);
    }
  }

  public toggleBlock(blockId: number) {
    if (this.filterSubject$.value.includes(blockId)) {
      this.filterSubject$.next(
        this.filterSubject$.value.filter((id) => id !== blockId)
      );
    } else {
      this.filterSubject$.next([...this.filterSubject$.value, blockId]);
    }
  }

  private countFiltered([selectedBlocksIds, allScopes, allActivities]) {
    const selectedScopesIds = allScopes
      .filter(({ blockId }) => selectedBlocksIds.includes(blockId))
      .map(({ scopeId }) => scopeId);
    const blocksCount = selectedBlocksIds.length;
    const scopesCount = selectedScopesIds.length;
    const activitiesCount = allActivities.filter(({ scopeId }) =>
      selectedScopesIds.includes(scopeId)
    ).length;

    return { blocksCount, scopesCount, activitiesCount };
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
