import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_KEY,
  IProjectTypesCollectionState,
  projectTypesEntityAdapter,
} from './project-types.reducer';

export const selectProjectTypesCollectionState =
  createFeatureSelector<IProjectTypesCollectionState>(FEATURE_KEY);

const { selectAll, selectEntities } = projectTypesEntityAdapter.getSelectors(
  selectProjectTypesCollectionState
);

export const selectAllProjectTypes = createSelector(selectAll, (v) => v);

export const selectProjectTypesMap = createSelector(selectEntities, (v) => v);

export const selectAllActiveProjectTypes = createSelector(
  selectAllProjectTypes,
  (projectTypes) => projectTypes.filter((projectType) => projectType.isActive)
);

export const selectAllActiveProjectTypesMap = createSelector(
  selectAllActiveProjectTypes,
  (projectTypes) =>
    projectTypes.reduce(
      (r, projectType) => ({ ...r, [projectType.projectTypeId]: projectType }),
      {}
    )
);
