import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClientsEffects } from './store/clients.effects';
import { FEATURE_KEY, reducer } from './store/clients.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([ClientsEffects]),
  ],
})
export class ClientsCollectionModule {}
