import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_KEY,
  IClientsCollectionState,
  clientsEntityAdapter,
} from './clients.reducer';

export const selectClientsCollectionState =
  createFeatureSelector<IClientsCollectionState>(FEATURE_KEY);

export const { selectAll: selectAllClients, selectEntities: selectClientsMap } =
  clientsEntityAdapter.getSelectors(selectClientsCollectionState);

export const selectAllActiveClients = createSelector(
  selectAllClients,
  (clients) => clients.filter((client) => client.isActive)
);
