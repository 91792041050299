import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AdminActivity } from '@models/admin-activity';
import { AdminScope } from '@models/admin-scope';

import { scopeQtyValueOptions } from '../edit-scope-form/edit-scope-form.component';
import { MasterdataTableService } from '../masterdata-table.service';
import {
  getAdminBlockActivitiesAction,
  openAddActivityAction,
  openCopyScopeAction,
  openEditScopeAction,
  openReorderScopeActivitiesAction,
  requestRemoveScopeAction,
} from '../store/admin-activities.actions';
import { selectAdminActivitiesByScopeIdFactory } from '../store/admin-activities.selectors';

const mapQtyValueLabelMap = Object.values(scopeQtyValueOptions).reduce(
  (r, { id, label }) => ({ ...r, [id]: label }),
  {}
);

@Component({
  selector: 'app-masterdata-scope',
  templateUrl: './masterdata-scope.component.html',
  styleUrls: [
    './masterdata-scope.component.scss',
    '../masterdata-table/masterdata-table.shared.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterdataScopeComponent implements OnChanges {
  @Input() data: AdminScope;

  public activities$: Observable<AdminActivity[]>;

  public mapQtyValueLabelMap = mapQtyValueLabelMap;

  constructor(
    public tableService: MasterdataTableService,
    private store: Store
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data &&
      (changes.data.firstChange ||
        changes.data.currentValue.blockId !==
          changes.data.previousValue.blockId ||
        changes.data.currentValue.scopeId !==
          changes.data.previousValue.scopeId)
    ) {
      this.store.dispatch(
        getAdminBlockActivitiesAction({
          payload: { blockId: this.data.blockId },
        })
      );

      this.activities$ = this.store
        .select(selectAdminActivitiesByScopeIdFactory(this.data.scopeId))
        .pipe(shareReplay(1));
    }
  }

  public openEditScope() {
    this.store.dispatch(
      openEditScopeAction({
        context: 'MasterdataScopeComponent::openEditScope',
        payload: {
          scopeId: this.data.scopeId,
        },
      })
    );
  }

  public openCopyScope() {
    this.store.dispatch(
      openCopyScopeAction({
        context: 'MasterdataScopeComponent::openCopyScope',
        payload: {
          scopeId: this.data.scopeId,
        },
      })
    );
  }

  public removeScopes() {
    this.store.dispatch(
      requestRemoveScopeAction({
        context: 'MasterdataScopeComponent::removeScopes',
        payload: this.data,
      })
    );
  }

  public openAddActivity() {
    this.store.dispatch(
      openAddActivityAction({
        context: 'MasterdataScopeComponent::openAddActivity',
        payload: {
          scopeId: this.data.scopeId,
        },
      })
    );
  }

  public openReorderActivities() {
    this.store.dispatch(
      openReorderScopeActivitiesAction({
        context: 'MasterdataScopeComponent::openReorderActivities',
        payload: {
          scopeId: this.data.scopeId,
        },
      })
    );
  }

  public trackByActivity(index: number, activity: AdminActivity) {
    return activity.id;
  }
}
