import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IGetProjectStagesResponseItem } from '@models/project-stages';

@Injectable({ providedIn: 'root' })
export class ProjectStagesApiService {
  constructor(private httpClient: HttpClient) {}

  public getProjectStages() {
    return this.httpClient.get<IGetProjectStagesResponseItem[]>(
      '{apiUrl}projectStages'
    );
  }
}
