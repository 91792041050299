import { NgModule } from '@angular/core';

import { PxdGridModule } from '@common/pxd-grid/pxd-grid.module';

import { BusinessSegmentSelectorModule } from './business-segment-selector/business-segment-selector.module';
import { PxdSelectorModule } from './pxd-selector/pxd-selector.module';

@NgModule({
  declarations: [],
  exports: [BusinessSegmentSelectorModule, PxdSelectorModule, PxdGridModule],
})
export class AdminSharedModule {}
