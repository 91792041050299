import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserDetails } from '@models/userDetails';
import { IGetUsersResponse } from '@models/users';

@Injectable({ providedIn: 'root' })
export class UsersApiService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Get current logged in user.
   */
  public getUsers() {
    return this.httpClient.get<IGetUsersResponse>('{apiUrl}users');
  }

  /**
   * Get current logged in user.
   */
  public getUser(userId: number) {
    return this.httpClient.get<IGetUsersResponse>(`{apiUrl}users/${userId}`);
  }

  public getAdminUserDetails(userId: number) {
    return this.httpClient.get<UserDetails>(
      `{apiUrl}admin/userDetails/${userId}`
    );
  }
}
