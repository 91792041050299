import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { isAfter } from 'date-fns';

export function validateDates(
  projectStartDate: string,
  projectEndDate: string,
  errDate: string
) {
  return (group: UntypedFormGroup) => {
    const startDate = group.controls[projectStartDate].value;
    const endDate = group.controls[projectEndDate].value;

    if (
      startDate != null &&
      endDate != '' &&
      startDate > endDate &&
      errDate == projectEndDate
    ) {
      return group.controls[projectEndDate].setErrors({ dateError: true });
    } else if (
      startDate != null &&
      endDate != null &&
      endDate != '' &&
      startDate > endDate &&
      errDate == projectStartDate
    ) {
      return group.controls[projectStartDate].setErrors({ dateError: true });
    } else {
      if (
        errDate == projectEndDate &&
        group.controls[projectEndDate].hasError('dateError')
      ) {
        delete group.controls[projectEndDate].errors.dateError;
        group.controls[projectEndDate].updateValueAndValidity();
      } else if (
        errDate == projectStartDate &&
        group.controls[projectStartDate].hasError('dateError')
      ) {
        delete group.controls[projectStartDate].errors.dateError;
        group.controls[projectStartDate].updateValueAndValidity();
      }

      return null;
    }
  };
}

export function CtrDueDateValidator(projectStartDateValue: string) {
  const projectStartDate = new Date(projectStartDateValue);
  projectStartDate.setDate(projectStartDate.getDate() - 730);

  return (ctrDueDateFormGroup: UntypedFormGroup) => {
    if (!projectStartDate) {
      return;
    }

    const ctrDueDate = new Date(ctrDueDateFormGroup.value);

    return isAfter(ctrDueDate, projectStartDate) ? null : { dateError: true };
  };
}

export function noWhitespaceValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const isWhitespace =
    (control.value || '').trim().length === 0 && control.value?.length > 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}

export function customValidateArrayGroup(
  validatedProperty = 'name'
): ValidatorFn {
  return (formArray: UntypedFormArray): { [key: string]: boolean } | null => {
    formArray.controls.forEach(
      (formGroup: UntypedFormGroup, formGroupIndex: number) => {
        formArray.controls.find(
          (secondFormGroup, secondFormGroupIndex: number) => {
            if (
              formGroup.value[validatedProperty] &&
              secondFormGroup.value[validatedProperty] &&
              secondFormGroup.value[validatedProperty].toLowerCase() ===
                formGroup.value[validatedProperty].toLowerCase() &&
              formGroupIndex !== secondFormGroupIndex
            ) {
              formGroup.setErrors({ duplicationError: true });
              return true;
            } else {
              formGroup.setErrors(null);
            }
          }
        );
      }
    );
    return null;
  };
}

export function customValidateArrayGroupAddCost(): ValidatorFn {
  return (formArray: UntypedFormArray): { [key: string]: boolean } | null => {
    formArray.controls.forEach((x: UntypedFormGroup) => {
      if (
        x.value.description != '' &&
        x.value.value == '' &&
        x.value.currencyId == ''
      ) {
        x.controls.value.markAsTouched();
        return x.controls.value.setErrors({ fieldError: true });
      } else if (x.controls.value.hasError('fieldError')) {
        x.controls.value.setErrors(null);
      }
    });
    return null;
  };
}
