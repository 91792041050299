import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CtrDeliverable } from '@models/ctr-deliverable-block';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeliverablesApiService {
  constructor(private http: HttpClient) {}

  public getDeliverables(ctrId: number): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}scenarioctrs/${ctrId}/deliverableBlocks`
    );
  }

  public updateDeliverablesSelection(
    ctrId: number,
    deliverables: CtrDeliverable[]
  ) {
    return this.http.patch(
      `${environment.apiUrl}scenarioctrs/${ctrId}/deliverables`,
      { deliverables: deliverables }
    );
  }
}
