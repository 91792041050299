import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, Subscription, combineLatest, defer, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { selectAllActiveBusinessSegments } from '@collections/business-segments/store/business-segments.selectors';
import { selectAllEntities } from '@collections/entities/store/entities.selectors';
import { selectSegmentPxDsFactory } from '@collections/pxds/store/pxds.selectors';
import { AdminScope } from '@models/admin-scope';

import { AdminActivitiesApiService } from '../admin-activities-api.service';
import { getAdminPxDBlocksAction } from '../store/admin-activities.actions';
import { selectAdminBlocksByPxDFactory } from '../store/admin-activities.selectors';
import { ScopeDisplayNameValidator } from '../validators/scope-display-name.validator';

export const scopeQtyValueOptions = {
  DEFAULT: { id: '1', label: 'Default' },
  QTY: { id: 'QTY', label: 'Quantity' },
  TOGGLE: { id: 'YES/NO', label: 'Toggle' },
  DELEGATION: { id: 'DelegateScope', label: 'Delegation' },
};

const parseStringToNumber = map((v: string) => parseInt(v, 10));

@Component({
  selector: 'app-edit-scope-form',
  templateUrl: './edit-scope-form.component.html',
  styleUrls: ['./edit-scope-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ScopeDisplayNameValidator],
})
export class EditScopeFormComponent implements OnInit, OnDestroy {
  @Input() originalBlockId: number;

  @Input() public originaScopeCode = '';

  @Input() public warnWhenMoving = true;

  @Input() set data(value: Partial<AdminScope>) {
    this.formGroup.patchValue(value);
    this.scopeDisplayNameValidator.setBlockId(value.blockId);
    this.scopeDisplayNameValidator.setScopeId(value.scopeId);
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity({ emitEvent: true, onlySelf: true });
  }

  @Input() set canChangePath(value: boolean) {
    if (value) {
      this.formGroup.get('businessSegmentId').enable();
      this.formGroup.get('pxdId').enable();
      this.formGroup
        .get('blockId')
        .enable({ onlySelf: false, emitEvent: true });
    } else {
      this.formGroup.get('businessSegmentId').disable();
      this.formGroup.get('pxdId').disable();
      this.formGroup
        .get('blockId')
        .disable({ onlySelf: false, emitEvent: true });
    }
  }

  private entityFormControlElementSubject$ = new Subject<ElementRef>();

  @ViewChild('entity', { read: ElementRef })
  public set entityFormControlElement(el: ElementRef) {
    this.entityFormControlElementSubject$.next(el);
  }

  public businessSegmentsOptions$ = this.store.select(
    selectAllActiveBusinessSegments
  );

  private businessSegmentIdValue$ =
    this.createFormInputValue('businessSegmentId').pipe(parseStringToNumber);

  public pxdOptions$ = this.businessSegmentIdValue$.pipe(
    switchMap((businessSegmentId) =>
      this.store.select(selectSegmentPxDsFactory(businessSegmentId))
    ),
    shareReplay(1)
  );

  private pxdIdValue$ =
    this.createFormInputValue('pxdId').pipe(parseStringToNumber);

  public blockOptions$ = this.pxdIdValue$.pipe(
    withLatestFrom(this.pxdOptions$),
    map(([pxdId, pxds]) => pxds.find((pxd) => pxd.id === pxdId)),
    switchMap((pxd) => {
      if (!pxd) {
        return of([]);
      }
      this.store.dispatch(
        getAdminPxDBlocksAction({
          context: 'EditScopeFormComponent::blockOptions$',
          payload: pxd,
        })
      );
      return this.store.select(selectAdminBlocksByPxDFactory(pxd.id));
    }),
    shareReplay(1)
  );

  private blockIdValue$ =
    this.createFormInputValue('blockId').pipe(parseStringToNumber);

  public entities$ = this.store.select(selectAllEntities);

  private scopeCodeValue$ = this.createFormInputValue('code');

  public calcLevels$ = of(['Equipment', 'CTR']);

  public qtyValueOptions$ = of([
    scopeQtyValueOptions.QTY,
    scopeQtyValueOptions.TOGGLE,
    scopeQtyValueOptions.DEFAULT,
    scopeQtyValueOptions.DELEGATION,
  ]);

  private qtyValueValue$ = this.createFormInputValue('qtyValue');

  public unitOptions$ = of(['item', 'weeks']);

  public formGroup: UntypedFormGroup = this.formBuilder.group({
    isActive: [true, [Validators.required]],
    businessSegmentId: [{ value: null, disabled: true }, [Validators.required]],
    pxdId: [
      { value: null, disabled: false },
      [],
      // [Validators.required],
      // [inArrayValidator(this.pxdOptions$, 'id')],
    ],
    blockId: [
      { value: null, disabled: false },
      [Validators.required],
      // [Validators.required],
      // [inArrayValidator(this.blockOptions$, 'blockId')],
    ],
    scopeId: [null],
    code: [{ value: null, disabled: true }, [Validators.required]],
    scopeDisplayName: [
      null,
      [Validators.required],
      [this.scopeDisplayNameValidator],
    ],
    description: [null, [Validators.maxLength(500)]],
    entityId: [null, [Validators.required]],
    calcLevel: ['Equipment', [Validators.required]],
    qtyType: ['item', [Validators.required, Validators.maxLength(20)]],
    qtyValue: ['QTY', [Validators.required]],
    applyClientCpxFactor: [false, [Validators.required]],
    cpxFactor: [1, [Validators.required]],
    isDeliverable: [false],
    order: [],
    copyFrom: [],
  });

  @Output() dataChange = this.formGroup.valueChanges.pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    map(() => {
      const data: AdminScope = this.formGroup.getRawValue();

      data.qtyType = data.qtyType.toLowerCase();

      return data;
    })
  );

  @Output() valid = this.formGroup.valueChanges.pipe(
    map(() => this.formGroup.valid)
  );

  private subscriptions = new Subscription();

  public suggestedScopeCode$ = this.blockIdValue$.pipe(
    distinctUntilChanged(),
    switchMap((blockId) =>
      !!blockId && (this.originalBlockId !== blockId || !this.originaScopeCode)
        ? this.adminActivitiesApiService.getAvailableScopeCode(blockId).pipe(
            map(({ code }) => code || ''),
            catchError(() => of(''))
          )
        : of(this.originaScopeCode)
    ),
    shareReplay(1)
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private adminActivitiesApiService: AdminActivitiesApiService,
    private scopeDisplayNameValidator: ScopeDisplayNameValidator
  ) {}

  public ngOnInit(): void {
    // reset form values on context changes
    this.subscriptions.add(
      combineLatest([
        this.pxdOptions$,
        this.pxdIdValue$,
        this.businessSegmentIdValue$,
      ]).subscribe(([options, pxdId]) => {
        if (pxdId !== null && options.every((option) => option.id !== pxdId)) {
          this.formGroup.get('pxdId').setValue(null, { emitEvent: false });
        }
      })
    );

    this.subscriptions.add(
      combineLatest([
        this.blockOptions$,
        this.blockIdValue$,
        this.pxdIdValue$,
      ]).subscribe(([options, blockId]) => {
        if (
          blockId !== null &&
          options.every((option) => option.blockId !== blockId)
        ) {
          this.formGroup.get('blockId').setValue(null, { emitEvent: false });
        }
      })
    );

    this.subscriptions.add(
      this.blockIdValue$.pipe(distinctUntilChanged()).subscribe((blockId) => {
        this.scopeDisplayNameValidator.setBlockId(blockId);
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity({ emitEvent: true });
      })
    );

    this.subscriptions.add(
      this.qtyValueValue$
        .pipe(
          switchMap((qtyValue) => {
            const entityFormControl = this.formGroup.get('entityId');

            if (qtyValue === scopeQtyValueOptions.DELEGATION.id) {
              entityFormControl.enable();
              this.cdr.markForCheck();

              // scroll entity input into view
              return this.entityFormControlElementSubject$.pipe(
                filter(({ nativeElement }) => !!nativeElement),
                take(1),
                tap((entityFormControlElement) => {
                  entityFormControlElement.nativeElement.scrollIntoView();
                })
              );
            } else {
              entityFormControl.disable();
              entityFormControl.setValue(null);
            }
            return of();
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.suggestedScopeCode$
        .pipe(
          startWith(null),
          pairwise(),
          withLatestFrom(this.scopeCodeValue$),
          map(([[previousSuggestedCode, suggestedScopeCode], scopeCode]) =>
            !scopeCode || scopeCode === previousSuggestedCode
              ? suggestedScopeCode
              : scopeCode
          )
        )
        .subscribe((scopeCode) => {
          this.formGroup.get('code').setValue(scopeCode, { emitEvent: true });
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private createFormInputValue(formControlName: string) {
    return defer(() =>
      this.formGroup
        .get(formControlName)
        .valueChanges.pipe(
          startWith(this.formGroup.get(formControlName).value),
          distinctUntilChanged()
        )
    ).pipe(shareReplay(1));
  }
}
