import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

import { SaveDialogComponent } from '@scenario/ctr/save-dialog/save-dialog.component';
import { UnsavedChangesGuard } from '@scenario/unsaved-changes.guard';
import { SharedModule } from '@shared/shared.module';

import { environment } from '../environments/environment';

import { FEATURE_KEY as USERS_FEATURE_KEY } from '@collections/users/store/users.reducer';
import { provideRouterStore } from '@ngrx/router-store';
import { ActivitiesChangeLogModule } from './admin/activities-change-log/activities-change-log.module';
import { AdminActivitiesModule } from './admin/admin-activities/admin-activities.module';
import { AppConfigService } from './app-config.service';
import { AppRoutingModule } from './app-routing.module';
import { AppService } from './app-service.service';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ConfirmDialogModule } from './common/confirm-dialog/confirm-dialog.module';
import { ContactComponent } from './contact/contact.component';
import { CoreModule } from './core/core.module';
import { EventLogModule } from './event-log/event-log.module';
import { HeaderModule } from './layout/header/header.module';
import { SideNavModule } from './layout/sidenav/sidenav.module';
import { MediatorService } from './mediator.service';
import { NoopComponent } from './noop/noop.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProjectModule } from './project/project.module';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { ErrorPopupModule } from './scenario/ctr/error-popup/error-popup.module';
import { SdkCallService } from './sdk-call.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
export function initializeApp(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}

const restoredUser: { currentUserId: number } = JSON.parse(
  localStorage.getItem(USERS_FEATURE_KEY)
) || {
  currentUserId: -1,
};
const googleAnalyticModules =
  environment.gTag.Key !== ''
    ? [
        NgxGoogleAnalyticsModule.forRoot(environment.gTag.Key, [
          {
            command: 'config',
            values: [
              environment.gTag.Key,
              { debug_mode: environment.gTag.EnableDebugMode },
              { user_id: restoredUser.currentUserId },
            ],
          },
        ]),
        NgxGoogleAnalyticsRouterModule,
      ]
    : [];

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    NoopComponent,
    ReleaseNotesComponent,
    SaveDialogComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
  ],
  imports: [
    ActivitiesChangeLogModule,
    AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    AdminActivitiesModule, // this needs to be after AppRoutingModule
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    ConfirmDialogModule,
    CoreModule,
    ErrorPopupModule,
    HeaderModule,
    HttpClientModule,
    ProjectModule,
    SharedModule,
    SideNavModule,
    WelcomePageComponent,
    EventLogModule,
    ...googleAnalyticModules,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useExisting: AppService, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true,
    },
    SdkCallService,
    UnsavedChangesGuard,
    AppConfigService,
    MediatorService,
    provideRouterStore(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
