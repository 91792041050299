import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import {
  CtrDeliverable,
  CtrDeliverableBlock,
} from '@models/ctr-deliverable-block';

export const getDeliverablesAction = createAction(
  '[Collection Deliverables] GET /scenarioCTRs/:ctrId/deliverableBlocks',
  contextAwareProps<{ payload: { ctrId: number } }>()
);

export const getDeliverablesSuccessAction = createAction(
  '[Collection Deliverables] GET /scenarioCTRs/:ctrId/deliverableBlocks SUCCESS',
  contextAwareProps<{
    payload: { ctrId: number; ctrDeliverableBlocks: CtrDeliverableBlock[] };
  }>()
);

export const getDeliverablesFailureAction = createAction(
  '[Collection Deliverables] GET /scenarioCTRs/:ctrId/deliverableBlocks FAILURE',
  contextAwareProps()
);

export const updateDeliverablesSelectAction = createAction(
  '[Collection Deliverables] PATCH /scenarioCTRs/:ctrId/deliverables',
  contextAwareProps<{
    payload: {
      ctrId: number;
      deliverables: CtrDeliverable[];
    };
  }>()
);

export const patchDeliverablesSelectActionSuccess = createAction(
  '[Collection Deliverables] PATCH /scenarioCTRs/:ctrId/deliverables SUCCES ',
  contextAwareProps<{ payload: { ctrId: number } }>()
);

export const patchDeliverablesSelectActionFailure = createAction(
  '[Collection Deliverables] PATCH /scenarioCTRs/:ctrId/deliverables FAILURE',
  contextAwareProps()
);
