import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'integer',
  standalone: true,
})
export class IntegerPipe implements PipeTransform {
  constructor(private readonly decimalPipe: DecimalPipe) {}
  transform(value: number, showZero = false): string {
    if (value === null || value === undefined || value === 0) {
      return value === 0 && showZero ? '0' : '';
    }
    return this.decimalPipe.transform(value, '1.0-0');
  }
}
