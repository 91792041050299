<ng-container *ngIf="!disabled">
  <textarea
    (input)="onInput($event)"
    (focus)="onFocus($event)"
    [attr.data-test]="controlId"
    [attr.maxlength]="maxLength || null"
    [style.fontSize]="fontSize"
    [disabled]="disabled"
    [id]="controlId"
    [ngClass]="{'without-counter': disableCounter, 'fit-to-cell': fitToCell}"
    [placeholder]="placeholder || ''"
    [(ngModel)]="content"
    #textarea
  ></textarea>
  <span
    [class.hide-on-blur]="counterForFocusedOnly"
    [class.max-length-exceeded]="textarea.value.length === maxLength"
    *ngIf="maxLength && textarea.value.length >= counterMinimumValue && !disableCounter"
    class="letter-counter"
  >
    {{textarea.value.length}}/{{maxLength}}
  </span>
  <div
    *ngIf="error"
    class="error-bar"
  ></div>
</ng-container>
<span
  *ngIf="disabled"
  class="disabled-label"
  [attr.data-test]="controlId + '-label'"
>{{content}}</span>
