<h3>All responsibilities of the user</h3>
<div class="table-wrapper">
  <table
    *ngIf="sortedResponsibilities.length > 0; else empty"
    matSort
    (matSortChange)="sortData($event)"
    aria-label="User responsibilities"
    data-test="user-responsibilities-table"
  >
    <thead>
      <tr>
        <th mat-sort-header="entity">Entity</th>
        <th mat-sort-header="pxdName">PxD</th>
        <th mat-sort-header="isPrincipal" class="principal-cell-header">
          Principal
        </th>
        <th>Unassign</th>
      </tr>
    </thead>
    <tbody>
      <tr
        [attr.data-test]="
          'user-responsibilities-pxd-id-' +
          responsibility.pxdId +
          '-entity-id-' +
          responsibility.entityId
        "
        *ngFor="
          let responsibility of sortedResponsibilities;
          trackBy: trackByResponsibility
        "
      >
        <td data-test="user-responsibilities-entity-cell">
          {{ responsibility.entity }}
        </td>
        <td data-test="user-responsibilities-pxd-name-cell">
          {{ responsibility.pxdName }}
        </td>
        <td
          class="principal-cell"
          data-test="user-responsibilities-is-principal-cell"
        >
          <i *ngIf="responsibility.isPrincipal" class="material-icons-outlined"
            >account_circle</i
          >
        </td>
        <td data-test="user-responsibilities-action-cell">
          <button
            *ngIf="!responsibility.deselectionDisabled"
            mat-icon-button
            (click)="
              unassignResponsibility(
                responsibility.pxdId,
                responsibility.entityId
              )
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #empty>User has no assigned responsibilities.</ng-template>
