<form [formGroup]="formGroup" class="row form-wrapper">
  <div class="col-xs-12 row">
    <div class="col-xs-12 checkbox-wrapper">
      <mat-slide-toggle formControlName="isActive">Active</mat-slide-toggle>
    </div>
  </div>

  <mat-form-field appearance="outline" class="col-xs-12 col-lg-6">
    <mat-label>Segment</mat-label>
    <mat-select
      formControlName="businessSegmentId"
      matTooltip="Can't edit as this block is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('businessSegmentId').disabled"
    >
      <mat-option
        *ngFor="let option of businessSegmentsOptions$ | async"
        [value]="option.businessSegmentId"
      >
        {{ option.businessSegmentName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-xs-12 col-lg-6">
    <mat-label>P x D</mat-label>
    <mat-select
      formControlName="ctrId"
      matTooltip="Can't edit as this block is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('ctrId').disabled"
    >
      <mat-option
        *ngFor="let option of pxdOptions$ | async"
        [value]="option.id"
      >
        {{ option.shortName }}
      </mat-option>
    </mat-select>
    <mat-hint></mat-hint>
  </mat-form-field>

  <div class="col-xs-12">
    <div
      class="alert alert-warning"
      *ngIf="
        warnWhenMoving &&
        !!formGroup?.get('blockId').value &&
        !!formGroup?.get('ctrId').value &&
        originalCtrId !== formGroup?.get('ctrId').value
      "
    >
      <mat-icon>info</mat-icon>
      <div>
        Block will be moved to another Product and Discipline.<br />
        All scopes and activities of this block will also be moved.
      </div>
    </div>
  </div>

  <mat-form-field appearance="outline" class="col-xs-12 col-md-6">
    <mat-label>WBS code</mat-label>

    <input
      matInput
      formControlName="blockCode"
      matTooltip="Can't edit as this block is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('blockCode').disabled"
    />

    <mat-hint
      *ngIf="
        canChangePath &&
        (suggestedBlockCode$ | async) !== formGroup?.get('blockCode').value
      "
    >
      Suggested: {{ suggestedBlockCode$ | async }}</mat-hint
    >

    <mat-error *ngIf="formGroup.controls.blockCode.errors?.required">
      WBS code is required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-xs-12 col-lg-6">
    <mat-label>CTO type</mat-label>
    <mat-select formControlName="ctoType">
      <mat-option
        *ngFor="let ctoType of ctoTypesOptions$ | async"
        [value]="ctoType"
      >
        {{ ctoType }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-xs-12" appearance="outline">
    <mat-label>Name</mat-label>
    <input
      #input
      matInput
      class="name-input"
      maxlength="150"
      formControlName="blockDisplayName"
    />
    <span matSuffix class="name-hint">
      {{ input.value?.length || 0 }}/150
    </span>

    <mat-error *ngIf="formGroup.controls.blockDisplayName.errors?.required">
      Name is required
    </mat-error>

    <mat-error
      *ngIf="formGroup.controls.blockDisplayName.errors?.duplicateName"
    >
      This name is already used by other block in this PxD
    </mat-error>
  </mat-form-field>
</form>
