import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ScenariosEffects } from './store/scenarios.effects';
import { FEATURE_KEY, reducer } from './store/scenarios.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([ScenariosEffects]),
  ],
})
export class ScenariosCollectionModule {}
