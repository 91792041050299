import {
  routerNavigatedAction,
  SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import { createReducer, on } from '@ngrx/store';

export enum UserRole {
  ADMIN = 'admin',
  REQUESTOR = 'requestor',
  ENGINEER = 'engineer',
}

export interface ICoreCollectionState {
  route: SerializedRouterStateSnapshot;
  routeParams: any;
  routeData: {
    requiredUserRole: UserRole;
    sidenavVisible?: boolean;
    activityLogVisible?: boolean;
    activityLogContext?: { name: 'projects' | 'scenarioctrs' | 'scenarios'; keyName: string; routeParamName: string; };
    [ key: string ]: any;
  };
}

export const FEATURE_KEY = 'core';

export const initialState = {
  route: {},
  routeData: {},
};

const accumulateRouteData = (item, key) => ({
  ...item[ key ],
  ...(item.firstChild ? accumulateRouteData(item.firstChild, key) : {}),
});

export const reducer = createReducer(
  initialState,
  on(routerNavigatedAction, (state, action) => ({
    ...state,
    routeParams: accumulateRouteData(action.payload.routerState.root, 'params'),
    routeData: accumulateRouteData(action.payload.routerState.root, 'data'),
  }))
);
