import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { ProjectStagesApiService } from '../project-stages-api.service';

import {
  getProjectStagesAction,
  getProjectStagesFailureAction,
  getProjectStagesSuccessAction,
} from './project-stages.actions';

@Injectable()
export class ProjectStagesEffects {
  public getProjectStages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectStagesAction),
      debounceTime(500),
      switchMap((action) =>
        this.projectStagesApiService.getProjectStages().pipe(
          map((payload) =>
            getProjectStagesSuccessAction({
              trigger: action,
              context: 'ProjectStagesEffects::getProjectStages$',
              payload,
            })
          ),
          catchError(() =>
            of(
              getProjectStagesFailureAction({
                trigger: action,
                context: 'ProjectStagesEffects::getProjectStages$',
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private projectStagesApiService: ProjectStagesApiService
  ) {}
}
