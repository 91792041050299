<div class="contacts-wrapper">
  <p>
    eCTR is the digital app created by Subsea Projects Organization. Its goal:
    to accelerate the CTR request and creation life-cycle and simplify
    collaboration between centers.
  </p>

  <h2>You need help?</h2>

  <p>
    If you have questions concerning some functionality of the application, you
    can check our
    <a [routerLink]="['/userguide']"><strong>user guide</strong></a
    >.
  </p>

  <h2>Give your feedback</h2>

  <p>
    This is the first release and bugs/defects may still be present.
    <strong>Your feedback is critical to improve</strong> our user experience.
    Please share it as soon as it arises. You can do so via Teams on our
    dedicated
    <a target="_blank" rel="noopener" [attr.href]="eCTRSupportPlatform"
      >eCTR Production - Support Platform</a
    >
    via the
    <a target="_blank" rel="noopener" [attr.href]="feedbackTrackerUrl"
      >Feedback Tracker</a
    >.
  </p>

  <h2>You have suggestions?</h2>

  <p>
    Similarly this tool has been built to help us be more efficient. Hence
    <strong>we welcome any suggestions</strong> for enhancement that you would
    point out. Just log it in the
    <a target="_blank" rel="noopener" [attr.href]="eCTRSupportPlatform"
      >eCTR Production - Support Platform</a
    >
    via the
    <a target="_blank" rel="noopener" [attr.href]="feedbackTrackerUrl"
      >Feedback Tracker</a
    >.
  </p>
</div>
<h2>eCTR Ambassadors</h2>
<p>
  We have a dedicated team of eCTR Ambassadors who are actively promoting the
  usage of the eCTR within the organization. They are available to assist you
  with any questions or support related to the application.
</p>
<p>
  You can find the full list of
  <a
    href="https://digicorner.sharepoint.com/sites/TheWell/SitePages/Community-ectr.aspx#3.-ectr-ambassadors"
    target="_blank"
    rel="noopener"
  >
    eCTR Ambassadors
  </a>
  on The Well.
</p>
