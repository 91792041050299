import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IGetScenarioSummaryCTRsResponseItem } from '@models/scenario-summary-ctrs';
import { getScenarioSummaryCTRsAction, getScenarioSummaryCTRsFailureAction, getScenarioSummaryCTRsSuccessAction } from './scenario-summary-ctrs.actions';

export const FEATURE_KEY = 'scenario-summary-ctrs';

export interface IScenarioSummaryCTRsCollectionState
  extends EntityState<IGetScenarioSummaryCTRsResponseItem> {
  loaded: boolean;
}

export const scenarioSummaryCTRsEntityAdapter = createEntityAdapter<
  IGetScenarioSummaryCTRsResponseItem
>({
  selectId: ({ scenarioId, productId, disciplineId }) => `${ scenarioId }-${ productId }-${ disciplineId }`,
});

export const initialState = scenarioSummaryCTRsEntityAdapter.getInitialState({
  loaded: false,
} as IScenarioSummaryCTRsCollectionState);

export const reducer = createReducer(
  initialState,
  on(getScenarioSummaryCTRsAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getScenarioSummaryCTRsSuccessAction, (state, { payload }) =>
    scenarioSummaryCTRsEntityAdapter.upsertMany(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getScenarioSummaryCTRsFailureAction, (state) => ({
    ...state,
    loaded: true,
  })),
)
