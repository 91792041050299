export interface ProjectFormData {
  businessSegmentId: number;
  clientId: number;
  designPressureNotes: string;
  designTempNotes: string;
  enggPhaseDuration: number;
  enggPhaseDurationNotes: string;
  executingEntityId: number;
  fieldLocation: string;
  fieldLocationNotes: string;
  id?: number;
  maxDesignPressure: number;
  maxDesignTemp: number;
  opPhaseDuration: number;
  opPhaseDurationNotes: string;
  projectAwardDate: string;
  projectBidDate: string;
  projectEndDate: string;
  projectEndDateNotes: string;
  projectId?: number; // TODO: used by some endpoints
  projectMDMNumber: string;
  projectName: string;
  projectStageId: number;
  projectStartDate: string;
  projectStartDateNotes: string;
  projectStatus: string;
  projectTypeId: number;
  totalDuration: string;
  waterDepth: number;
  waterDepthNotes: string;
  /** used for initializing project currencies */
  currencies?: {
    isDefaultCurrency: boolean;
    currencyCode: string;
    fxRate: number;
  }[];
}

export function emptyProject(
  executingEntityId: number,
  businessSegmentId: number
): ProjectFormData {
  return {
    businessSegmentId,
    clientId: null,
    designPressureNotes: '',
    designTempNotes: '',
    enggPhaseDuration: null,
    enggPhaseDurationNotes: '',
    executingEntityId,
    fieldLocation: '',
    fieldLocationNotes: '',
    maxDesignPressure: null,
    maxDesignTemp: null,
    opPhaseDuration: null,
    opPhaseDurationNotes: '',
    projectAwardDate: null,
    projectBidDate: null,
    projectEndDate: null,
    projectEndDateNotes: '',
    projectMDMNumber: '',
    projectName: '',
    projectStageId: null,
    projectStartDate: null,
    projectStartDateNotes: '',
    projectStatus: '',
    projectTypeId: null,
    totalDuration: '0 months',
    waterDepth: null,
    waterDepthNotes: '',
  };
}
