<ng-container
  *ngIf="scenarioCTR"
  [ngTemplateOutlet]="showCtrOwners ? ctrOwnerCell : ctrCell"
></ng-container>

<ng-template #ctrOwnerCell>
  <ng-container *ngIf="scenarioCTR.ctrOwner">
    <span
      class="status-label"
      [matTooltip]="ownerTooltip"
      data-test="pxd-cell-owner-name"
      >{{
        scenarioCTR.ctrOwner === currentUserFullName
          ? 'Your CTR'
          : scenarioCTR?.ctrOwner
      }}</span
    >
  </ng-container>
</ng-template>

<ng-template #ctrCell>
  <span class="status-label" data-test="pxd-cell-status-label">{{
    statusDescription
  }}</span>
  <span
    class="request-date"
    *ngIf="ctrSelected && scenarioCTR.ctrStatus === CtrStatus.NOT_STARTED"
    >{{ scenarioCTR.ctrRequestedDate | date }}</span
  >
</ng-template>
