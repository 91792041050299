<div
  *ngIf="assignedPxDs?.length > 0; else noAssignment"
  class="admin-selection-marker fill-cell-button"
  matTooltip="There are CTRs in progress"
  [matTooltipDisabled]="!deselectionDisabled"
  (click)="updatePxDAssignmentSelection()"
>
  <mat-icon *ngIf="deselectionDisabled" class="selection-lock">lock</mat-icon>
  <div
    *ngIf="enablePrincipalSelection"
    matTooltipPosition="above"
    [matTooltip]="principalMatTooltipMessage"
    [matTooltipDisabled]="
      !principalDeselectionDisabled && !enforcePrincipalSelection
    "
  >
    <mat-slide-toggle
      (click)="$event.stopPropagation()"
      [disabled]="principalDeselectionDisabled"
      [checked]="isPrincipal || enforcePrincipalSelection"
      (change)="togglePrincipalRole($event)"
      data-test="pxd-is-principal-toggle"
    ></mat-slide-toggle>
  </div>
  <span class="principal-label" *ngIf="isPrincipal">Principal</span>
  <span class="po-ok" *ngIf="poOk">POs ok</span>
</div>

<ng-template #noAssignment>
  <div
    matButton
    class="fill-cell-button"
    (click)="updatePxDAssignmentSelection()"
  ></div>
</ng-template>
