import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

import { legendItems } from '@common/pxd-grid/pxd-cells-legend/pxd-cells-legend.component';
import { PxDIndex } from '@models/pxd';

@Component({
  selector: 'app-pxd-selector',
  templateUrl: './pxd-selector.component.html',
})
export class PxdSelectorComponent {
  @Input() businessSegmentId: number;

  @Input() selectedPxD: PxDIndex;

  @Input() customData: PxDIndex[] = [];

  @Input() legendItems: any[] = [
    legendItems.WITH_MASTERDATA_AND_PRINCIPALS,
    legendItems.WITH_MASTERDATA_WITHOUT_PRINCIPALS,
    legendItems.WITHOUT_MASTERDATA_AND_PRINCIPALS,
    legendItems.WITHOUT_MASTERDATA_WITH_PRINCIPALS,
    legendItems.INACTIVE,
    legendItems.VALUE,
    legendItems.SELECTED,
  ];

  @Input() columnHeaderTemplate: TemplateRef<any> = null;

  @Input() rowHeaderTemplate: TemplateRef<any> = null;

  @Input() cellTemplate: TemplateRef<any> = null;

  @Output() selectedPxDChange = new EventEmitter<PxDIndex>();
}
