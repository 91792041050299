<div class="row" [formGroup]="calendarizationFilterFormGroup">
  <div class="col-xs-12 col-md-4 col-lg-6">
    <mat-form-field
      appearance="outline"
      *ngIf="scenarios$ | async as scenarios"
    >
      <mat-label>Scenario</mat-label>
      <mat-select formControlName="scenarioId">
        <mat-select-trigger *ngIf="findSelectedScenario(scenarios) as scenario">
          <app-scenario-status-icon
            [scenarioSubmitted]="scenario.submitted"
            [scenarioStatus]="scenario.scenarioStatus"
            [scenarioNumber]="scenario.scenarioNo"
          ></app-scenario-status-icon>
          &nbsp; {{ scenario.scenarioName }}
          <mat-icon
            *ngIf="scenario.baseCase"
            title="Basecase"
            class="basecase-icon"
            >star</mat-icon
          >
        </mat-select-trigger>
        <mat-option
          *ngFor="let scenario of scenarios"
          [value]="scenario.scenarioId"
        >
          <app-scenario-status-icon
            [scenarioSubmitted]="scenario.submitted"
            [scenarioStatus]="scenario.scenarioStatus"
            [scenarioNumber]="scenario.scenarioNo"
          ></app-scenario-status-icon>
          &nbsp; {{ scenario.scenarioName }}
          <mat-icon
            *ngIf="scenario.baseCase"
            title="Basecase"
            class="basecase-icon"
            >star</mat-icon
          >
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<app-calendarization-table-ctrs
  [scenarioId]="scenarioId$ | async"
></app-calendarization-table-ctrs>
