<div #container>
  <form
    *ngIf="projectScenarios.length > 0 !== false"
    [formGroup]="projectScenariosSection"
  >
    <table
      formArrayName="projectScenarios"
      aria-label="project-scenario-summary"
      class="project__scen__table"
    >
      <thead class="borders bottomborder">
        <tr>
          <th
            class="bold_header header-string-text"
            (click)="sortDataAsec('scenarioName')"
          >
            <div class="span-wrap">
              <span
                class="header-text header-string-text"
                data-test="project-scenarios-scenario-name-label"
                >Scenario name
              </span>
              <span class="icon_wrapper" (click)="sortDataAsec('scenarioName')">
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="decideArrowVisibility('scenarioName', 'upArrow')"
                    (click)="sortDataAsec('scenarioName')"
                    >ic_arrow_upward</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="
                      decideArrowVisibility('scenarioName', 'downArrow')
                    "
                    (click)="sortDataAsec('scenarioName')"
                    >ic_arrow_downward</i
                  >
                </span>
              </span>
              <span class="icon_wrapper" (click)="sortDataAsec('scenarioName')">
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="
                      decideExpandVisibility('scenarioName', 'upArrow')
                    "
                    (click)="sortDataAsec('scenarioName')"
                    >ic_expand_less</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="
                      decideExpandVisibility('scenarioName', 'downArrow')
                    "
                    (click)="sortDataAsec('scenarioName')"
                    >ic_expand_more</i
                  >
                </span>
              </span>
            </div>
          </th>
          <th
            class="bold_header header-string-text"
            (click)="sortDataAsec('status')"
          >
            <div class="span-wrap">
              <span
                class="header-text"
                data-test="project-scenarios-scenario-status-label"
                >Status</span
              >
              <span class="icon_wrapper" (click)="sortDataAsec('status')">
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="decideArrowVisibility('status', 'upArrow')"
                    (click)="sortDataAsec('status')"
                    >ic_arrow_upward</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="decideArrowVisibility('status', 'downArrow')"
                    (click)="sortDataAsec('status')"
                    >ic_arrow_downward</i
                  >
                </span>
              </span>
              <span class="icon_wrapper" (click)="sortDataAsec('status')">
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="decideExpandVisibility('status', 'upArrow')"
                    (click)="sortDataAsec('status')"
                    >ic_expand_less</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="decideExpandVisibility('status', 'downArrow')"
                    (click)="sortDataAsec('status')"
                    >ic_expand_more</i
                  >
                </span>
              </span>
            </div>
          </th>
          <th
            class="bold_header header-numeric-text"
            (click)="sortNumericDataAsec('totalCost')"
          >
            <div class="span-wrap">
              <span
                class="header-text"
                data-test="project-scenarios-scenario-total-cost-label"
                >Total cost</span
              >
              <span
                class="icon_wrapper"
                (click)="sortNumericDataAsec('totalCost')"
              >
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="decideArrowVisibility('totalCost', 'upArrow')"
                    (click)="sortNumericDataAsec('totalCost')"
                    >ic_arrow_upward</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="decideArrowVisibility('totalCost', 'downArrow')"
                    (click)="sortNumericDataAsec('totalCost')"
                    >ic_arrow_downward</i
                  >
                </span>
              </span>
              <span
                class="icon_wrapper"
                (click)="sortNumericDataAsec('totalCost')"
              >
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="decideExpandVisibility('totalCost', 'upArrow')"
                    (click)="sortNumericDataAsec('totalCost')"
                    >ic_expand_less</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="decideExpandVisibility('totalCost', 'downArrow')"
                    (click)="sortNumericDataAsec('totalCost')"
                    >ic_expand_more</i
                  >
                </span>
              </span>
            </div>
          </th>
          <th
            class="bold_header header-numeric-text"
            (click)="sortNumericDataAsec('totalHours')"
          >
            <div class="span-wrap">
              <span
                class="header-text"
                data-test="project-scenarios-scenario-total-hours-label"
                >Total hours</span
              >
              <span
                class="icon_wrapper"
                (click)="sortNumericDataAsec('totalHours')"
              >
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="decideArrowVisibility('totalHours', 'upArrow')"
                    (click)="sortNumericDataAsec('totalHours')"
                    >ic_arrow_upward</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="decideArrowVisibility('totalHours', 'downArrow')"
                    (click)="sortNumericDataAsec('totalHours')"
                    >ic_arrow_downward</i
                  >
                </span>
              </span>
              <span
                class="icon_wrapper"
                (click)="sortNumericDataAsec('totalHours')"
              >
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="decideExpandVisibility('totalHours', 'upArrow')"
                    (click)="sortNumericDataAsec('totalHours')"
                    >ic_expand_less</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="
                      decideExpandVisibility('totalHours', 'downArrow')
                    "
                    (click)="sortNumericDataAsec('totalHours')"
                    >ic_expand_more</i
                  >
                </span>
              </span>
            </div>
          </th>
          <th
            class="bold_header header-numeric-text"
            (click)="sortNumericDataAsec('totalGESSavings')"
          >
            <div class="span-wrap">
              <span
                class="header-text"
                data-test="project-scenarios-scenario-total-gec-label"
                >Total GEC utilization</span
              >
              <span
                (click)="sortNumericDataAsec('totalGESSavings')"
                class="icon_wrapper"
              >
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    (click)="sortNumericDataAsec('totalGESSavings')"
                    [ngClass]="
                      decideArrowVisibility('totalGESSavings', 'upArrow')
                    "
                    >ic_arrow_upward</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="
                      decideArrowVisibility('totalGESSavings', 'downArrow')
                    "
                    (click)="sortNumericDataAsec('totalGESSavings')"
                    >ic_arrow_downward</i
                  >
                </span>
              </span>
              <span
                class="icon_wrapper"
                (click)="sortNumericDataAsec('totalGESSavings')"
              >
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="
                      decideExpandVisibility('totalGESSavings', 'upArrow')
                    "
                    (click)="sortNumericDataAsec('totalGESSavings')"
                    >ic_expand_less</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="
                      decideExpandVisibility('totalGESSavings', 'downArrow')
                    "
                    (click)="sortNumericDataAsec('totalGESSavings')"
                    >ic_expand_more</i
                  >
                </span>
              </span>
            </div>
          </th>

          <th
            class="bold_header"
            data-test="project-scenarios-scenario-base-case-label"
          >
            Base case
          </th>
          <th
            class="bold_header header-numeric-text"
            (click)="sortDataAsec('submitted')"
          >
            <div class="span-wrap">
              <span
                class="header-text"
                data-test="project-scenarios-scenario-submitted-label"
                >Submitted</span
              >
              <span class="icon_wrapper" (click)="sortDataAsec('submitted')">
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="decideArrowVisibility('submitted', 'upArrow')"
                    (click)="sortDataAsec('submitted')"
                    >ic_arrow_upward</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyicon"
                    [ngClass]="decideArrowVisibility('submitted', 'downArrow')"
                    (click)="sortDataAsec('submitted')"
                    >ic_arrow_downward</i
                  >
                </span>
              </span>
              <span class="icon_wrapper" (click)="sortDataAsec('submitted')">
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="decideExpandVisibility('submitted', 'upArrow')"
                    (click)="sortDataAsec('submitted')"
                    >ic_expand_less</i
                  >
                </span>
                <span class="style-for-arrow">
                  <i
                    class="material-icons stickyiconexpand"
                    [ngClass]="decideExpandVisibility('submitted', 'downArrow')"
                    (click)="sortDataAsec('submitted')"
                    >ic_expand_more</i
                  >
                </span>
              </span>
            </div>
          </th>
          <th class="bold_header header-export-text">
            <span
              class="header-text"
              data-test="project-scenarios-scenario-export-label"
              >Export</span
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          class="borders bottomborder"
          *ngFor="
            let scenario of getProjectScenariosData();
            let scenarioIndex = index
          "
          [formGroupName]="scenarioIndex"
        >
          <tr class="borders bottomborder">
            <td>
              <a
                class="project-scen-achor"
                [matTooltip]="getScenarioTooltip(scenario.value)"
                [routerLink]="[
                  '/viewscenario',
                  projectId,
                  scenario?.get('scenarioId')?.value
                ]"
              >
                <app-scenario-status-icon
                  [scenarioStatus]="scenario.get('status')?.value"
                  [scenarioNumber]="scenario.get('number')?.value"
                  [scenarioSubmitted]="scenario.get('submitted')?.value"
                  data-test="project-scenarios-scenario-status-icon"
                ></app-scenario-status-icon>
                &nbsp;
                <p data-test="project-scenarios-scenario-name-value">
                  {{ scenario?.get('scenarioName')?.value }}
                </p>
                <mat-icon
                  *ngIf="
                    scenario?.get('setAsBaseCase')?.value ==
                    scenario?.get('scenarioId')?.value
                  "
                  title="Basecase"
                  class="basecase-icon"
                  data-test="project-scenarios-scenario-base-case-icon"
                  >star
                </mat-icon>
              </a>
            </td>
            <td>
              <span data-test="project-scenarios-scenario-status-value">{{
                scenario?.get('status')?.value
              }}</span>
            </td>
            <td class="projectscen-numeric-text">
              <span
                *ngIf="
                  scenario?.get('status')?.value != cancelledStatus &&
                  scenario?.get('totalCost')?.value > 0
                "
                data-test="project-scenarios-scenario-total-cost-value"
              >
                {{ scenario?.get('totalCost')?.value | number : '1.0-0' }}
                {{ defaultCurrency }}</span
              >
            </td>
            <td class="projectscen-numeric-text">
              <span
                *ngIf="
                  scenario?.get('status')?.value != cancelledStatus &&
                  scenario?.get('totalHours')?.value > 0
                "
                data-test="project-scenarios-scenario-total-hours-value"
                >{{
                  scenario?.get('totalHours')?.value | number : '1.0-0'
                }}</span
              >
            </td>
            <td
              class="projectscen-numeric-text"
              data-test="project-scenarios-scenario-total-gec-value"
            >
              <span
                *ngIf="
                  scenario?.get('status')?.value != cancelledStatus &&
                  scenario?.get('totalGESSavings')?.value > 0
                "
                >{{
                  scenario?.get('totalGESSavings')?.value | number : '1.0-0'
                }}
                %</span
              >
            </td>
            <td class="align-center">
              <mat-radio-group formControlName="setAsBaseCase">
                <mat-radio-button
                  type="radio"
                  class="bold_header"
                  [value]="scenario?.get('scenarioId').value"
                  (change)="selectAsBaseCase(scenario)"
                  data-test="project-scenarios-scenario-base-case-button"
                ></mat-radio-button>
              </mat-radio-group>
            </td>
            <td class="header-export-text">
              <span *ngIf="scenario?.get('status')?.value == completedStatus">
                <ng-container
                  *ngIf="
                    scenario?.get('submitted')?.value != null &&
                      scenario?.get('submitted')?.value != false &&
                      scenario?.get('submitted')?.value != true;
                    else notSubmitted
                  "
                >
                  <p data-test="project-scenarios-scenario-submitted-date">
                    {{ scenario?.get('submitted')?.value | date }}
                  </p>
                </ng-container>
                <ng-template #notSubmitted>
                  <mat-slide-toggle
                    formControlName="submitted"
                    *ngIf="!isProjectDisabled"
                    (toggleChange)="submitScenario(scenario)"
                  ></mat-slide-toggle>
                </ng-template>
              </span>
            </td>
            <td>
              <div
                class="export-links"
                *ngIf="
                  scenario?.get('status')?.value == completedStatus ||
                  scenario?.get('status')?.value === 'In progress'
                "
              >
                <a
                  (click)="downloadExcel(scenario?.get('scenarioId').value)"
                  data-test="project-summary-xls-export"
                  >XLS</a
                >
                <a
                  data-test="project-summary-client-xls-export"
                  (click)="
                    downloadClientExcel(scenario?.get('scenarioId').value)
                  "
                  >Client XLS</a
                >
                <a
                  (click)="downloadMDR(scenario?.get('scenarioId').value)"
                  data-test="project-summary-mdr-export"
                  >MDR</a
                >
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </form>

  <div class="error_submit">
    <span class="error__msg" *ngIf="showPromptError"
      >You cannot submit scenario which has already been submitted or not in
      completed state. Check and try again</span
    >
  </div>
  <div class="error_submit">
    <span class="error__msg" *ngIf="showPromptErrorExcel"
      >Scenarios that are in 'In progress' or 'Completed' status can only be
      exported.Please check and try again</span
    >
  </div>
  <div class="error_submit">
    <span class="error__msg" *ngIf="noCTRS"
      >Only scenarios with completed CTRs can be exported.</span
    >
  </div>
</div>
