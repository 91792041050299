import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import {
  LiveSaveSnackbarService,
  SnackbarSettings,
} from './live-save-snackbar.service';

enum SavingState {
  IDLE,
  SAVING,
  SAVED,
  FAILED,
}

@Component({
  selector: 'app-live-save-snackbar',
  templateUrl: './live-save-snackbar.component.html',
  styleUrls: ['./live-save-snackbar.component.scss'],
})
export class LiveSaveSnackbarComponent implements OnInit {
  public snackbarFadingOut: boolean;
  public snackbarSettings: SnackbarSettings;
  public readonly SavingState = SavingState;
  public savingState: SavingState = SavingState.IDLE;
  private resetTimeout: ReturnType<typeof setTimeout>;
  private fadeOutTimeout: ReturnType<typeof setTimeout>;

  constructor(
    private liveSaveSnackbarService: LiveSaveSnackbarService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.liveSaveSnackbarService.snackbarTriggerObservable.subscribe(
      (snackbarSettings: SnackbarSettings) => {
        this.reset();
        this.savingState = SavingState.SAVING;
        this.snackbarSettings = snackbarSettings;
        this.changeDetector.detectChanges();
      }
    );
    this.liveSaveSnackbarService.snackbarClosureObservable.subscribe(
      (success: boolean) => {
        this.savingState = success ? SavingState.SAVED : SavingState.FAILED;
        this.fadeOutTimeout = setTimeout(() => {
          this.snackbarFadingOut = true;
        }, 2000);
        this.resetTimeout = setTimeout(() => {
          this.reset();
        }, 2800);
      }
    );
  }

  reset(): void {
    this.savingState = SavingState.IDLE;
    this.snackbarSettings = null;
    this.snackbarFadingOut = false;
    clearTimeout(this.fadeOutTimeout);
    clearTimeout(this.resetTimeout);
  }
}
