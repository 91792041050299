<mat-toolbar class="fixed-top">
  <mat-toolbar-row class="main-topbar-row">
    <a routerLink="/" class="logo-box">
      <img src="/assets/logo.jpg" class="logo" alt="logo" />
      eCTR
    </a>

    <h1 class="page-name" data-test="header-page-title">
      {{ layoutService.pageName$ | async }}
    </h1>
    <div class="page-actions">
      <ng-template
        [cdkPortalOutlet]="layoutService.headerPortal$ | async"
      ></ng-template>

      <div class="banner" *ngIf="displaySurveyBanner">
        <a
          mat-flat-button
          color="survey"
          href="https://forms.office.com/e/rcyiizsjft"
          rel="noopener"
          target="__blank"
          matTooltip="Response deadline is April 17, 2023"
          >Take part in user survey!</a
        >
      </div>

      <a
        *ngIf="currentUser$ | async as currentUser"
        class="profile"
        [matMenuTriggerFor]="menu"
        data-test="header-user-profile-dropdown-trigger"
      >
        <app-user-avatar
          [avatar]="currentUser.email"
          [signature]="userSignature$ | async"
        ></app-user-avatar>

        <div class="user-displayname" data-test="header-user-profile-label">
          <div class="user-name">{{ currentUser.fullName }}</div>
          <div class="user-role">
            {{ shortRoleName$ | async }}, {{ currentUser.enggCenter }}
          </div>
        </div>

        <mat-menu #menu="matMenu" class="user-menu">
          <span
            (click)="$event.stopPropagation()"
            (mouseup)="$event.stopPropagation()"
            [disableRipple]="true"
            mat-menu-item
            class="label"
            data-test="header-user-profile-dropdown-user-label"
            >{{ currentUser.fullName }}</span
          >
          <a
            *ngIf="actualAdmin$ | async"
            mat-menu-item
            routerLink="/intermediate"
            data-test="header-user-profile-dropdown-change-user-button"
            >Change user</a
          >
          <mat-divider></mat-divider>
          <a
            (click)="logout()"
            mat-menu-item
            data-test="header-user-profile-dropdown-logout-button"
            >Logout</a
          >
        </mat-menu>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
