import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CurrentUserIsAdminGuard } from '@app/auth/current-user-is-admin.guard';

import { MasterdataPageComponent } from './masterdata-page/masterdata-page.component';

const routes: Routes = [
  {
    path: '',
    data: {
      pageTitle: 'Activities',
    },
    canActivate: [CurrentUserIsAdminGuard],
    component: MasterdataPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminMasterdataRoutingModule {}
