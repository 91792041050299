import { Component } from '@angular/core';

/**
 * Component used for routes that are never rendered for example when used for redirecting guards.
 */
@Component({
  selector: 'app-noop',
  template: '',
})
export class NoopComponent {}
