<ng-container *ngIf="businessSegmentId$ | async; else missingBussinesSegmentId">
  <div class="pxd-grid-wrapper" *ngIf="gridData$ | async as grid; else loading">
    <table class="pxd-grid" aria-label="pxd grid" #pxdTable>
      <thead>
        <tr>
          <th class="product-discipline-header"></th>
          <th
            *ngFor="
              let column of grid.columns;
              let columnIndex = index;
              trackBy: trackById
            "
            class="discipline-header"
            [class.inactive]="!column.value.isActive"
            [ngClass]="column.class || ''"
            [matTooltip]="column.tooltip"
          >
            <ng-container
              [ngTemplateOutlet]="
                columnHeaderTemplate ? columnHeaderTemplate : defaultHeader
              "
              [ngTemplateOutletContext]="{ $implicit: column }"
            ></ng-container>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let row of grid.rows;
            let rowIndex = index;
            trackBy: trackById
          "
        >
          <ng-container *ngIf="grid.data[row.id]">
            <th
              class="product-header"
              [class.inactive]="!row.value.isActive"
              [ngClass]="row.class"
              [matTooltip]="row.tooltip"
            >
              <ng-container
                [ngTemplateOutlet]="
                  rowHeaderTemplate ? rowHeaderTemplate : defaultHeader
                "
                [ngTemplateOutletContext]="{ $implicit: row }"
              ></ng-container>
            </th>
            <td
              *ngFor="
                let column of grid.columns;
                let columnIndex = index;
                trackBy: trackById
              "
              class="pxd-grid-cell"
              [ngClass]="grid.data[row.id][column.id].classes || ''"
              [class.selectable]="
                selectable && grid.data[row.id][column.id].selectable
              "
              (click)="
                onPxdCellSelect(row.id, column.id, grid.data[row.id][column.id])
              "
              (mouseenter)="onCellEnter(rowIndex, columnIndex)"
              (mouseleave)="onCellOut(rowIndex, columnIndex)"
              [attr.data-test]="
                'pxd-grid-pxd-cell-product-id-' +
                row.id +
                '-discipline-id-' +
                column.id
              "
            >
              <ng-container
                [ngTemplateOutlet]="cellTemplate ? cellTemplate : defaultCell"
                [ngTemplateOutletContext]="{
                  selected: grid.data[row.id][column.id].selected,
                  selectable:
                    selectable && grid.data[row.id][column.id].selectable,
                  product: row.value,
                  discipline: column.value,
                  pxd: grid.data[row.id][column.id].pxd,
                  data: grid.data[row.id][column.id].data,
                  classes: grid.data[row.id][column.id].classes,
                  cellType: grid.data[row.id][column.id].cellType
                }"
              ></ng-container>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-template #loading>
  <mat-spinner class="pxd-loader" color="primary" size="64"></mat-spinner>
</ng-template>

<ng-template #missingBussinesSegmentId>
  PxDGrid Error: Set [businessSegmentId]
</ng-template>

<ng-template #defaultHeader let-data>{{ data.name }}</ng-template>

<ng-template #defaultCell let-pxd="pxd" let-selected="selected">
  <app-admin-pxd-grid-cell [pxd]="pxd" [selected]="selected">
  </app-admin-pxd-grid-cell>
</ng-template>
