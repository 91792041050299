import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { IGetScenarioSummaryCTRsResponseItem } from '@models/scenario-summary-ctrs';

export const getScenarioSummaryCTRsAction = createAction(
  '[Collection ScenarioSummaryCTRs] GET /scenarios/:scenarioId/summary',
  contextAwareProps<{ payload: { scenarioId: number; } }>()
);

export const getScenarioSummaryCTRsSuccessAction = createAction(
  '[Collection ScenarioSummaryCTRs Effect] GET /scenarios/:scenarioId/summary SUCCESS',
  contextAwareProps<{ payload: IGetScenarioSummaryCTRsResponseItem[] }>()
);

export const getScenarioSummaryCTRsFailureAction = createAction(
  '[Collection ScenarioSummaryCTRs Effect] GET /scenarios/:scenarioId/summary FAILURE',
  contextAwareProps()
);
