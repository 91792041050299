<ng-container [formGroup]="commentFormGroup">
  <ng-container *ngIf="!showOptions">
    <div class="title">
      <h3>
        Your comment
        <a class="show-comment-button" (click)="showOptions = true"
          >Comment options</a
        >
      </h3>

      <app-breadcrumbs
        [projectId]="projectId"
        [scenarioId]="commentFormGroup.get('scenarioId').value"
        [ctrId]="commentFormGroup.get('scenarioCTRId').value"
      ></app-breadcrumbs>
    </div>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Comment Message</mat-label>
      <textarea
        matInput
        formControlName="message"
        (keypress)="inputControl($event)"
      ></textarea>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="showOptions">
    <div class="title">
      <h3>Comment options</h3>
      <mat-icon class="close-options" (click)="showOptions = false"
        >close</mat-icon
      >
    </div>

    <div class="form">
      <mat-form-field appearance="outline">
        <mat-label>Scenario</mat-label>
        <mat-select formControlName="scenarioId">
          <mat-option
            *ngFor="let scenario of scenarios$ | async"
            [value]="scenario.scenarioId"
            >{{ scenario.scenarioName }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>CTR</mat-label>
        <mat-select formControlName="scenarioCTRId">
          <mat-option
            *ngFor="let ctr of ctrs$ | async"
            [value]="ctr.scenarioCTRId"
            >{{ ctr.ctrShortName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
</ng-container>
