import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { AdminActivity } from '@models/admin-activity';
import { AdminBlock } from '@models/admin-block';
import { AdminScope } from '@models/admin-scope';

import {
  addActivitySuccessAction,
  addBlockAction,
  addBlockFailureAction,
  addBlockSuccessAction,
  addScopeAction,
  addScopeFailureAction,
  addScopeSuccessAction,
  deleteAdminActivityAction,
  deleteAdminActivityFailureAction,
  deleteAdminActivitySuccessAction,
  deleteAdminBlockAction,
  deleteAdminBlockSuccessAction,
  deleteAdminScopeAction,
  deleteAdminScopeFailureAction,
  deleteAdminScopeSuccessAction,
  exportMasterDataAction,
  exportMasterDataFailureAction,
  exportMasterDataSuccessAction,
  getActivitiesAutocompletionDataSuccessAction,
  getAdminActivityAction,
  getAdminActivityFailureAction,
  getAdminActivitySuccessAction,
  getAdminBlockAction,
  getAdminBlockActivitiesAction,
  getAdminBlockActivitiesFailureAction,
  getAdminBlockActivitiesSuccessAction,
  getAdminBlockFailureAction,
  getAdminBlockScopesAction,
  getAdminBlockScopesFailureAction,
  getAdminBlockScopesSuccessAction,
  getAdminBlockSuccessAction,
  getAdminPxDBlocksAction,
  getAdminPxDBlocksFailureAction,
  getAdminPxDBlocksSuccessAction,
  getAdminScopeAction,
  getAdminScopeFailureAction,
  getAdminScopeSuccessAction,
  getScopesAutocompletionDataSuccessAction,
  openEditActivityAction,
  openEditBlockAction,
  openEditScopeAction,
  reloadMasterdataAction,
  updateActivitySuccessAction,
  updateBlockAction,
  updateBlockFailureAction,
  updateBlockScopesOrderSuccessAction,
  updateBlockSuccessAction,
  updatePxDBlocksOrderSuccessAction,
  updateScopeAction,
  updateScopeActivitiesOrderSuccessAction,
  updateScopeFailureAction,
  updateScopeSuccessAction,
} from './admin-activities.actions';

export const FEATURE_KEY = 'admin-masterdata';

export interface IAdminActivitiesCollectionState
  extends EntityState<AdminActivity> {
  loaded: boolean;
  qtyTypes: string[];
  types: string[];
}

export interface IAdminScopeCollectionState extends EntityState<AdminScope> {
  loaded: boolean;
  qtyTypes: string[];
  qtyValues: { [key: string]: string };
}

export interface IAdminBlockCollectionState extends EntityState<AdminBlock> {
  loaded: boolean;
}

export interface IAdminActivitiesState {
  blocks: IAdminBlockCollectionState;
  scopes: IAdminScopeCollectionState;
  activities: IAdminActivitiesCollectionState;
  exportingMasterdata: boolean;
}

export const adminActivitiesEntityAdapter = createEntityAdapter<AdminActivity>({
  selectId: ({ id }) => id,
});

export const adminScopesEntityAdapter = createEntityAdapter<AdminScope>({
  selectId: ({ scopeId }) => scopeId,
});

export const adminBlocksEntityAdapter = createEntityAdapter<AdminBlock>({
  selectId: ({ blockId }) => blockId,
});

export const initialState = {
  blocks: adminBlocksEntityAdapter.getInitialState({
    loaded: false,
  }),
  scopes: adminScopesEntityAdapter.getInitialState({
    loaded: false,
    qtyTypes: [],
    qtyValues: {},
  }),
  activities: adminActivitiesEntityAdapter.getInitialState({
    loaded: false,
    qtyTypes: [],
    types: [],
  }),
  exportingMasterdata: false,
} as IAdminActivitiesState;

export const reducer = createReducer(
  initialState,

  // General Masterdata
  on(exportMasterDataAction, (state) => ({
    ...state,
    exportingMasterdata: true,
  })),

  on(exportMasterDataSuccessAction, exportMasterDataFailureAction, (state) => ({
    ...state,
    exportingMasterdata: false,
  })),

  on(reloadMasterdataAction, (state) => ({
    ...state,
    blocks: adminBlocksEntityAdapter.removeAll({
      ...state.blocks,
      loaded: false,
    }),
    scopes: adminScopesEntityAdapter.removeAll({
      ...state.scopes,
      loaded: false,
    }),
    activities: adminActivitiesEntityAdapter.removeAll({
      ...state.activities,
      loaded: false,
    }),
  })),

  // Blocks

  on(getAdminBlockAction, openEditBlockAction, (state) => ({
    ...state,
    blocks: {
      ...state.blocks,
      loaded: false,
    },
  })),

  on(getAdminBlockSuccessAction, (state, { payload }) => ({
    ...state,
    blocks: adminBlocksEntityAdapter.upsertOne(payload, {
      ...state.blocks,
      loaded: true,
    }),
  })),

  on(getAdminBlockFailureAction, (state) => ({
    ...state,
    blocks: {
      ...state.blocks,
      loaded: true,
    },
  })),

  on(getAdminPxDBlocksAction, (state) => ({
    ...state,
    blocks: {
      ...state.blocks,
      loaded: false,
    },
  })),

  on(getAdminPxDBlocksSuccessAction, (state, { payload }) => ({
    ...state,
    blocks: adminBlocksEntityAdapter.upsertMany(payload, {
      ...state.blocks,
      loaded: true,
    }),
  })),

  on(getAdminPxDBlocksFailureAction, (state) => ({
    ...state,
    blocks: {
      ...state.blocks,
      loaded: true,
    },
  })),

  on(updateBlockAction, addBlockAction, (state) => ({
    ...state,
    blocks: {
      ...state.blocks,
      loaded: false,
    },
  })),

  on(addBlockSuccessAction, updateBlockSuccessAction, (state, { payload }) => ({
    ...state,
    blocks: adminBlocksEntityAdapter.upsertOne(
      {
        ...payload,
      },
      {
        ...state.blocks,
        loaded: true,
      }
    ),
  })),

  on(updateBlockFailureAction, addBlockFailureAction, (state) => ({
    ...state,
    blocks: {
      ...state.blocks,
      loaded: true,
    },
  })),

  on(deleteAdminBlockAction, (state) => ({
    ...state,
    blocks: {
      ...state.blocks,
      loaded: false,
    },
  })),

  on(deleteAdminBlockSuccessAction, (state, { payload }) => ({
    ...state,
    blocks: adminBlocksEntityAdapter.removeOne(payload.blockId, {
      ...state.blocks,
      loaded: true,
    }),
  })),

  on(deleteAdminScopeFailureAction, (state) => ({
    ...state,
    blocks: {
      ...state.blocks,
      loaded: true,
    },
  })),

  on(updatePxDBlocksOrderSuccessAction, (state, { payload }) => ({
    ...state,
    blocks: adminBlocksEntityAdapter.updateMany(
      payload.blocks.map(({ id, order }) => ({ id, changes: { order } })),
      {
        ...state.blocks,
        loaded: true,
      }
    ),
  })),

  // Scopes

  on(getAdminScopeAction, openEditScopeAction, (state) => ({
    ...state,
    scopes: {
      ...state.scopes,
      loaded: false,
    },
  })),

  on(getAdminScopeSuccessAction, (state, { payload }) => ({
    ...state,
    scopes: adminScopesEntityAdapter.upsertOne(payload, {
      ...state.scopes,
      loaded: true,
    }),
  })),

  on(getAdminScopeFailureAction, (state) => ({
    ...state,
    scopes: {
      ...state.scopes,
      loaded: true,
    },
  })),

  on(getAdminBlockScopesAction, (state) => ({
    ...state,
    scopes: {
      ...state.scopes,
      loaded: false,
    },
  })),

  on(getAdminBlockScopesSuccessAction, (state, { payload }) => ({
    ...state,
    scopes: adminScopesEntityAdapter.upsertMany(payload, {
      ...state.scopes,
      loaded: true,
    }),
  })),

  on(getAdminBlockScopesFailureAction, (state) => ({
    ...state,
    scopes: {
      ...state.scopes,
      loaded: true,
    },
  })),

  on(updateScopeAction, addScopeAction, (state) => ({
    ...state,
    scopes: {
      ...state.scopes,
      loaded: false,
    },
  })),

  on(addScopeSuccessAction, updateScopeSuccessAction, (state, { payload }) => ({
    ...state,
    scopes: adminScopesEntityAdapter.upsertOne(
      {
        ...payload,
      },
      {
        ...state.scopes,
        loaded: true,
      }
    ),
  })),

  on(addScopeFailureAction, updateScopeFailureAction, (state) => ({
    ...state,
    scopes: {
      ...state.scopes,
      loaded: true,
    },
  })),

  on(deleteAdminScopeAction, (state) => ({
    ...state,
    scopes: {
      ...state.scopes,
      loaded: false,
    },
  })),

  on(deleteAdminScopeSuccessAction, (state, { payload }) => ({
    ...state,
    scopes: adminScopesEntityAdapter.removeOne(payload.scopeId, {
      ...state.scopes,
      loaded: true,
    }),
  })),

  on(deleteAdminScopeFailureAction, (state) => ({
    ...state,
    scopes: {
      ...state.scopes,
      loaded: true,
    },
  })),

  on(getScopesAutocompletionDataSuccessAction, (state, { payload }) => ({
    ...state,
    scopes: {
      ...state.scopes,
      qtyTypes: payload.scopeQtyType,
      qtyValues: payload.scopeQtyVal,
    },
  })),

  on(updateBlockScopesOrderSuccessAction, (state, { payload }) => ({
    ...state,
    scopes: adminScopesEntityAdapter.updateMany(
      payload.scopes.map(({ id, order }) => ({ id, changes: { order } })),
      {
        ...state.scopes,
        loaded: true,
      }
    ),
  })),

  // Activities

  on(getAdminActivityAction, openEditActivityAction, (state) => ({
    ...state,
    activities: {
      ...state.activities,
      loaded: false,
    },
  })),
  on(getAdminActivitySuccessAction, (state, { payload }) => ({
    ...state,
    activities: adminActivitiesEntityAdapter.upsertOne(
      {
        description: null,
        ...payload,
      },
      {
        ...state.activities,
        loaded: true,
      }
    ),
  })),
  on(getAdminActivityFailureAction, (state) => ({
    ...state,
    activities: {
      ...state.activities,
      loaded: true,
    },
  })),

  on(getAdminBlockActivitiesAction, (state) => ({
    ...state,
    activities: {
      ...state.activities,
      loaded: false,
    },
  })),
  on(getAdminBlockActivitiesSuccessAction, (state, { payload }) => ({
    ...state,
    activities: adminActivitiesEntityAdapter.upsertMany(
      payload.map((activity) => ({ description: null, ...activity })),
      {
        ...state.activities,
        loaded: true,
      }
    ),
  })),
  on(getAdminBlockActivitiesFailureAction, (state) => ({
    ...state,
    activities: {
      ...state.activities,
      loaded: true,
    },
  })),

  on(updateActivitySuccessAction, (state, { payload }) => ({
    ...state,
    activities: adminActivitiesEntityAdapter.upsertOne(
      {
        description: null,
        ...payload,
      },
      {
        ...state.activities,
      }
    ),
  })),

  on(addActivitySuccessAction, (state, { payload }) => ({
    ...state,
    activities: adminActivitiesEntityAdapter.upsertOne(payload, {
      ...state.activities,
    }),
  })),

  on(deleteAdminActivityAction, (state) => ({
    ...state,
    activities: {
      ...state.activities,
      loaded: false,
    },
  })),

  on(deleteAdminActivitySuccessAction, (state, { payload }) => ({
    ...state,
    activities: adminActivitiesEntityAdapter.removeOne(payload.activityId, {
      ...state.activities,
      loaded: true,
    }),
  })),

  on(deleteAdminActivityFailureAction, (state) => ({
    ...state,
    activities: {
      ...state.activities,
      loaded: true,
    },
  })),

  on(getActivitiesAutocompletionDataSuccessAction, (state, { payload }) => ({
    ...state,
    activities: {
      ...state.activities,
      qtyTypes: payload.qtyType,
      types: payload.type,
    },
  })),

  on(updateScopeActivitiesOrderSuccessAction, (state, { payload }) => ({
    ...state,
    activities: adminActivitiesEntityAdapter.updateMany(
      payload.activities.map(({ id, order }) => ({ id, changes: { order } })),
      {
        ...state.activities,
        loaded: true,
      }
    ),
  }))
);
