import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectAllActiveEntities } from '@collections/entities/store/entities.selectors';

@Component({
  selector: 'app-entity-multi-selector',
  templateUrl: './entity-multi-selector.component.html',
  styleUrls: ['./entity-multi-selector.component.scss'],
})
export class EntityMultiSelectorComponent {
  constructor(private store: Store) {}

  @Input() public selectedEntitiesIds: number[] = [];

  @Input() public pxdsByEntity: {
    [entityId: number]: { pxdsCount: number };
  } = {};

  @Output() public selectedEntitiesIdsChange = new EventEmitter();

  public availableEntities$ = this.store.select(selectAllActiveEntities);

  public onEntityToggle(entityId: number, checked: boolean) {
    if (checked) {
      this.selectedEntitiesIds = [...this.selectedEntitiesIds, entityId];
    } else {
      this.selectedEntitiesIds = this.selectedEntitiesIds.filter(
        (id) => id !== entityId
      );
    }

    this.selectedEntitiesIdsChange.next(this.selectedEntitiesIds);
  }
}
