import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { IGetProjectTypesResponseItem } from '@models/project-types';

export const getProjectTypesAction = createAction(
  '[Collection ProjectTypes] GET /projectTypes',
  contextAwareProps()
);

export const getProjectTypesSuccessAction = createAction(
  '[Collection ProjectTypes Effect] GET /projectTypes SUCCESS',
  contextAwareProps<{ payload: IGetProjectTypesResponseItem[] }>()
);

export const getProjectTypesFailureAction = createAction(
  '[Collection ProjectTypes Effect] GET /projectTypes FAILURE',
  contextAwareProps()
);
