import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';

import { AdminBlock } from '@models/admin-block';
import { IPxD } from '@models/pxd';

import { MasterdataTableService } from '../masterdata-table.service';
import {
  openAddBlockAction,
  openReorderPxdBlocksAction,
} from '../store/admin-activities.actions';

@Component({
  selector: 'app-masterdata-table',
  templateUrl: './masterdata-table.component.html',
  styleUrls: ['./masterdata-table.component.scss'],
  providers: [MasterdataTableService],
})
export class MasterdataTableComponent implements OnChanges {
  @Input() public pxd: IPxD;

  public blocksLabelMap = {
    '=0': '0 blocks',
    '=1': '1 block',
    other: '# blocks',
  };

  public scopesLabelMap = {
    '=0': '0 scopes',
    '=1': '1 scope',
    other: '# scopes',
  };

  public activitiesLabelMap = {
    '=0': '0 activities',
    '=1': '1 activity',
    other: '# activities',
  };

  constructor(
    public tableService: MasterdataTableService,
    private store: Store
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.pxd) {
      this.tableService.selectPxD(this.pxd);
    }
  }

  public openReorderBlocks() {
    this.store.dispatch(
      openReorderPxdBlocksAction({
        context: 'MasterdataTableComponent::openReorderBlock',
        payload: { ctrId: this.pxd.id },
      })
    );
  }

  public openAddBlock() {
    this.store.dispatch(
      openAddBlockAction({
        context: 'MasterdataTableComponent::openAddBlock',
        payload: {
          ctrId: this.pxd.id,
        },
      })
    );
  }

  public trackByBlock(index: number, block: AdminBlock) {
    return block.blockId;
  }
}
