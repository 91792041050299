import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest, defer, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { selectAllActiveBusinessSegments } from '@collections/business-segments/store/business-segments.selectors';
import { selectSegmentPxDsFactory } from '@collections/pxds/store/pxds.selectors';
import { AdminActivity } from '@models/admin-activity';

import { AdminActivitiesApiService } from '../admin-activities-api.service';
import {
  getAdminBlockScopesAction,
  getAdminPxDBlocksAction,
} from '../store/admin-activities.actions';
import {
  selectAdminActivitiesQtyTypesOptions,
  selectAdminActivitiesTypeOptions,
  selectAdminBlocksByPxDFactory,
  selectAdminScopesByBlockIdFactory,
} from '../store/admin-activities.selectors';
import { ActivityDisplayNameValidator } from '../validators/activity-display-name.validator';

export const activityQtyValueOptions = {
  INHERITED: { id: '1', label: 'Inherit from scope' },
  QTY: { id: 'QTY', label: 'Quantity' },
  TOGGLE: { id: 'YES/NO', label: 'Toggle' },
  CTR_DURATION: { id: 'NbrOfWeeks', label: 'CTR Duration' },
  DELEGATION: { id: 'DelegateScope', label: 'Delegation' },
};

const parseStringToNumber = map((v: string) => parseInt(v, 10));

@Component({
  selector: 'app-edit-activity-form',
  templateUrl: './edit-activity-form.component.html',
  styleUrls: ['./edit-activity-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ActivityDisplayNameValidator],
})
export class EditActivityFormComponent implements OnInit, OnDestroy {
  @Input() originalScopeId: number;

  @Input() public originalActivityCode = '';

  @Input() public warnWhenMoving = true;

  @Input() set data(value: Partial<AdminActivity>) {
    this.formGroup.patchValue(value);
    this.activityDisplayNameValidator.setScopeId(value.scopeId);
    this.activityDisplayNameValidator.setActivityId(value.id);
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity({ emitEvent: true, onlySelf: true });
  }

  @Input() set canChangePath(value: boolean) {
    if (value) {
      this.formGroup.get('businessSegmentId').enable();
      this.formGroup.get('pxdId').enable();
      this.formGroup.get('blockId').enable();
      this.formGroup
        .get('scopeId')
        .enable({ onlySelf: false, emitEvent: true });
    } else {
      this.formGroup.get('businessSegmentId').disable();
      this.formGroup.get('pxdId').disable();
      this.formGroup.get('blockId').disable();
      this.formGroup
        .get('scopeId')
        .disable({ onlySelf: false, emitEvent: true });
    }
  }

  public businessSegmentsOptions$ = this.store.select(
    selectAllActiveBusinessSegments
  );

  private businessSegmentIdValue$ =
    this.createFormInputValue('businessSegmentId').pipe(parseStringToNumber);

  public pxdOptions$ = this.businessSegmentIdValue$.pipe(
    switchMap((businessSegmentId) =>
      this.store.select(selectSegmentPxDsFactory(businessSegmentId))
    ),
    shareReplay(1)
  );

  private pxdIdValue$ =
    this.createFormInputValue('pxdId').pipe(parseStringToNumber);

  public blockOptions$ = this.pxdIdValue$.pipe(
    withLatestFrom(this.pxdOptions$),
    map(([pxdId, pxds]) => pxds.find((pxd) => pxd.id === pxdId)),
    switchMap((pxd) => {
      if (!pxd) {
        return of([]);
      }
      this.store.dispatch(
        getAdminPxDBlocksAction({
          context: 'EditActivityFormComponent::blockOptions$',
          payload: pxd,
        })
      );
      return this.store.select(selectAdminBlocksByPxDFactory(pxd.id));
    }),
    shareReplay(1)
  );

  private blockIdValue$ =
    this.createFormInputValue('blockId').pipe(parseStringToNumber);

  public scopeOptions$ = this.blockIdValue$.pipe(
    withLatestFrom(this.blockOptions$),
    map(([blockId, blocks]) =>
      blocks.find((block) => block.blockId === blockId)
    ),
    switchMap((block) => {
      if (!block) {
        return of([]);
      }
      this.store.dispatch(getAdminBlockScopesAction({ payload: block }));
      return this.store.select(
        selectAdminScopesByBlockIdFactory(block.blockId)
      );
    }),
    shareReplay(1)
  );

  private scopeIdValue$ =
    this.createFormInputValue('scopeId').pipe(parseStringToNumber);

  public unitOptions$ = this.store.select(selectAdminActivitiesQtyTypesOptions);

  private typeValue$ = this.createFormInputValue('type');

  private qtyTypeValue$ = this.createFormInputValue('qtyType');

  private activityCodeValue$ = this.createFormInputValue('activityCode');

  public qtyValueOptions$ = this.typeValue$.pipe(
    map((type) =>
      type === 'STD'
        ? [
            activityQtyValueOptions.INHERITED,
            activityQtyValueOptions.QTY,
            activityQtyValueOptions.TOGGLE,
            activityQtyValueOptions.CTR_DURATION,
            activityQtyValueOptions.DELEGATION,
          ]
        : [activityQtyValueOptions.QTY, activityQtyValueOptions.TOGGLE]
    )
  );

  public typeOptions$ = this.store.select(selectAdminActivitiesTypeOptions);

  public formGroup: UntypedFormGroup = this.formBuilder.group({
    isActive: [true, [Validators.required]],
    businessSegmentId: [{ value: null, disabled: true }, [Validators.required]],
    pxdId: [
      { value: null, disabled: false },
      [],
      // [Validators.required],
      // [inArrayValidator(this.pxdOptions$, 'id')],
    ],
    blockId: [
      { value: null, disabled: false },
      [],
      // [Validators.required],
      // [inArrayValidator(this.blockOptions$, 'blockId')],
    ],
    scopeId: [
      { value: null },
      [Validators.required],
      // [inArrayValidator(this.scopeOptions$, 'scopeId')],
    ],
    id: [null],
    activityCode: [{ value: null, disabled: true }, [Validators.required]],
    activityDisplayName: [
      null,
      [Validators.required],
      [this.activityDisplayNameValidator],
    ],
    description: [null, [Validators.maxLength(500)]],
    type: ['STD', [Validators.required]],
    isDeliverable: [false],
    engStdHrs: [0],
    engIterationFactor: [1],
    dftStdHrs: [0],
    dftIterationFactor: [1],
    qtyType: ['item', [Validators.required, Validators.maxLength(20)]],
    qtyVal: ['QTY', [Validators.required]],
    order: [],
  });

  @Output() dataChange = this.formGroup.valueChanges.pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    map(() => {
      const data: AdminActivity = this.formGroup.getRawValue();

      data.qtyType = data.qtyType.toLowerCase();
      data.dftStdHrs = data.dftStdHrs ?? 0;
      data.dftIterationFactor = data.dftIterationFactor ?? 1;
      data.engStdHrs = data.engStdHrs ?? 0;
      data.engIterationFactor = data.engIterationFactor ?? 1;

      return data;
    })
  );

  @Output() valid = this.formGroup.valueChanges.pipe(
    map(() => this.formGroup.valid)
  );

  private subscriptions = new Subscription();

  public suggestedActivityCode$ = this.scopeIdValue$.pipe(
    distinctUntilChanged(),
    switchMap((scopeId) =>
      !!scopeId &&
      (this.originalScopeId !== scopeId || !this.originalActivityCode)
        ? this.adminActivitiesApiService.getAvailableActivityCode(scopeId).pipe(
            map(({ code }) => code || ''),
            catchError(() => of(''))
          )
        : of(this.originalActivityCode)
    ),
    shareReplay(1)
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private adminActivitiesApiService: AdminActivitiesApiService,
    private activityDisplayNameValidator: ActivityDisplayNameValidator,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    // reset form values on context changes
    this.subscriptions.add(
      combineLatest([
        this.pxdOptions$,
        this.pxdIdValue$,
        this.businessSegmentIdValue$,
      ]).subscribe(([options, pxdId]) => {
        if (pxdId !== null && options.every((option) => option.id !== pxdId)) {
          this.formGroup.get('pxdId').setValue(null, { emitEvent: false });
        }
      })
    );

    this.subscriptions.add(
      combineLatest([
        this.blockOptions$,
        this.blockIdValue$,
        this.pxdIdValue$,
      ]).subscribe(([options, blockId]) => {
        if (
          blockId !== null &&
          options.every((option) => option.blockId !== blockId)
        ) {
          this.formGroup.get('blockId').setValue(null, { emitEvent: false });
        }
      })
    );

    this.subscriptions.add(
      combineLatest([
        this.scopeOptions$,
        this.scopeIdValue$,
        this.blockIdValue$,
      ]).subscribe(([options, scopeId]) => {
        if (
          scopeId !== null &&
          options.every((option) => option.scopeId !== scopeId)
        ) {
          this.formGroup.get('scopeId').setValue(null, { emitEvent: false });
        }
      })
    );

    this.subscriptions.add(
      this.scopeIdValue$.pipe(distinctUntilChanged()).subscribe((scopeId) => {
        this.activityDisplayNameValidator.setScopeId(scopeId);
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity({ emitEvent: true });
      })
    );

    this.subscriptions.add(
      this.typeValue$
        .pipe(withLatestFrom(this.qtyValueOptions$))
        .subscribe(([type, qtyValOptions]) => {
          const qtyVal = this.formGroup.get('qtyVal').value;
          if (!qtyValOptions.find(({ id }) => id === qtyVal)) {
            this.formGroup
              .get('qtyVal')
              .setValue(qtyValOptions[0].id, { emitEvent: true });
          }
        })
    );

    this.subscriptions.add(
      this.suggestedActivityCode$
        .pipe(
          startWith(null),
          pairwise(),
          withLatestFrom(this.activityCodeValue$),
          map(([[previousSuggestedCode, suggestedBlockCode], activityCode]) =>
            !activityCode || activityCode === previousSuggestedCode
              ? suggestedBlockCode
              : activityCode
          )
        )
        .subscribe((activityCode) => {
          this.formGroup
            .get('activityCode')
            .setValue(activityCode, { emitEvent: true });
          this.cdr.markForCheck();
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private createFormInputValue(formControlName: string) {
    return defer(() =>
      this.formGroup
        .get(formControlName)
        .valueChanges.pipe(
          startWith(this.formGroup.get(formControlName).value),
          distinctUntilChanged()
        )
    ).pipe(shareReplay(1));
  }
}
