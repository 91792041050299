<form
  [formGroup]="formGroup"
  class="row"
>
  <div class="col-xs-12 checkbox-wrapper">
    <mat-slide-toggle formControlName="isActive">Active</mat-slide-toggle>
  </div>

  <mat-form-field
    appearance="outline"
    class="col-xs-12 col-md-4 col-lg-6"
  >
    <mat-label>Segment</mat-label>
    <mat-select
      formControlName="businessSegmentId"
      matTooltip="Can't edit as this activity is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('businessSegmentId').disabled"
    >
      <mat-option
        *ngFor="let option of businessSegmentsOptions$ | async"
        [value]="option.businessSegmentId"
      >
        {{ option.businessSegmentName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="col-xs-12 col-md-8 col-lg-6"
  >
    <mat-label>P x D</mat-label>
    <mat-select
      formControlName="pxdId"
      matTooltip="Can't edit as this activity is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('pxdId').disabled"
    >
      <mat-option
        *ngFor="let option of pxdOptions$ | async"
        [value]="option.id"
      >
        {{ option.shortName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="col-xs-12 col-md-6"
  >
    <mat-label>Block</mat-label>
    <mat-select
      formControlName="blockId"
      matTooltip="Can't edit as this activity is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('blockId').disabled"
    >
      <mat-option
        *ngFor="let option of blockOptions$ | async"
        [value]="option.blockId"
      >
        {{ option.blockDisplayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="col-xs-12 col-md-6"
  >
    <mat-label>Scope</mat-label>
    <mat-select
      formControlName="scopeId"
      matTooltip="Can't edit as this activity is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('scopeId').disabled"
    >
      <mat-option
        *ngFor="let option of scopeOptions$ | async"
        [value]="option.scopeId"
      >
        {{ option.scopeDisplayName }}
      </mat-option>
    </mat-select>
    <mat-hint></mat-hint>
  </mat-form-field>

  <div
    class="col-xs-12"
    *ngIf="warnWhenMoving && !!formGroup?.get('id').value && !!formGroup?.get('scopeId').value && originalScopeId != formGroup?.get('scopeId').value"
  >
    <div class="alert alert-warning">
      <mat-icon>info</mat-icon>
      <div>
        This activity will be moved to a different scope.
      </div>
    </div>
  </div>

  <mat-form-field
    appearance="outline"
    class="col-xs-12 col-md-6"
  >
    <mat-label>Activity code</mat-label>
    <input
      matInput
      formControlName="activityCode"
      matTooltip="Can't edit as this activity is already used by some CTR's."
      [matTooltipDisabled]="!formGroup?.get('activityCode').disabled"
    >
    <mat-error *ngIf="formGroup.controls.activityCode.errors?.required">
      WBS code is required
    </mat-error>
  </mat-form-field>

  <hr>

  <mat-form-field
    appearance="outline"
    class="col-xs-12"
  >
    <mat-label>Display name</mat-label>
    <input
      #input
      matInput
      maxlength="150"
      formControlName="activityDisplayName"
    >
    <span
      matSuffix
      class="name-hint"
    >
      {{input.value?.length || 0}}/150
    </span>

    <mat-error *ngIf="formGroup.controls.activityDisplayName.errors?.required">
      Name is required
    </mat-error>

    <mat-error *ngIf="formGroup.controls.activityDisplayName.errors?.duplicateName">
      This name is already used by other activity in this scope
    </mat-error>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="col-xs-12"
  >
    <mat-label>Description</mat-label>
    <textarea
      appCharacterCounter
      matInput
      formControlName="description"
      maxLength="500"
    ></textarea>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="col-xs-12 col-md-6"
  >
    <mat-label>Type</mat-label>
    <mat-select formControlName="type">
      <mat-option
        *ngFor="let option of typeOptions$ | async"
        [value]="option.id"
      >
        {{ option.label }}
      </mat-option>
    </mat-select>
    <mat-hint>
      <p>Options are:</p>
      <dl>
        <dt>Standard Activity</dt>
        <dd>If scope is selected, this activities hours will automatically be included in CTR. </dd>
        <dt>Standard Complexity</dt>
        <dd>CTR owner can choose whether to include those hours in the scope.</dd>
      </dl>
    </mat-hint>
  </mat-form-field>

  <div class="col-xs-12 col-md-6 checkbox-wrapper">
    <mat-slide-toggle formControlName="isDeliverable">Deliverable</mat-slide-toggle>
  </div>

  <hr>

  <div class="col-xs-12 col-md-6 column">
    <mat-form-field
      appearance="outline"
      class="col-xs-12"
    >
      <mat-label>Engineering hours</mat-label>
      <input
        matInput
        formControlName="engStdHrs"
        type="number"
        min="-9999"
        max="9999"
      >
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="col-xs-12"
    >
      <mat-label>Eng. iteration factor</mat-label>
      <input
        matInput
        formControlName="engIterationFactor"
        type="number"
        min="0"
        max="9.99"
        step="0.01"
      >
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="col-xs-12"
      *ngIf="unitOptions$ | async as unitOptions"
    >
      <mat-label>Unit</mat-label>
      <input
        matInput
        formControlName="qtyType"
        maxLength="20"
        pattern="[\-a-z0-9 ]+"
        [matAutocomplete]="qtyTypeAutocomplete"
      >
      <mat-autocomplete #qtyTypeAutocomplete="matAutocomplete">
        <mat-option
          *ngFor="let option of unitOptions"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint>
        <p>Unit in which quantity of this activity is defined. Ex: item, x-section, day, case, campaign, etc.<br>
          For Standard activities Unit will be inherited from Scope Unit.<br>
          Preferably use standard options. Default is “Item”. Type your own short type if needed.<br />
        </p>
        <dl>
          <dt>Weeks</dt>
          <dd>quantity will be automatically calculated according to CTR duration</dd>
        </dl>
      </mat-hint>
    </mat-form-field>

  </div>

  <div class="col-xs-12 col-md-6 column">

    <mat-form-field
      appearance="outline"
      class="col-xs-12"
    >
      <mat-label>Drafting hours</mat-label>
      <input
        matInput
        formControlName="dftStdHrs"
        type="number"
        min="-9999"
        max="9999"
      >
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="col-xs-12"
    >
      <mat-label>Dft. iteration factor</mat-label>
      <input
        matInput
        formControlName="dftIterationFactor"
        type="number"
        min="0"
        max="9.99"
        step="0.01"
      >
    </mat-form-field>


    <mat-form-field
      appearance="outline"
      class="col-xs-12"
    >
      <mat-label>Quantity value</mat-label>
      <mat-select formControlName="qtyVal">
        <mat-option
          *ngFor="let option of qtyValueOptions$ | async"
          [value]="option.id"
        >
          {{ option.label }}
        </mat-option>
      </mat-select>
      <mat-hint>
        <p>Options are:</p>
        <dl>
          <dt>Inherit from scope</dt>
          <dd>value is inherited from scope</dd>
          <dt>Quantity</dt>
          <dd>CTR owner can enter own numeric value</dd>
          <dt>Toggle</dt>
          <dd>CTR owner can toggle it on or off</dd>
          <dt>CTR Duration</dt>
          <dd>calculated according to CTR duration</dd>
          <dt>Delegation</dt>
          <dd>calculated automatically if there is scope delegation</dd>
        </dl>
      </mat-hint>
    </mat-form-field>
  </div>
</form>