import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';

import { LiveSaveSnackbarComponent } from './live-save-snackbar.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [LiveSaveSnackbarComponent],
  exports: [LiveSaveSnackbarComponent],
})
export class LiveSaveSnackbarModule {}
