import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbsComponent } from './breadcrumbs.component';

const components = [
  BreadcrumbsComponent
]

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule
  ]
})
export class BreadcrumbsModule { }
