import { createFeatureSelector, createSelector } from '@ngrx/store';
import _ from 'lodash';

import { IGetEntitiesResponseItem } from '@app/models/entities';
import {
  FEATURE_KEY,
  IEntitiesCollectionState,
  entitiesEntityAdapter,
} from './entities.reducer';

export const selectEntitiesCollectionState =
  createFeatureSelector<IEntitiesCollectionState>(FEATURE_KEY);

const { selectAll, selectEntities } = entitiesEntityAdapter.getSelectors(
  selectEntitiesCollectionState
);

export const selectAllEntities = createSelector(selectAll, (v = []) => v);

export const selectEntitiesMap = createSelector(selectEntities, (v = {}) => v);

export const selectAllActiveEntities = createSelector(
  selectAllEntities,
  (entities): IGetEntitiesResponseItem[] =>
    _.sortBy(
      entities.filter((entity) => entity.isActive),
      ['enggCenter']
    )
);

export const selectAllActiveEntitiesMap = createSelector(
  selectAllActiveEntities,
  (entities) =>
    entities.reduce((r, entity) => ({ ...r, [entity.entityId]: entity }), {})
);

export const selectEntityByIdFactory = (id: number) =>
  createSelector(selectAllActiveEntitiesMap, (entities) =>
    entities[id] ? entities[id] : null
  );
