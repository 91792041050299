import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';

import { CtrStatusIconComponent } from './ctr-status-icon.component';

const components = [CtrStatusIconComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, MaterialModule],
  exports: components,
})
export class CtrStatusIconModule {}
