import { createReducer } from '@ngrx/store';

export const FEATURE_KEY = 'project-statuses';

export interface IProjectStatusesCollectionState {
  projectAvailableStatuses: string[];
}

const initialState: IProjectStatusesCollectionState = {
  projectAvailableStatuses: ['Not started', 'In progress', 'Completed'],
};

export const reducer = createReducer(initialState);
