import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { Discipline } from '@models/discipline';
import { OrderableItem } from '@models/orderable-item';
import { Product } from '@models/product';
import {
  IPxD,
  IPxDDetailed,
  ProductsAndDisciplines,
  PxDPrincipals,
} from '@models/pxd';

export const getMinifiedPxDsAction = createAction(
  '[Collection PxDs] GET /CTRs/defined/minified/:businessSegmentId',
  contextAwareProps<{ payload: { businessSegmentId: number } }>()
);

export const getMinifiedPxDsSuccessAction = createAction(
  '[Collection PxDs Effect] GET /CTRs/defined/minified/:businessSegmentId SUCCESS',
  contextAwareProps<{ payload: IPxD[] }>()
);

export const getMinifiedPxDsFailureAction = createAction(
  '[Collection PxDs Effect] GET /CTRs/defined/minified/:businessSegmentId FAILURE',
  contextAwareProps()
);

export const getPxDsPrincipalsAction = createAction(
  '[Collection PxDs] GET /CTRs/defined/:businessSegmentId',
  contextAwareProps<{ payload: { businessSegmentId: number } }>()
);
export const getPxDsPrincipalsSuccessAction = createAction(
  '[Collection PxDs Effect] GET /CTRs/defined/:businessSegmentId SUCCESS',
  contextAwareProps<{ payload: PxDPrincipals[] }>()
);

export const getPxDsPrincipalsFailureAction = createAction(
  '[Collection PxDs Effect] GET /CTRs/defined/:businessSegmentId FAILURE',
  contextAwareProps()
);

export const getProductsAndDisciplinesAction = createAction(
  '[Collection PxDs] GET /CTRs/:businessSegmentId',
  contextAwareProps<{ payload: { businessSegmentId: number } }>()
);

export const getProductsAndDisciplinesSuccessAction = createAction(
  '[Collection PxDs Effect] GET /CTRs/:businessSegmentId SUCCESS',
  contextAwareProps<{
    payload: ProductsAndDisciplines & { businessSegmentId: number };
  }>()
);

export const getProductsAndDisciplinesFailureAction = createAction(
  '[Collection PxDs Effect] GET /CTRs/:businessSegmentId FAILURE',
  contextAwareProps()
);

export const openReorderPxDsAction = createAction(
  '[Collection PxDs Effect] open order PxDs dialog',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
    };
  }>()
);

export const updatePxDsOrderAction = createAction(
  '[Collection PxDs Effect] PATCH /pxd/productsanddisciplines/order',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
      disciplines: OrderableItem[];
      products: OrderableItem[];
    };
  }>()
);

export const updatePxDsOrderSuccessAction = createAction(
  '[Collection PxDs Effect] PATCH /pxd/productsAndDisciplines/order SUCCESS',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
      disciplines: OrderableItem[];
      products: OrderableItem[];
    };
  }>()
);

export const updatePxDsOrderFailureAction = createAction(
  '[Collection PxDs Effect] PATCH /pxd/productsAndDisciplines/order FAILURE',
  contextAwareProps()
);

export const openCreateProductAction = createAction(
  '[Collection PxDs Effects] open create product dialog',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
    };
  }>()
);

export const createProductAction = createAction(
  '[Collection PxDs Effect] POST /admin/products/:businessSegmentId',
  contextAwareProps<{ payload: Product }>()
);

export const createProductSuccessAction = createAction(
  '[Collection PxDs Effect] POST /admin/products/:businessSegmentId SUCCESS',
  contextAwareProps<{ payload: Product }>()
);

export const createProductFailureAction = createAction(
  '[Collection PxDs Effect] POST /admin/products/:businessSegmentId FAILURE',
  contextAwareProps()
);

export const openCreateDisciplineAction = createAction(
  '[Collection PxDs Effects] open create discipline dialog',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
    };
  }>()
);

export const createDisciplineAction = createAction(
  '[Collection PxDs Effect] POST /admin/disciplines/:businessSegmentId',
  contextAwareProps<{ payload: Discipline }>()
);

export const createDisciplineSuccessAction = createAction(
  '[Collection PxDs Effect] POST /admin/disciplines/:businessSegmentId SUCCESS',
  contextAwareProps<{ payload: Discipline }>()
);

export const createDisciplineFailureAction = createAction(
  '[Collection PxDs Effect] POST /admin/disciplines/:businessSegmentId FAILURE',
  contextAwareProps()
);

export const openEditProductAction = createAction(
  '[Collection PxDs Effects] open edit product dialog',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
      productId: number;
    };
  }>()
);

export const editProductAction = createAction(
  '[Collection PxDs Effect] PUT /admin/products/:productId',
  contextAwareProps<{ payload: Product }>()
);

export const editProductSuccessAction = createAction(
  '[Collection PxDs Effect] PUT /admin/products/:productId SUCCESS',
  contextAwareProps<{ payload: Product }>()
);

export const editProductFailureAction = createAction(
  '[Collection PxDs Effect] PUT /admin/products/:productId FAILURE',
  contextAwareProps()
);

export const openEditDisciplineAction = createAction(
  '[Collection PxDs Effects] open edit discipline dialog',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
      disciplineId: number;
    };
  }>()
);

export const editDisciplineAction = createAction(
  '[Collection PxDs Effect] PUT /admin/disciplines/:disciplineId',
  contextAwareProps<{ payload: Discipline }>()
);

export const editDisciplineSuccessAction = createAction(
  '[Collection PxDs Effect] PUT /admin/disciplines/:disciplineId SUCCESS',
  contextAwareProps<{ payload: Discipline }>()
);

export const editDisciplineFailureAction = createAction(
  '[Collection PxDs Effect] PUT /admin/disciplines/:disciplineId FAILURE',
  contextAwareProps()
);

export const deleteProductAction = createAction(
  '[Collection PxDs Effect] DELETE /admin/products/:productId',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
      productId: number;
    };
  }>()
);

export const deleteProductSuccessAction = createAction(
  '[Collection PxDs Effect] DELETE /admin/products/:productId SUCCESS',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
      productId: number;
    };
  }>()
);

export const deleteProductFailureAction = createAction(
  '[Collection PxDs Effect] DELETE /admin/products/:productId FAILURE',
  contextAwareProps()
);

export const deleteDisciplineAction = createAction(
  '[Collection PxDs Effect] DELETE /admin/disciplines/:disciplineId',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
      disciplineId: number;
    };
  }>()
);

export const deleteDisciplineSuccessAction = createAction(
  '[Collection PxDs Effect] DELETE /admin/disciplines/:disciplineId SUCCESS',
  contextAwareProps<{
    payload: {
      businessSegmentId: number;
      disciplineId: number;
    };
  }>()
);

export const deleteDisciplineFailureAction = createAction(
  '[Collection PxDs Effect] DELETE /admin/disciplines/:disciplineId FAILURE',
  contextAwareProps()
);

export const getDetailedPxDAction = createAction(
  '[Collection PxDs Effect] GET /admin/pxd/ctr/:pxdId',
  contextAwareProps<{ payload: { pxdId: number } }>()
);

export const getDetailedPxDSuccessAction = createAction(
  '[Collection PxDs Effect] GET /admin/pxd/ctr/:pxdId SUCCESS',
  contextAwareProps<{ payload: IPxDDetailed }>()
);

export const getDetailedPxDFailureAction = createAction(
  '[Collection PxDs Effect] GET /admin/pxd/ctr/:pxdId FAILURE',
  contextAwareProps()
);

export const editPxDAction = createAction(
  '[Collection Pxds] PATCH /admin/pxd/ctr/:pxdId',
  contextAwareProps<{ payload: { pxd: IPxDDetailed } }>()
);

export const editPxDSuccessAction = createAction(
  '[Collection Pxds Effect] PATCH /admin/pxd/ctr/:pxdId SUCCESS',
  contextAwareProps<{ payload: IPxDDetailed }>()
);

export const editPxDFailureAction = createAction(
  '[Collection Pxds Effect] PATCH /admin/pxd/ctr/:pxdId FAILURE',
  contextAwareProps()
);
