import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { IGetBusinessSegmentsResponseItem } from '@models/business-segments';

export const getBusinessSegmentsAction = createAction(
  '[Collection BusinessSegments] GET /admin/businessSegments',
  contextAwareProps()
);

export const getBusinessSegmentsSuccessAction = createAction(
  '[Collection BusinessSegments Effect] GET /admin/businessSegments SUCCESS',
  contextAwareProps<{ payload: IGetBusinessSegmentsResponseItem[] }>()
);

export const getBusinessSegmentsFailureAction = createAction(
  '[Collection BusinessSegments Effect] GET /admin/businessSegments FAILURE',
  contextAwareProps()
);
