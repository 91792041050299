import { NgModule } from '@angular/core';
import { CalendarizationTableSharedService } from './calendarization-table-shared.service';
import { CalendarizationTableTotalInMonthComponent } from './calendarization-table-total-in-month/calendarization-table-total-in-month.component';

@NgModule({
  imports: [CalendarizationTableTotalInMonthComponent],
  providers: [CalendarizationTableSharedService],
  exports: [CalendarizationTableTotalInMonthComponent],
})
export class CalendarizationTableSharedModule {}
