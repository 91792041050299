import { NgModule } from '@angular/core';

import { EntityMultiSelectorModule } from '@common/entity-multiselector/entity-multi-selector.module';
import { PxdGridModule } from '@common/pxd-grid/pxd-grid.module';
import { SharedModule } from '@shared/shared.module';

import { UserResponsibilitiesModule } from '../user-responsibilities/user-responsibilities.module';

import { EditUserFormComponent } from './edit-user-form.component';

const components = [EditUserFormComponent];

@NgModule({
  declarations: components,
  imports: [
    SharedModule,
    PxdGridModule,
    EntityMultiSelectorModule,
    UserResponsibilitiesModule,
  ],
  exports: components,
})
export class EditUserFormModule {}
