<div *ngIf="businessSegments$ | async as businessSegments">

  <div class="label">Segment:</div>
  <mat-radio-group
    class="options"
    aria-label="Select an option"
    [ngModel]="selectedBusinessSegmentId"
    (ngModelChange)="selectionChange($event)"
  >
    <mat-radio-button
      class="option"
      *ngFor="let segment of businessSegments"
      [value]="segment.businessSegmentId"
    >{{ segment.businessSegmentName }}</mat-radio-button>
  </mat-radio-group>
</div>