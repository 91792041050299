import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UserAvatarComponent } from './user-avatar.component';

const components = [
  UserAvatarComponent
]

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule
  ]
})
export class UserAvatarModule { }
