import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IGetProjectNameResponseItem } from '@models/project';

@Injectable({ providedIn: 'root' })
export class ProjectsNamesApiService {
  constructor(private httpClient: HttpClient) {}

  public getProjectNames() {
    return this.httpClient.get<IGetProjectNameResponseItem[]>(
      '{apiUrl}projects/names'
    );
  }
}
