import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AdminBlockRequestData } from '@models/admin-block';

export interface EditBlockDialogData {
  title: string;
  actionLabel: string;
  copyFrom: number;
  blockData: Partial<AdminBlockRequestData>;
}

@Component({
  selector: 'app-edit-block-dialog',
  templateUrl: './edit-block-dialog.component.html',
})
export class EditBlockDialogComponent {
  public title = this.data.title;

  public actionLabel = this.data.actionLabel;

  public blockData: Partial<AdminBlockRequestData> = {
    ...this.data.blockData,
  };

  public canSave = false;

  public copyMode = !!this.data.copyFrom;

  public canChangePath = !this.data.blockData.isUsed || this.copyMode;

  public originalCtrId = this.data.blockData.ctrId;

  public originalBlockCode = this.data.blockData.blockCode;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditBlockDialogData) {}

  public updateState(valid: boolean) {
    const changed = Object.entries(this.blockData).some(
      ([key, value]) => this.data.blockData[key] !== value
    );

    this.canSave = (changed || this.copyMode) && valid;
  }

  public markCopyChildren(value: boolean) {
    this.blockData = {
      ...this.blockData,
      copyFrom: value ? this.data.copyFrom : null,
    };
  }
}
