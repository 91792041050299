import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ScenarioStatus } from '@common/enums';

@Component({
  selector: 'app-scenario-status-icon',
  templateUrl: './scenario-status-icon.component.html',
  styleUrls: ['./scenario-status-icon.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ScenarioStatusIconComponent implements OnChanges {
  @Input() scenarioStatus: ScenarioStatus;
  @Input() scenarioId: number;
  @Input() scenarioNumber: number;
  @Input() scenarioSubmitted: boolean;

  scenarioStatusClass: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scenarioStatus) {
      this.scenarioStatusClass = this.getScenarioStatusClass(
        this.scenarioStatus
      );
    }
  }

  getScenarioStatusClass(scenarioStatus: ScenarioStatus): string {
    const mappedScenarioStatus =
      this.getMappedScenarioStatusClass(scenarioStatus);
    const isScenarioSubmitted = this.getScenarioSubmissionClass();
    const shouldScenarioNumberBeShrunk =
      this.checkIfScenarioNumberShouldBeShrunk();
    return [
      mappedScenarioStatus,
      isScenarioSubmitted,
      shouldScenarioNumberBeShrunk,
    ]
      .filter((segment: string) => !!segment)
      .join(' ');
  }

  checkIfScenarioNumberShouldBeShrunk() {
    if (this.scenarioNumber > 9) {
      return 'shrunk';
    }
  }

  getMappedScenarioStatusClass(scenarioStatus: ScenarioStatus): string {
    switch (scenarioStatus) {
      case ScenarioStatus.COMPLETED: {
        return 'completed-status';
      }
      case ScenarioStatus.IN_PROGRESS: {
        return 'inprogress-status';
      }
      case ScenarioStatus.CANCELLED: {
        return 'cancelled-status';
      }
      case ScenarioStatus.NOT_STARTED: {
        return 'notstarted-status';
      }
      case ScenarioStatus.ON_HOLD: {
        return 'onhold-status';
      }
      default: {
        return '';
      }
    }
  }

  getScenarioSubmissionClass(): string {
    if (this.scenarioSubmitted) {
      return 'submitted-scenario';
    }
  }
}
