import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IGetEventLogResponseItem } from '@models/event-log';

import {
  getEventLogAction,
  getEventLogFailureAction,
  getEventLogSuccessAction,
} from './event-log.actions';

export const FEATURE_KEY = 'event-log';

export interface IEventLogCollectionState
  extends EntityState<IGetEventLogResponseItem> {
  loaded: boolean;
}

export const eventLogEntityAdapter = createEntityAdapter<
  IGetEventLogResponseItem
>({
  selectId: ({ eventId }) => eventId,
});

export const initialState = eventLogEntityAdapter.getInitialState({
  loaded: false,
} as IEventLogCollectionState);

export const reducer = createReducer(
  initialState,
  on(getEventLogAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getEventLogSuccessAction, (state, { payload }) =>
    eventLogEntityAdapter.upsertMany(payload.response, {
      ...state,
      loaded: true,
    })
  ),
  on(getEventLogFailureAction, (state) => ({
    ...state,
    loaded: true,
  })),
)
