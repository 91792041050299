import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { OrderableItem } from '@models/orderable-item';

export interface OrderDialogData {
  title: string;
  elementTypeLabel: string;
  path: string[];
  actionLabel: string;
  list: OrderableItem[];
}

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss'],
})
export class OrderDialogComponent {
  public title = this.data.title;

  public breadCrumbs = this.data.path;

  public elementTypeLabel = this.data.elementTypeLabel;

  public actionLabel = this.data.actionLabel;

  public list: OrderableItem[] = [...this.data.list];

  public canSave = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: OrderDialogData) {}

  public reorder({ previousIndex, currentIndex }) {
    moveItemInArray(this.list, previousIndex, currentIndex);
    this.list.forEach((item, index) => (item.order = index + 1));

    this.canSave =
      JSON.stringify(this.list.map(({ code }) => code)) !==
      JSON.stringify(this.data.list.map(({ code }) => code));
  }

  public trackById(index: number, item: OrderableItem) {
    return item.id;
  }
}
