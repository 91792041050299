import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_KEY,
  IBusinessSegmentsCollectionState,
  businessSegmentsEntityAdapter,
} from './business-segments.reducer';

export const selectBusinessSegmentsCollectionState =
  createFeatureSelector<IBusinessSegmentsCollectionState>(FEATURE_KEY);

const { selectAll, selectEntities } =
  businessSegmentsEntityAdapter.getSelectors(
    selectBusinessSegmentsCollectionState
  );

export const selectAllBusinessSegments = createSelector(selectAll, (v) => v);

export const selectBusinessSegmentsMap = createSelector(
  selectEntities,
  (v) => v
);

export const selectAllActiveBusinessSegments = createSelector(
  selectAllBusinessSegments,
  (segments) => segments
);
