import { Component, Input } from '@angular/core';

export const legendItems: Record<string, { classes: string; label: string }> = {
  WITH_MASTERDATA_AND_PRINCIPALS: {
    classes: 'pxd-with-master-data pxd-with-principals',
    label: 'PxDs visible for admin and on public site (MD and POs ok)',
  },
  WITH_MASTERDATA_WITHOUT_PRINCIPALS: {
    classes: 'pxd-with-master-data pxd-without-principals',
    label: 'Masterdata (MD) is present',
  },
  WITHOUT_MASTERDATA_AND_PRINCIPALS: {
    classes: 'pxd-without-master-data pxd-without-principals',
    label: 'No master data yet',
  },
  WITHOUT_MASTERDATA_WITH_PRINCIPALS: {
    classes: 'pxd-without-master-data pxd-with-principals',
    label: 'All necessary POs assigned',
  },
  INACTIVE: { classes: 'pxd-disabled', label: 'Inactive' },
  VALUE: { classes: 'pxd-count', label: 'Number of CTRs requested' },
  SELECTED: { classes: 'selected', label: 'Selected' },
};

@Component({
  selector: 'app-pxd-cells-legend',
  templateUrl: './pxd-cells-legend.component.html',
  styleUrls: ['./pxd-cells-legend.component.scss'],
})
export class PxdCellsLegendComponent {
  @Input() public legendItems = [
    legendItems.WITH_MASTERDATA_AND_PRINCIPALS,
    legendItems.WITH_MASTERDATA_WITHOUT_PRINCIPALS,
    legendItems.WITHOUT_MASTERDATA_AND_PRINCIPALS,
    legendItems.WITHOUT_MASTERDATA_WITH_PRINCIPALS,
    legendItems.INACTIVE,
    legendItems.VALUE,
    legendItems.SELECTED,
  ];
}
