import { NgModule } from '@angular/core';

import { NumbersInputGuardDirective } from './numbers-input-guard.directive';

const components = [NumbersInputGuardDirective];

@NgModule({
  declarations: components,
  exports: components,
})
export class NumbersInputGuardModule {}
