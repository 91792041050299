<div
  class="snackbar"
  *ngIf="savingState !== SavingState.IDLE"
  [ngStyle]="snackbarSettings?.position"
  [class.fading-out]="snackbarFadingOut"
  [ngSwitch]="savingState"
>
  <ng-container *ngSwitchCase="SavingState.SAVING">
    <span class="saving-state">Saving...</span>
  </ng-container>
  <ng-container *ngSwitchCase="SavingState.SAVED">
    <span class="saving-state">Saved</span>
    <mat-icon class="check-icon">
      done
    </mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="SavingState.FAILED">
    <span class="saving-state">Saving failed!</span>
  </ng-container>
</div>