import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { OrderableItem } from '@models/orderable-item';

export interface PxDOrderDialogData {
  actionLabel: string;
  products: OrderableItem[];
  disciplines: OrderableItem[];
}

@Component({
  selector: 'app-pxd-order-dialog',
  templateUrl: './pxd-order-dialog.component.html',
  styleUrls: ['./pxd-order-dialog.component.scss'],
})
export class PxDOrderDialogComponent {
  public actionLabel = this.data.actionLabel;

  public products: OrderableItem[] = [...this.data.products];

  public disciplines: OrderableItem[] = [...this.data.disciplines];

  public canSave = false;

  private initialValueHash = this.generateValueHash(
    this.products,
    this.disciplines
  );

  constructor(@Inject(MAT_DIALOG_DATA) public data: PxDOrderDialogData) {}

  public reorder(list, { previousIndex, currentIndex }) {
    moveItemInArray(list, previousIndex, currentIndex);
    list.forEach((item, index) => (item.order = index + 1));

    this.canSave =
      this.generateValueHash(this.products, this.disciplines) !==
      this.initialValueHash;
  }

  private generateValueHash(
    products: OrderableItem[],
    disciplines: OrderableItem[]
  ) {
    return (
      JSON.stringify(products.map(({ id }) => id)) +
      JSON.stringify(disciplines.map(({ id }) => id))
    );
  }

  public trackById(index: number, item: OrderableItem) {
    return item.id;
  }
}
