import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IAssumptionsAndExclusionsResponse } from '@models/assumptions-and-exclusions-response';
import { getAssumptionsAndExclusionsSuccessAction } from '@collections/assumptions-and-exclusions/store/assumptions-and-exclusions.actions';

export const FEATURE_KEY = 'assumptions-and-exclusions';

export type IAssumptionsAndExclusionsState = EntityState<IAssumptionsAndExclusionsResponse>

export const assumptionsAndExclusionsEntityAdapter = createEntityAdapter<
  IAssumptionsAndExclusionsResponse
>({
  selectId: ({ ctrId }) => ctrId,
});

export const initialState = assumptionsAndExclusionsEntityAdapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(getAssumptionsAndExclusionsSuccessAction, (state, { payload }) =>
    assumptionsAndExclusionsEntityAdapter.upsertOne(payload, state)
  )
);
