import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IProjectScenarioComparison } from '@models/scenario-comparison';

import { getScenarioComparisonSuccessAction } from './scenario-comparison.actions';

export const FEATURE_KEY = 'scenario-comparison';

export interface IScenarioComparisonCollectionState
  extends EntityState<IProjectScenarioComparison> {
  loaded: boolean;
}

export const scenarioComparisonEntityAdapter =
  createEntityAdapter<IProjectScenarioComparison>({
    selectId: ({ projectId }) => projectId,
  });

export const initialState = scenarioComparisonEntityAdapter.getInitialState({
  loaded: false,
} as IScenarioComparisonCollectionState);

export const reducer = createReducer(
  initialState,
  on(getScenarioComparisonSuccessAction, (state, { payload }) =>
    scenarioComparisonEntityAdapter.upsertOne(payload, { ...state })
  )
);
