import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';

export const lockAcquiredAction = createAction(
  '[Lock] acquired',
  contextAwareProps()
);

export const lockRemovedAction = createAction(
  '[Lock] removed',
  contextAwareProps()
);

export const lockExpiredAction = createAction(
  '[Lock] expired',
  contextAwareProps()
);
