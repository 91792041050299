import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CtrStatus, ScenarioStatus } from '@common/enums';

export interface BasicCtrDetails {
  ctrStatus: CtrStatus | string;
}
export interface DialogConfig {
  downloadHandlers: {
    downloadExcelHandler: () => void;
    downloadClientExcelHandler: () => void;
    downloadiQuestExcelHandler: () => void;
    downloadPdfHandler: () => void;
    downloadMdrHandler: () => void;
    downloadPdfClientHandler?: () => void;
  };
  content: {
    title: string;
    status: ScenarioStatus | CtrStatus;
    ctrName?: string;
    revisionNumber?: number;
    scenarioName?: string;
    scenarioNumber?: string;
    scenarioId: number;
    ctrsData: BasicCtrDetails[];
  };
}

@Component({
  selector: 'app-document-export-dialog',
  templateUrl: './document-export-dialog.component.html',
  styleUrls: ['./document-export-dialog.component.scss'],
})
export class DocumentExportDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DocumentExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogConfig: DialogConfig
  ) {}
  getCtrsQuantityDescription(): string {
    this.dialogConfig.content.ctrsData =
      this.dialogConfig.content.ctrsData.filter(
        (x) => x.ctrStatus != CtrStatus.CANCELLED
      );
    const exportedCtrsQuantity = this.dialogConfig.content.ctrsData.length;
    const ctrsInProgressQuantity =
      this.dialogConfig.content.ctrsData.filter(
        (ctrData) => ctrData.ctrStatus === CtrStatus.IN_PROGRESS
      )?.length || 0;
    const completedCtrsQuantity =
      this.dialogConfig.content.ctrsData.filter(
        (ctrData) => ctrData.ctrStatus === CtrStatus.COMPLETED
      )?.length || 0;
    return `${exportedCtrsQuantity} CTRs: ${ctrsInProgressQuantity} In progress, ${completedCtrsQuantity} Completed`;
  }

  getStatusColorClass(): string {
    const scenarioStatus = this.dialogConfig.content.status;
    if (scenarioStatus === ScenarioStatus.COMPLETED) {
      return 'completed-status';
    } else if (scenarioStatus === ScenarioStatus.IN_PROGRESS) {
      return 'inprogress-status';
    } else if (scenarioStatus === ScenarioStatus.CANCELLED) {
      return 'cancelled-status';
    } else if (scenarioStatus === ScenarioStatus.NOT_STARTED) {
      return 'notstarted-status';
    } else if (scenarioStatus === ScenarioStatus.ON_HOLD) {
      return 'onhold-status';
    }
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }
}
