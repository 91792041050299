import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { IGetProjectNameResponseItem } from '@models/project';

export const getProjectNamesAction = createAction(
  '[Collection ProjectNames] GET /projectNames',
  contextAwareProps()
);

export const getProjectNamesSuccessAction = createAction(
  '[Collection ProjectNames Effect] GET /projectNames SUCCESS',
  contextAwareProps<{ payload: IGetProjectNameResponseItem[] }>()
);

export const getProjectNamesFailureAction = createAction(
  '[Collection ProjectNames Effect] GET /projectNames FAILURE',
  contextAwareProps()
);
