import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { EquipAssignsApiService } from '@collections/equip-assigns/equip-assigns-api.service';
import {
  getEquipAssignsAction,
  getEquipAssignsActionFailure,
  getEquipAssignsActionSuccess,
} from '@collections/equip-assigns/store/equip-assigns.actions';
import { selectEquipAssignsByIdFactory } from '@collections/equip-assigns/store/equip-assigns.selectors';

@Injectable()
export class EquipAssignsEffects {
  public getEquipAssigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEquipAssignsAction),
      switchMap((action) =>
        this.equipAssignsApiService
          .getEquipAssigns(action.payload.ctrId, action.payload.equipmentId)
          .pipe(
            withLatestFrom(
              this.store.select(
                selectEquipAssignsByIdFactory(action.payload.equipmentId)
              )
            ),
            map(([equipAssigns, alreadyStoredEquipAssigns]) =>
              getEquipAssignsActionSuccess({
                context: 'EquipAssignsEffects::getEquipAssigns$',
                trigger: action,
                payload: {
                  ...alreadyStoredEquipAssigns,
                  ...equipAssigns,
                },
              })
            ),
            catchError(() =>
              of(
                getEquipAssignsActionFailure({
                  context: 'EquipAssignsEffects::getProject$',
                  trigger: action,
                  payload: action.payload,
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private equipAssignsApiService: EquipAssignsApiService,
    private store: Store
  ) {}
}
