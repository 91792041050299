import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserRole } from '@core/store/core.reducer';

import { FiltersEffects } from './store/filters.effects';
import { reducer } from './store/filters.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('requestor-filters', (state, action) =>
      !action.payload ||
      !action.payload.context ||
      action.payload.context === UserRole.REQUESTOR
        ? reducer(state, action)
        : state
    ),
    StoreModule.forFeature('engineer-filters', (state, action) =>
      !action.payload ||
      !action.payload.context ||
      action.payload.context === UserRole.ENGINEER
        ? reducer(state, action)
        : state
    ),
    EffectsModule.forFeature([FiltersEffects]),
  ],
})
export class FiltersCollectionModule {}
