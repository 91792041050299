import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import {
  filter,
  first,
  map,
  share,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import { selectPxDsMap } from '@collections/pxds/store/pxds.selectors';
import {
  ConfirmDialogComponent,
  DialogData,
} from '@common/confirm-dialog/confirm-dialog.component';
import {
  OrderDialogComponent,
  OrderDialogData,
} from '@common/order-dialog/order-dialog.component';
import { selectAdminActivitiesLocked } from '@core/lock/store/lock.selectors';
import { AdminActivity } from '@models/admin-activity';
import { AdminBlockRequestData } from '@models/admin-block';
import { AdminScopeRequestData } from '@models/admin-scope';

import {
  EditActivityDialogComponent,
  EditActivityDialogData,
} from './edit-activity-dialog/edit-activity-dialog.component';
import {
  EditBlockDialogComponent,
  EditBlockDialogData,
} from './edit-block-dialog/edit-block-dialog.component';
import {
  EditScopeDialogComponent,
  EditScopeDialogData,
} from './edit-scope-dialog/edit-scope-dialog.component';
import {
  addActivityAction,
  addBlockAction,
  addScopeAction,
  getAdminActivityAction,
  getAdminBlockAction,
  getAdminScopeAction,
  updateActivityAction,
  updateBlockAction,
  updateScopeAction,
} from './store/admin-activities.actions';
import {
  selectAdminActivitiesByScopeIdFactory,
  selectAdminActivityFactory,
  selectAdminBlockFactory,
  selectAdminBlocksByPxDFactory,
  selectAdminBlocksLoaded,
  selectAdminScopeFactory,
  selectAdminScopesByBlockIdFactory,
  selectBlockAncestorsPathFactory,
} from './store/admin-activities.selectors';

@Injectable({
  providedIn: 'root',
})
export class AdminActivitiesService {
  public lockExpired$ = this.store.select(selectAdminActivitiesLocked).pipe(
    filter((v) => !v),
    share()
  );

  constructor(private dialog: MatDialog, private store: Store) {}

  private openDialog<T, S>(dialog: ComponentType<T>, data: MatDialogConfig<S>) {
    data.maxHeight = '95vh';
    data.maxWidth = 'calc(min(90vw, 1200px))';
    const dialogRef = this.dialog.open<T, S>(dialog, data);

    this.lockExpired$
      .pipe(take(1), takeUntil(dialogRef.afterClosed()))
      .subscribe(() => {
        dialogRef.close();
      });

    return dialogRef;
  }

  public openAddActivity(scopeId: number) {
    return this.getScopePathData(scopeId).pipe(
      switchMap((scopePath) =>
        this.openEditActivityDialog('Add activity', 'Add', {
          ...scopePath,
          scopeId,
        })
      ),
      map((activityData) =>
        addActivityAction({
          context: 'AdminActivitiesService::openAddActivity',
          payload: activityData,
        })
      )
    );
  }

  private getEditActivityData(activityId: number) {
    return combineLatest([
      this.store.select(selectAdminActivityFactory(activityId)),
      this.getActivityPathData(activityId),
    ]).pipe(
      filter(
        ([originalActivityData, activityPathData]) =>
          originalActivityData.id === activityId &&
          activityPathData.activityId === activityId
      ),
      first()
    );
  }

  public openEditActivity(activityId: number) {
    return this.getEditActivityData(activityId).pipe(
      first(),
      switchMap(([originalActivityData, activityPathData]) =>
        this.openEditActivityDialog(`Edit activity`, 'Save', {
          ...activityPathData,
          ...originalActivityData,
        }).pipe(
          map((activityData) =>
            updateActivityAction({
              context: 'AdminActivitiesService::openEditActivity',
              payload: activityData,
            })
          )
        )
      )
    );
  }

  public openCopyActivity(activityId: number) {
    return this.getEditActivityData(activityId).pipe(
      first(),
      switchMap(([originalActivityData, activityPathData]) =>
        this.openEditActivityDialog(
          `Copy activity`,
          'Copy',
          {
            ...activityPathData,
            ...originalActivityData,
            id: null,
            activityCode: null,
            activityDisplayName:
              originalActivityData.activityDisplayName + ' COPY',
          },
          originalActivityData.id
        ).pipe(
          map((activityData) =>
            addActivityAction({
              context: 'AdminActivitiesService::openCopyActivity',
              payload: activityData,
            })
          )
        )
      )
    );
  }

  public openAddScope(blockId: number) {
    return this.getBlockPathData(blockId).pipe(
      switchMap((blockPathData) =>
        this.openEditScopeDialog('Add Scope', 'Add', {
          ...blockPathData,
          blockId,
        })
      ),
      map((scopeData) =>
        addScopeAction({
          context: 'AdminActivitiesService::openAddScope',
          payload: scopeData,
        })
      )
    );
  }

  private getEditScopeData(scopeId: number) {
    return combineLatest([
      this.store.select(selectAdminScopeFactory(scopeId)),
      this.getScopePathData(scopeId),
    ]).pipe(
      filter(
        ([originalScopeData, scopePathData]) =>
          originalScopeData.scopeId === scopeId &&
          scopePathData.scopeId === scopeId
      ),
      first()
    );
  }

  public openEditScope(scopeId: number) {
    return this.getEditScopeData(scopeId).pipe(
      switchMap(([originalScopeData, scopePathData]) =>
        this.openEditScopeDialog(`Edit scope`, 'Save', {
          ...scopePathData,
          ...originalScopeData,
        }).pipe(
          map((scopeData) =>
            updateScopeAction({
              context: 'AdminActivitiesService::openEditScope',
              payload: scopeData,
            })
          )
        )
      )
    );
  }

  public openCopyScope(scopeId: number) {
    return this.getEditScopeData(scopeId).pipe(
      switchMap(([originalScopeData, scopePathData]) =>
        this.openEditScopeDialog(
          `Copy scope`,
          'Copy',
          {
            ...scopePathData,
            ...originalScopeData,
            code: null,
            scopeId: null,
            scopeDisplayName: originalScopeData.scopeDisplayName + ' COPY',
            copyFrom: originalScopeData.scopeId,
          },
          originalScopeData.scopeId
        ).pipe(
          map((scopeData) =>
            addScopeAction({
              context: 'AdminActivitiesService::openCopyScope',
              payload: scopeData,
            })
          )
        )
      )
    );
  }

  public openAddBlock(ctrId: number) {
    return this.store.select(selectPxDsMap).pipe(
      map((pxds) => {
        const parentPxd = pxds[ctrId];

        return {
          businessSegmentId: parentPxd.businessSegmentId,
          productId: parentPxd.productId,
          disciplineId: parentPxd.disciplineId,
          ctrId: parentPxd.id,
          pxdId: parentPxd.id,
          pxdName: parentPxd.shortName || 'New PxD',
          isUsed: false,
        };
      }),
      first(),
      switchMap((newBlockData) =>
        this.openEditBlockDialog('Add Block', 'Add', newBlockData)
      ),
      map((blockData) =>
        addBlockAction({
          context: 'AdminActivitiesService::openAddBlock',
          payload: blockData,
        })
      )
    );
  }

  private getEditBlockData(blockId: number) {
    return combineLatest([
      this.store.select(selectAdminBlockFactory(blockId)),
      this.getBlockPathData(blockId),
      this.store.select(selectAdminBlocksLoaded),
    ]).pipe(
      filter(
        ([originalBlockData, blockPathData, ready]) =>
          ready &&
          originalBlockData.blockId === blockId &&
          blockPathData.blockId === blockId
      ),
      first()
    );
  }

  public openEditBlock(blockId: number) {
    return this.getEditBlockData(blockId).pipe(
      switchMap(([originalBlockData, blockPathData]) =>
        this.openEditBlockDialog(`Edit block`, 'Save', {
          ...blockPathData,
          ...originalBlockData,
        }).pipe(
          map((blockData) =>
            updateBlockAction({
              context: 'AdminActivitiesService::openEditBlock',
              payload: blockData,
            })
          )
        )
      )
    );
  }

  public openCopyBlock(blockId: number) {
    return this.getEditBlockData(blockId).pipe(
      switchMap(([originalBlockData, blockPathData]) =>
        this.openEditBlockDialog(
          `Copy block`,
          'Copy',
          {
            ...blockPathData,
            ...originalBlockData,
            blockCode: null,
            blockId: 0,
            blockDisplayName: originalBlockData.blockDisplayName + ' COPY',
            copyFrom: originalBlockData.blockId,
          },
          originalBlockData.blockId
        ).pipe(
          map((blockData) =>
            addBlockAction({
              context: 'AdminActivitiesService::openCopyBlock',
              payload: blockData,
            })
          )
        )
      )
    );
  }

  public openConfirmRemove(title: string, message: string) {
    return this.openDialog<ConfirmDialogComponent, DialogData>(
      ConfirmDialogComponent,
      {
        data: {
          title,
          message,
          rejectionButtonLabel: 'Cancel',
          confirmationButtonLabel: 'Remove',
        },
      }
    )
      .afterClosed()
      .pipe(filter((v) => !!v));
  }

  private openEditActivityDialog(
    title: string,
    actionLabel: string,
    activityData: Partial<AdminActivity>,
    copyFrom: number = null
  ) {
    return this.openDialog<EditActivityDialogComponent, EditActivityDialogData>(
      EditActivityDialogComponent,
      {
        data: { title, copyFrom, actionLabel, activityData },
      }
    )
      .afterClosed()
      .pipe(filter((v) => !!v));
  }

  private openEditScopeDialog(
    title: string,
    actionLabel: string,
    scopeData: Partial<AdminScopeRequestData>,
    copyFrom: number = null
  ) {
    return this.openDialog<EditScopeDialogComponent, EditScopeDialogData>(
      EditScopeDialogComponent,
      {
        data: { title, copyFrom, actionLabel, scopeData },
      }
    )
      .afterClosed()
      .pipe(filter((v) => !!v));
  }

  private openEditBlockDialog(
    title: string,
    actionLabel: string,
    blockData: Partial<AdminBlockRequestData>,
    copyFrom: number = null
  ) {
    return this.openDialog<EditBlockDialogComponent, EditBlockDialogData>(
      EditBlockDialogComponent,
      {
        data: { title, copyFrom, actionLabel, blockData },
      }
    )
      .afterClosed()
      .pipe(filter((v) => !!v));
  }

  private getPxDPathData(ctrId: number) {
    return this.store.select(selectPxDsMap).pipe(
      map((pxds) => pxds[ctrId]),
      filter((v) => !!v),
      first()
    );
  }

  private getBlockPathData(blockId: number) {
    this.store.dispatch(
      getAdminBlockAction({
        context: 'AdminActivitiesService::getBlockPathData',
        payload: { blockId },
      })
    );
    return this.store.select(selectBlockAncestorsPathFactory(blockId)).pipe(
      filter((block) => block?.blockId === blockId),
      first()
    );
  }

  private getScopePathData(scopeId: number) {
    this.store.dispatch(
      getAdminScopeAction({
        context: 'AdminActivitiesService::getScopePathData',
        payload: { scopeId },
      })
    );
    return this.store.select(selectAdminScopeFactory(scopeId)).pipe(
      filter((scope) => scope?.scopeId === scopeId),
      first(),
      switchMap((scope) =>
        this.getBlockPathData(scope.blockId).pipe(
          map((blockPathData) => ({
            ...blockPathData,
            scopeName: scope.scopeDisplayName,
            scopeId: scope.scopeId,
          }))
        )
      ),
      shareReplay(1)
    );
  }

  private getActivityPathData(activityId: number) {
    this.store.dispatch(
      getAdminActivityAction({
        context: 'AdminActivitiesService::getActivityPathData',
        payload: { activityId },
      })
    );
    return this.store.select(selectAdminActivityFactory(activityId)).pipe(
      filter((activity) => activity?.id === activityId),
      first(),
      switchMap((activity) =>
        this.getScopePathData(activity.scopeId).pipe(
          map((scopekPathData) => ({
            ...scopekPathData,
            activityName: activity.activityDisplayName,
            activityId: activity.id,
          }))
        )
      ),
      shareReplay(1)
    );
  }

  public openReorderScopeActivities(scopeId: number) {
    return this.store
      .select(selectAdminActivitiesByScopeIdFactory(scopeId))
      .pipe(
        first(),
        withLatestFrom(this.getScopePathData(scopeId)),
        switchMap(([activities, scopePathData]) =>
          this.openDialog<OrderDialogComponent, OrderDialogData>(
            OrderDialogComponent,
            {
              data: {
                title: 'Change order of activities',
                elementTypeLabel: 'Activities',
                path: [
                  scopePathData.pxdName,
                  scopePathData.blockName,
                  scopePathData.scopeName,
                ],
                actionLabel: 'Ok',
                list: activities.map(
                  ({
                    activityDisplayName,
                    activityCode,
                    id,
                    isActive,
                    order,
                  }) => ({
                    id,
                    code: activityCode,
                    label: activityDisplayName,
                    isActive,
                    order,
                  })
                ),
              },
            }
          )
            .afterClosed()
            .pipe(filter((v) => !!v))
        )
      );
  }

  public openReorderBlockScopes(blockId: number) {
    return this.store.select(selectAdminScopesByBlockIdFactory(blockId)).pipe(
      first(),
      withLatestFrom(this.getBlockPathData(blockId)),
      switchMap(([scopes, blockPathData]) =>
        this.openDialog<OrderDialogComponent, OrderDialogData>(
          OrderDialogComponent,
          {
            data: {
              title: 'Change order of scopes',
              elementTypeLabel: 'Scopes',
              path: [blockPathData.pxdName, blockPathData.blockName],
              actionLabel: 'Ok',
              list: scopes.map(
                ({ scopeDisplayName, code, scopeId, isActive, order }) => ({
                  id: scopeId,
                  code,
                  label: scopeDisplayName,
                  isActive,
                  order,
                })
              ),
            },
          }
        )
          .afterClosed()
          .pipe(filter((v) => !!v))
      )
    );
  }

  public openReorderPxDBlocks(ctrId: number) {
    return this.store.select(selectAdminBlocksByPxDFactory(ctrId)).pipe(
      first(),
      withLatestFrom(this.getPxDPathData(ctrId)),
      switchMap(([blocks, pxd]) =>
        this.openDialog<OrderDialogComponent, OrderDialogData>(
          OrderDialogComponent,
          {
            data: {
              title: `Change order of blocks`,
              elementTypeLabel: 'Blocks',
              path: [pxd.shortName],
              actionLabel: 'Ok',
              list: blocks.map(
                ({
                  blockDisplayName,
                  blockCode,
                  blockId,
                  isActive,
                  order,
                }) => ({
                  id: blockId,
                  code: blockCode,
                  label: blockDisplayName,
                  isActive,
                  order,
                })
              ),
            },
          }
        )
          .afterClosed()
          .pipe(filter((v) => !!v))
      )
    );
  }
}
