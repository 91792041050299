<ng-container [formGroup]="commentFormGroup">
  <h2 class="title">Events filters</h2>

  <div class="formfield" formArrayName="type">
    <mat-checkbox [formControlName]="1">Comments</mat-checkbox>
    <mat-checkbox [formControlName]="0">Events</mat-checkbox>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Scenario</mat-label>
    <mat-select formControlName="scenarioId">
      <mat-option
        *ngFor="let scenario of scenarios$ | async"
        [value]="scenario.scenarioId"
        >{{ scenario.scenarioName }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</ng-container>
