import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProjectStagesEffects } from './store/project-stages.effects';
import { FEATURE_KEY, reducer } from './store/project-stages.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([ProjectStagesEffects]),
  ],
})
export class ProjectStagesCollectionModule {}
