<h1>Welcome</h1>
<p>
  It looks like you don't have eCTR account yet. You can request it by filling
  below form.
</p>
<hr />

<button
  *ngIf="!showForm"
  (click)="showForm = true"
  mat-raised-button
  data-test="request-account-button"
  color="primary"
  data-test="request-account-button"
>
  Request account
</button>

<ng-container *ngIf="showForm">
  <app-edit-user-form
    [(data)]="userData"
    [orginalAssignedPxDs]="[]"
    [userCanBeMadeInActive]="false"
    (valid)="updateState($event)"
    [showEntitySelector]="false"
    [enablePrincipalSelection]="false"
    [allowRequestingAdminRole]="false"
    [allowEditingEmail]="false"
    data-test="edit-user-form"
    [allowEditingEmail]="isTestMode"
  ></app-edit-user-form>

  <button
    mat-raised-button
    color="primary"
    [disabled]="!isValid"
    data-test="create-account-button"
    (click)="createAccount()"
    data-test="create-account-button"
  >
    Create account
  </button>
</ng-container>

<div
  *ngIf="userData?.isRequestor || userData?.isEngineer"
  class="info-button"
  (click)="openTutorial()"
  data-test="open-tutorial-info-button"
>
  <mat-icon>info</mat-icon>
</div>
