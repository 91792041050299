import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { IProjectScenarioComparison } from '@models/scenario-comparison';

export const getScenarioComparisonAction = createAction(
  '[Collection ScenarioComparison] GET /projects/:projectId/summary/scenarioComparisons',
  contextAwareProps<{ payload: { projectId: number } }>()
);

export const getScenarioComparisonSuccessAction = createAction(
  '[Collection ScenarioComparison Effect] GET /projects/:projectId/summary/scenarioComparisons SUCCESS',
  contextAwareProps<{ payload: IProjectScenarioComparison }>()
);

export const getScenarioComparisonFailureAction = createAction(
  '[Collection ScenarioComparison Effect] GET /projects/:projectId/summary/scenarioComparisons FAILURE',
  contextAwareProps()
);
