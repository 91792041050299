import {
  CtrFteDistributionForRoles,
  CtrHoursDistributionForRoles,
} from '@app/models/backendModel';
import { createReducer, on } from '@ngrx/store';
import { RolesCalendarizationActions } from './roles-calendarization.actions';

export const ROLES_FEATURE_KEY = 'RolesCalendarization';

export interface IRolesCalendarizationState {
  scenarioCtrId: number;
  startDate: Date;
  endDate: Date;
  ctrHoursDistributionForRoles: CtrHoursDistributionForRoles[];
  ctrFteDistributionForRoles: CtrFteDistributionForRoles[];
  currentUserIsOwner: boolean;
}

export const initialState: Record<number, IRolesCalendarizationState> = {};

export const reducer = createReducer(
  initialState,
  on(
    RolesCalendarizationActions.initRolesCalendarizationSuccess,
    (state, action) => ({
      ...state,
      [action.scenarioCtrId]: action,
    })
  )
);
