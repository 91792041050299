<ng-container [formGroup]="formGroup" *ngIf="formGroup">
  <div class="form-container">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-4 field">
          <mat-form-field appearance="outline">
            <mat-label for="firstName" class="form-required"
              >First name
            </mat-label>
            <input
              matInput
              type="text"
              id="firstName"
              formControlName="firstName"
              maxlength="25"
            />
            <mat-error
              *ngIf="formGroup.get('firstName')?.errors?.required"
              data-test="error-first-name-is-required"
            >
              First name is required
            </mat-error>
            <mat-error *ngIf="formGroup.get('firstName')?.errors?.maxlength">
              First name length should not exceed 25 characters
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-xs-12 col-md-4 field">
          <mat-form-field appearance="outline">
            <mat-label for="lastName" class="form-required"
              >Last name
            </mat-label>
            <input
              matInput
              type="text"
              id="lastName"
              formControlName="lastName"
              maxlength="25"
            />
            <mat-error
              *ngIf="formGroup.get('firstName')?.errors?.required"
              data-test="error-last-name-is-required"
            >
              Last name is required
            </mat-error>
            <mat-error *ngIf="formGroup.get('firstName')?.errors?.maxlength">
              Last name length should not exceed 25 characters
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4 field">
          <mat-form-field appearance="outline">
            <mat-label for="email" class="form-required">Email </mat-label>
            <input
              matInput
              type="text"
              id="email"
              formControlName="email"
              maxlength="100"
            />
            <mat-error
              *ngIf="formGroup.get('email').errors?.required"
              data-test="error-email-is-required"
            >
              Email is required
            </mat-error>
            <mat-error *ngIf="formGroup.get('email').errors?.pattern">
              Please enter a valid email
            </mat-error>
            <mat-error *ngIf="formGroup.get('email').errors?.maxlength">
              Email length should not exceed 100 characters
            </mat-error>
            <mat-error *ngIf="formGroup.get('email').errors?.duplicate">
              User email id already exists
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-4 field">
          <mat-form-field appearance="outline">
            <mat-label for="entityId" class="form-required">Entity </mat-label>
            <mat-select id="entityId" formControlName="entityId">
              <mat-option
                *ngFor="let entity of entitiesOptions$ | async"
                [value]="entity.entityId"
                data-test="user-entity-input"
              >
                {{ entity.enggCenter }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                !formGroup.get('entityId').pristine &&
                formGroup.get('entityId').errors?.required
              "
              data-test="error-entity-is-required"
            >
              Entity is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4 field">
          <mat-slide-toggle formControlName="isActive" class="active-toggle">
            Active
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <div class="roles-container">
    <div class="roles-label">Roles</div>
    <div class="roles-container container">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 field">
          <mat-checkbox formControlName="isRequestor">Requestor</mat-checkbox>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 field">
          <mat-checkbox formControlName="isEngineer">Engineering</mat-checkbox>
        </div>
        <div
          *ngIf="allowRequestingAdminRole"
          class="col-xs-12 col-sm-6 col-md-4 col-lg-3 field"
        >
          <mat-checkbox formControlName="isAdmin">Admin</mat-checkbox>
        </div>
      </div>
      <mat-error
        *ngIf="formGroup.errors?.missingRoles"
        data-test="create-account-need-role-warning"
        >User needs to have at least one role</mat-error
      >
    </div>
  </div>
  <ng-container *ngIf="formGroup.get('isEngineer').value">
    <hr />
    <div class="engineer-wrapper">
      <div class="pxd-grid-view" data-test="edit-user-form-pxd-grid">
        <h2>PxD</h2>
        <p>
          Click on PxD to attribute it to the user. Click 2 times to deselect
          the PxD.
        </p>

        <app-entity-multi-selector
          *ngIf="showEntitySelector"
          [selectedEntitiesIds]="selectedEntitiesIds$ | async"
          (selectedEntitiesIdsChange)="selectedEntitiesIds$.next($event)"
          [pxdsByEntity]="pxdsByEntity$ | async"
        ></app-entity-multi-selector>

        <div
          class="alert alert-warning"
          *ngIf="
            showEntitySelector && (selectedEntitiesIds$ | async)?.length === 0
          "
        >
          <mat-icon>info</mat-icon>
          <div>
            Please select entities to witch you want to edit assignments
          </div>
        </div>

        <div
          class="alert alert-warning"
          *ngIf="
            !showEntitySelector && (selectedEntitiesIds$ | async)?.length === 0
          "
        >
          <mat-icon>info</mat-icon>
          <div>Please select user entity to select his responsibilities</div>
        </div>

        <app-pxd-grid
          *ngIf="filteredAssignedPxds$ | async as filteredAssignedPxds"
          [businessSegmentId]="formGroup.get('businessSegmentId').value"
          [customData]="filteredAssignedPxds"
          [cellTemplate]="pxdCellTemplate"
        >
          <ng-template
            #pxdCellTemplate
            let-selected="selected"
            let-pxd="pxd"
            let-data="data"
          >
            <app-admin-pxd-grid-cell [pxd]="pxd" [selected]="selected">
              <app-admin-user-pxd-grid-cell
                [pxd]="pxd"
                [enforcePrincipalSelection]="data?.enforcePrincipalSelection"
                [deselectionDisabled]="data?.deselectionDisabled"
                [principalDeselectionDisabled]="
                  data?.principalDeselectionDisabled
                "
                [enablePrincipalSelection]="enablePrincipalSelection"
                [isPrincipal]="data?.isPrincipal"
                [assignedPxDs]="data?.pxds"
                (assignPxDs)="assignPxDs($event)"
                (unassignPxDs)="unassignPxDs($event)"
              ></app-admin-user-pxd-grid-cell>
            </app-admin-pxd-grid-cell>
          </ng-template>
        </app-pxd-grid>
        <app-pxd-cells-legend
          [legendItems]="legendItems"
        ></app-pxd-cells-legend>
      </div>
      <app-user-responsibilities
        class="table-view"
        [assignedPxDs]="allUserResponsibilities$ | async"
        (onUnassignResponsibility)="unassignResponsibility($event)"
      ></app-user-responsibilities>
    </div>
  </ng-container>
</ng-container>
