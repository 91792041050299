import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_KEY,
  IProjectsNamesCollectionState,
  projectNamesEntityAdapter,
} from './projects-names.reducer';

export const selectProjectNamesCollectionState =
  createFeatureSelector<IProjectsNamesCollectionState>(FEATURE_KEY);

const { selectAll, selectEntities } = projectNamesEntityAdapter.getSelectors(
  selectProjectNamesCollectionState
);

export const selectAllProjectNames = createSelector(selectAll, (v) => v);

export const selectAllProjectNamesMap = createSelector(
  selectEntities,
  (v) => v
);

export const selectProjectNameByProjectIdFactory = (projectId: number) => createSelector(
  selectAllProjectNamesMap,
  (projectNames) => projectNames[ projectId ]?.projectName
)
