import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EntitiesEffects } from './store/entities.effects';
import { FEATURE_KEY, reducer } from './store/entities.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([EntitiesEffects]),
  ],
})
export class EntitiesCollectionModule {}
