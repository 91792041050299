import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { filtersFormFieldConfiguration } from '@collections/filters/store/filters-form.config';
import { selectFilterFormAutocompletionData } from '@collections/filters/store/filters-form.selectors';
import { LoadingStateEnum } from '@collections/state-commons';
import { UserRole } from '@core/store/core.reducer';
import { selectCurrentRouteUserRole } from '@core/store/core.selectors';

import { ProjectWithDetailsDto } from '@app/models/backendModel';
import {
  IProjectsCollectionState,
  projectDetailEntityAdapter,
  searchProjectsEntityAdapter,
} from './projects.reducer';

export class ProjectSelectors {
  constructor(private feature: string) {}

  public selectProjectsCollectionState =
    createFeatureSelector<IProjectsCollectionState>(this.feature);

  private selectProjectsSearchResults = createSelector(
    this.selectProjectsCollectionState,
    ({ searchResult }) => searchResult
  );

  private selectProjectsDetails = createSelector(
    this.selectProjectsCollectionState,
    ({ projectsDetails }) => projectsDetails
  );

  public selectAllProjects = createSelector(
    searchProjectsEntityAdapter.getSelectors(this.selectProjectsSearchResults)
      .selectAll,
    (v) => v
  );

  public selectProjectsDetailsMap = createSelector(
    projectDetailEntityAdapter.getSelectors(this.selectProjectsDetails)
      .selectEntities,
    (v) => v
  );

  /**
   * List of projects that have already applied pagination and filters on server side.
   * Prepared for displaying on lists.
   */
  public selectFilteredProjectsPage = createSelector(
    this.selectAllProjects,
    (searchResultsPage) => searchResultsPage
  );

  public selectFilteredProjectsCount = createSelector(
    this.selectProjectsCollectionState,
    ({ totalElements = 0 }) => totalElements
  );

  public selectProjectsListFilters = createSelector(
    this.selectProjectsCollectionState,
    ({ filters }) => filters
  );

  public selectProjectsListFilterName = createSelector(
    this.selectProjectsCollectionState,
    ({ filterName }) => filterName
  );

  public selectProjectsListLoadingState = createSelector(
    this.selectProjectsCollectionState,
    ({ searching }) => searching
  );

  public selectProjectsListLoadedState = createSelector(
    this.selectProjectsCollectionState,
    ({ loaded }) => loaded
  );

  public selectProjectListAviablePageSizes = createSelector(
    this.selectProjectsCollectionState,
    ({ availablePageSizes }) => availablePageSizes
  );

  public selectCurrentFilterConditions = createSelector(
    this.selectProjectsListFilters,
    selectFilterFormAutocompletionData,
    (filters, context) =>
      filters
        ? Object.entries(filters).reduce(
            (r, [formFieldName, serializedValue]) => {
              const fieldConfiguration =
                filtersFormFieldConfiguration[formFieldName];
              if (!fieldConfiguration) {
                return r;
              }

              return [
                ...r,
                ...fieldConfiguration.convertServerToFilterOverview(
                  serializedValue,
                  fieldConfiguration.type,
                  context,
                  filtersFormFieldConfiguration[formFieldName].allowOtherValues
                ),
              ];
            },
            []
          )
        : []
  );

  public selectCurrentSearchString = createSelector(
    this.selectProjectsListFilters,
    (filters) => filters.searchString
  );

  /**
   * Select project detail data from store.
   */
  public selectProjectByIdFactory = (projectId: number) =>
    createSelector(
      this.selectProjectsDetailsMap,
      (projects) =>
        (projects[projectId]
          ? projects[projectId]
          : null) as ProjectWithDetailsDto
    );

  public selectProjectLoadStateByIdFactory = (projectId: number) =>
    createSelector(
      this.selectProjectsCollectionState,
      ({ projectLoadingState }) =>
        projectLoadingState[projectId]
          ? projectLoadingState[projectId]
          : LoadingStateEnum.NOT_STARTED
    );

  public selectScenarioByProjectIdAndScenarioIdFactory = (
    projectId: number,
    scenarioId: number
  ) =>
    createSelector(this.selectProjectByIdFactory(projectId), (project) =>
      !!project && !!project.scenarios
        ? project.scenarios.find((scenario) => scenario.id === scenarioId)
        : null
    );
}

export const projectsSelectors = {
  [UserRole.REQUESTOR]: new ProjectSelectors('requestor-projects'),
  [UserRole.ENGINEER]: new ProjectSelectors('engineer-projects'),
};

const getProjectsSelectorsByContext = (userRole) =>
  [UserRole.REQUESTOR, UserRole.ENGINEER].includes(userRole)
    ? (projectsSelectors[userRole] as ProjectSelectors)
    : null;

export const selectProjectByIdFactory$ = (
  store: Store,
  projectId: number,
  userRole: UserRole
) => {
  const selector = getProjectsSelectorsByContext(userRole);

  return selector
    ? store.select(selector.selectProjectByIdFactory(projectId))
    : EMPTY;
};

export const selectProjectLoadStateByIdFactory$ = (
  store: Store,
  projectId: number
) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRole) => {
      const selector = getProjectsSelectorsByContext(userRole);
      return selector
        ? store.select(selector.selectProjectLoadStateByIdFactory(projectId))
        : EMPTY;
    })
  );

export const selectScenarioByProjectIdAndScenarioIdFactory$ = (
  store: Store,
  projectId: number,
  scenarioId: number
) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRole) => {
      const selector = getProjectsSelectorsByContext(userRole);
      return selector
        ? store.select(
            selector.selectScenarioByProjectIdAndScenarioIdFactory(
              projectId,
              scenarioId
            )
          )
        : EMPTY;
    })
  );

export const selectCurrentFilterConditionsFactory$ = (store: Store) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRole) => {
      const selector = getProjectsSelectorsByContext(userRole);
      return selector
        ? store.select(selector.selectCurrentFilterConditions)
        : EMPTY;
    })
  );

export const selectCurrentSearchStringFactory$ = (store: Store) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRole) => {
      const selector = getProjectsSelectorsByContext(userRole);
      return selector
        ? store.select(selector.selectCurrentSearchString)
        : EMPTY;
    })
  );

export const selectProjectsListFilterNameFactory$ = (store: Store) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRole) => {
      const selector = getProjectsSelectorsByContext(userRole);
      return selector
        ? store.select(selector.selectProjectsListFilterName)
        : EMPTY;
    })
  );

export const selectProjectsListFiltersFactory$ = (store: Store) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRole) => {
      const selector = getProjectsSelectorsByContext(userRole);
      return selector
        ? store.select(selector.selectProjectsListFilters)
        : EMPTY;
    })
  );

export const selectProjectsListLoadingStateFactory$ = (store: Store) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRole) => {
      const selector = getProjectsSelectorsByContext(userRole);
      return selector
        ? store.select(selector.selectProjectsListLoadingState)
        : EMPTY;
    })
  );
