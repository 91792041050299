import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ctrStatus',
})
export class CtrStatusPipe implements PipeTransform {
  statusMapping = {
    'CTR selected': 'Request CTR',
    loading: '',
  };

  transform(status: string): string {
    return Object.keys(this.statusMapping).includes(status)
      ? this.statusMapping[status]
      : status;
  }
}
