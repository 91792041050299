import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CurrenciesEffects } from './store/currencies.effects';
import { FEATURE_KEY, reducer } from './store/currencies.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([CurrenciesEffects]),
  ],
})
export class CurrenciesCollectionModule {}
