import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { CtrStatus } from '@common/enums';
import { IPxD, PxDCell } from '@models/pxd';

@Component({
  selector: 'app-engineer-pxd-grid-cell',
  templateUrl: './engineer-pxd-grid-cell.component.html',
  styleUrls: ['./engineer-pxd-grid-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineerPxdGridCellComponent implements OnChanges {
  public readonly CtrStatus = CtrStatus;

  @Input() public pxd: IPxD = null;

  @Input() public scenarioCTR: PxDCell = null;

  @Input() public showCtrOwners = false;

  @Input() public currentUserFullName = null;

  @Input()
  @HostBinding('class.ctr-selected')
  public ctrSelected = false;

  @HostBinding('class.with-ctr')
  public withCTR = false;

  @HostBinding('class.pxd-active')
  public active = false;

  @HostBinding('class.pxd-disabled')
  public disabled = false;

  @HostBinding('class.pxd-with-master-data')
  public withMasterdata = false;

  @HostBinding('class.pxd-without-master-data')
  public withoutMasterdata = false;

  @HostBinding('class.pxd-with-principals')
  public withPrincipals = false;

  @HostBinding('class.pxd-without-principals')
  public withoutPrincipals = false;

  public canEdit = false;

  public ownerTooltip = '';

  public statusDescription = '';

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.pxd && this.pxd) {
      this.withMasterdata = this.pxd.hasMasterData;
      this.withoutMasterdata = !this.withMasterdata;
      this.withPrincipals = this.pxd.hasPrincipals;
      this.withoutPrincipals = !this.withPrincipals;
      this.active = this.pxd.isActive;
      this.disabled = !this.pxd.isActive;
    }
    if (changes.scenarioCTR && !!this.scenarioCTR) {
      this.ownerTooltip = `${this.scenarioCTR.ctrOwner} (${this.scenarioCTR.entity})`;

      this.statusDescription =
        this.scenarioCTR.ctrStatus === CtrStatus.NOT_STARTED
          ? `CTR requested`
          : this.scenarioCTR.ctrStatus;
    }

    this.withCTR =
      this.scenarioCTR &&
      [
        CtrStatus.NOT_STARTED,
        CtrStatus.IN_PROGRESS,
        CtrStatus.COMPLETED,
        CtrStatus.CANCELLED,
      ].includes(this.scenarioCTR.ctrStatus);
  }
}
