<div class="infosubheadingdiv">
  <p data-test="project-summary-text-description">
    Here you can compare the scenarios once they are added to the project.
  </p>
</div>
<ng-container *ngIf="projectScenarioData$ | async as projectScenarioData">
  <ng-container *ngIf="projectScenarioData.length > 0">
    <mat-expansion-panel class="project__scen__panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title
          class="project__scen__panel__title"
          data-test="project-scenarios-label"
        >
          Project scenarios
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-project-scenarios
        [projectScenarios]="projectScenarioData"
        [isProjectDisabled]="isProjectDisabled"
        [defaultCurrency]="defaultCurrency"
      >
      </app-project-scenarios>
    </mat-expansion-panel>
    <div class="definitionwrapper">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title data-test="scenario-definition-label">
            Scenario definition
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-scenario-definition
          [projectScenarios]="projectScenarioData"
        ></app-scenario-definition>
      </mat-expansion-panel>
    </div>
    <mat-card appearance="outlined">
      <mat-card-title
        class="project__scen__panel__title"
        data-test="scenario-comparison-label"
      >
        Scenario comparison
      </mat-card-title>
      <app-scenario-comparison
        *ngIf="projectId$ | async as projectId"
        [defaultCurrency]="defaultCurrency"
        [projectId]="projectId"
      ></app-scenario-comparison>
    </mat-card>
  </ng-container>
</ng-container>
