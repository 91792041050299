import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { FEATURE_KEY, reducer } from './store/lock.reducer';

// TODO: gather together all lock related services and components in this module
@NgModule({
  imports: [StoreModule.forFeature(FEATURE_KEY, reducer)],
})
export class LockModule {}
