<div
  *ngIf="scenario"
  class="column-header-wrapper"
  [class.first-column]="scenario.baseCase"
>
  <div class="column-header-content">
    <div
      *ngIf="!allProjectScenarios"
      class="scenario-name-wrapper"
    >
      <app-scenario-status-icon
        [scenarioStatus]="scenario.scenarioStatus"
        [scenarioId]="scenario.scenarioId"
        [scenarioNumber]="scenario.scenarioNumber"
        [scenarioSubmitted]="scenario.submittedDate"
        class="scenario-icon"
        [attr.data-test]="'scenario-comparison-scenario-id-' + scenario.scenarioId + '-scenario-status-icon'"
      >
      </app-scenario-status-icon>
      <span
        class="scenario-name"
        [attr.data-test]="'scenario-comparison-scenario-id-' + scenario.scenarioId + '-scenario-name'"
      >
        {{scenario.scenarioName}}
      </span>
      <mat-icon
        *ngIf="scenario.baseCase"
        class="base-case-icon"
        data-test="scenario-comparison-scenario-base-case-icon"
      >star</mat-icon>
    </div>
    <div *ngIf="allProjectScenarios && selectedScenario">
      <mat-form-field>
        <mat-select [(value)]="selectedScenario">
          <mat-option
            *ngFor="let scenarioOption of allProjectScenarios"
            [value]="scenarioOption"
          >
            <app-scenario-status-icon
              [scenarioStatus]="scenarioOption.scenarioStatus"
              [scenarioId]="scenarioOption.scenarioId"
              [scenarioNumber]="scenarioOption.scenarioNumber"
              [scenarioSubmitted]="scenarioOption.submittedDate"
              class="scenario-icon"
            >
            </app-scenario-status-icon>
            <span class="scenario-name">
              {{ scenarioOption.scenarioName }}
            </span>
          </mat-option>
          <mat-select-trigger>
            <app-scenario-status-icon
              [scenarioStatus]="selectedScenario.scenarioStatus"
              [scenarioId]="selectedScenario.scenarioId"
              [scenarioNumber]="selectedScenario.scenarioNumber"
              [scenarioSubmitted]="selectedScenario.submittedDate"
              class="scenario-icon"
            >
            </app-scenario-status-icon>
            <span class="scenario-name">
              {{ selectedScenario.scenarioName }}
            </span>
          </mat-select-trigger>
        </mat-select>
      </mat-form-field>
    </div>
    <span
      class="status-description-column"
      [attr.data-test]="'scenario-comparison-scenario-id-' + scenario.scenarioId + '-status-description'"
    >
      {{selectedScenario.scenarioStatus}}
    </span>
  </div>
</div>
<app-scenario-ctrs-summary
  *ngIf="selectedScenario"
  [firstColumn]="selectedScenario.baseCase"
  [scenario]="selectedScenario"
  [ctrTypes]="ctrTypes"
  [projectId]="projectId"
></app-scenario-ctrs-summary>