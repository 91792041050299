import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { BusinessSegmentSelectorComponent } from './business-segment-selector.component';

const components = [BusinessSegmentSelectorComponent];

@NgModule({
  declarations: components,
  imports: [SharedModule],
  exports: components,
})
export class BusinessSegmentSelectorModule {}
