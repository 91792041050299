import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SdkCallService } from '@app/sdk-call.service';
import { IGetScenarioComparisonResponseItem } from '@models/scenario-comparison';

import { getScenarioComparisonAction } from './store/scenario-comparison.actions';
import {
  selectAllScenarioComparison,
  selectScenarioComparisonItemsByProjectIdFactory,
} from './store/scenario-comparison.selectors';

@Component({
  selector: 'app-scenario-comparison',
  templateUrl: './scenario-comparison.component.html',
  styleUrls: ['./scenario-comparison.component.scss'],
})
export class ScenarioComparisonComponent implements OnInit {
  @Input() defaultCurrency: string;
  @Input() projectId: number;

  public compareScenarioItems$: Observable<
    IGetScenarioComparisonResponseItem[]
  >;
  public baseCaseScenario$: Observable<IGetScenarioComparisonResponseItem>;
  public compareScenarioItemsWithoutBaseCase$: Observable<
    IGetScenarioComparisonResponseItem[]
  >;
  public ctrTypes: string[];

  constructor(public sdkService: SdkCallService, public store$: Store) {}

  public ngOnInit(): void {
    this.store$.select(selectAllScenarioComparison);
    this.store$.dispatch(
      getScenarioComparisonAction({
        payload: { projectId: this.projectId },
      })
    );
    this.compareScenarioItems$ = this.store$.select(
      selectScenarioComparisonItemsByProjectIdFactory(this.projectId)
    );
    this.baseCaseScenario$ = this.compareScenarioItems$.pipe(
      map((x) => x.find((scenario) => scenario?.baseCase))
    );
    this.compareScenarioItemsWithoutBaseCase$ = this.compareScenarioItems$.pipe(
      map((x) => x.filter((scenario) => !scenario?.baseCase))
    );
    this.compareScenarioItems$.subscribe((compareItems) => {
      this.ctrTypes = this.getCtrTypes(compareItems);
    });
  }

  private getCtrTypes(
    scenarios: IGetScenarioComparisonResponseItem[]
  ): string[] {
    if (scenarios) {
      let x: string[] = [];
      scenarios.forEach((element) => {
        x = x.concat(element.scenarioCTRs.map((ctr) => ctr.ctrShortName));
      });
      // Set for the sake of removing duplicate shortNames
      return [...new Set(x)].sort();
    }
  }
}
