export const defaultInputDebounce = 500;

export const allowedExtensions = [
  'jpg',
  'jpeg',
  'png',
  'JPG',
  'JPEG',
  'JFIF',
  'BMP',
  'SVG',
  'dwg',
  'DWG',
  'zip',
  'ZIP',
  '7z',
  '7Z',
  'GIF',
  'gif',
  'pdf',
  'PDF',
  'txt',
  'TXT',
  'doc',
  'docx',
  'xlsx',
  'xls',
  'ppt',
  'pptx',
  'docm',
  'dotx',
  'dotm',
  'docb',
  'xlt',
  'xlm',
  'xlsm',
  'xltx',
  'xltm',
  'xlsb',
  'xla',
  'xlam',
  'xll',
  'xlw',
  'pot',
  'pps',
  'pptm',
  'potx',
  'ppam',
  'ppsx',
  'potm',
  'ppsm',
  'sldx',
  'rtf',
  'sldm',
  'adn',
  'msg',
  'csv',
  'CSV',
];

export const dateRange = 'Select the date range within 6 months time period.';

export const dateMismatch =
  'Ensure that the to Date is greater than or equal to the from Date';

export const initialLoad = 'initial Load';

export function adminDeleteWarnMsg(item) {
  return `Are you sure you want to delete this ${item}?`;
}

export const commonKeys = {
  firstName: 'First Name',
  lastName: 'Last Name',
  clientName: 'Name',
  clientCPXFactorId: 'Client CPX factor',
  isActive: 'Active',
  shortName: ' short name',
  name: 'Name',
  blockInternalName: 'block internal name',
  blockDisplayName: 'block display name',
  blockCode: 'block code',
  scopeInternalName: 'scope internal name',
  scopeDisplayName: 'scope display name',
  entity: 'entity',
  calcLevel: 'calculationlevel',
  code: ' scope code',
  cpxFactor: 'CPX factor',
  qtyType: 'quantity type',
  description: 'scope description',
  activityInternalName: 'activity internal name',
  activityDisplayName: 'activity display name',
  entityId: 'entity',
  activityCode: 'activity code',
  engStdHrs: 'engineering hours',
  engIterationFactor: 'engineering iteration factor',
  dftStdHrs: 'drafting hours',
  dftIterationFactor: 'drafting iteration factors',
  qtyVal: 'quantity value',
  clientCmplxFactor: 'Value',
  clientTier: 'Tier',
  isSameForAllProducts: 'Same for all products',
};

export const shortMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
