import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_KEY,
  IProjectStagesCollectionState,
  projectStagesEntityAdapter,
} from './project-stages.reducer';

export const selectProjectStagesCollectionState =
  createFeatureSelector<IProjectStagesCollectionState>(FEATURE_KEY);

const { selectAll, selectEntities } = projectStagesEntityAdapter.getSelectors(
  selectProjectStagesCollectionState
);

export const selectAllProjectStages = createSelector(selectAll, (v) => v);

export const selectProjectStagesMap = createSelector(selectEntities, (v) => v);

export const selectAllActiveProjectStages = createSelector(
  selectAllProjectStages,
  (projectStages) =>
    projectStages.filter((projectStage) => projectStage.isActive)
);

export const selectAllActiveProjectStagesMap = createSelector(
  selectAllActiveProjectStages,
  (projectStages) =>
    projectStages.reduce(
      (r, projectStage) => ({
        ...r,
        [projectStage.projectStageId]: projectStage,
      }),
      {}
    )
);
