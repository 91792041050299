import { createReducer, on } from '@ngrx/store';

import {
  lockAcquiredAction,
  lockExpiredAction,
  lockRemovedAction,
} from './lock.actions';

export const FEATURE_KEY = 'lock';

export interface ILockCollectionState {
  locked: boolean;
}

export const initialState = {
  locked: false,
} as ILockCollectionState;

export const reducer = createReducer(
  initialState,
  on(lockAcquiredAction, (state) => ({
    locked: true,
  })),
  on(lockExpiredAction, (state) => ({
    locked: false,
  })),
  on(lockRemovedAction, (state) => ({
    locked: false,
  }))
);
