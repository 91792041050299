import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sorting',
})
export class SortingPipe implements PipeTransform {
  transform(array: any[], order: string, columnName?: string): any[] {
    if (!array || !order || array.length <= 1) {
      return array;
    }
    return this.orderBy(array, columnName, order);
  }

  orderBy(array, columnName, order) {
    return array.sort((a, b) => {
      switch (true) {
        case columnName ? a[columnName] > b[columnName] : a > b:
          return order === 'asc' ? 1 : -1;
        case columnName ? a[columnName] < b[columnName] : a < b:
          return order === 'asc' ? -1 : 1;
        default:
          return 0;
      }
    });
  }
}
