import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-table-cell-select',
  templateUrl: './table-cell-select.component.html',
  styleUrls: ['./table-cell-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TableCellSelectComponent),
    },
  ],
})
export class TableCellSelectComponent implements ControlValueAccessor {
  @Input() controlId: string;
  @Input() disabled: boolean;
  @Input() options: any[];
  @Input() valueName: string;
  @Input() labelName: string;
  @Input() withSubtitle: boolean;
  @Input() subtitleName: string;
  @Input() placeholder: string;
  @Output() selectionEvent: EventEmitter<string> = new EventEmitter<string>();

  public selectedOptionIndex: number = null;

  private value: any;

  private onChange: (value) => void;
  private onTouched: () => void;

  public onSelection(selectedOptionValue: any): void {
    this.selectedOptionIndex = this.getOptionIndex(selectedOptionValue);
    this.onChange(selectedOptionValue);
    this.selectionEvent.emit(selectedOptionValue);
  }

  private getOptionIndex(optionValue: any): number {
    return this.options
      .map((option: any) => option[this.valueName])
      .indexOf(optionValue);
  }

  public writeValue(value: string | number) {
    this.value = value;

    this.selectedOptionIndex = this.getOptionIndex(this.value);
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
