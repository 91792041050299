import { Injectable } from '@angular/core';
import {
  FteDistributionForMatrix,
  HoursDistributionForMatrix,
} from '@app/models/backendModel';
import { CalendarizationForProject } from '../../store/ctrs-calendarization.reducer';

@Injectable()
export class CalendarizationTableCTRsService {
  getMaxEndYear(ctrs: CalendarizationForProject[]): number {
    return Math.max(...ctrs.map((ctr) => ctr.endDateAsDate.getFullYear()));
  }
  getMinStartYear(ctrs: CalendarizationForProject[]): number {
    return Math.min(...ctrs.map((ctr) => ctr.startDateAsDate.getFullYear()));
  }

  fulfillTimePeriods(
    ctrs: CalendarizationForProject[]
  ): CalendarizationForProject[] {
    if (ctrs.length === 0) {
      return ctrs;
    }
    const minYear = this.getMinStartYear(ctrs);
    const maxYear = this.getMaxEndYear(ctrs);
    ctrs.forEach((ctr) => {
      this.addMissingTimePeriods(ctr, minYear, maxYear);
    });
    return ctrs;
  }

  addMissingTimePeriods(
    ctr: CalendarizationForProject,
    minYear: number,
    maxYear: number
  ): void {
    const hasMinimumYear = ctr.hoursDistributionForYears[0]?.index === minYear;
    const hasMaximumYear =
      ctr.hoursDistributionForYears[ctr.hoursDistributionForYears.length - 1]
        ?.index === maxYear;
    if (hasMinimumYear && hasMaximumYear) {
      return;
    }
    const hoursDistributionForYears: HoursDistributionForMatrix[] = [];
    const fteDistributionForYears: FteDistributionForMatrix[] = [];
    this.addMissingYears(
      maxYear,
      minYear,
      ctr,
      hoursDistributionForYears,
      fteDistributionForYears
    );
    ctr.hoursDistributionForYears = hoursDistributionForYears;
    ctr.fteDistributionForYears = fteDistributionForYears;
  }

  addMissingYears(
    maxYear: number,
    minYear: number,
    ctr: CalendarizationForProject,
    hoursDistributionForYears: HoursDistributionForMatrix[],
    fteDistributionForYears: FteDistributionForMatrix[]
  ): void {
    [...Array(maxYear - minYear + 1)].forEach((v, yearOffset) => {
      const currentYear = minYear + yearOffset;
      const yearIndex = ctr.hoursDistributionForYears.findIndex(
        (year) => year.index === currentYear
      );
      if (yearIndex !== -1) {
        hoursDistributionForYears.push(
          ctr.hoursDistributionForYears[yearIndex]
        );
        fteDistributionForYears.push(ctr.fteDistributionForYears[yearIndex]);
        return;
      }
      hoursDistributionForYears.push({
        index: currentYear,
        hoursPeriods: [...Array(12)].map((v, monthIndex) => ({
          index: monthIndex + 1,
          hoursInPeriod: 0,
          isPeriodEditable: false,
        })),
      });
      fteDistributionForYears.push({
        index: currentYear,
        ftePeriods: [...Array(12)].map((v, monthIndex) => ({
          index: monthIndex + 1,
          fteInPeriod: 0,
          isPeriodEditable: false,
        })),
      });
    });
  }
}
