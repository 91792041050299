import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IGetCurrenciesResponseItem } from '@models/currencies';

import {
  getCurrenciesAction,
  getCurrenciesFailureAction,
  getCurrenciesSuccessAction,
} from './currencies.actions';

export const FEATURE_KEY = 'currencies';

export interface ICurrenciesCollectionState
  extends EntityState<IGetCurrenciesResponseItem> {
  loaded: boolean;
}

export const currenciesEntityAdapter =
  createEntityAdapter<IGetCurrenciesResponseItem>({
    selectId: ({ currencyId }) => currencyId,
  });

export const initialState = currenciesEntityAdapter.getInitialState({
  loaded: false,
} as ICurrenciesCollectionState);

export const reducer = createReducer(
  initialState,
  on(getCurrenciesAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getCurrenciesSuccessAction, (state, { payload }) =>
    currenciesEntityAdapter.upsertMany(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getCurrenciesFailureAction, (state) => ({
    ...state,
    loaded: true,
  }))
);
