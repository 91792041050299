<h2 mat-dialog-title data-test="edit-scope-dialog-title">{{ title }}</h2>
<mat-dialog-content>
  <app-edit-scope-form
    [(data)]="scopeData"
    [canChangePath]="canChangePath"
    [originalBlockId]="originalBlockId"
    [originaScopeCode]="originaScopeCode"
    [warnWhenMoving]="!copyMode"
    (valid)="updateState($event)"
  ></app-edit-scope-form>
</mat-dialog-content>
<div class="button-right" mat-dialog-actions [align]="'end'">
  <mat-checkbox
    *ngIf="copyMode"
    (change)="markCopyChildren($event.checked)"
    [checked]="!!scopeData.copyFrom"
    matTooltip="Copy all activities of the old scope to the new scope"
    data-test="edit-scope-copy-all-children-checkbox"
    >Copy all children</mat-checkbox
  >

  <button
    [mat-dialog-close]="false"
    color="primary"
    data-test="edit-scope-dialog-close-button"
    mat-button
  >
    Cancel
  </button>

  <button
    [disabled]="!canSave"
    [mat-dialog-close]="scopeData"
    color="primary"
    data-test="edit-scope-dialog-save-button"
    mat-button
  >
    {{ actionLabel }}
  </button>
</div>
