import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

import { selectAllActiveEntities } from '@collections/entities/store/entities.selectors';
import { selectSegmentDisciplinesFactory } from '@collections/pxds/store/pxds.selectors';
import { Discipline } from '@models/discipline';

export interface DisciplineDialogData {
  discipline: Discipline;
  actionName: string;
}

@Component({
  selector: 'app-discipline-dialog',
  templateUrl: './discipline-dialog.component.html',
  styleUrls: ['../dialogs-style.scss'],
})
export class DisciplineDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public entities$ = this.store.select(selectAllActiveEntities);
  public disciplines$ = this.store.select(
    selectSegmentDisciplinesFactory(this.data.discipline.businessSegmentId)
  );
  public actionName = this.data.actionName;
  public valueChanged$;
  public initialValue;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: DisciplineDialogData,
    private formBuilder: UntypedFormBuilder,
    private store: Store
  ) {}

  public ngOnInit(): void {
    const discipline = this.data.discipline;
    this.formGroup = this.formBuilder.group({
      disciplineName: [
        discipline.name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ],
        [this.duplicateFieldValidator('name', discipline.id)],
      ],
      disciplineShortName: [
        discipline.shortName ? this.data.discipline.shortName : '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20),
        ],
        [this.duplicateFieldValidator('shortName', discipline.id)],
      ],
      isActive: [discipline.id ? discipline.isActive : true],
      code: [
        discipline.code,
        [Validators.required, Validators.pattern('^[0-9]*$')],
      ],
      isEntityGlobal: [discipline.isEntityGlobal],
      globalEntityId: [discipline.globalEntityId],
    });

    this.formGroup.get('isEntityGlobal').valueChanges.subscribe((value) => {
      const globalEntityControl = this.formGroup.get('globalEntityId');
      if (value) {
        globalEntityControl.setValidators([Validators.required]);
      } else {
        globalEntityControl.setValue(null);
        globalEntityControl.clearValidators();
      }
      globalEntityControl.updateValueAndValidity();
    });

    this.valueChanged$ = this.formGroup.valueChanges.pipe(
      map(
        (newValue) =>
          JSON.stringify(newValue) !== JSON.stringify(this.initialValue),
        shareReplay(1)
      )
    );
  }

  public duplicateFieldValidator(
    fieldName: string,
    disciplineId: number
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.disciplines$.pipe(
        map((disciplines) => {
          return disciplines.some(
            (x) =>
              x[fieldName].toLowerCase() === control.value.toLowerCase() &&
              x.id !== disciplineId
          )
            ? { duplicateName: { value: control.value } }
            : null;
        }),
        take(1)
      );
    };
  }
}
