import { createAction } from '@ngrx/store';

import { UserRole } from '@core/store/core.reducer';
import { contextAwareProps } from '@core/utils/context-aware-props';
import { IFilter, IFilterCondition } from '@models/filters';

import { IAdvancedProjectFiltersFormValue } from './filters-form.config';

export const createFilterAction = createAction(
  '[Collection Filters] create filter',
  contextAwareProps<{ payload: { context: UserRole } }>()
);

export const upsertFilterAction = createAction(
  '[Collection Filters] POST /users/filter',
  contextAwareProps<{ payload: { filter: IFilter; context: UserRole } }>()
);

export const upsertFilterSuccessAction = createAction(
  '[Collection Filters Effect] POST /users/filter SUCCESS',
  contextAwareProps<{ payload: { filter: IFilter; context: UserRole } }>()
);

export const upsertFilterFailureAction = createAction(
  '[Collection Filters Effect] POST /users/filter FAILURE',
  contextAwareProps<{ payload: { filter: IFilter; context: UserRole } }>()
);

export const getFiltersAction = createAction(
  '[Collection Filters] GET /users/filter',
  contextAwareProps<{ payload: { context: UserRole } }>()
);

export const getFiltersSuccessAction = createAction(
  '[Collection Filters Effect] GET /users/filter SUCCESS',
  contextAwareProps<{ payload: { filters: IFilter[]; context: UserRole } }>()
);

export const getFiltersFailureAction = createAction(
  '[Collection Filters Effect] GET /users/filter FAILURE',
  contextAwareProps<{ payload: { context: UserRole } }>()
);

export const upsertManyFiltersAction = createAction(
  '[Collection Filters Effect] upsert many filters',
  contextAwareProps<{ payload: { filters: IFilter[]; context: UserRole } }>()
);

export const deleteFilterAction = createAction(
  '[Collection Filters] DELETE /users/filter',
  contextAwareProps<{
    payload: { filterId: number; filterName: string; context: UserRole };
  }>()
);

export const deleteFilterSuccessAction = createAction(
  '[Collection Filters Effect] DELETE /users/filter SUCCESS',
  contextAwareProps<{ payload: { filterName: string; context: UserRole } }>()
);

export const deleteFilterFailureAction = createAction(
  '[Collection Filters Effect] DELETE /users/filter FAILURE',
  contextAwareProps<{ payload: { filterName: string; context: UserRole } }>()
);

export const removeFilterConditionAction = createAction(
  '[Collection Filters] remove filter condition',
  contextAwareProps<{
    payload: { condition: IFilterCondition; context: UserRole };
  }>()
);

export const resetCurrentFilterAction = createAction(
  '[Collection Filters] reset current filter',
  contextAwareProps<{ payload: { context: UserRole } }>()
);

export const setFilterAsCurrentAction = createAction(
  '[Collection Filters] set filter as current',
  contextAwareProps<{ payload: { filter: IFilter; context: UserRole } }>()
);

export const applyFilterAction = createAction(
  '[Collection Filters] apply filter',
  contextAwareProps<{ payload: { userId: number; context: UserRole } }>()
);

export const applyNewFilterAndCreateAction = createAction(
  '[Collection Filters] apply filter and save it',
  contextAwareProps<{
    payload: { name: string; userId: number; context: UserRole };
  }>()
);

export const saveFilterActiveSwitchAction = createAction(
  '[Collection Filters] switch save filter active value',
  contextAwareProps<{ payload: { context: UserRole } }>()
);

export const setCurrentFilterFromFormDataAction = createAction(
  '[Collection Filters] update current filter with form data',
  contextAwareProps<{
    payload: { filter: IAdvancedProjectFiltersFormValue; context: UserRole };
  }>()
);

export const setMyDefaultFilterAsCurrentFilterAction = createAction(
  '[Collection Filters] set my default filter as current filter',
  contextAwareProps<{ payload: { context: UserRole } }>()
);

export const getCurrentConditionsFromProjectsAction = createAction(
  '[Collection Filters] get current filter conditions from project filters',
  contextAwareProps<{ payload: { context: UserRole } }>()
);

export const applyCurrentConditionsFromProjectsAction = createAction(
  '[Collection Filters] update current filter with project filters',
  contextAwareProps<{
    payload: { filter: IFilter; context: UserRole };
  }>()
);
