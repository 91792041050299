<div
  class="switch-wrapper"
  [class.disabled]="disabled"
>
  <mat-slide-toggle
    (change)="emitChangeEvent($event.checked)"
    [id]="controlId"
    [attr.data-test]="controlId"
    [checked]="!!value"
  ></mat-slide-toggle>
</div>