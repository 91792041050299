import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { EntityMultiSelectorComponent } from './entity-multi-selector.component';

const components = [EntityMultiSelectorComponent];

@NgModule({
  declarations: components,
  imports: [SharedModule, MaterialModule],
  exports: components,
})
export class EntityMultiSelectorModule {}
