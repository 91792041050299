import { Injectable } from '@angular/core';
import { shortMonthNames } from '@app/constants/constants';
import {
  FteDistributionForMatrix,
  HoursDistributionForMatrix,
} from '@app/models/backendModel';

export interface YearData {
  yearIndex: number;
  months: MonthData[];
}
export interface MonthData {
  monthIndex: number;
  monthShortName: string;
  totalHours: number;
  totalFte: number;
  periodDays: number;
  isPeriodEditable: boolean;
}
@Injectable()
export class CalendarizationTableSharedService {
  public getDaysInMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }
  public parseDateWithoutTimeOffset(dateStr: string): Date {
    const parsedDate = new Date(dateStr);
    parsedDate.setHours(0, 0, 0, 0);
    return parsedDate;
  }
  public getYearsMonthsSplitWithTotals(
    startDate: Date,
    endDate: Date,
    returnFullYears: boolean,
    hoursDistribution: HoursDistributionForMatrix[],
    fteDistribution: FteDistributionForMatrix[]
  ): YearData[] {
    const years: YearData[] = [];
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const firstMonth = returnFullYears ? 0 : startDate.getMonth();
    const lastMonth = returnFullYears ? 11 : endDate.getMonth();
    for (let yearIndex = startYear; yearIndex <= endYear; yearIndex++) {
      const months: MonthData[] = [];
      const startMonth = yearIndex === startYear ? firstMonth : 0;
      const endMonth = yearIndex === endYear ? lastMonth : 11;
      const currentYearHours = hoursDistribution
        .filter((x) => x.index === yearIndex)
        .reduce(
          (acc, curr) => {
            curr.hoursPeriods.forEach((period) => {
              acc[period.index - 1].hours += period.hoursInPeriod;
              if (
                period.isPeriodEditable &&
                !acc[period.index - 1].isPeriodEditable
              ) {
                acc[period.index - 1].isPeriodEditable = true;
              }
            });
            return acc;
          },
          Array(12)
            .fill(0)
            .map(() => ({ hours: 0, isPeriodEditable: false }))
        );
      const currentYearFte = fteDistribution
        .filter((x) => x.index === yearIndex)
        .reduce((acc, curr) => {
          curr.ftePeriods.forEach((period) => {
            acc[period.index - 1] += period.fteInPeriod;
          });
          return acc;
        }, Array(12).fill(0));
      for (let month = startMonth; month <= endMonth; month++) {
        months.push({
          totalFte: currentYearFte[month],
          totalHours: currentYearHours[month].hours,
          monthIndex: month,
          monthShortName: shortMonthNames[month],
          periodDays: new Date(yearIndex, month + 1, 0).getDate(),
          isPeriodEditable: currentYearHours[month].isPeriodEditable,
        });
      }

      years.push({ yearIndex, months });
    }
    return years;
  }
  public getTotalHoursForCtr(hours: HoursDistributionForMatrix[]): number {
    return hours.reduce((parentAcc, curr) => {
      return (
        parentAcc +
        curr.hoursPeriods.reduce((acc, curr) => {
          return acc + curr.hoursInPeriod;
        }, 0)
      );
    }, 0);
  }
  public getTotalFteForCtr(fte: FteDistributionForMatrix[]): number {
    return fte.reduce((parentAcc, curr) => {
      return (
        parentAcc +
        curr.ftePeriods.reduce((acc, curr) => {
          return acc + curr.fteInPeriod;
        }, 0)
      );
    }, 0);
  }
}
