import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_KEY, ICoreCollectionState, UserRole } from './core.reducer';

export const selectCoreCollectionState = createFeatureSelector<
  ICoreCollectionState
>(FEATURE_KEY);

export const selectCurrentRouteParams = createSelector(
  selectCoreCollectionState,
  ({ routeParams }) => routeParams
);

export const selectCurrentRouteData = createSelector(
  selectCoreCollectionState,
  ({ routeData }) => routeData
);

export const selectCurrentRouteUserRole = createSelector(
  selectCurrentRouteData,
  (routeData) => routeData.requiredUserRole
);

export const selectCurrentRouteUserRoleIsRequestor = createSelector(
  selectCurrentRouteUserRole,
  (userRole) => userRole === UserRole.REQUESTOR
);

export const selectCurrentRouteUserRoleIsEngineer = createSelector(
  selectCurrentRouteUserRole,
  (userRole) => userRole === UserRole.ENGINEER
);

export const selectCurrentRouteUserRoleIsAdmin = createSelector(
  selectCurrentRouteUserRole,
  (userRole) => userRole === UserRole.ADMIN
);

export const selectSidenavVisible = createSelector(
  selectCurrentRouteData,
  (routeData) =>
    typeof routeData.sidenavVisible === 'undefined'
      ? true
      : routeData.sidenavVisible
);

export const selectEventLogVisible = createSelector(
  selectCurrentRouteData,
  (routeData) =>
    typeof routeData.activityLogVisible === 'undefined'
      ? false
      : routeData.activityLogVisible
);

export const selectEventLogContext = createSelector(
  selectCurrentRouteData,
  (routeData) =>
    typeof routeData.activityLogContext === 'undefined'
      ? null
      : routeData.activityLogContext
);

export const selectCurrentRouteParamFactory = (name: string) => createSelector(
  selectCurrentRouteParams,
  (params) => params[ name ] ? params[ name ] : null
);
