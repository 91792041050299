import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectAllBusinessSegments } from '@collections/business-segments/store/business-segments.selectors';
import { selectAllPxDs } from '@collections/pxds/store/pxds.selectors';

import {
  adminActivitiesEntityAdapter,
  adminBlocksEntityAdapter,
  adminScopesEntityAdapter,
  FEATURE_KEY,
  IAdminActivitiesState,
} from './admin-activities.reducer';

export const selectAdminActivitiesState = createFeatureSelector<
  IAdminActivitiesState
>(FEATURE_KEY);

export const selectMastarDataExporting = createSelector(
  selectAdminActivitiesState,
  ({ exportingMasterdata }) => exportingMasterdata
);

// Activities

export const selectAdminActivitiesCollectionState = createSelector(
  selectAdminActivitiesState,
  ({ activities }) => activities
);

const {
  selectAll: _selectAllAdminActivities,
  selectEntities: _selectAdminActivitiesMap,
} = adminActivitiesEntityAdapter.getSelectors(
  selectAdminActivitiesCollectionState
);

export const selectAllAdminActivities = createSelector(
  _selectAllAdminActivities,
  (v) => v
);

export const selectAdminActivitiesMap = createSelector(
  _selectAdminActivitiesMap,
  (v) => v
);

export const selectAdminActivitiesQtyTypesOptions = createSelector(
  selectAdminActivitiesCollectionState,
  ({ qtyTypes }) => qtyTypes
);

export const selectAdminActivitiesTypeOptions = createSelector(
  selectAdminActivitiesCollectionState,
  ({ types }) => types.map((value) => ({ id: value, label: value }))
);

export const selectAdminActivityFactory = (activityId: number) =>
  createSelector(selectAdminActivitiesMap, (activities) =>
    activities[activityId] ? activities[activityId] : null
  );

export const selectAdminActivitiesByScopeIdFactory = (scopeId: number) =>
  createSelector(selectAllAdminActivities, (activities) =>
    activities
      .filter((activity) => activity.scopeId === scopeId)
      .sort((a, b) => a.order - b.order)
  );

// Scopes

export const selectAdminScopesCollectionState = createSelector(
  selectAdminActivitiesState,
  ({ scopes }) => scopes
);

const {
  selectAll: _selectAllAdminScopes,
  selectEntities: _selectAdminScopesMap,
} = adminScopesEntityAdapter.getSelectors(selectAdminScopesCollectionState);

export const selectAllAdminScopes = createSelector(
  _selectAllAdminScopes,
  (v) => v
);

export const selectAdminScopesMap = createSelector(
  _selectAdminScopesMap,
  (v) => v
);

export const selectAdminScopesQtyTypesOptions = createSelector(
  selectAdminScopesCollectionState,
  ({ qtyTypes }) => qtyTypes.map((value) => ({ id: value, label: value }))
);

export const selectAdminScopesQtyValuesOptions = createSelector(
  selectAdminScopesCollectionState,
  ({ qtyValues }) =>
    Object.entries(qtyValues).map(([id, label]) => ({ id, label }))
);

export const selectAdminScopeFactory = (scopeId: number) =>
  createSelector(selectAdminScopesMap, (scopes) =>
    scopes[scopeId] ? scopes[scopeId] : null
  );

export const selectAdminScopesByBlockIdFactory = (blockId: number) =>
  createSelector(selectAllAdminScopes, (scopes) =>
    scopes
      .filter((scope) => scope.blockId === blockId)
      .sort((a, b) => a.order - b.order)
  );

// Blocks

export const selectAdminBlocksCollectionState = createSelector(
  selectAdminActivitiesState,
  ({ blocks }) => blocks
);

export const selectAdminBlocksLoaded = createSelector(
  selectAdminBlocksCollectionState,
  ({ loaded }) => loaded
);

const {
  selectAll: _selectAllAdminBlocks,
  selectEntities: _selectAdminBlocksMap,
} = adminBlocksEntityAdapter.getSelectors(selectAdminBlocksCollectionState);

export const selectAllAdminBlocks = createSelector(
  _selectAllAdminBlocks,
  (v) => v
);

export const selectAdminBlocksMap = createSelector(
  _selectAdminBlocksMap,
  (v) => v
);

export const selectAdminBlockFactory = (blockId: number) =>
  createSelector(selectAdminBlocksMap, (blocks) =>
    blocks[blockId] ? blocks[blockId] : null
  );

export const selectAdminBlocksByPxDFactory = (ctrId: number) =>
  createSelector(selectAllAdminBlocks, (blocks) =>
    blocks
      .filter((block) => block.ctrId === ctrId)
      .sort((a, b) => a.order - b.order)
  );

// Utils

export const selectBlockAncestorsPathFactory = (blockId: number) =>
  createSelector(
    selectAdminBlockFactory(blockId),
    selectAllPxDs,
    selectAllBusinessSegments,
    (block, pxds, segments) => {
      if (!block) {
        return null;
      }

      const parentPxD = pxds.find((pxd) => pxd.id === block.ctrId);

      if (!parentPxD) {
        return {
          blockId,
          blockName: block.blockDisplayName,
        };
      }

      const blockSegment = segments[parentPxD.businessSegmentId];

      return {
        businessSegmentId: parentPxD.businessSegmentId,
        businessSegmentName: blockSegment.businessSegmentName,
        pxdId: parentPxD.id,
        pxdName: parentPxD.shortName,
        blockId,
        blockName: block.blockDisplayName,
      };
    }
  );
