import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { UserPickerComponent } from '@common/user-picker/user-picker.component';
import { MaterialModule } from '@shared/material.module';

@NgModule({
  declarations: [UserPickerComponent],
  imports: [MaterialModule, CommonModule, ReactiveFormsModule],
  exports: [UserPickerComponent],
})
export class UserPickerModule {}
