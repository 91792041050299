import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DeliverablesEffects } from '@collections/deliverables/store/deliverables.effects';

import { FEATURE_KEY, reducer } from './store/deliverables.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([DeliverablesEffects]),
  ],
})
export class DeliverablesCollectionModule {}
