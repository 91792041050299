import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { UserRole } from '@core/store/core.reducer';

import { environment } from '../environments/environment';

import { CurrentUserIsAdminGuard } from './auth/current-user-is-admin.guard';
import { MainUserIsAdminGuard } from './auth/main-user-is-admin.guard';
import { UserDashboardGuard } from './auth/user-dashboard.guard';
import { UserIsEngineerGuard } from './auth/user-is-engineer.guard';
import { UserIsRequestorGuard } from './auth/user-is-requestor.guard';
import { RequestorProjectResolver } from './collections/projects/requestor-project-resolver.service';
import { ContactComponent } from './contact/contact.component';
import { KpiComponent } from './home/kpi/kpi.component';
import { NoopComponent } from './noop/noop.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [UserDashboardGuard],
    component: NoopComponent,
  },
  {
    path: '',
    canActivate: environment.authentication ? [MsalGuard] : [],
    canActivateChild: environment.authentication ? [MsalGuard] : [],
    children: [
      {
        path: 'users',
        pathMatch: 'full',
        data: { pageTitle: 'Users', requiredUserRole: UserRole.ADMIN },
        loadChildren: () =>
          import('./admin/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'dashboard/requestor',
        pathMatch: 'full',
        data: {
          pageTitle: 'Overview',
          requiredUserRole: UserRole.REQUESTOR,
          showAddProjectButton: true,
        },
        canActivate: [UserIsRequestorGuard],
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'dashboard/engineer',
        pathMatch: 'full',
        data: {
          pageTitle: 'Overview',
          requiredUserRole: UserRole.ENGINEER,
        },
        canActivate: [UserIsEngineerGuard],
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'intermediate',
        data: { pageTitle: 'Change user' },
        canActivate: [MainUserIsAdminGuard],
        loadChildren: () =>
          import('./intermediate/intermediate.module').then(
            (m) => m.IntermediateModule
          ),
      },
      {
        path: 'scenario/:projectId',
        data: {
          pageTitle: 'New scenario',
          requiredUserRole: UserRole.REQUESTOR,
          showNewScenarioSidenav: true,
        },
        canActivate: [UserIsRequestorGuard],
        resolve: { project: RequestorProjectResolver },
        loadChildren: () =>
          import('./scenario/scenario.module').then((m) => m.ScenarioModule),
      },
      {
        path: 'project',
        canActivate: [UserIsRequestorGuard],
        loadChildren: () =>
          import('./project/project.module').then((m) => m.ProjectModule),
      },
      {
        path: 'viewscenario/:projectId/:scenarioId',
        data: {
          pageTitle: 'Scenario',
          requiredUserRole: UserRole.REQUESTOR,
          activityLogVisible: true,
          activityLogContext: {
            name: 'scenarios',
            keyName: 'scenarioId',
            routeParamName: 'scenarioId',
          },
          pageFor: UserRole.REQUESTOR,
        },
        canActivate: [UserIsRequestorGuard],
        resolve: { project: RequestorProjectResolver },
        loadChildren: () =>
          import('./scenario/scenario.module').then((m) => m.ScenarioModule),
      },
      {
        path: 'view',
        data: {
          pageTitle: 'Scenario view',
          requiredUserRole: UserRole.ENGINEER,
        },
        canActivate: [UserIsEngineerGuard],
        loadChildren: () =>
          import('./scenario/ctr/ctr.module').then((m) => m.CtrModule),
      },
      {
        path: 'documents',
        data: { pageTitle: 'Documents', requiredUserRole: UserRole.REQUESTOR },
        canActivate: [UserIsRequestorGuard],
        loadChildren: () =>
          import('./project-documents/project-documents.module').then(
            (m) => m.ProjectDocumentsModule
          ),
      },
      {
        path: 'view/documents',
        data: { pageTitle: 'Documents', requiredUserRole: UserRole.ENGINEER },
        canActivate: [UserIsEngineerGuard],
        loadChildren: () =>
          import('./project-documents/project-documents.module').then(
            (m) => m.ProjectDocumentsModule
          ),
      },
      {
        path: 'view',
        data: { pageTitle: 'Admin', requiredUserRole: UserRole.ADMIN },
        canActivate: [CurrentUserIsAdminGuard],
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'view/kpi',
        data: {
          pageTitle: 'KPI & Export',
        },
        component: KpiComponent,
      },
      {
        path: 'contact',
        data: { pageTitle: 'Contact' },
        component: ContactComponent,
      },
      {
        path: 'userguide',
        data: { pageTitle: 'User Guide' },
        component: UserGuideComponent,
      },
      {
        path: 'release-notes',
        data: { pageTitle: 'Release Notes' },
        component: ReleaseNotesComponent,
      },
    ],
  },
  {
    path: 'overview',
    pathMatch: 'full',
    canActivate: [UserDashboardGuard],
    component: NoopComponent,
  },
  {
    path: 'register',
    component: WelcomePageComponent,
    data: { sidenavVisible: false },
    canActivateChild: [],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: { pageTitle: 'Unauthorized' },
    canActivateChild: [],
  },
  {
    path: '**',
    data: { pageTitle: '' },
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
      paramsInheritanceStrategy: 'always',
      initialNavigation: 'enabledNonBlocking',
      enableTracing: false,
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
