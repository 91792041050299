import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DeliverablesApiService } from '@collections/deliverables/deliverables-api-service';
import {
  getDeliverablesAction,
  getDeliverablesFailureAction,
  getDeliverablesSuccessAction,
  patchDeliverablesSelectActionFailure,
  patchDeliverablesSelectActionSuccess,
  updateDeliverablesSelectAction,
} from '@collections/deliverables/store/deliverables.actions';

@Injectable()
export class DeliverablesEffects {
  public getDeliverables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDeliverablesAction),
      switchMap((action) =>
        this.deliverablesApiService.getDeliverables(action.payload.ctrId).pipe(
          map((deliverableBlocks) =>
            getDeliverablesSuccessAction({
              trigger: action,
              context: 'CTRDeliverablesEffects:: getDeliverables$',
              payload: {
                ctrId: action.payload.ctrId,
                ctrDeliverableBlocks: deliverableBlocks,
              },
            })
          ),
          catchError(() =>
            of(
              getDeliverablesFailureAction({
                trigger: action,
                context: 'CTRDeliverablesEffects::getDeliverables$',
              })
            )
          )
        )
      )
    )
  );

  public patchDeliverablesSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDeliverablesSelectAction),
      switchMap((action) =>
        this.deliverablesApiService
          .updateDeliverablesSelection(
            action.payload.ctrId,
            action.payload.deliverables
          )
          .pipe(
            map(() =>
              patchDeliverablesSelectActionSuccess({
                trigger: action,
                context: 'DeliverablesEffects:patchDeliverablesSelect$',
                payload: {
                  ctrId: action.payload.ctrId,
                },
              })
            ),
            catchError(() =>
              of(
                patchDeliverablesSelectActionFailure({
                  trigger: action,
                  context: 'DeliverablesEffects:patchDeliverablesSelect$',
                })
              )
            )
          )
      )
    )
  );

  public getDeliverablesAfterPatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchDeliverablesSelectActionSuccess),
      map((action) =>
        getDeliverablesAction({
          trigger: action,
          context: 'DeliverablesEffects:getDeliverablesAfterPatch$',
          payload: {
            ctrId: action.payload.ctrId,
          },
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private deliverablesApiService: DeliverablesApiService
  ) {}
}
