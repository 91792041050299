import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatusLabelComponent } from './status-label.component';

const components = [
  StatusLabelComponent
]

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule
  ]
})
export class StatusLabelModule { }
