<svg
  class="ctr-icon"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 17"
>
  <path
    d="M.1 15.8C-1 13.9 7.7 0 10.1 0s10.7 14.3 9.8 15.8-18.6 1.9-19.8 0z"
    [ngClass]="statusClass"
  />
</svg>
