import { format } from 'date-fns';

import { DefaultDateFormat } from '@app/DateAdapter';
import { ConditionType } from '@common/enums';
import { IFilterCondition, IFilterConditionWithName } from '@models/filters';

export function convertObjectListToAutocompleteOptions(
  values: Object[],
  valueKey: string,
  labelKey: string
) {
  return values.map(
    (value) =>
      ({
        value: value[valueKey],
        name: value[labelKey],
      } as IFilterConditionWithName)
  );
}

/**
 * Uses caller scope to retrieve arguments.
 */
export function convertObjectListToAutocompleteOptionsCallerBound(
  values: Object[]
) {
  return convertObjectListToAutocompleteOptions(
    values,
    this.optionValueKey,
    this.optionLabelKey
  );
}

export function convertStringListToAutocompleteOptions(values: Object[]) {
  return values.map(
    (value) =>
      ({
        value: value,
        name: value,
      } as IFilterConditionWithName)
  );
}

/**
 * Server returns filters as comma seperated list of ids.
 * To fill filters form we need collect labels for those ids.
 * This function extracts those labels from provided context
 * and returns data formated for use in filters form.
 */
export function convertServerIdListToFormListData(
  value: string,
  type: ConditionType,
  context: { [conditionType: number]: { [id: number]: any } }
): IFilterConditionWithName[] {
  if (!value) {
    return [];
  }
  return value.split(',').map((id) => {
    const valueId = parseInt(id, 10);
    const conditionAvailableValues = context[type];
    const valueData = conditionAvailableValues[valueId];

    return valueData
      ? ({
          type,
          value: valueId,
          name: valueData[this.optionLabelKey],
        } as IFilterConditionWithName<number>)
      : ({
          type,
          value: valueId,
          name: `[${valueId}] ${this.formControlName} name unavailable`,
        } as IFilterConditionWithName<number>);
  });
}

export function convertServerStringToBooleanFormData(
  value: string,
  type: ConditionType,
  context: { [conditionType: number]: { [id: number]: any } }
): IFilterConditionWithName[] {
  if (!value) {
    return [];
  }
  return [
    {
      type,
      value: value === 'true',
      name: value === 'true' ? this.overviewLabel : '',
    },
  ];
}

export function convertConditionServerIdListToFormListData(
  condition: IFilterCondition<string>,
  context: { [conditionType: number]: { [id: number]: any } }
) {
  return convertServerIdListToFormListData.call(
    this,
    condition.value,
    condition.type,
    context
  );
}

export function convertConditionServerToBooleanFormValue(
  condition: IFilterCondition<string>,
  context: { [conditionType: number]: { [id: number]: any } }
) {
  return !!condition.value;
}

export function convertFormBooleanToServerData(condition: boolean) {
  if (condition) {
    return condition.toString();
  }
  return null;
}

export function convertFormObjectListToServerList(list: IFilterCondition[]) {
  if (!Array.isArray(list) || list.length === 0) {
    return null;
  }
  return list.map(({ value }) => value).join(',');
}

export function convertServerStringToFormData(
  value: string,
  type: ConditionType,
  context: { [conditionType: number]: { [id: number]: any } },
  allowOtherValues: boolean
): IFilterConditionWithName[] {
  if (!value) {
    return [];
  }
  return value
    .split(',')
    .map((name) => {
      const conditionAvailableValues = context[type] as string[];

      if (!allowOtherValues && !conditionAvailableValues.includes(name)) {
        return null;
      }

      return {
        type,
        value: name,
        name,
      };
    })
    .filter((v) => !!v);
}

export function convertConditionServerStringListToFormListData(
  condition: IFilterCondition<string>,
  context: { [conditionType: number]: { [id: number]: any } },
  allowOtherValues
) {
  return convertServerStringToFormData.call(
    this,
    condition.value,
    condition.type,
    context,
    allowOtherValues
  );
}

export function convertServerDateToFormDate(condition: IFilterCondition) {
  return condition.value ? new Date(condition.value) : null;
}

export function convertServerDateToConditions(
  date: string,
  type: ConditionType
) {
  const value: Date = date ? new Date(date) : null;
  return [
    {
      type,
      value,
      name: value ? format(value, DefaultDateFormat) : null,
    },
  ].filter((condition) => !!condition.value);
}

export function convertFormDateToServerDate(date: Date): string {
  return !!date && date.toISOString ? date.toISOString() : '';
}

export function removeConditionValue(
  condition: IFilterCondition
): IFilterCondition {
  return {
    ...condition,
    value: null,
  };
}

export function removeConditionItemByValue(
  condition: IFilterCondition,
  removedValue: string
): IFilterCondition {
  return {
    ...condition,
    value: condition.value
      .split(',')
      .filter((x) => x.toString() !== removedValue.toString())
      .join(','),
  };
}
