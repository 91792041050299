<mat-form-field
  appearance="outline"
  [matTooltip]="'There are no other users to pick'"
  [matTooltipDisabled]="!showTooltip"
  [matTooltipPosition]="'above'"
>
  <input
    matInput
    type="text"
    [matAutocomplete]="auto"
    #trigger="matAutocompleteTrigger"
    [formControl]="control"
    (click)="inputClick($event,trigger)"
  >
  <mat-icon matSuffix>arrow_drop_down</mat-icon>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="userToString"
  >
    <mat-option
      *ngFor="let user of (filteredUsers$ | async)"
      [value]="user"
      data-test="autocomplete-user-option"
    >
      {{user.fullName}} <span *ngIf="showEntity">({{user.enggCenter}})</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
