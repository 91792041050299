import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserCtrRole } from '@common/enums';
import { deepClone } from '@common/utils';

import { FEATURE_KEY, ISidenavState } from './sidenav.reducer';

export const selectSidenavCollectionState =
  createFeatureSelector<ISidenavState>(FEATURE_KEY);

export const selectSidenavProjectConfiguration = selectSidenavCollectionState;

export const selectSidenavProjectConfigurationOwn = createSelector(
  selectSidenavProjectConfiguration,
  (configuration) => {
    const configurationDeepCopy = deepClone(configuration);

    configurationDeepCopy.scenarios?.filter((scenario) => {
      scenario.sctrs = scenario.sctrs.filter(
        (sctr) => sctr.role !== UserCtrRole.VIEWER
      );

      return scenario.sctrs.length;
    });

    return configurationDeepCopy;
  }
);

export const selectSidenavProjectName = createSelector(
  selectSidenavProjectConfiguration,
  ({ projectName }) => projectName
);

export const selectSidenavProjectId = createSelector(
  selectSidenavProjectConfiguration,
  ({ projectId }) => projectId
);

export const selectScenarioLayoutInfo = (scenarioId: number) =>
  createSelector(
    selectSidenavProjectConfiguration,
    ({ scenarios = [], loading }) =>
      loading
        ? null
        : scenarios.find((scenario) => scenario.scenarioId === scenarioId)
  );

export const selectCtrGeneralInfoFactory = (ctrId: number) =>
  createSelector(selectSidenavProjectConfiguration, (projectMetaData) => {
    for (const scenario of projectMetaData?.scenarios || []) {
      const result = scenario.sctrs.find((ctr) => ctr.scenarioCTRId === ctrId);
      if (result) {
        return result;
      }
    }

    return null;
  });

export const selectSidenavScenarios = createSelector(
  selectSidenavProjectConfiguration,
  (configuration) => configuration?.scenarios || []
);

export const selectSidenavUserIsViewer = createSelector(
  selectSidenavProjectConfiguration,
  (configuration) => configuration?.userIsViewer
);
