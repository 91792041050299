import { DOCUMENT } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import {
  StoreDevtoolsModule,
  StoreDevtoolsOptions,
} from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';

import { CollectionsModule } from '@collections/collections.module';
import { FEATURE_KEY as USERS_FEATURE_KEY } from '@collections/users/store/users.reducer';
import {
  SelectionMode,
  selectionModeToken,
} from '@common/pxd-grid/selection.service';
import { environment } from '@environments/environment';

import { routerReducer } from '@ngrx/router-store';
import { ApiInterceptor } from './api.interceptor';
import { I18nModule } from './i18n/i18n.module';
import { LockModule } from './lock/lock.module';
import {
  FEATURE_KEY as CORE_FEATURE_KEY,
  reducer as coreReducer,
} from './store/core.reducer';
import { SaveBlobHelper } from './utils/save-blob-helper';
import { WebSocketModule } from './web-socket/web-socket.module';

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [{ [USERS_FEATURE_KEY]: ['currentUserId', 'mainUserId'] }],
    rehydrate: false,
  })(reducer);
}
const metaReducers: MetaReducer<any, any>[] = [localStorageSyncReducer];

@NgModule({
  imports: [
    StoreModule.forRoot(
      { [CORE_FEATURE_KEY]: coreReducer, router: routerReducer },
      { metaReducers }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
      autoPause: true,
      features: {
        pause: false,
        lock: true,
        persist: false,
        test: !environment.production,
        trace: !environment.production,
      },
    } as StoreDevtoolsOptions),

    EffectsModule.forRoot([]),
    CollectionsModule,
    I18nModule,
    WebSocketModule.forRoot(),
    LockModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    {
      provide: Document,
      useExisting: DOCUMENT,
    },
    {
      provide: selectionModeToken,
      useValue: SelectionMode.NO_SELECTION,
    },
    SaveBlobHelper,
  ],
  exports: [StoreModule, EffectsModule],
})
export class CoreModule {
  constructor() {}
}
