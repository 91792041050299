<div *ngIf="(compareScenarioItems$ | async)">
  <div class="table-wrapper">
    <ng-container *ngIf="ctrTypes && ctrTypes.length > 0">
      <div class="row-names-column">
        <div class="row-names-header">
          <span>CTRs</span>
        </div>
        <ng-container *ngFor="let type of ctrTypes">
          <app-scenario-comparison-cell
            [content]="type"
            [header]="true"
            [bold]="true"
            data-test="scenario-comparison-ctr-pxd-name"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            content="Total hours"
            data-test="scenario-comparison-total-hours-label"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            content="Total cost"
            data-test="scenario-comparison-total-cost-label"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            content="Total GEC utilization"
            data-test="scenario-comparison-gec-utilization-label"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            content="Requested on"
            data-test="scenario-comparison-requested-date-label"
          >
          </app-scenario-comparison-cell>
        </ng-container>
      </div>
      <div class="table-wrapper">
        <app-scenario-comparison-column
          [scenario]="baseCaseScenario$ | async"
          [ctrTypes]="ctrTypes"
          [projectId]="projectId"
        ></app-scenario-comparison-column>
        <app-scenario-comparison-column
          *ngFor="let scenario of (compareScenarioItemsWithoutBaseCase$ | async).slice(0,2);"
          [scenario]="scenario"
          [allProjectScenarios]="(compareScenarioItemsWithoutBaseCase$ | async).length > 2 ? (compareScenarioItemsWithoutBaseCase$ | async) : null"
          [ctrTypes]="ctrTypes"
          [projectId]="projectId"
        ></app-scenario-comparison-column>
      </div>
    </ng-container>
    <span
      *ngIf="!ctrTypes || !ctrTypes.length > 0"
      class="no-scenario-message"
      data-test="scenario-comparison-no-ctrs-label"
    >
      No ctrs to display.
    </span>
  </div>
</div>