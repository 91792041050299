import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import { CtrStatus } from '@common/enums';
import { isNumeric } from '@core/utils';

@Component({
  selector: 'app-scenario-comparison-cell',
  templateUrl: './scenario-comparison-cell.component.html',
  styleUrls: ['./scenario-comparison-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioComparisonCellComponent implements OnChanges {
  @Input() content: string;
  @Input() ctrStatus: CtrStatus;
  @Input() link: string[] = null;
  @Input() alignRight = false;
  @Input() bold = false;
  @Input() header = false;
  @Input() firstColumn = false;
  @Input() percentValue = false;

  public backgroundClass: string;
  public contentIsNumeric: boolean;
  private backgroundClassConditionsConfiguration: any[] = [
    {
      class: 'default',
      header: false,
      firstColumn: false,
    },
    {
      class: 'first-column',
      header: false,
      firstColumn: true,
    },
    {
      class: 'header',
      header: true,
      firstColumn: false,
    },
    {
      class: 'first-column-header',
      header: true,
      firstColumn: true,
    },
  ];

  public ngOnChanges() {
    this.backgroundClass = this.getBackgroundClass();
    this.contentIsNumeric = isNumeric(this.content);
  }

  private getBackgroundClass(): string {
    return this.backgroundClassConditionsConfiguration.find((binding) => {
      return (
        binding.header === this.header &&
        binding.firstColumn === this.firstColumn
      );
    }).class;
  }
}
