import { Action } from '@ngrx/store';

let actionUniqueIndex = 0;

let generateActionId = () =>
  actionUniqueIndex++ + '-' + Date.now().toString().substr(-4);

export interface ContextAwareAction<P extends object | void = any>
  extends Action {
  context: string;
  trigger?: Action | Action[];
  id: string;
  payload: P;
}

export const contextAwareProps =
  <T = {}>() =>
  (
    {
      context = 'unknown',
      trigger = null,
      id = generateActionId(),
      ...parameters
    }: {
      context?: string;
      trigger?: Action | Action[];
      id?: string;
    } & T = {} as T
  ) => ({
    context,
    trigger,
    id,
    ...(parameters as T),
  });

export const mockActionIdGenerator = (generator: () => string) => {
  generateActionId = generator;
};
