import { createAction } from '@ngrx/store';

import { ScenarioChangeStatusRequest } from '@common/enums';
import { contextAwareProps } from '@core/utils/context-aware-props';
import { IGetScenarioResponse } from '@models/scenario';

export const getScenarioAction = createAction(
  '[Collection Scenarios] GET /scenarios/:id',
  contextAwareProps<{ payload: { scenarioId: number } }>()
);

export const getScenarioSuccessAction = createAction(
  '[Collection Scenarios Effect] GET /scenarios/:id SUCCESS',
  contextAwareProps<{ payload: IGetScenarioResponse }>()
);

export const getScenarioFailureAction = createAction(
  '[Collection Scenarios Effect] GET /scenarios/:id FAILURE',
  contextAwareProps()
);

export const putScenarioStatusAction = createAction(
  '[Collection Scenarios] PUT /scenarios/:id/scenarioStatus',
  contextAwareProps<{
    payload: { scenarioId: number; statusChange: ScenarioChangeStatusRequest };
  }>()
);

export const putScenarioStatusSuccessAction = createAction(
  '[Collection Scenarios Effect] PUT /scenarios/:id/scenarioStatus SUCCESS',
  contextAwareProps<{ payload: any }>()
);

export const putScenarioStatusFailureAction = createAction(
  '[Collection Scenarios Effect] PUT /scenarios/:id/scenarioStatus FAILURE',
  contextAwareProps()
);
