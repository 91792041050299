import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Application } from '@models/application';

import {
  getApplicationsAction,
  getApplicationsFailureAction,
  getApplicationsSuccessAction,
} from './applications.actions';

export const FEATURE_KEY = 'applications';

export interface IApplicationsCollectionState extends EntityState<Application> {
  loaded: boolean;
}

export const applicationsEntityAdapter = createEntityAdapter<Application>({
  selectId: ({ applicationId }) => applicationId,
});

export const initialState = applicationsEntityAdapter.getInitialState({
  loaded: false,
} as IApplicationsCollectionState);

export const reducer = createReducer(
  initialState,
  on(getApplicationsAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getApplicationsSuccessAction, (state, { payload }) =>
    applicationsEntityAdapter.upsertMany(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getApplicationsFailureAction, (state) => ({
    ...state,
    loaded: true,
  }))
);
