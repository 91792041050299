<app-user-avatar
  class="avatar"
  [avatar]="avatar"
  [signature]="signature"
></app-user-avatar>

<div class="box" [class.user-comment]="type === 'comment'">
  <h3 class="author">{{ author }}</h3>
  <p *ngIf="message" class="message">
    {{ message | slice : 0 : charLimit()
    }}<a *ngIf="message.length > 120 && limit" (click)="limit = false"
      >...more</a
    >
  </p>
  <div class="footer">
    <small>{{ date }}</small>
    <button
      *ngIf="type === 'comment'"
      class="reply"
      mat-button
      color="primary"
      (click)="onReply()"
    >
      Replay <mat-icon>reply</mat-icon>
    </button>
  </div>
</div>
