import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { environment } from '@environments/environment';

export enum HubEvents {
  'CtrOpened' = 'CtrOpened',
  'CtrClosed' = 'CtrClosed',
}
export enum EctrEvents {
  'MasterDataUpdated' = 'MasterDataUpdated',
  'WorkloadForRolesUpdated' = 'WorkloadForRolesUpdated',
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private readonly connectionSubject$ =
    new BehaviorSubject<signalR.HubConnection>(null);

  public initiateSocketConnection(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(environment.socketUrl) // the SignalR server url
        .build();

      connection
        .start()
        .then(() => {
          resolve();

          this.connectionSubject$.next(connection);
        })
        .catch((error) => {
          console.log(
            `%cSignalR`,
            'color: #f40',
            `Socket connection error: ${error}`
          );
          resolve();
        });
    });
  }
  public executeOnServer$<TRequest, TResult>(
    methodName: string,
    args: TRequest
  ): Observable<TResult> {
    return this.connectionSubject$.pipe(
      filter((v) => !!v),
      switchMap((connection) =>
        from(connection.invoke<TResult>(methodName, args))
      )
    );
  }
  public on$<T = unknown>(message: string): Observable<T> {
    return this.connectionSubject$.pipe(
      filter((v) => !!v),
      switchMap(
        (connection) =>
          new Observable<T>((subscribe) => {
            const handler = (data) => subscribe.next(data);
            connection.on(message, handler);

            return () => {
              connection.off(message, handler);
            };
          })
      )
    );
  }
}
