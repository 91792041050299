import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ClientsApiService } from '../clients-api.service';

import {
  getClientsAction,
  getClientsFailureAction,
  getClientsSuccessAction,
} from './clients.actions';

@Injectable()
export class ClientsEffects {
  public getClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClientsAction),
      switchMap((action) =>
        this.clientsApiService.getClients().pipe(
          map((payload) =>
            getClientsSuccessAction({
              trigger: action,
              context: 'ClientsEffects::getClients$',
              payload,
            })
          ),
          catchError(() =>
            of(
              getClientsFailureAction({
                trigger: action,
                context: 'ClientsEffects::getClients$',
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private clientsApiService: ClientsApiService
  ) {}
}
