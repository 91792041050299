import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IGetBusinessSegmentsResponseItem } from '@models/business-segments';

import {
  getBusinessSegmentsAction,
  getBusinessSegmentsFailureAction,
  getBusinessSegmentsSuccessAction,
} from './business-segments.actions';

export const FEATURE_KEY = 'business-segments';

export interface IBusinessSegmentsCollectionState
  extends EntityState<IGetBusinessSegmentsResponseItem> {
  loaded: boolean;
}

export const businessSegmentsEntityAdapter =
  createEntityAdapter<IGetBusinessSegmentsResponseItem>({
    selectId: ({ businessSegmentId }) => businessSegmentId,
  });

export const initialState = businessSegmentsEntityAdapter.getInitialState({
  loaded: false,
} as IBusinessSegmentsCollectionState);

export const reducer = createReducer(
  initialState,
  on(getBusinessSegmentsAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getBusinessSegmentsSuccessAction, (state, { payload }) =>
    businessSegmentsEntityAdapter.upsertMany(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getBusinessSegmentsFailureAction, (state) => ({
    ...state,
    loaded: true,
  }))
);
