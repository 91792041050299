import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { GetClientsResponse } from '@app/models/backendModel';
import {
  getClientsAction,
  getClientsFailureAction,
  getClientsSuccessAction,
} from './clients.actions';

export const FEATURE_KEY = 'clients';

export interface IClientsCollectionState
  extends EntityState<GetClientsResponse> {
  loaded: boolean;
}

export const clientsEntityAdapter = createEntityAdapter<GetClientsResponse>({
  selectId: ({ clientId }) => clientId,
});

export const initialState = clientsEntityAdapter.getInitialState({
  loaded: false,
} as IClientsCollectionState);

export const reducer = createReducer(
  initialState,
  on(getClientsAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getClientsSuccessAction, (state, { payload }) =>
    clientsEntityAdapter.upsertMany(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getClientsFailureAction, (state) => ({
    ...state,
    loaded: true,
  }))
);
