import { SaveWorkloadDistributionForRolesCommand } from '@app/models/backendModel';
import { createActionGroup, props } from '@ngrx/store';
import { IRolesCalendarizationState } from './roles-calendarization.reducer';

export const RolesCalendarizationActions = createActionGroup({
  source: 'Roles Calendarization',
  events: {
    'Init roles calendarization': props<{ ctrId: number }>(),
    'Init roles calendarization Success': props<IRolesCalendarizationState>(),
    'Update roles calendarization':
      props<SaveWorkloadDistributionForRolesCommand>(),
    'Distribute evenly': props<{ scenarioCtrId: number }>(),
  },
});
