<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <p display-2>{{ data.message }}</p>
</mat-dialog-content>

<div class="button-right" mat-dialog-actions [align]="'end'">
  <button
    (click)="onConfirm()"
    color="primary"
    data-test="error-popup-confirm-button"
    mat-button
  >
    OK
  </button>
</div>
