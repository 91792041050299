import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mapTo, switchMap } from 'rxjs/operators';

import {
  IGetAdminActivitiesAutocompletionDataResponse,
  IGetAdminScopesAutocompletionDataResponse,
} from '@models/admin';
import {
  AdminActivity,
  IGetAdminBlockActivitiesResponseItem,
} from '@models/admin-activity';
import { AdminBlock } from '@models/admin-block';
import { AdminScope, IGetAdminBlockScopesResponse } from '@models/admin-scope';
import { OrderableItem } from '@models/orderable-item';

@Injectable({ providedIn: 'root' })
export class AdminActivitiesApiService {
  constructor(private httpClient: HttpClient) {}

  public exportMasterData() {
    return this.httpClient.get(`{apiUrl}masterdata/export`, {
      responseType: 'blob',
    });
  }

  // Activities

  public getBlockActivities(blockId: number) {
    return this.httpClient.get<IGetAdminBlockActivitiesResponseItem[]>(
      `{apiUrl}admin/block/${blockId}/activity`
    );
  }

  public getActivity(activityId: number) {
    return this.httpClient.get<AdminActivity>(
      `{apiUrl}masterdata/activity/${activityId}`
    );
  }

  public postActivity(activityData: AdminActivity) {
    return this.httpClient
      .post(`{apiUrl}masterdata/activity`, activityData)
      .pipe(switchMap((activityId: number) => this.getActivity(activityId)));
  }

  public putActivity(activityData: AdminActivity) {
    return this.httpClient
      .put(`{apiUrl}masterdata/activity/${activityData.id}`, activityData)
      .pipe(switchMap(() => this.getActivity(activityData.id)));
  }

  public deleteAdminActivity(activityId: number) {
    return this.httpClient
      .delete(`{apiUrl}masterdata/activity/${activityId}`)
      .pipe(mapTo({ activityId }));
  }

  public patchActivityOrder(scopeId: number, activities: OrderableItem[]) {
    return this.httpClient
      .patch(`{apiUrl}masterdata/activities/order`, activities)
      .pipe(mapTo({ scopeId, activities }));
  }

  public getActivitiesAutocompletionData() {
    return this.httpClient.get<IGetAdminActivitiesAutocompletionDataResponse>(
      `{apiUrl}admin/activityData`
    );
  }

  // Scopes

  public getAdminScope(scopeId: number) {
    return this.httpClient.get<AdminScope>(
      `{apiUrl}masterdata/scope/${scopeId}`
    );
  }

  public getBlockScopes(blockId: number) {
    return this.httpClient
      .get<IGetAdminBlockScopesResponse>(
        `{apiUrl}masterdata/block/${blockId}/scopes`
      )
      .pipe(map(({ scopes }) => scopes));
  }

  public postScope(scopeData: AdminScope) {
    return this.httpClient
      .post(`{apiUrl}masterdata/scope`, scopeData)
      .pipe(switchMap((scopeId: number) => this.getAdminScope(scopeId)));
  }

  public putScope(scopeData: AdminScope) {
    return this.httpClient
      .put(`{apiUrl}masterdata/scope/${scopeData.scopeId}`, scopeData)
      .pipe(switchMap(() => this.getAdminScope(scopeData.scopeId)));
  }

  public deleteAdminScope(scopeId: number) {
    return this.httpClient
      .delete(`{apiUrl}masterdata/scope/${scopeId}`)
      .pipe(mapTo({ scopeId }));
  }

  public patchScopeOrder(blockId: number, scopes: OrderableItem[]) {
    return this.httpClient
      .patch(`{apiUrl}masterdata/scopes/order`, scopes)
      .pipe(mapTo({ blockId, scopes }));
  }

  public getScopesAutocompletionData() {
    return this.httpClient.get<IGetAdminScopesAutocompletionDataResponse>(
      `{apiUrl}masterdata/scope/data`
    );
  }

  // Blocks

  public getAdminBlock(blockId: number) {
    return this.httpClient.get<AdminBlock>(
      `{apiUrl}masterdata/block/${blockId}`
    );
  }

  public getPxDBlocks(productId: number, disciplineId: number) {
    return this.httpClient
      .get<AdminBlock[]>(
        `{apiUrl}admin/blocks/product/${productId}/discipline/${disciplineId}`
      )
      .pipe(
        map((blocks) =>
          blocks.map((block) => ({ ...block, productId, disciplineId }))
        )
      );
  }

  public postBlock(blockData: AdminBlock) {
    return this.httpClient
      .post(`{apiUrl}masterdata/block`, blockData)
      .pipe(switchMap((blockId: number) => this.getAdminBlock(blockId)));
  }

  public putBlock(blockData: AdminBlock) {
    return this.httpClient
      .put(`{apiUrl}masterdata/block/${blockData.blockId}`, blockData)
      .pipe(switchMap(() => this.getAdminBlock(blockData.blockId)));
  }

  public getAvailableBlockCode(ctrId: number) {
    return this.httpClient.get<any>(
      `{apiUrl}masterdata/block/${ctrId}/availableCode`
    );
  }

  public getAvailableScopeCode(blockId: number) {
    return this.httpClient.get<any>(
      `{apiUrl}masterdata/scope/${blockId}/availableCode`
    );
  }

  public getAvailableActivityCode(scopeId: number) {
    return this.httpClient.get<any>(
      `{apiUrl}masterdata/activity/${scopeId}/availableCode`
    );
  }

  public deleteAdminBlock(blockId: number) {
    return this.httpClient
      .delete(`{apiUrl}masterdata/block/${blockId}`)
      .pipe(mapTo({ blockId }));
  }

  public patchBlockOrder(ctrId: number, blocks: OrderableItem[]) {
    return this.httpClient
      .patch(`{apiUrl}masterdata/blocks/order`, blocks)
      .pipe(mapTo({ ctrId, blocks }));
  }
}
