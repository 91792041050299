<app-user-avatar
  class="avatar"
  [avatar]="avatar"
  [signature]="signature"
></app-user-avatar>

<div class="box user-comment">
  <h3 class="author">{{ author }}<span class="muted">&#64;{{ to }}</span></h3>
  <textarea
    [formControl]="messageFormControl"
    (keypress)="inputControl($event)"
    #input
  ></textarea>
</div>
