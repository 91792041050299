<div
  class="card-wrapper"
  [style.display]="fitToContent ? 'inline-block' : 'block'"
>
  <div
    class="card"
    [class.disabled]="disabled"
  >
    <div class="title-bar">
      <div class="title-container">
        <p
          class="title"
          [style.fontSize]="titleFontSize"
          [class.with-subtitle]="!!subtitle"
        >{{title}}</p>
        <p
          *ngIf="subtitle"
          class="subtitle"
        >{{subtitle}}</p>
      </div>
      <div class="title-bar-content-container">
        <ng-content select=".title-bar-content"></ng-content>
      </div>
    </div>
    <div
      class="content"
      [style.padding]="customContentPaddingSize !== undefined ? customContentPaddingSize : null"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>