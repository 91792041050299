import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_KEY, ILockCollectionState } from './lock.reducer';

export const selectLockState =
  createFeatureSelector<ILockCollectionState>(FEATURE_KEY);

export const selectAdminActivitiesLocked = createSelector(
  selectLockState,
  ({ locked }) => locked
);
