<app-lock-indicator></app-lock-indicator>

<div class="masterdata-action-row">

  <app-business-segment-selector
    (selectedBusinessSegmentIdChange)="selectedBusinessSegmentId$.next($event)"
    [selectedBusinessSegmentId]="selectedBusinessSegmentId$ | async"
  ></app-business-segment-selector>

  <div class="export-masterdata-button">
    <button
      mat-button
      color="accent"
      (click)="exportMasterdata()"
      data-test="export-masterdata-button"
      [disabled]="mastardataIsExporting$ | async"
    >Export masterdata</button>

  </div>
</div>

<ng-container *ngIf="selectedBusinessSegmentId$ | async as selectedBusinessSegmentId">
  <app-pxd-selector
    (selectedPxDChange)="selectedPxDKey$.next($event)"
    [businessSegmentId]="selectedBusinessSegmentId"
    [selectedPxD]="selectedPxDKey$ | async"
    [legendItems]="legendItems"
  ></app-pxd-selector>

  <ng-container *ngIf="selectedPxD$ | async as selectedPxD">
    <app-masterdata-table [pxd]="selectedPxD"></app-masterdata-table>
  </ng-container>
</ng-container>
