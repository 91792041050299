import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IGetProjectNameResponseItem } from '@models/project';

import {
  getProjectNamesAction,
  getProjectNamesFailureAction,
  getProjectNamesSuccessAction,
} from './projects-names.actions';

export const FEATURE_KEY = 'project-names';

export interface IProjectsNamesCollectionState
  extends EntityState<IGetProjectNameResponseItem> {
  loaded: boolean;
}

export const projectNamesEntityAdapter =
  createEntityAdapter<IGetProjectNameResponseItem>({
    selectId: ({ id }) => id,
  });

export const initialState = projectNamesEntityAdapter.getInitialState({
  loaded: false,
} as IProjectsNamesCollectionState);

export const reducer = createReducer(
  initialState,
  on(getProjectNamesAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getProjectNamesSuccessAction, (state, { payload }) =>
    projectNamesEntityAdapter.upsertMany(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getProjectNamesFailureAction, (state) => ({
    ...state,
    loaded: true,
  }))
);
