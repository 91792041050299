import { createAction } from '@ngrx/store';

import { GetClientsResponse } from '@app/models/backendModel';
import { contextAwareProps } from '@core/utils/context-aware-props';

export const getClientsAction = createAction(
  '[Collection Clients] GET /clients',
  contextAwareProps()
);

export const getClientsSuccessAction = createAction(
  '[Collection Clients Effect] GET /clients SUCCESS',
  contextAwareProps<{ payload: GetClientsResponse[] }>()
);

export const getClientsFailureAction = createAction(
  '[Collection Clients Effect] GET /clients FAILURE',
  contextAwareProps()
);
