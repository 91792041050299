import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import {
  EMPTY,
  ReplaySubject,
  Subject,
  combineLatest,
  fromEvent,
  merge,
  of,
  timer,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  share,
  shareReplay,
  skipUntil,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';

import { ActivitiesChangeLogService } from '@admin/activities-change-log/activities-change-log.service';
import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';
import { WebSocketService } from '@core/web-socket/web-socket.service';
import { IGetMasterDataLock } from '@models/master-data-lock';

import { LockApiService, LockOverrideAction } from './lock-api.service';
import {
  lockAcquiredAction,
  lockExpiredAction,
  lockRemovedAction,
} from './store/lock.actions';

export enum LockOwnerState {
  OWNER = 'owner',
  LOCKED = 'locked',
  UNLOCKED = 'unlocked',
  EXPIRED = 'expired',
}

export enum SocketEvents {
  LOCK_CREATED = 'LockCreated',
  LOCK_DELETED = 'LockDeleted',
  LOCK_EXPIRED = 'LockExpired',
}

export enum EndLockActions {
  APPLY = 'apply',
  DISCARD = 'discard',
  CONTINUE = 'continue',
}

@Injectable()
export class LockService implements OnDestroy {
  /** in case if socket won't update state we at least should trigger it for current user */
  private triggerLockGet$ = new Subject<void>();

  private destroyed$ = new ReplaySubject<void>(1);

  private getLock$ = this.triggerLockGet$.pipe(
    switchMap(() => this.lockApiService.getLock()),
    shareReplay(1)
  );

  public lock$ = merge(
    this.getLock$,
    this.webSocketService.on$<IGetMasterDataLock>(SocketEvents.LOCK_CREATED),
    this.webSocketService
      .on$<IGetMasterDataLock>(SocketEvents.LOCK_DELETED)
      .pipe(mapTo(null))
  ).pipe(
    debounceTime(500),
    map((lock) =>
      !!lock && lock.isActive ? lock : { isActive: false, ...lock }
    ),
    takeUntil(this.destroyed$),
    shareReplay(1)
  );

  public lockOwnerState$ = combineLatest([
    this.lock$,
    this.store.select(selectCurrentInitializedUser),
  ]).pipe(
    map(([lock, { fullName }]) => {
      if (!lock || !lock.lockedBy) {
        return LockOwnerState.UNLOCKED;
      }

      if (!lock.isActive && !!lock.lockedBy) {
        return LockOwnerState.EXPIRED;
      }

      return lock.lockedBy === fullName
        ? LockOwnerState.OWNER
        : LockOwnerState.LOCKED;
    }),
    takeUntil(this.destroyed$),
    shareReplay(1)
  );

  private updateLockTimeout$ = this.lockOwnerState$.pipe(
    switchMap((lockState) =>
      lockState === LockOwnerState.OWNER
        ? fromEvent(this.document, 'click').pipe(
            throttleTime(10000),
            switchMap(() => this.extendLock()),
            takeUntil(this.lockExpired$),
            takeUntil(this.destroyed$)
          )
        : EMPTY
    ),
    takeUntil(this.destroyed$),
    shareReplay(1)
  );

  public lockExpirationCountdown$ = this.lock$.pipe(
    switchMap((lock) =>
      this.updateLockTimeout$.pipe(startWith(lock.expirationDate))
    ),
    switchMap((expirationDate: string) => {
      if (expirationDate) {
        const expirationTime = new Date(expirationDate).getTime();
        const expireIn = expirationTime - Date.now();

        if (expireIn > 0) {
          return timer(expireIn % 1000, 1000).pipe(
            map(() => Math.floor((expirationTime - Date.now()) / 1000)),
            filter((t) => t >= 0),
            takeUntil(this.destroyed$)
          );
        }
      }

      return EMPTY;
    }),
    takeUntil(this.destroyed$),
    shareReplay(1)
  );

  public lockExpired$ = merge(
    this.lockExpirationCountdown$.pipe(
      map((timeToExpiration) => (timeToExpiration as number) <= 0)
    ),
    this.webSocketService
      .on$<IGetMasterDataLock>(SocketEvents.LOCK_EXPIRED)
      .pipe(mapTo(true))
  ).pipe(
    debounceTime(1000),
    distinctUntilChanged(),
    filter((v) => !!v),
    skipUntil(this.getLock$),
    takeUntil(this.destroyed$),
    share()
    // do not replay as this is used as takeUntil trigger
  );

  constructor(
    private lockApiService: LockApiService,
    private webSocketService: WebSocketService,
    private activitiesChangeLogService: ActivitiesChangeLogService,
    private store: Store,
    private snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.updateLockTimeout$.subscribe();

    this.lockOwnerState$.subscribe((lockState) => {
      switch (lockState) {
        case LockOwnerState.UNLOCKED:
          break;
        case LockOwnerState.EXPIRED:
          this.store.dispatch(
            lockExpiredAction({
              context: 'LockService::lockOwnerState',
            })
          );
          break;
        default:
          this.store.dispatch(
            lockAcquiredAction({
              context: 'LockService::lockOwnerState',
            })
          );
          break;
      }
    });

    this.lockExpired$.subscribe(() => {
      this.triggerLockGet$.next();
      this.showNotification('Edit mode lock has expired');
    });

    this.triggerLockGet$.next();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public showLockModal() {
    this.lockOwnerState$
      .pipe(
        take(1),
        switchMap((lockOwnerState) => {
          if (
            lockOwnerState === LockOwnerState.UNLOCKED ||
            lockOwnerState === LockOwnerState.EXPIRED
          ) {
            return this.activitiesChangeLogService
              .openEnterEditModeDialog()
              .pipe(
                filter((v) => !!v),
                switchMap(() => this.doEditModeModalAction(lockOwnerState))
              );
          }

          return this.doEditModeModalAction(lockOwnerState);
        })
      )
      .subscribe();
  }

  private doEditModeModalAction(lockOwnerState) {
    switch (lockOwnerState) {
      case LockOwnerState.EXPIRED:
        return this.activitiesChangeLogService
          .reviewTakeOverChanges()
          .pipe(
            switchMap(({ action, notifyUsers }) =>
              this.requestLockTakeover(action, notifyUsers)
            )
          );
      case LockOwnerState.UNLOCKED:
        return this.requestLock();
      case LockOwnerState.OWNER:
        return this.activitiesChangeLogService
          .reviewOwnChanges()
          .pipe(
            switchMap(({ action, notifyUsers }) =>
              action === EndLockActions.APPLY
                ? this.commitChanges(notifyUsers)
                : this.discardChanges()
            )
          );
    }
  }

  private requestLock() {
    return this.lockApiService.takeLock().pipe(
      tap(() => this.showNotification('Edit mode was activated')),
      catchError(() => {
        this.showNotification('Taking over lock failed...', 5000);

        return of(null);
      }),
      tap(() => this.triggerLockGet$.next())
    );
  }

  private extendLock() {
    return this.lockApiService.extendLock().pipe(
      catchError(() => {
        this.showNotification(`Lock doesn't exist`);
        this.store.dispatch(
          lockRemovedAction({
            context: 'LockService::extendLock',
          })
        );

        return EMPTY;
      })
    );
  }

  private requestLockTakeover(
    overrideAction: LockOverrideAction,
    notifyUsers: boolean
  ) {
    return this.lockApiService
      .takeLock({
        overrideAction:
          overrideAction === LockOverrideAction.APPLY
            ? LockOverrideAction.TAKE
            : overrideAction,
      })
      .pipe(
        switchMap((lock) => {
          if (overrideAction === LockOverrideAction.APPLY) {
            return this.commitChanges(notifyUsers);
          }
          this.triggerLockGet$.next();
          this.showNotification('Edit mode was activated');
          this.store.dispatch(
            lockAcquiredAction({
              context: 'LockService::requestLockTakeover',
            })
          );

          return of(lock);
        })
      );
  }

  private discardChanges() {
    const notification = this.showNotification('Discarding changes');
    return this.lockApiService.discardChanges().pipe(
      tap(() => {
        notification.dismiss();
        this.showNotification('Changes were discarded');
        this.store.dispatch(
          lockRemovedAction({
            context: 'LockService::discardChanges',
          })
        );
      }),
      catchError(() => {
        notification.dismiss();
        this.showNotification('Discarding changes. Failed...', 5000);

        return EMPTY;
      })
    );
  }

  private commitChanges(notifyUsers: boolean) {
    const notification = this.showNotification(
      'Applying changes. Can take few minutes...',
      null
    );
    return this.lockApiService.commitChanges(notifyUsers).pipe(
      tap(() => {
        notification.dismiss();
        this.showNotification('Changes applied');
        this.store.dispatch(
          lockRemovedAction({
            context: 'LockService::commitChanges',
          })
        );
      })
    );
  }

  private showNotification(message: string, duration = 2000) {
    return this.snackBar.open(message, null, {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}
