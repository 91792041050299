import { createReducer, on } from '@ngrx/store';

import { deepClone } from '@common/utils';
import { IScenarioGeneralInfo } from '@models/project';

import { UserCtrRole } from '@app/common/enums';
import { CtrActions } from '@app/scenario/ctr/store/ctr.actions';
import {
  emptyNavbarAction,
  getSidenavProjectDetailsAction,
  getSidenavProjectDetailsActionSuccess,
  prepareNewProjectNavbarAction,
} from './sidenav.actions';

export const FEATURE_KEY = 'sidenav';

export interface ISidenavState {
  // view data
  projectId?: number;
  projectName?: string;
  projectStatus?: string;
  scenarios?: IScenarioGeneralInfo[];
  // view configuration
  showDocuments?: boolean;
  loading: boolean;
  userIsViewer: boolean;
}

export const initialState: ISidenavState = {
  loading: false,
  userIsViewer: true,
};

export const reducer = createReducer(
  initialState,
  on(getSidenavProjectDetailsAction, (state) => ({
    ...state,
    loading: true,
  })),
  on(getSidenavProjectDetailsActionSuccess, (state, { payload }) => ({
    ...state,
    ...sortData(deepClone(payload)),
    showDocuments: true,
    loading: false,
  })),
  on(prepareNewProjectNavbarAction, () => ({
    projectName: 'New project',
    showDocuments: false,
    loading: false,
    userIsViewer: true,
  })),
  on(emptyNavbarAction, () => ({
    loading: false,
    userIsViewer: true,
  })),
  on(CtrActions.setUserRole, (state, { role }) => ({
    ...state,
    userIsViewer: role === UserCtrRole.VIEWER,
  }))
);

function sortData(payload: ISidenavState): any {
  return {
    ...payload,
    scenarios: sortScenarios(payload.scenarios || []).map((scenario) => ({
      ...scenario,
      sctrs: sortCtrs(scenario.sctrs),
    })),
  };
}

function sortScenarios(collection: any[]): any[] {
  if (collection) {
    return collection.sort(({ scenarioNo: a }, { scenarioNo: b }) => a - b);
  }
  return collection;
}

function sortCtrs(collection: any[]): any[] {
  if (collection) {
    const fieldName = 'ctrShortName';
    return [...collection].sort((a, b) => {
      if (a[fieldName] < b[fieldName]) {
        return -1;
      }
      if (a[fieldName] > b[fieldName]) {
        return 1;
      }
      return 0;
    });
  }
  return collection;
}
