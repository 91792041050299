import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { updateCurrentlyViewedProjectAction } from '@collections/projects/store/projects.actions';
import { IGetScenarioResponse } from '@models/scenario';

import {
  getScenarioAction,
  getScenarioFailureAction,
  getScenarioSuccessAction,
} from './scenarios.actions';

export const FEATURE_KEY = 'scenarios';

export interface IScenariosCollectionState
  extends EntityState<IGetScenarioResponse> {
  loaded: boolean;
}

export const scenariosEntityAdapter = createEntityAdapter<IGetScenarioResponse>(
  {
    selectId: ({ id }) => id,
  }
);

export const initialState = scenariosEntityAdapter.getInitialState({
  loaded: false,
} as IScenariosCollectionState);

export const reducer = createReducer(
  initialState,
  on(updateCurrentlyViewedProjectAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getScenarioAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getScenarioSuccessAction, (state, { payload }) =>
    scenariosEntityAdapter.upsertOne(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getScenarioFailureAction, (state) => ({
    ...state,
    loaded: true,
  }))
);
