import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import {
  getEquipAssignsAction,
  getEquipAssignsActionSuccess,
} from '@collections/equip-assigns/store/equip-assigns.actions';
import { LoadingStateEnum } from '@collections/state-commons';
import { EquipAssigns } from '@models/equip-assigns';

export const FEATURE_KEY = 'equip_assigns';

export interface EquipAssignsCollectionState extends EntityState<EquipAssigns> {
  loaded: boolean;
  searching: boolean;
  equipAssignsLoadingState: { [equipmentId: number]: LoadingStateEnum };
}

/**
 * Equip assigns store is populated by get equip assigns request.
 * @see EquipAssigns
 */

export const equipAssignsEntityAdapter = createEntityAdapter<EquipAssigns>();

export const initialState = equipAssignsEntityAdapter.getInitialState({
  searching: false,
  loaded: false,
  equipAssignsLoadingState: {},
} as EquipAssignsCollectionState);

export const reducer = createReducer(
  initialState,

  // handle GET equip assigns

  on(getEquipAssignsAction, (state, { payload }) => ({
    ...state,
  })),
  on(getEquipAssignsActionSuccess, (state, { payload }) =>
    equipAssignsEntityAdapter.upsertOne(payload, {
      ...state,
    })
  )
);
