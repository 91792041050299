<div class="kpi-wrapper">
  <h2 class="content-head">KPI</h2>
  <p class="top__margin">
    Choose dates and download a basic Key Performance Indicators report of eCTR
    application.
  </p>
  <div class="segment-selection">
    <span class="label"> Segment: </span>
    <mat-checkbox
      [(ngModel)]="segmentsSelection.surf"
      data-test="surf-segment-checkbox"
    >
      SURF
    </mat-checkbox>
    <mat-checkbox
      [(ngModel)]="segmentsSelection.rems"
      data-test="rems-segment-checkbox"
    >
      REMS
    </mat-checkbox>
  </div>
  <div class="div__flex" [formGroup]="registerForm">
    <div>
      <mat-form-field
        class="inputroot__child right__margin"
        appearance="outline"
      >
        <mat-label class="form-required">From</mat-label>
        <input
          matInput
          [matDatepicker]="ctrpicker"
          autocomplete="off"
          placeholder="YYYY-MM-DD"
          formControlName="fromDate"
          [max]="maxDateVal"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="ctrpicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #ctrpicker></mat-datepicker>
        <mat-error *ngIf="registerForm.controls.fromDate.errors?.required"
          >From date is required
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="inputroot__child" appearance="outline">
        <mat-label class="form-required">To</mat-label>
        <input
          matInput
          [matDatepicker]="ctrpicker1"
          autocomplete="off"
          placeholder="YYYY-MM-DD"
          formControlName="toDate"
          [max]="maxDateVal"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="ctrpicker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #ctrpicker1></mat-datepicker>
        <mat-error *ngIf="registerForm.controls.toDate.errors?.required"
          >To date is required
        </mat-error>
      </mat-form-field>
    </div>
    <mat-error *ngIf="registerForm.errors?.dateError">
      'From' date should be before 'To' date.
    </mat-error>
  </div>
  <div *ngIf="showError" class="error">
    <p>
      No data exists for the selected date range. Please choose a different date
      range.
    </p>
  </div>
  <div class="button__download">
    <button
      (click)="onDownload()"
      [disabled]="checkIfKpiReportDownloadDisabled()"
      class="downloadManual"
      color="primary"
      id="downloadkpi"
      mat-button
    >
      Download
    </button>
  </div>
</div>
<activities-export></activities-export>
<app-add-export></app-add-export>
