<section>
  <h1>User Manual</h1>
  <p>
    The User Manual contents and detailed explanations of the modules are
    available on the
    <a
      href="https://digicorner.sharepoint.com/sites/TheWell/SitePages/Welcome-to-Subsea-eCTR-Wiki.aspx"
      target="_blank"
      rel="noopener"
      ><strong>eCTR Wiki</strong></a
    >
    page on <strong>The Well</strong>.
  </p>

  <p>
    To access this information, click on the
    <a
      href="https://digicorner.sharepoint.com/sites/TheWell/SitePages/eCTR-User-manual.aspx"
      target="_blank"
      rel="noopener"
      >"User Manual"</a
    >
    tile on the eCTR page.
  </p>

  <p>
    You can also find a lot of useful information about this application there.
  </p>

  <p>
    If you prefer, you can download the PDF version of the User Manual here:
    <a
      href="https://digicorner.sharepoint.com/:b:/r/sites/eCTRProduction-SupportPlatform/Shared%20Documents/General/User%20support/eCTR_User%20Manual.pdf"
      target="_blank"
      rel="noopener"
      >"eCTR User Manual (PDF)".</a
    >
  </p>

  <a
    href="https://digicorner.sharepoint.com/sites/TheWell/SitePages/Welcome-to-Subsea-eCTR-Wiki.aspx"
    target="_blank"
    rel="noopener"
  >
    <img
      src="..\..\assets\manualImages\eCTR-wiki.png"
      width="600px"
      alt="eCTR Wiki page"
  /></a>

  <h1 style="margin-top: 60px">Video Tutorials</h1>

  <p>
    Our video tutorials are designed to help you learn how to perform specific
    tasks or understand key concepts within the application.
  </p>
  <p>
    We have created a series of videos, including a full introduction to the
    eCTR application and short 2-3 minute videos that cover specific workflows.
  </p>
  <p>
    To view our video content, simply click on the
    <a
      href="https://digicorner.sharepoint.com/sites/TheWell/SitePages/eCTR-Tutorials.aspx"
      target="_blank"
      rel="noopener"
      >"Video Tutorials"</a
    >
    tile on the eCTR Wiki page on The Well.
  </p>
</section>
