import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IGetProjectStagesResponseItem } from '@models/project-stages';

import {
  getProjectStagesAction,
  getProjectStagesFailureAction,
  getProjectStagesSuccessAction,
} from './project-stages.actions';

export const FEATURE_KEY = 'project-stages';

export interface IProjectStagesCollectionState
  extends EntityState<IGetProjectStagesResponseItem> {
  loaded: boolean;
}

export const projectStagesEntityAdapter =
  createEntityAdapter<IGetProjectStagesResponseItem>({
    selectId: ({ projectStageId }) => projectStageId,
  });

export const initialState = projectStagesEntityAdapter.getInitialState({
  loaded: false,
} as IProjectStagesCollectionState);

export const reducer = createReducer(
  initialState,
  on(getProjectStagesAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getProjectStagesSuccessAction, (state, { payload }) =>
    projectStagesEntityAdapter.upsertMany(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getProjectStagesFailureAction, (state) => ({
    ...state,
    loaded: true,
  }))
);
