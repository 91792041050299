import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import type { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { IPxD } from '@models/pxd';
import { AdminUserPxdCell } from '@models/userDetails';

@Component({
  selector: 'app-admin-user-pxd-grid-cell',
  templateUrl: './admin-user-pxd-grid-cell.component.html',
  styleUrls: ['./admin-user-pxd-grid-cell.component.scss'],
})
export class AdminUserPxdGridCellComponent implements OnChanges {
  @Input() public pxd: IPxD = null;

  @Input() public assignedPxDs: AdminUserPxdCell[];

  @Input() public deselectionDisabled = false;

  @Input() public enforcePrincipalSelection = false;

  @Input() public enablePrincipalSelection = true;

  @Input() public principalDeselectionDisabled = false;

  @Input() public isPrincipal = false;

  @Output() public assignPxDs = new EventEmitter<{
    pxdId: number;
    isPrincipal: boolean;
  }>();

  @Output() public unassignPxDs = new EventEmitter<number>();

  public poOk = false;

  public principalMatTooltipMessage: string;

  public ngOnChanges() {
    if (this.pxd) {
      this.poOk = !this.pxd.hasMasterData && this.pxd.hasPrincipals;
    }

    this.principalMatTooltipMessage = this.enforcePrincipalSelection
      ? 'User needs to be principal if assigned to not his own entity'
      : 'You need to assign another principal -> this one will be deselected automatically';
  }

  public togglePrincipalRole(matSlideToggleChange: MatSlideToggleChange) {
    this.assignPxDs.emit({
      pxdId: this.pxd.id,
      isPrincipal: matSlideToggleChange.checked,
    });
  }

  public updatePxDAssignmentSelection() {
    if (this.assignedPxDs?.length > 0) {
      this.unassignPxDs.emit(this.pxd.id);
    } else {
      this.assignPxDs.emit({
        pxdId: this.pxd.id,
        isPrincipal: false,
      });
    }
  }
}
