import { ElementRef, Injectable } from '@angular/core';

export interface TableCell {
  rowIndex: number;
  columnIndex: number;
}

@Injectable({ providedIn: 'root' })
export class PxDTableHelperService {
  private cellSelector: string = null;

  private className: string = null;

  private pxdTableElement: ElementRef;

  public registerPxDTable(
    element: ElementRef,
    cellSelector = '',
    className = 'border'
  ) {
    this.pxdTableElement = element;
    this.cellSelector = cellSelector;
    this.className = className;
  }

  public markCells(
    cell: Partial<TableCell>,
    cellSelection = false
  ): void {
    this.handleTableClass('add', cell, cellSelection);
  }

  public unmarkCells(
    cell: Partial<TableCell>,
    cellSelection = false
  ): void {
    this.handleTableClass('remove', cell, cellSelection);
  }

  private handleTableClass(
    method: string,
    cell: Partial<TableCell>,
    cellSelection = false
  ) {
    if (!cell || cell.columnIndex === 0) {
      return;
    }

    const { hoveredCell, columnCells, rowCells } = this.getCellsToMark(
      cell.rowIndex,
      cell.columnIndex
    );

    hoveredCell?.classList[method]('hovered-cell');

    columnCells.forEach((cellElement) => {
      cellElement.classList[method](
        `${cellSelection ? 'selection-' : ''}${this.className}-column`
      );
    });

    rowCells.forEach((cellElement) => {
      cellElement.classList[method](
        `${cellSelection ? 'selection-' : ''}${this.className}-row`
      );
    });
  }

  private getCellsToMark(
    hoveredRowIndex: number,
    hoveredColumnIndex: number
  ): { hoveredCell; rowCells; columnCells } {
    if (!this.pxdTableElement) {
      return {
        rowCells: [],
        columnCells: [],
        hoveredCell: null,
      };
    }

    const columnCells = [
      ...this.pxdTableElement.nativeElement.querySelectorAll(
        `tr > td:nth-child(${hoveredColumnIndex + 1}) ${this.cellSelector},
        tr > th:nth-child(${hoveredColumnIndex + 1}) ${this.cellSelector}`
      ),
    ].slice(0, hoveredRowIndex + 1);

    const rowCells = [
      ...this.pxdTableElement.nativeElement.querySelectorAll(
        `tbody tr:nth-child(${hoveredRowIndex}) > td ${this.cellSelector},
        tbody tr:nth-child(${hoveredRowIndex}) > th ${this.cellSelector}`
      ),
    ].slice(0, hoveredColumnIndex + 1);

    return {
      rowCells,
      columnCells,
      hoveredCell: columnCells[hoveredRowIndex + 1],
    };
  }
}
