import { NgModule } from '@angular/core';

import { UserAvatarModule } from '@app/user-avatar/user-avatar.module';
import { SharedModule } from '@shared/shared.module';

import { HeaderComponent } from './header.component';

const components = [HeaderComponent];

@NgModule({
  declarations: components,
  imports: [SharedModule, UserAvatarModule],
  exports: components,
})
export class HeaderModule {}
