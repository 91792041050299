import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';

export interface ChangeLogDialogData<A = any> {
  title: string;
  message?: string;
  readonly: boolean;
  notifyUsers?: boolean;
  changesUserName: string;
  changesUserEmail: string;
  changesDate: Date;
  changesList: any[];
  showNotifyUsersCheckbox?: boolean;
  buttons: { label: string; action: A; class?: string }[];
}

export interface ChangeLogDialogResult<A = any> {
  action: A;
  notifyUsers?: boolean;
}

@Component({
  selector: 'app-change-log-dialog',
  templateUrl: './change-log-dialog.component.html',
  styleUrls: ['./change-log-dialog.component.scss'],
})
export class ChangeLogDialogComponent {
  public notifyAboutChangesFormControl = new UntypedFormControl(this.data.notifyUsers);

  public changesDate = this.data.changesDate;

  public changesList = this.data.changesList;

  public readonly = this.data.readonly;

  private currentUser$ = this.store.select(selectCurrentInitializedUser);

  public changesOwner$ = this.currentUser$.pipe(
    map(({ fullName }) =>
      fullName !== this.data.changesUserName
        ? {
            userName: this.data.changesUserName,
            email: this.data.changesUserEmail,
          }
        : { userName: 'You' }
    )
  );

  public buttons = this.data.buttons;

  public showNotifyUsersCheckbox = this.data.showNotifyUsersCheckbox;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangeLogDialogData,
    private matDialogRef: MatDialogRef<ChangeLogDialogComponent>,
    private store: Store
  ) {}

  public close() {
    this.matDialogRef.close();
  }
}
