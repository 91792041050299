import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { selectAdminActivitiesByScopeIdFactory } from '@admin/admin-activities/store/admin-activities.selectors';

@Injectable()
export class ActivityDisplayNameValidator implements AsyncValidator {
  private scopeId$ = new ReplaySubject<number>(1);

  private activityId$ = new ReplaySubject<number>(1);

  constructor(private store: Store) {}

  public validate(
    control: AbstractControl
  ): Promise<ValidationErrors> | Observable<ValidationErrors> {
    const requestedName = control.value;

    return this.scopeId$.pipe(
      switchMap((scopeId) =>
        this.store.select(selectAdminActivitiesByScopeIdFactory(scopeId))
      ),
      withLatestFrom(this.activityId$),
      map(([blocks, activityOwnId]) =>
        blocks
          .filter(({ id }) => id !== activityOwnId)
          .map(({ activityDisplayName }) => activityDisplayName)
      ),
      map((activitiesNames) =>
        activitiesNames
          .map((a) => a.trim().toLowerCase())
          .includes(requestedName.trim().toLowerCase())
      ),
      map((duplicate) => (duplicate ? { duplicateName: true } : null)),
      take(1)
    );
  }

  public setScopeId(value: number) {
    this.scopeId$.next(value);
  }

  public setActivityId(value: number) {
    this.activityId$.next(value);
  }
}
