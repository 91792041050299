import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import {
  IGetCurrenciesResponseItem,
  IGetProjectsCurrenciesResponseItem,
} from '@models/currencies';

import { MediatorService } from '@app/mediator.service';
import {
  ProjectCurrencyForUpdate,
  UpdateProjectCurrenciesCommand,
} from '@app/models/backendModel';
import { Observable } from 'rxjs';
import { updateCurrentlyViewedProjectAction } from '../projects/store/projects.actions';

@Injectable({ providedIn: 'root' })
export class CurrenciesApiService {
  constructor(
    private httpClient: HttpClient,
    private mediator: MediatorService,
    private store: Store
  ) {}

  public getCurrencies() {
    return this.httpClient.get<IGetCurrenciesResponseItem[]>(
      '{apiUrl}admin/currency'
    );
  }

  public getProjectCurrencies(projectId: number) {
    return this.httpClient.get<IGetProjectsCurrenciesResponseItem[]>(
      `{apiUrl}projects/${projectId}/currencies`
    );
  }

  public getDefaultProjectCurrencies() {
    return this.httpClient.get<IGetProjectsCurrenciesResponseItem[]>(
      `{apiUrl}projects/currencies/default`
    );
  }

  public updateProjectCurrencies(
    projectCurrencies: ProjectCurrencyForUpdate[],
    projectId: number
  ): Observable<void> {
    return this.mediator
      .send<UpdateProjectCurrenciesCommand>('UpdateProjectCurrenciesCommand', {
        projectId,
        projectCurrencies,
      })
      .pipe(
        // TODO: convert to effect
        tap(() =>
          this.store.dispatch(
            updateCurrentlyViewedProjectAction({
              context: 'CurrenciesApiService:updateProjectCurrencies',
            })
          )
        )
      );
  }
}
