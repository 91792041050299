import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { Application } from '@models/application';

export const getApplicationsAction = createAction(
  '[Collection Applications] GET /applications',
  contextAwareProps()
);

export const getApplicationsSuccessAction = createAction(
  '[Collection Applications Effect] GET /applications SUCCESS',
  contextAwareProps<{ payload: Application[] }>()
);

export const getApplicationsFailureAction = createAction(
  '[Collection Applications Effect] GET /applications FAILURE',
  contextAwareProps()
);
