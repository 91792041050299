<h1 mat-dialog-title>{{ actionName }} product</h1>

<div [formGroup]="formGroup" class="container" mat-dialog-content>
  <mat-slide-toggle class="active-toggle" formControlName="isActive"
    ><span class="toggle-text">Active</span></mat-slide-toggle
  >
  <div class="row">
    <mat-form-field appearance="outline" class="field col-12">
      <mat-label>Long name</mat-label>
      <input
        appCharacterCounter
        formControlName="name"
        matInput
        maxlength="50"
      />
      <mat-error *ngIf="formGroup.controls.name.hasError('duplicateName')">
        This name is already used. Provide a different one.
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="outline" class="field col-12">
      <mat-label>Short name</mat-label>
      <input
        appCharacterCounter
        formControlName="shortName"
        matInput
        maxlength="20"
      />
      <mat-error *ngIf="formGroup.controls.shortName.hasError('duplicateName')">
        This short name is already used. Provide a different one.
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="outline" class="col-4 wbs-code-field">
      <mat-label>WBS Code</mat-label>
      <input
        formControlName="code"
        matInput
        maxlength="3"
        numbersInputGuardDirective
      />
    </mat-form-field>
    <div *ngIf="data.product.id" class="wbs-warning">
      Warning: Changing WBS number will affect all existing CTRs. It may break
      connection to actual hours reported in SAP, that are connected to the old
      WBS number.
    </div>
  </div>
  <div
    matTooltip="There is at least one CTR that has been already started for one of this product's PxDs"
    [matTooltipDisabled]="data.product.deletable"
    [matTooltipPosition]="'above'"
    *ngIf="data.product.id"
  >
    <button
      mat-button
      appearance="flat"
      [disabled]="!data.product.deletable"
      [mat-dialog-close]="{ deleted: true }"
      class="delete-button"
    >
      Delete product
    </button>
  </div>
</div>
<div mat-dialog-actions [align]="'end'">
  <button [mat-dialog-close]="false" color="primary" mat-button>Cancel</button>
  <button
    [disabled]="!formGroup.valid || !(valueChanged$ | async)"
    [mat-dialog-close]="formGroup.getRawValue()"
    color="primary"
    mat-button
  >
    Save
  </button>
</div>
