<ng-container *ngIf="!showFilters">
  <div class="header">
    <h2 class="title">Activities <button
        mat-icon-button
        color="primary"
        (click)="showFilters = true"
      ><mat-icon>filter_list</mat-icon></button></h2>
    <app-breadcrumbs
      *ngIf="breadcrumb$ | async as breadcrumb"
      [projectId]="breadcrumb.projectId"
      [scenarioId]="breadcrumb.scenarioId"
      [ctrId]="breadcrumb.ctrId"
    ></app-breadcrumbs>
    <a
      *ngIf="hasFilters$ | async"
      (click)="setFilter({})"
    >clear filters</a>
  </div>

  <div class="messages">
    <ng-container *ngFor="let message of messages$ | async; let index = index">
      <app-message
        (reply)="replyTo = index"
        [author]="message.author"
        [avatar]="message.avatar"
        [class.reply]="message.reply"
        [date]="message.date"
        [message]="message.message"
        [signature]="message.signature"
        [type]="message.type"
      ></app-message>
      <app-reply-comment
        *ngIf="index === replyTo"
        (onCloseReply)="replyTo = null"
        [avatar]="avatar$ | async"
        [parentId]="message.id"
        [signature]="signature$ | async"
        [to]="message.author"
        author="You"
        class="reply"
      ></app-reply-comment>
    </ng-container>
  </div>
  <app-comment
    class="add-comment"
    *ngIf="breadcrumb$ | async as breadcrumb"
    [projectId]="breadcrumb.projectId"
    [scenarioId]="breadcrumb.scenarioId"
    [ctrId]="breadcrumb.ctrId"
  ></app-comment>
</ng-container>

<ng-container *ngIf="showFilters">
  <div class="header">
    <button
      class="small"
      mat-button
      color="primary"
      (click)="showFilters = false"
    ><mat-icon>arrow_back</mat-icon>back</button>

    <app-filter
      [filter]="filter$ | async"
      (updateFilter)="setFilter($event)"
    ></app-filter>
  </div>
</ng-container>
