import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { CtrDeliverableBlock } from '@models/ctr-deliverable-block';
import { getDeliverablesSuccessAction } from '@collections/deliverables/store/deliverables.actions';

export const FEATURE_KEY = 'ctr-deliverables';

export type IDeliverablesCollectionState = EntityState<{
    ctrId: number;
    ctrDeliverableBlocks: CtrDeliverableBlock[];
  }>

export const deliverablesEntityAdapter = createEntityAdapter<{
  ctrId: number;
  ctrDeliverableBlocks: CtrDeliverableBlock[];
}>({
  selectId: ({ ctrId }) => ctrId,
});

export const initialState = deliverablesEntityAdapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(getDeliverablesSuccessAction, (state, { payload }) =>
    deliverablesEntityAdapter.upsertOne(payload, state)
  )
);
