import {
  CtrFteDistributionForRoles,
  CtrHoursDistributionForRoles,
} from '@app/models/backendModel';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import {
  IRolesCalendarizationState,
  ROLES_FEATURE_KEY,
} from './roles-calendarization.reducer';

export const selectCtrCalendarizationState =
  createFeatureSelector<Record<number, IRolesCalendarizationState>>(
    ROLES_FEATURE_KEY
  );

export const selectCtrCalendarizationData = (
  ctrId: number
): MemoizedSelector<
  object,
  IRolesCalendarizationState,
  (s1: Record<number, IRolesCalendarizationState>) => IRolesCalendarizationState
> => createSelector(selectCtrCalendarizationState, (state) => state[ctrId]);

export const selectCtrHoursDistributionForRoles = (
  ctrId: number
): MemoizedSelector<
  object,
  CtrHoursDistributionForRoles[],
  (
    s1: Record<number, IRolesCalendarizationState>
  ) => CtrHoursDistributionForRoles[]
> =>
  createSelector(
    selectCtrCalendarizationState,
    (state) => state[ctrId]?.ctrHoursDistributionForRoles ?? []
  );

export const selectCtrFteDistributionForRoles = (
  ctrId: number
): MemoizedSelector<
  object,
  CtrFteDistributionForRoles[],
  (
    s1: Record<number, IRolesCalendarizationState>
  ) => CtrFteDistributionForRoles[]
> =>
  createSelector(
    selectCtrCalendarizationState,
    (state) => state[ctrId]?.ctrFteDistributionForRoles ?? []
  );

export const selectCtrStartAndEndDates = (
  ctrId: number
): MemoizedSelector<
  object,
  {
    startDate: Date;
    endDate: Date;
  },
  (s1: Record<number, IRolesCalendarizationState>) => {
    startDate: Date;
    endDate: Date;
  }
> =>
  createSelector(selectCtrCalendarizationState, (state) => ({
    startDate: state[ctrId].startDate,
    endDate: state[ctrId].endDate,
  }));

export const selectCurrentUserIsOwner = (
  ctrId: number
): MemoizedSelector<
  object,
  boolean,
  (s1: Record<number, IRolesCalendarizationState>) => boolean
> =>
  createSelector(
    selectCtrCalendarizationState,
    (state) => state[ctrId].currentUserIsOwner
  );
