<div
  *ngIf="scenario"
  class="block-wrapper"
>
  <ng-container *ngIf="ctrsByType">
    <ng-container *ngFor="let ctr of ctrsByType">
      <ng-container *ngIf="ctr && isCompleted(ctr.ctrStatus)">
        <div class="block-wrapper">
          <app-scenario-comparison-cell
            data-test="scenario-comparison-status-icon-link"
            [ctrStatus]="ctr.ctrStatus"
            [link]="['/viewscenario', projectId, scenario.scenarioId]"
            [header]="true"
            [firstColumn]="firstColumn"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            data-test="scenario-comparison-total-hours-value"
            [content]="ctr.ctrTotHrs"
            [alignRight]="true"
            [firstColumn]="firstColumn"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            data-test="scenario-comparison-total-cost-value"
            [content]="ctr.ctrTotalCost"
            [alignRight]="true"
            [firstColumn]="firstColumn"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            data-test="scenario-comparison-gec-utilization-value"
            [content]="ctr.totalGECUtilization"
            [alignRight]="true"
            [firstColumn]="firstColumn"
            [percentValue]="true"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            data-test="scenario-comparison-requested-date-value"
            [content]="ctr.requestedOn | date: 'dd LLL yyyy'"
            [firstColumn]="firstColumn"
          >
          </app-scenario-comparison-cell>
        </div>
      </ng-container>
      <ng-container *ngIf="ctr && !isCompleted(ctr.ctrStatus)">
        <div class="block-wrapper">
          <app-scenario-comparison-cell
            [ctrStatus]="ctr.ctrStatus"
            [link]="['/viewscenario', projectId, scenario.scenarioId]"
            [header]="true"
            [firstColumn]="firstColumn"
            data-test="scenario-comparison-status-icon-link"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            *ngFor='let n of [0,1,2];let i = index;'
            [content]="ctr.ctrStatus"
            [firstColumn]="firstColumn"
            data-test="scenario-comparison-totals-in-progress-values"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            [content]="ctr.requestedOn | date: 'dd LLL yyyy'"
            [firstColumn]="firstColumn"
            data-test="scenario-comparison-requested-date-value"
          >
          </app-scenario-comparison-cell>
        </div>
      </ng-container>
      <ng-container *ngIf="!ctr">
        <div class="block-wrapper">
          <app-scenario-comparison-cell
            [firstColumn]="firstColumn"
            [header]="true"
          >
          </app-scenario-comparison-cell>
          <app-scenario-comparison-cell
            *ngFor='let n of [0,1,2,3];let i = index'
            [firstColumn]="firstColumn"
          >
          </app-scenario-comparison-cell>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>