import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';

import { ISidenavState } from './sidenav.reducer';

export const getSidenavProjectDetailsAction = createAction(
  '[Sidenav] GET /scenarioCTRs/:projectId/navbarDetails',
  contextAwareProps<{ payload: { projectId: number } }>()
);

export const getSidenavProjectDetailsActionSuccess = createAction(
  '[Sidenav Effect] GET /scenarioCTRs/:projectId/navbarDetails SUCCESS',
  contextAwareProps<{ payload: ISidenavState }>()
);

export const getSidenavProjectDetailsActionFailure = createAction(
  '[Sidenav Effect] GET /scenarioCTRs/:projectId/navbarDetails FAILURE',
  contextAwareProps()
);

export const prepareNewProjectNavbarAction = createAction(
  '[Sidenav Effect] Setup new project sidebar configuration',
  contextAwareProps()
);

export const emptyNavbarAction = createAction(
  '[Sidenav Effect] Setup empty sidebar configuration',
  contextAwareProps()
);
