import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';

import { IMdmProjectResponse } from '..//mdm-api.service';

export const getMdmProjectInfoAction = createAction(
  '[Collection Mdm] GET projects//mdm/:mdmNumber',
  contextAwareProps<{ payload: { mdmNumber: string } }>()
);

export const getMdmProjectInfoSuccessAction = createAction(
  '[Collection Mdm Effect] GET /projects/mdm/:mdmNumber SUCCESS',
  contextAwareProps<{ payload: IMdmProjectResponse }>()
);

export const getMdmProjectInfoFailureAction = createAction(
  '[Collection Mdm Effect] GET /projects/mdm/:mdmNumber FAILURE',
  contextAwareProps()
);
