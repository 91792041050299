import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UsersEffects } from './store/users.effects';
import { FEATURE_KEY, reducer } from './store/users.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([UsersEffects]),
  ],
})
export class UsersCollectionModule {}
