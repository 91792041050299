import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AssumptionsAndExclusionsEffects } from '@collections/assumptions-and-exclusions/store/assumptions-and-exclusions.effects';
import {
  FEATURE_KEY,
  reducer,
} from '@collections/assumptions-and-exclusions/store/assumptions-and-exclusions.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([AssumptionsAndExclusionsEffects]),
  ],
})
export class AssumptionsAndExclusionsCollectionModule {}
