import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Discipline } from '@models/discipline';
import { Product } from '@models/product';
import { IPxD, IPxDDetailed } from '@models/pxd';

import {
  createDisciplineSuccessAction,
  createProductSuccessAction,
  deleteDisciplineSuccessAction,
  deleteProductSuccessAction,
  editDisciplineSuccessAction,
  editProductSuccessAction,
  editPxDSuccessAction,
  getDetailedPxDSuccessAction,
  getMinifiedPxDsAction,
  getMinifiedPxDsFailureAction,
  getMinifiedPxDsSuccessAction,
  getProductsAndDisciplinesSuccessAction,
  getPxDsPrincipalsAction,
  getPxDsPrincipalsFailureAction,
  getPxDsPrincipalsSuccessAction,
  updatePxDsOrderSuccessAction,
} from './pxds.actions';

export const FEATURE_KEY = 'pxds';

export interface IPxDsCollectionState {
  pxds: EntityState<IPxD>;
  pxdsDetailed: EntityState<IPxDDetailed>;
  products: EntityState<Product>;
  disciplines: EntityState<Discipline>;
  loaded: boolean;
}

export const pxdsEntityAdapter = createEntityAdapter<IPxD>({
  selectId: ({ id }) => id,
});

export const pxdsDetailedEntityAdapter = createEntityAdapter<IPxDDetailed>({
  selectId: ({ id }) => id,
});

export const productsEntityAdapter = createEntityAdapter<Product>({
  selectId: ({ businessSegmentId, id }) => `${businessSegmentId}-${id}`,
});

export const disciplinesEntityAdapter = createEntityAdapter<Discipline>({
  selectId: ({ businessSegmentId, id }) => `${businessSegmentId}-${id}`,
});

export const initialState = {
  pxds: pxdsEntityAdapter.getInitialState({}),
  pxdsDetailed: pxdsDetailedEntityAdapter.getInitialState({}),
  products: productsEntityAdapter.getInitialState({}),
  disciplines: disciplinesEntityAdapter.getInitialState({}),
  loaded: false,
} as IPxDsCollectionState;

export const reducer = createReducer(
  initialState,
  on(getPxDsPrincipalsAction, getMinifiedPxDsAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(
    getPxDsPrincipalsSuccessAction,
    getMinifiedPxDsSuccessAction,
    (state, { payload }) => ({
      ...state,
      pxds: pxdsEntityAdapter.upsertMany(payload as IPxD[], {
        ...state.pxds,
      }),
      loaded: true,
    })
  ),
  on(getPxDsPrincipalsFailureAction, getMinifiedPxDsFailureAction, (state) => ({
    ...state,
    loaded: true,
  })),
  on(getProductsAndDisciplinesSuccessAction, (state, { payload }) => ({
    ...state,
    products: productsEntityAdapter.upsertMany(payload.products, {
      ...state.products,
    }),
    disciplines: disciplinesEntityAdapter.upsertMany(payload.disciplines, {
      ...state.disciplines,
    }),
    loaded: true,
  })),
  on(
    updatePxDsOrderSuccessAction,
    (state, { payload: { businessSegmentId, products, disciplines } }) => ({
      ...state,
      products: productsEntityAdapter.upsertMany(
        products.map(({ id, order }) => ({
          ...state.products.entities[`${businessSegmentId}-${id}`],
          businessSegmentId,
          id,
          order,
        })),
        {
          ...state.products,
        }
      ),
      disciplines: disciplinesEntityAdapter.upsertMany(
        disciplines.map(({ id, order }) => ({
          ...state.disciplines.entities[`${businessSegmentId}-${id}`],
          businessSegmentId,
          id,
          order,
        })),
        {
          ...state.disciplines,
        }
      ),
    })
  ),
  on(
    createProductSuccessAction,
    editProductSuccessAction,
    (state, { payload }) => ({
      ...state,
      products: productsEntityAdapter.upsertOne(payload, {
        ...state.products,
      }),
    })
  ),
  on(
    createDisciplineSuccessAction,
    editDisciplineSuccessAction,
    (state, { payload }) => ({
      ...state,
      disciplines: disciplinesEntityAdapter.upsertOne(payload, {
        ...state.disciplines,
      }),
    })
  ),
  on(getDetailedPxDSuccessAction, (state, { payload }) => ({
    ...state,
    pxdsDetailed: pxdsEntityAdapter.upsertOne(payload, {
      ...state.pxdsDetailed,
    }),
  })),
  on(editPxDSuccessAction, (state, { payload }) => ({
    ...state,
    pxdsDetailed: pxdsEntityAdapter.upsertOne(payload, {
      ...state.pxdsDetailed,
    }),
  })),
  on(deleteProductSuccessAction, (state, { payload }) => ({
    ...state,
    products: productsEntityAdapter.removeOne(
      `${payload.businessSegmentId}-${payload.productId}`,
      {
        ...state.products,
      }
    ),
  })),
  on(deleteDisciplineSuccessAction, (state, { payload }) => ({
    ...state,
    disciplines: disciplinesEntityAdapter.removeOne(
      `${payload.businessSegmentId}-${payload.disciplineId}`,
      {
        ...state.disciplines,
      }
    ),
  }))
);
