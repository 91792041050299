import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

import { UserDto } from '@app/models/backendModel';
import { contextAwareProps } from '@core/utils/context-aware-props';
import { UserDetails } from '@models/userDetails';
import { IGetUsersResponse } from '@models/users';

export const UsersActions = createActionGroup({
  source: 'Collaboration',
  events: {
    'Get Inactive Users': emptyProps(),
    'Get Inactive Users Success': props<{ users: UserDto[] }>(),
    'Get Active Users Query': emptyProps(),
    'Force Get Active Users Query': emptyProps(),
    'Get Active Users Query SUCCESS': props<{ users: UserDto[] }>(),
    'Get Active Users Query FAILURE': emptyProps(),
  },
});
export const setMsalUserAction = createAction(
  '[Collection Users] set msal user',
  contextAwareProps<{ payload: { user: IGetUsersResponse } }>()
);

/**
 * Get current logged in user information.
 */
export const identifyCurrentUserAction = createAction(
  '[Collection Users] GET /users',
  contextAwareProps<{
    payload: {
      msalUser: { firstName: string; lastName: string; email: string };
    };
  }>()
);

export const identifyCurrentUserSuccessAction = createAction(
  '[Collection Users Effect] GET /users SUCCESS',
  contextAwareProps<{ payload: { user: IGetUsersResponse; ready: boolean } }>()
);

export const identifyCurrentUserFailureAction = createAction(
  '[Collection Users Effect] GET /users FAILURE',
  contextAwareProps<{ payload: { user: any } }>()
);

export const createUserAction = createAction(
  '[Collection Users] CreateUserCommand',
  contextAwareProps<{ payload: { userData: UserDetails } }>()
);

export const createUserPublicAction = createAction(
  '[Collection Users] CreateUserPublicCommand',
  contextAwareProps<{ payload: { userData: UserDetails } }>()
);

export const createUserSuccessAction = createAction(
  '[Collection Users Effect] POST /admin/users SUCCESS',
  contextAwareProps<{ payload: { user: UserDetails } }>()
);

export const createUserFailureAction = createAction(
  '[Collection Users Effect] POST /admin/users FAILURE',
  contextAwareProps()
);

export const updateUserAction = createAction(
  '[Collection Users] PUT /admin/users/:userId',
  contextAwareProps<{ payload: { userData: UserDetails } }>()
);

export const updateUserSuccessAction = createAction(
  '[Collection Users Effect] PUT /admin/users/:userId SUCCESS',
  contextAwareProps<{ payload: { userData: UserDetails } }>()
);

export const updateUserFailureAction = createAction(
  '[Collection Users Effect] PUT /admin/users/:userId FAILURE',
  contextAwareProps()
);
