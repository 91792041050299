import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, map } from 'rxjs';
import { saveImportedDataAs } from './common/utils';
import {
  IGetBlobMediatorRequest,
  IMediatorRequest,
  MediatorViewModel,
} from './models/backendModel';

@Injectable({
  providedIn: 'root',
})
export class MediatorService {
  private baseUrl = `${environment.apiUrl}Mediator`;

  constructor(private http: HttpClient) {}

  sendPublicRequest<TRequest extends IMediatorRequest, TResponse = void>(
    requestName: string,
    requestBody: TRequest
  ): Observable<TResponse> {
    const request: MediatorViewModel = {
      requestInJson: JSON.stringify(requestBody),
    };
    return this.http.post<TResponse>(
      `${this.baseUrl}/publicRequest/${requestName}`,
      request
    );
  }

  send<TRequest extends IMediatorRequest, TResponse = void>(
    requestName: string,
    requestBody: TRequest
  ): Observable<TResponse> {
    const request: MediatorViewModel = {
      requestInJson: JSON.stringify(requestBody),
    };
    return this.http.post<TResponse>(`${this.baseUrl}/${requestName}`, request);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendWithBlobResponse<TRequest extends IGetBlobMediatorRequest>(
    requestName: string,
    requestBody: TRequest
  ): Observable<boolean> {
    const request: MediatorViewModel = {
      requestInJson: JSON.stringify(requestBody),
    };
    return this.http
      .post(`${this.baseUrl}/GetBlob/${requestName}`, request, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(map((response) => saveImportedDataAs(response)));
  }
}
