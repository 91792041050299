<div class="page-head">
  <div>
    <h4 class="page-name" *ngIf="routeProjectId$ | async">
      Project: <span class="page-heading">{{ projectName$ | async }}</span>
    </h4>
  </div>
  <div>
    <button
      class="actions"
      mat-button
      color="primary"
      [matMenuTriggerFor]="actions"
      data-test="project-actions-button"
    >
      Actions <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #actions="matMenu">
      <button
        [disabled]="isChangeProjectOwnerDisabled"
        mat-menu-item
        (click)="openEditOwnerDialog()"
        data-test="edit-project-owner-button"
      >
        Edit Project Owner
      </button>
    </mat-menu>
  </div>
</div>
<nav mat-tab-nav-bar mat-stretch-tabs class="nav-tab" [tabPanel]="tabPanel">
  <a
    class="mat-tab-label"
    mat-tab-link
    [routerLink]="
      (routeProjectId$ | async)
        ? ['/project', routeProjectId$ | async, 'edit']
        : ['/project/create']
    "
    routerLinkActive
    #projectLink="routerLinkActive"
    [active]="projectLink.isActive"
    >Info</a
  >
  <a
    class="mat-tab-label"
    mat-tab-link
    [routerLink]="['/project', routeProjectId$ | async, 'calendarization']"
    routerLinkActive
    #calendarizationLink="routerLinkActive"
    [active]="calendarizationLink.isActive"
    [disabled]="(routeProjectId$ | async) === null"
    >Calendarization</a
  >
  <a
    class="mat-tab-label"
    mat-tab-link
    [routerLink]="['/project', routeProjectId$ | async, 'summary']"
    routerLinkActive
    #projectSummaryLink="routerLinkActive"
    [active]="projectSummaryLink.isActive"
    [disabled]="(routeProjectId$ | async) === null"
    >Summary</a
  >
</nav>

<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
