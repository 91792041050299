<h2 mat-dialog-title>Distribute CTRs hours evenly</h2>
<mat-dialog-content data-test="distribute-evenly-text">
  <p style="color: red">
    Are you sure to evenly distribute hours through the CTR duration? All
    previously edited data will be lost.
  </p>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button mat-raised-button (click)="onCancel()">Cancel</button>
  <button
    mat-raised-button
    color="accent"
    cdkFocusInitial
    (click)="onYes()"
    data-test="distribute-evenly-save-button"
  >
    Yes
  </button>
</mat-dialog-actions>
