import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  selectActiveUsers,
  selectCurrentBusinessSegmentId,
} from '@collections/users/store/users.selectors';

import {
  disciplinesEntityAdapter,
  FEATURE_KEY,
  IPxDsCollectionState,
  productsEntityAdapter,
  pxdsDetailedEntityAdapter,
  pxdsEntityAdapter,
} from './pxds.reducer';

export const selectPxDsCollectionState =
  createFeatureSelector<IPxDsCollectionState>(FEATURE_KEY);

export const selectPxDsCollectionPxds = createSelector(
  selectPxDsCollectionState,
  ({ pxds }) => pxds
);

const pxdsSelectors = pxdsEntityAdapter.getSelectors(selectPxDsCollectionPxds);

export const selectAllPxDs = createSelector(pxdsSelectors.selectAll, (v) =>
  v.sort((a, b) => a.shortName.localeCompare(b.shortName))
);

export const selectPxDsMap = createSelector(
  pxdsSelectors.selectEntities,
  (v) => v
);

export const selectPxDsDetailedCollection = createSelector(
  selectPxDsCollectionState,
  ({ pxdsDetailed }) => pxdsDetailed
);

const pxdsDetailedSelectors = pxdsDetailedEntityAdapter.getSelectors(
  selectPxDsDetailedCollection
);

const selectAllPxDsDetailed = createSelector(
  pxdsDetailedSelectors.selectAll,
  (v) => v
);

export const selectAllPxDsDetailedMap = createSelector(
  pxdsDetailedSelectors.selectEntities,
  (v) => v
);

export const selectDetailedPxDFactory = (pxdId: number) =>
  createSelector(selectAllPxDsDetailedMap, (pxdsDetailedMap) =>
    pxdsDetailedMap[pxdId] ? pxdsDetailedMap[pxdId] : null
  );

export const selectDetailedPxDWithUsersMapped = (pxdId: number) =>
  createSelector(
    selectAllPxDsDetailedMap,
    selectActiveUsers,
    (pxdsDetailedMap, users) =>
      pxdsDetailedMap[pxdId]
        ? {
            ...pxdsDetailedMap[pxdId],
            users: users.filter((user) =>
              pxdsDetailedMap[pxdId].users.some(
                (pxdUser) => pxdUser.id === user.userId
              )
            ),
          }
        : null
  );

export const selectPxDsCollectionProducts = createSelector(
  selectPxDsCollectionState,
  ({ products }) => products
);

export const productSelectors = productsEntityAdapter.getSelectors(
  selectPxDsCollectionProducts
);

export const selectAllProducts = createSelector(
  productSelectors.selectAll,
  (v) => v.sort((a, b) => a.order - b.order)
);

export const selectPxDsCollectionDisciplines = createSelector(
  selectPxDsCollectionState,
  ({ disciplines }) => disciplines
);

const disciplineSelectors = disciplinesEntityAdapter.getSelectors(
  selectPxDsCollectionDisciplines
);

export const selectAllDisciplines = createSelector(
  disciplineSelectors.selectAll,
  (v) => v.sort((a, b) => a.order - b.order)
);

export const selectAllPxDsWithMasterdata = createSelector(
  selectAllPxDs,
  (pxds) => pxds.filter((pxd) => pxd.hasMasterData)
);

export const selectPxDFactory = (ctrId: number) =>
  createSelector(selectPxDsMap, (pxdsMap) => pxdsMap[ctrId]);

export const selectPxDByProductIdDisciplineIdFactory = (
  productId: number,
  disciplineId: number
) =>
  createSelector(selectAllPxDs, (pxds) =>
    pxds.find(
      (pxd) => pxd.productId === productId && pxd.disciplineId === disciplineId
    )
  );

export const selectSegmentPxDsFactory = (businessSegmentId: number) =>
  createSelector(selectAllPxDs, (pxds) =>
    pxds
      .filter((pxd) => pxd.businessSegmentId === businessSegmentId)
      .sort((a, b) => a.shortName.localeCompare(b.shortName))
  );

export const selectCurrentUserBusinessSegmentPxDs = createSelector(
  selectCurrentBusinessSegmentId,
  selectAllPxDs,
  (businessSegmentId, pxds) =>
    pxds
      .filter((pxd) => pxd.businessSegmentId === businessSegmentId)
      .sort((a, b) => a.shortName.localeCompare(b.shortName))
);

export const selectSegmentPxDsWithMasterdataFactory = (
  businessSegmentId: number
) =>
  createSelector(selectAllPxDs, (pxds) =>
    pxds.filter(
      (pxd) =>
        pxd.businessSegmentId === businessSegmentId &&
        pxd.hasMasterData &&
        pxd.hasPrincipals
    )
  );

export const selectCurrentUserPxDs = createSelector(
  selectAllPxDs,
  selectCurrentBusinessSegmentId,
  (pxds, businessSegmentId) =>
    pxds.filter((pxd) => pxd.businessSegmentId === businessSegmentId)
);

export const selectCurrentUserBusinessSegment = createSelector(
  selectCurrentBusinessSegmentId,
  selectAllProducts,
  selectAllDisciplines,
  (businessSegmentId, products, disciplines) => ({
    businessSegmentId,
    products: products.filter(
      (product) => product.businessSegmentId === businessSegmentId
    ),
    disciplines: disciplines.filter(
      (discipline) => discipline.businessSegmentId === businessSegmentId
    ),
  })
);

export const selectSegmentFactory = (businessSegmentId: number) =>
  createSelector(
    selectAllProducts,
    selectAllDisciplines,
    (products, disciplines) => ({
      products: products.filter(
        (product) => product.businessSegmentId === businessSegmentId
      ),
      disciplines: disciplines.filter(
        (discipline) => discipline.businessSegmentId === businessSegmentId
      ),
    })
  );

export const selectSegmentProductsFactory = (businessSegmentId: number) =>
  createSelector(selectSegmentFactory(businessSegmentId), ({ products }) =>
    products.sort((a, b) => a.order - b.order)
  );

export const selectSegmentDisciplinesFactory = (businessSegmentId: number) =>
  createSelector(selectSegmentFactory(businessSegmentId), ({ disciplines }) =>
    disciplines.sort((a, b) => a.order - b.order)
  );

export const selectSegmentProductByIdFactory = (
  businessSegmentId: number,
  productId: number
) =>
  createSelector(
    selectSegmentProductsFactory(businessSegmentId),
    (products) => products.find((p) => p.id === productId) || null
  );

export const selectSegmentDisciplineByIdFactory = (
  businessSegmentId: number,
  disciplineId: number
) =>
  createSelector(
    selectSegmentDisciplinesFactory(businessSegmentId),
    (disciplines) => disciplines.find((d) => d.id === disciplineId) || null
  );
