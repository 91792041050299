import { EventEmitter, Injectable } from '@angular/core';

export interface SnackbarSettings {
  position?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class LiveSaveSnackbarService {
  private snackbarTriggerEmitter = new EventEmitter<SnackbarSettings>();
  public snackbarTriggerObservable = this.snackbarTriggerEmitter.asObservable();
  private snackbarClosureEmitter = new EventEmitter<boolean>();
  public snackbarClosureObservable = this.snackbarClosureEmitter.asObservable();

  open(snackbarSettings?: SnackbarSettings): void {
    this.snackbarTriggerEmitter.next(snackbarSettings);
  }

  close(success: boolean): void {
    this.snackbarClosureEmitter.next(success);
  }
}
