import { Injectable } from '@angular/core';
import { LiveSavingInterceptorService } from '@app/common/live-saving-interceptor.service';
import { MediatorService } from '@app/mediator.service';
import {
  CalendarizationForCtrDto,
  CalendarizationForCtrQuery,
  CtrFteDistributionForRoles,
  CtrHoursDistributionForRoles,
  DistributeWorkloadEvenlyCommand,
  FteDistributionForMatrix,
  HoursDistributionForMatrix,
  HoursPeriod,
  SaveWorkloadDistributionForRolesCommand,
  SaveWorkloadDistributionForRolesResult,
} from '@app/models/backendModel';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { CalendarizationTableSharedService } from '../calendarization-table-shared/calendarization-table-shared.service';
import { CtrsCalendarizationActions } from './ctrs-calendarization.actions';
import { RolesCalendarizationActions } from './roles-calendarization.actions';
import { IRolesCalendarizationState } from './roles-calendarization.reducer';
import { selectCtrCalendarizationData } from './roles-calendarization.selectors';

@Injectable()
export class RolesCalendarizationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly mediatorService: MediatorService,
    private readonly liveSavingInterceptorService: LiveSavingInterceptorService,
    private readonly store: Store,
    private readonly calendarizationTableSharedService: CalendarizationTableSharedService
  ) {}

  public initCalendarization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesCalendarizationActions.initRolesCalendarization),
      switchMap((action) =>
        this.mediatorService
          .send<CalendarizationForCtrQuery, CalendarizationForCtrDto>(
            'CalendarizationForCtrQuery',
            {
              scenarioCtrId: action.ctrId,
            }
          )
          .pipe(
            map((response) =>
              RolesCalendarizationActions.initRolesCalendarizationSuccess({
                ...response,
                startDate:
                  this.calendarizationTableSharedService.parseDateWithoutTimeOffset(
                    response.startDate
                  ),
                endDate:
                  this.calendarizationTableSharedService.parseDateWithoutTimeOffset(
                    response.endDate
                  ),
              })
            )
          )
      )
    )
  );

  public distributeEvenly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesCalendarizationActions.distributeEvenly),
      switchMap((action) =>
        this.mediatorService
          .send<DistributeWorkloadEvenlyCommand>(
            'DistributeWorkloadEvenlyCommand',
            {
              scenarioCTRId: action.scenarioCtrId,
            }
          )
          .pipe(
            switchMap(() =>
              of(
                RolesCalendarizationActions.initRolesCalendarization({
                  ctrId: action.scenarioCtrId,
                }),
                CtrsCalendarizationActions.reloadCalendarizationForScenarioCtr({
                  scenarioCtrId: action.scenarioCtrId,
                })
              )
            )
          )
      )
    )
  );

  public updateRolesCalendarization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesCalendarizationActions.updateRolesCalendarization),
      switchMap((action) =>
        this.liveSavingInterceptorService
          .send<
            SaveWorkloadDistributionForRolesCommand,
            SaveWorkloadDistributionForRolesResult
          >('SaveWorkloadDistributionForRolesCommand', action)
          .pipe(
            map((response) => ({
              ...action,
              hours: response.hours,
              fte: action.fteDistribution,
            }))
          )
      ),
      switchMap((action) =>
        this.store
          .select(selectCtrCalendarizationData(action.scenarioCTRId))
          .pipe(
            take(1),
            map((state) => ({ state: state, ...action }))
          )
      ),
      map((action) =>
        this.updateStateBasedOnEditedDistribution(action.state, action)
      ),
      switchMap((state) =>
        of(
          RolesCalendarizationActions.initRolesCalendarizationSuccess(state),
          CtrsCalendarizationActions.reloadCalendarizationForScenarioCtr({
            scenarioCtrId: state.scenarioCtrId,
          })
        )
      )
    )
  );

  public getUpdatedFtesDistributionForRoles(
    distributions: CtrFteDistributionForRoles[],
    engineeringRoleId: number,
    entityId: number,
    fte: FteDistributionForMatrix[]
  ): CtrFteDistributionForRoles[] {
    return distributions.map((x) => {
      if (
        x.engineeringRoleId !== engineeringRoleId ||
        x.entityId !== entityId
      ) {
        return x;
      }
      return {
        ...x,
        fteDistribution: fte,
      } as CtrFteDistributionForRoles;
    });
  }

  public getUpdatedHoursDistributionForRoles(
    distributions: CtrHoursDistributionForRoles[],
    engineeringRoleId: number,
    entityId: number,
    hours: HoursDistributionForMatrix[]
  ): CtrHoursDistributionForRoles[] {
    return distributions.map((x) => {
      if (
        x.engineeringRoleId !== engineeringRoleId ||
        x.entityId !== entityId
      ) {
        return x;
      }
      return {
        ...x,
        hoursDistribution:
          hours.length > 0
            ? hours
            : x.hoursDistribution.map((x) => ({
                ...x,
                hoursPeriods: x.hoursPeriods.map(
                  (z) =>
                    ({
                      ...z,
                      hoursInPeriod: 0,
                    } as HoursPeriod)
                ),
              })),
      };
    });
  }
  public updateStateBasedOnEditedDistribution(
    state: IRolesCalendarizationState,
    action: {
      fte: FteDistributionForMatrix[];
    } & SaveWorkloadDistributionForRolesResult
  ): IRolesCalendarizationState {
    const entityId = action.entityId;
    const engineeringRoleId = action.engineeringRoleId;
    const newFteDistribution = this.getUpdatedFtesDistributionForRoles(
      state.ctrFteDistributionForRoles,
      engineeringRoleId,
      entityId,
      action.fte
    );
    const newHoursDistribution = this.getUpdatedHoursDistributionForRoles(
      state.ctrHoursDistributionForRoles,
      engineeringRoleId,
      entityId,
      action.hours
    );
    const updateScenarioCtr: IRolesCalendarizationState = {
      ...state,
      ctrFteDistributionForRoles: newFteDistribution,
      ctrHoursDistributionForRoles: newHoursDistribution,
    };

    return updateScenarioCtr;
  }
}
