import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BusinessSegmentsEffects } from './store/business-segments.effects';
import { FEATURE_KEY, reducer } from './store/business-segments.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([BusinessSegmentsEffects]),
  ],
})
export class BusinessSegmentsCollectionModule {}
