import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { OrderDialogComponent } from './order-dialog.component';

const components = [OrderDialogComponent];

@NgModule({
  declarations: components,
  imports: [SharedModule],
  exports: components,
})
export class OrderDialogModule {}
