import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppService } from '@app/app-service.service';
import {
  getProjectAction,
  updateCurrentlyViewedProjectAction,
} from '@collections/projects/store/projects.actions';
import { ConfirmDialogComponent } from '@common/confirm-dialog/confirm-dialog.component';
import { LiveSavingInterceptorService } from '@common/live-saving-interceptor.service';
import { Entity } from '@models/entity';
import { Equipments } from '@models/equipments';
import {
  IGetProjectResponse,
  IScenarioCreationPayload,
  ScenarioDetails,
} from '@models/project';
import { IProjectScenario } from '@models/project-scenario';

import { UserRole } from './core/store/core.reducer';
import {
  DelegateUsersResponse,
  GetEngineeringRoleResult,
  RolesAndRatesResponse,
} from './models/backendModel';
import { IGetCtrBlocksResponseItem } from './models/ctr-blocks';
import { ICTRRevision } from './models/ctr-revision';
import { ProjectDocumentCategory } from './models/project-documents';
import { IScenarioDefn } from './scenario/ctr/scenariodef/scenariodefn';

@Injectable({
  providedIn: 'root',
})
export class SdkCallService {
  public promptEnable = false;

  constructor(
    private appService: AppService,
    public dialog: MatDialog,
    private http: HttpClient,
    private store: Store,
    private liveSavingInterceptorService: LiveSavingInterceptorService
  ) {}

  /**
   * this is going to be @deprecated
   *
   * keep/move resource loading state into store
   */
  public isLoaderVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public refreshBuild$ = new Subject<number>();
  /** @deprecated remove this when no longer needed */
  public document$ = new Subject<any>();
  public spinnerDisplay = new BehaviorSubject<boolean>(false);
  public baseCaseChanged = new BehaviorSubject<boolean>(false);

  /** @deprecated move to using collections/entities store */
  getProjectEntity(activeInactiveValues: boolean): Observable<Entity[]> {
    return this.appService.get(
      `{apiUrl}entities?getAllEntities=${activeInactiveValues}`
    );
  }

  /** @deprecated move to using collections/business-segments store */
  getBusinessSegment(): Observable<any> {
    return this.appService.get(`{apiUrl}admin/businessSegments`);
  }

  getDelegateUsers(id: number): Observable<DelegateUsersResponse[]> {
    return this.appService.get(`{apiUrl}scenarioCTRs/${id}/delegateusers`);
  }

  delegateToUser(id: number, userId: number): Observable<any> {
    return this.appService.get(
      `{apiUrl}scenarioCTRs/${id}/delegations/${userId}`
    );
  }

  getSummaryData(id: any, sctrequipid: any): Observable<any> {
    return this.appService.get(
      `{apiUrl}scenarioCTRs/${id}/getequips/${sctrequipid}`
    );
  }

  getSummaryInput(ctrId: number, equipId: number): Observable<any> {
    return this.appService.get(
      `{apiUrl}scenarioCTRs/${ctrId}/sctrInputs/${equipId}`
    );
  }

  getRolesAndRates(id): Observable<RolesAndRatesResponse> {
    this.isLoaderVisible.next(true);
    return this.appService.get(`{apiUrl}admin/rolesAndRates/${id}`);
  }

  getEquipments(activeInactiveValues): Observable<Equipments[]> {
    return this.appService.get(
      `{apiUrl}equipments?getAll=${activeInactiveValues}`
    );
  }

  getprojectScenariosData(projectId): Observable<IProjectScenario[]> {
    return this.appService.get<IProjectScenario[]>(
      `{apiUrl}projects/${projectId}/summary/scenarios`
    );
  }

  submitProjectScenariosData(
    projectId,
    projectScenarioData
  ): Observable<IProjectScenario[]> {
    return this.appService
      .put<IProjectScenario[]>(
        `{apiUrl}projects/${projectId}/summary/updateScenarios`,
        projectScenarioData
      )
      .pipe(
        // TODO: convert to effect
        tap(() =>
          this.store.dispatch(
            updateCurrentlyViewedProjectAction({
              context: 'SdkCallService:submitProjectScenariosData',
              // payload: { context: UserRole.REQUESTOR },
            })
          )
        )
      );
  }

  addScenario(project: IScenarioCreationPayload): Observable<any> {
    return this.appService.post(`{apiUrl}scenarios`, project).pipe(
      tap(() =>
        this.store.dispatch(
          getProjectAction({
            context: 'SdkCallService::addScenario/response',
            payload: {
              projectId: project.projectId,
              context: UserRole.REQUESTOR,
            },
          })
        )
      )
    );
  }

  updateScenarioDetails(
    scenarioId: number,
    scenarioDetails: ScenarioDetails
  ): Observable<any> {
    return this.liveSavingInterceptorService.put(
      `{apiUrl}scenarios/${scenarioId}/details`,
      scenarioDetails
    );
  }

  resetScenarioDefinition(scenarioId: number): Observable<any> {
    return this.http.delete(`{apiUrl}scenarios/${scenarioId}/details`);
  }

  updateScenarioDescription(
    scenarioId: number,
    scenarioDescription: string
  ): Observable<any> {
    return this.liveSavingInterceptorService.put(
      `{apiUrl}scenarios/${scenarioId}/description`,
      { description: scenarioDescription }
    );
  }

  selectScenarioCtr(scenarioId: number, ctrId: number): Observable<any> {
    return this.http.post(`{apiUrl}scenarios/${scenarioId}/ctr/${ctrId}`, null);
  }

  deselectScenarioCtr(scenarioId: number, ctrId: number): Observable<any> {
    return this.http.delete(`{apiUrl}scenarios/${scenarioId}/ctr/${ctrId}`);
  }

  requestCtr(scenarioId: number): Observable<any> {
    return this.http.put(`{apiUrl}scenarios/${scenarioId}/request`, null).pipe(
      // TODO: convert to effect
      tap(() =>
        this.store.dispatch(
          updateCurrentlyViewedProjectAction({
            context: 'SdkCallService:requestCtr',
            // payload: { context: UserRole.REQUESTOR },
          })
        )
      )
    );
  }

  cancelCtr(scenarioId: number, ctrId: number): Observable<void> {
    return this.http.put<void>(
      `{apiUrl}scenarios/${scenarioId}/ctr/cancel/${ctrId}`,
      null
    );
  }

  requestCtrRevision(scenarioId: number, ctrId: number, comment: string) {
    return this.http.put<number>(
      `{apiUrl}scenarios/${scenarioId}/ctr/revision/${ctrId}`,
      { comment }
    );
  }

  requestCtrRevisionForNonActiveOwner(
    scenarioId: number,
    ctrId: number,
    currentOwnerName: string
  ) {
    return this.http.put(
      `{apiUrl}scenarios/${scenarioId}/ctr/revisioninactiveowner/${ctrId}`,
      { currentOwnerName }
    );
  }

  recoverCtr(scenarioId: number, ctrId: number): Observable<any> {
    return this.http
      .put(`{apiUrl}scenarios/${scenarioId}/ctr/restore/${ctrId}`, null)
      .pipe(
        // TODO: convert to effect
        tap(() =>
          this.store.dispatch(
            updateCurrentlyViewedProjectAction({
              context: 'SdkCallService:recoverCtr',
              // payload: { context: UserRole.REQUESTOR },
            })
          )
        )
      );
  }

  /** @deprecated move to using collections/projects store */
  getProject(id: number): Observable<IGetProjectResponse> {
    return this.appService.get(`{apiUrl}projects/${id}`);
  }

  updateScenario(scenarioId: number, data: any): Observable<any> {
    return this.liveSavingInterceptorService
      .put(`{apiUrl}scenarios/${scenarioId}`, data)
      .pipe(
        // TODO: convert to effect
        tap(() =>
          this.store.dispatch(
            updateCurrentlyViewedProjectAction({
              context: 'SdkCallService:updateScenario',
              // payload: { context: UserRole.REQUESTOR },
            })
          )
        )
      );
  }

  /** @deprecated use ngrx store */
  getBuildScenarioBlocks(ctrId: any): Observable<IGetCtrBlocksResponseItem[]> {
    return this.appService.get<IGetCtrBlocksResponseItem[]>(
      `{apiUrl}scenarioCTRs/${ctrId}/build/scenarioBlocks`
    );
  }

  getScenarioDef(id: any): Observable<IScenarioDefn[]> {
    return this.appService.get(`{apiUrl}scenarios/${id}/scenarioDefinitions`);
  }

  getProjectCurrencies(projectId: number): Observable<any> {
    return this.appService.get(`{apiUrl}projects/${projectId}/currencies`);
  }

  getCTRgeneralInfo(id: any) {
    return this.appService.get(`{apiUrl}scenarioCTRs/${id}/generalinfo`);
  }

  getDocumentData(id: any): Observable<ProjectDocumentCategory[]> {
    this.isLoaderVisible.next(true);
    return this.appService.get(`{apiUrl}Documents/projects/${id}`);
  }

  getEngineeringRoles(
    activeInactiveValues
  ): Observable<GetEngineeringRoleResult[]> {
    return this.appService.get(
      `{apiUrl}engineeringRoles?getAll=${activeInactiveValues}`
    );
  }

  copyCtr(sourceId: any, targetId: any): Observable<any> {
    return this.appService.get(
      `{apiUrl}scenarioCTRs/${sourceId}/copyScenarioCTR/${targetId}`
    );
  }

  uploadDocuments(formData: any, id: any): Observable<any> {
    this.isLoaderVisible.next(true);
    return this.appService.post(`{apiUrl}documents/projects/${id}`, formData);
  }

  downloadKpi(fromDate: any, toDate: any, businessSegmentId: number) {
    this.isLoaderVisible.next(true);
    return this.http.get(
      `{apiUrl}admin/kpi/?from=${fromDate}&to=${toDate}&businessSegmentId=${businessSegmentId}`,
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  downloadLog(fromDate: any, toDate: any) {
    this.isLoaderVisible.next(true);
    return this.http.get(`{apiUrl}dataLogs/export/${fromDate}/${toDate}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  downloadMDR(scenarioId: any, scenarioCtrId: any) {
    this.isLoaderVisible.next(true);
    return this.http.get(
      `{apiUrl}export/mdrexcel/scenario/${scenarioId}/scenarioCTR/${scenarioCtrId}`,
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  downloadDocument(id: any) {
    this.isLoaderVisible.next(true);
    return this.http.get(`{apiUrl}documents/${id}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  downloadiQuestSummaryExcel(scenarioId: number) {
    return this.http.get(`{apiUrl}export/iquestExcel/${scenarioId}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  downloadScenSummarypdf(id: any) {
    return this.http.get(`{apiUrl}export/pdf/scenario/${id}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  deleteDocument(id: any) {
    return this.appService.delete(`{apiUrl}Documents/projects/${id}`);
  }

  getDocumentCategory(): Observable<any> {
    return this.appService.get(`{apiUrl}documents/categories`);
  }

  getSummaryScenarioDef(id: any): Observable<any> {
    return this.appService.get(
      `{apiUrl}projects/${id}/summary/scenarioDefinitions`
    );
  }

  getCTRrevisions(scenarioCtrId: number) {
    return this.appService.get<ICTRRevision[]>(
      `{apiUrl}scenarioCTRs/${scenarioCtrId}/revisions`
    );
  }

  getAdminUsers(): Observable<any> {
    return this.appService.get(`{apiUrl}admin/users`);
  }

  getAdminCurrency(): Observable<any> {
    this.isLoaderVisible.next(true);
    return this.appService.get(`{apiUrl}admin/currency`);
  }

  addAdminCurrency(data: any): Observable<any> {
    return this.appService.post(`{apiUrl}admin/currency`, data);
  }

  updateAdminCurrency(data: any): Observable<any> {
    return this.appService.put(`{apiUrl}admin/currency`, data);
  }

  getAdminEntity(): Observable<any> {
    this.isLoaderVisible.next(true);
    return this.http.get(`{apiUrl}admin/entity`);
  }

  addAdminEntity(data: any): Observable<any> {
    return this.appService.post(`{apiUrl}admin/entities`, data);
  }

  updateAdminEntity(data: any): Observable<any> {
    return this.appService.put(`{apiUrl}admin/entities`, data);
  }

  getAdminClients(): Observable<any> {
    this.isLoaderVisible.next(true);
    return this.appService.get(`{apiUrl}clients/adminClients`);
  }

  addAdminClients(data: any): Observable<any> {
    return this.appService.post(`{apiUrl}admin/client`, data);
  }

  updateAdminClients(data: any): Observable<any> {
    return this.appService.put(`{apiUrl}admin/client`, data);
  }

  deleteAdminUsers(id): Observable<any> {
    return this.appService.delete(`{apiUrl}admin/userDetails/${id}`);
  }

  saveNewRole(id, data) {
    return this.appService.post(`{apiUrl}admin/rolesAndRates/${id}`, data);
  }

  editRole(id, data) {
    return this.appService.put(`{apiUrl}admin/rolesAndRates/${id}`, data);
  }

  getDataLogs(segmentId): Observable<any> {
    return this.appService.get(
      `{apiUrl}dataLogs/?businessSegmentId=${segmentId}`
    );
  }

  /** @deprecated do not use */
  saveDataLog(log: any): Observable<any> {
    return this.appService.post(`{apiUrl}dataLogs`, log);
  }

  updateBaseCaseScenario(projectId, data) {
    return this.appService
      .put(`{apiUrl}projects/${projectId}/summary/updateBaseCaseScenario`, data)
      .pipe(
        // TODO: convert to effect
        tap(() =>
          this.store.dispatch(
            updateCurrentlyViewedProjectAction({
              context: 'SdkCallService:updateBaseCaseScenario',
              // payload: { context: UserRole.REQUESTOR },
            })
          )
        )
      );
  }

  exportCommercialData(fromDate, toDate, segmentId, entityArr) {
    return this.http.post(
      `{apiUrl}export/commercialExport/${fromDate}/${toDate}/${segmentId}`,
      entityArr,
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  DownloadAddComplexManualAct(fromDate, toDate, pxdList: any, segmentId) {
    return this.http.post(
      `{apiUrl}export/exportManCpxAndAddAct/${fromDate}/${toDate}/${segmentId}`,
      pxdList,
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  DownloadActivitiesExcel(
    fromDate,
    toDate,
    ctrIdList: any,
    projectIdList: any,
    segmentId,
    includeInProgressCtrs: boolean
  ) {
    return this.http.post(
      `{apiUrl}export/exportactivities/${fromDate}/${toDate}/${segmentId}/${includeInProgressCtrs}`,
      { ctrIdList, projectIdList },
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  openUnsavedChangesModal(): any {
    if (!this.promptEnable) {
      return true;
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Leave the page?',
          message: 'Unsaved data will be lost.',
        },
      });

      return dialogRef.afterClosed().pipe(
        map((dialogResult) => {
          return !!dialogResult;
        })
      );
    }
  }

  resetCTR(id: number): Observable<any> {
    return this.http.put(`{apiUrl}scenarioCTRs/${id}/reset`, null).pipe(
      // TODO: convert to effect
      tap(() =>
        this.store.dispatch(
          updateCurrentlyViewedProjectAction({
            context: 'SdkCallService:resetCTR',
            // payload: { context: UserRole.ENGINEER },
          })
        )
      )
    );
  }
}
