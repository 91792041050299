import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { SdkCallService } from '@app/sdk-call.service';

@Component({
  selector: 'app-scenario-definition',
  templateUrl: './scenario-definition.component.html',
  styleUrls: [ './scenario-definition.component.scss' ],
})
export class ScenarioDefinitionComponent implements OnChanges {
  scenario: any;
  applications: any;
  definitionSection: UntypedFormGroup;
  projectId: number;
  @Input() projectScenarios = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sdkCallService: SdkCallService,
    private actr: ActivatedRoute
  ) {
    this.actr.params.subscribe((data) => {
      this.projectId = data.projectId;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.projectScenarios !== null &&
      changes.projectScenarios.currentValue !== null
    ) {
      this.scenario = changes.projectScenarios.currentValue;
      this.sdkCallService.baseCaseChanged.subscribe((value) => {
        if (this.projectId !== null) {
          this.sdkCallService
            .getSummaryScenarioDef(this.projectId)
            .subscribe((data) => {
              this.applications = data.applications;
              this.definitionSection = new UntypedFormGroup({});
              this.definitionSection.addControl(
                'defApplication',
                this.setFormArray(this.applications)
              );
            });
        }
      });
    }
  }

  getScenarioTooltip(scenario): string {
    const tooltipParts = [
      scenario.status,
      scenario.isBaseCase && 'Base case',
      !!scenario.submitted && 'Submitted',
    ].filter((tooltipPart: string) => tooltipPart);
    return tooltipParts.join(' - ');
  }

  setFormArray(arg0: any): UntypedFormArray {
    const arr = arg0.map((service) => this.setFormGroup(service));
    return this.formBuilder.array(arr);
  }

  setFormGroup(data: any): UntypedFormGroup {
    const applnGroup = new UntypedFormGroup({});
    for (const key in data) {
      if (key === 'applications') {
        applnGroup.addControl(key, this.setFormArray(data[ key ]));
      } else if (key === 'equipments') {
        applnGroup.addControl(key, this.setFormArray(data[ key ]));
      } else if (key === 'scenarioDefinitions') {
        data[ key ] = this.getdefinitionEquipData(data[ key ]);
        applnGroup.addControl(key, this.setFormArray(data[ key ]));
      } else {
        applnGroup.addControl(key, new UntypedFormControl(data[ key ]));
      }
    }
    return applnGroup;
  }

  getApplicationData() {
    const x = (this.definitionSection?.controls
      ?.defApplication as UntypedFormArray)?.controls;
    return x;
  }

  getdefinitionEquipData(arg: any) {
    const arr = [];
    this.scenario?.forEach((element) => {
      let x = {
        scenarioId: element.scenarioId,
        scenarioStatus: null,
        scenarioDefinitionValue: null,
      };
      if (!!arg && typeof arg !== 'object') {
        const index = arg.findIndex((y) => y.scenarioId === element.scenarioId);
        if (index !== -1) {
          x = arg[ index ];
        }
      }
      arr.push(x);
    });
    return arr;
  }
}
