import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

import { CtrStatus, UserCtrRole } from '@app/common/enums';
import { FocusLocation } from '../ctr.service';
export const CtrActions = createActionGroup({
  source: 'CTR',
  events: {
    'Set user role': props<{ role: UserCtrRole }>(),
    'Set active ctr id': props<{ ctrId: number }>(),
    'Set ctr status': props<{ ctrStatus: CtrStatus }>(),
    'Get Workload For Roles Needs Update Query Success': props<{
      needsUpdate: boolean;
    }>(),
    'Activities Hours Needs Update': props<{
      blockId: number;
      inputId: string;
      message: string;
    }>(),
    'Clear Activities Hours Needs Update': emptyProps(),
    'Clear Workload For Roles Needs Update': emptyProps(),
  },
});
export const focusCtrInputAction = createAction(
  '[Collection Ctr] focus CTR input',
  props<{ payload: { focus: FocusLocation } }>()
);
