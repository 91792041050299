import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const userRolesValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const isRequestor = control.get('isRequestor').value;
  const isEngineer = control.get('isEngineer').value;
  const isAdmin = control.get('isAdmin').value;

  return ![isRequestor, isEngineer, isAdmin].some((role) => role)
    ? { missingRoles: true }
    : null;
};
