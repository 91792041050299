import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_KEY,
  IScenarioComparisonCollectionState,
  scenarioComparisonEntityAdapter,
} from './scenario-comparison.reducer';

export const selectScenarioComparisonCollectionState = createFeatureSelector<
  IScenarioComparisonCollectionState
>(FEATURE_KEY);

export const {
  selectAll: selectAllScenarioComparison,
  selectEntities: selectScenarioComparisonMap,
} = scenarioComparisonEntityAdapter.getSelectors(
  selectScenarioComparisonCollectionState
);

export const selectScenarioComparisonItemsByProjectIdFactory = (
  projectId: number
) =>
  createSelector(
    selectScenarioComparisonMap,
    (projectScenarioComparisonItems) =>
      projectScenarioComparisonItems[projectId]
        ? projectScenarioComparisonItems[projectId].scenarioComparisonItems
        : null
  );

export const selectScenarioComparisionItemExistsForProjectFactory = (
  projectId: number
) =>
  createSelector(
    selectAllScenarioComparison,
    (projectScenarioComparisonItems) =>
      projectScenarioComparisonItems
        ? !!projectScenarioComparisonItems[projectId]
        : false
  );

export const selectLoadedComparisonItemsProjectIds = createSelector(
  selectAllScenarioComparison,
  (projectScenarioComparisonItems) =>
    projectScenarioComparisonItems
      ? projectScenarioComparisonItems.keys()
      : []
);
