import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { IAssumptionsAndExclusionsResponse } from '@models/assumptions-and-exclusions-response';

export const getAssumptionsAndExclusionsAction = createAction(
  '[Collection AssumptionsAndExclusions] GET /scenarioCTRs/:ctrId/assumptionsandexclusions',
  contextAwareProps<{ payload: { ctrId: number } }>()
);

export const getAssumptionsAndExclusionsSuccessAction = createAction(
  '[Collection AssumptionsAndExclusions] GET /scenarioCTRs/:ctrId/assumptionsandexclusions SUCCESS',
  contextAwareProps<{
    payload: IAssumptionsAndExclusionsResponse;
  }>()
);

export const getAssumptionsAndExclusionsFailureAction = createAction(
  '[Collection AssumptionsAndExclusions] GET /scenarioCTRs/:ctrId/assumptionsandexclusions FAILURE',
  contextAwareProps<{}>()
);

export const updateCtrAssumptionsAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/:ctrId/assumptions',
  contextAwareProps<{
    payload: { ctrId: number; text: string };
  }>()
);

export const updateCtrAssumptionsSuccessAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/:ctrId/assumptions SUCCESS',
  contextAwareProps<{
    payload: { ctrId: number };
  }>()
);

export const updateCtrAssumptionsFailureAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/:ctrId/assumptions FAILURE',
  contextAwareProps<{}>()
);

export const updateCtrExclusionsAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/:ctrId/exclusions',
  contextAwareProps<{
    payload: { ctrId: number; text: string };
  }>()
);

export const updateCtrExclusionsSuccessAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/:ctrId/exclusions SUCCESS',
  contextAwareProps<{
    payload: { ctrId: number };
  }>()
);

export const updateCtrExclusionsFailureAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/:ctrId/exclusions FAILURE',
  contextAwareProps()
);

export const updateCtrScopeAssumptionsAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/assumptions',
  contextAwareProps<{
    payload: { ctrId: number; ctrScopeId: number; text: string };
  }>()
);

export const updateCtrScopeAssumptionsSuccessAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/assumptions SUCCESS',
  contextAwareProps<{
    payload: { ctrId: number };
  }>()
);

export const updateCtrScopeAssumptionsFailureAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/assumptions FAILURE',
  contextAwareProps()
);

export const updateCtrScopeExclusionsAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/exclusions',
  contextAwareProps<{
    payload: { ctrId: number; ctrScopeId: number; text: string };
  }>()
);

export const updateCtrScopeExclusionsSuccessAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/exclusions SUCCESS',
  contextAwareProps<{
    payload: { ctrId: number };
  }>()
);

export const updateCtrScopeExclusionsFailureAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/exclusions FAILURE',
  contextAwareProps()
);

export const updateCtrScopeEquipmentAssumptionsAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/equipment/:equipmentId/assumptions',
  contextAwareProps<{
    payload: {
      ctrId: number;
      ctrScopeId: number;
      equipmentId: number;
      text: string;
    };
  }>()
);

export const updateCtrScopeEquipmentAssumptionsSuccessAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/equipment/:equipmentId/assumptions SUCCESS',
  contextAwareProps<{ payload: { ctrId: number } }>()
);

export const updateCtrScopeEquipmentAssumptionsFailureAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/equipment/:equipmentId/assumptions FAILURE',
  contextAwareProps()
);

export const updateCtrScopeEquipmentExclusionsAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/equipment/:equipmentId/exclusions',
  contextAwareProps<{
    payload: {
      ctrId: number;
      ctrScopeId: number;
      equipmentId: number;
      text: string;
    };
  }>()
);

export const updateCtrScopeEquipmentExclusionsSuccessAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/equipment/:equipmentId/exclusions SUCCESS',
  contextAwareProps<{
    payload: {
      ctrId: number;
    };
  }>()
);

export const updateCtrScopeEquipmentExclusionsFailureAction = createAction(
  '[Collection AssumptionsAndExclusions] PUT /scenarioCTRs/scope/:sctrScopeId/equipment/:equipmentId/exclusions FAILURE',
  contextAwareProps()
);
