import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IGetProjectTypesResponseItem } from '@models/project-types';

import {
  getProjectTypesAction,
  getProjectTypesFailureAction,
  getProjectTypesSuccessAction,
} from './project-types.actions';

export const FEATURE_KEY = 'project-types';

export interface IProjectTypesCollectionState
  extends EntityState<IGetProjectTypesResponseItem> {
  loaded: boolean;
}

export const projectTypesEntityAdapter =
  createEntityAdapter<IGetProjectTypesResponseItem>({
    selectId: ({ projectTypeId }) => projectTypeId,
  });

export const initialState = projectTypesEntityAdapter.getInitialState({
  loaded: false,
} as IProjectTypesCollectionState);

export const reducer = createReducer(
  initialState,
  on(getProjectTypesAction, (state) => ({
    ...state,
    loaded: false,
  })),
  on(getProjectTypesSuccessAction, (state, { payload }) =>
    projectTypesEntityAdapter.upsertMany(payload, {
      ...state,
      loaded: true,
    })
  ),
  on(getProjectTypesFailureAction, (state) => ({
    ...state,
    loaded: true,
  }))
);
