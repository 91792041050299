import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProjectTypesEffects } from './store/project-types.effects';
import { FEATURE_KEY, reducer } from './store/project-types.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([ProjectTypesEffects]),
  ],
})
export class ProjectTypesCollectionModule {}
