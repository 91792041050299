import { createFeatureSelector, createSelector } from '@ngrx/store';


import {
  FEATURE_KEY,
  IEventLogCollectionState,
  eventLogEntityAdapter,
} from './event-log.reducer';

export const selectEventLogCollectionState = createFeatureSelector<
  IEventLogCollectionState
>(FEATURE_KEY);

const {
  selectAll,
  selectEntities,
} = eventLogEntityAdapter.getSelectors(selectEventLogCollectionState);

export const selectAllEventLog = createSelector(
  selectAll,
  (v) => v
);

export const selectEventLogMap = createSelector(
  selectEntities,
  (v) => v
);

export const selectEventLogFactory = (keyName: string, id: number) => createSelector(
  selectAllEventLog,
  (events) => events.filter((event) => event[ keyName ] == id)
);
