<div class="activity-header">
  <div class="cell name">
    <div class="label">Activity name</div>
    <button
      (click)="openActivityEdit()"
      [disabled]="tableService.readOnly$ | async"
      [disableRipple]="true"
      class="activity-name"
      data-test="activity-name-label"
      color="primary"
      mat-button
    >
      <span class="order-number">{{ data.order }}. </span
      >{{ data.activityDisplayName }}
    </button>
  </div>
  <div class="cell code">
    <div class="label">Code</div>
    <div class="value" data-test="activity-code-label">
      {{ data.activityCode }}
    </div>
  </div>
  <div class="cell active">
    <div class="label">Active</div>
    <div class="value">
      <mat-icon
        class="material-icons-outlined"
        [class.active]="data.isActive"
        [class.inactive]="!data.isActive"
        data-test="activity-is-active-label"
        >{{ data.isActive ? 'done' : 'cancel_outlined' }}</mat-icon
      >
    </div>
  </div>
  <div class="cell type">
    <div class="label">Type</div>
    <div class="value" data-test="activity-type-label">{{ data.type }}</div>
  </div>
  <div class="cell hours">
    <div class="label">Eng. hours</div>
    <div class="value" data-test="activity-eng-hours-label">
      {{ data.engStdHrs }}
    </div>
  </div>
  <div class="cell iter-factor">
    <div class="label">Eng. iter factor</div>
    <div class="value" data-test="activity-eng-iteration-factor-label">
      {{ data.engIterationFactor !== 1 ? data.engIterationFactor : '' }}
    </div>
  </div>
  <div class="cell hours">
    <div class="label">Dft. hours</div>
    <div class="value" data-test="activity-dft-hours-label">
      {{ data.dftStdHrs }}
    </div>
  </div>
  <div class="cell iter-factor">
    <div class="label">Dft. iter factor</div>
    <div class="value" data-test="activity-dft-iteration-factor-label">
      {{ data.dftIterationFactor !== 1 ? data.dftIterationFactor : '' }}
    </div>
  </div>
  <div class="cell qty-type">
    <div class="label">Unit</div>
    <div class="value" data-test="activity-qty-type-label">
      {{ data.qtyType }}
    </div>
  </div>
  <div class="cell qty-value">
    <div class="label">Qty value</div>
    <div class="value" data-test="activity-qty-value-label">
      {{ mapQtyValueLabelMap[data.qtyVal] || data.qtyVal }}
    </div>
  </div>
  <div class="cell deliverable">
    <div class="label">Deliverable</div>
    <div class="value" data-test="activity-is-deliverable-label">
      {{ data.isDeliverable ? 'Yes' : '' }}
    </div>
  </div>
  <div class="cell description">
    <div class="label">Description</div>
    <div class="value" data-test="activity-description-label">
      {{ data.description }}
    </div>
  </div>
  <div class="cell update">
    <div class="label">Updated</div>
    <div class="value" data-test="activity-updated-date-label">
      {{ data.lastUpdateDate | date }}
    </div>
  </div>

  <div class="cell actions">
    <div
      class="disabled-button-tooltip-trigger"
      matTooltip="Activity is used in CTR's and cannot be removed."
      [matTooltipDisabled]="
        (tableService.readOnly$ | async) === true || !data.isUsed
      "
    >
      <button
        class="delete small"
        mat-button
        data-test="activity-delete-button"
        [disabled]="(tableService.readOnly$ | async) === true || data.isUsed"
        (click)="removeActivity()"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <div class="disabled-button-tooltip-trigger">
      <button
        class="copy small"
        mat-button
        data-test="activity-copy-button"
        [disabled]="tableService.readOnly$ | async"
        (click)="openCopyActivity()"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
  </div>
</div>
