import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LoadingStateEnum } from '@collections/state-commons';
import { EquipAssigns } from '@models/equip-assigns';

import {
  EquipAssignsCollectionState,
  FEATURE_KEY,
  equipAssignsEntityAdapter,
} from './equip-assigns.reducer';

const { selectEntities } = equipAssignsEntityAdapter.getSelectors();

export const selectEquipAssignsCollectionState =
  createFeatureSelector<EquipAssignsCollectionState>(FEATURE_KEY);

export const selectEquipAssignsMap = createSelector(
  selectEquipAssignsCollectionState,
  selectEntities
);

/**
 * Select equips assigns from store.
 */

export const selectEquipAssignsByIdFactory = (equipmentId: number) =>
  createSelector(
    selectEquipAssignsMap,
    (equipAssigns) =>
      (equipAssigns[equipmentId]
        ? equipAssigns[equipmentId]
        : null) as EquipAssigns
  );

export const selectEquipAssignsLoadStateByIdFactory = (equipmentId: number) =>
  createSelector(
    selectEquipAssignsCollectionState,
    ({ equipAssignsLoadingState }) =>
      equipAssignsLoadingState[equipmentId]
        ? equipAssignsLoadingState[equipmentId]
        : LoadingStateEnum.NOT_STARTED
  );
