<div class="tooltip">
  <div class="tooltip-left">
    <div class="tooltip-name">CTR</div>
    <div class="tooltip-name">Planned hours</div>
    <div class="tooltip-name">Planned FTE</div>
    <div class="tooltip-name">Registered hours</div>
    <div class="tooltip-name">Duration in weeks</div>
    <div class="tooltip-name">Start</div>
    <div class="tooltip-name">End</div>
    <div class="tooltip-name">Executing centers</div>
  </div>
  <div class="tooltip-right">
    <div class="tooltip-value" data-test="tooltip-ctr-name-value">
      {{ ctr.ctrShortName }}
    </div>
    <div class="tooltip-value" data-test="tooltip-ctr-hours-value">
      {{ ctr.totalHours | integer : true }}
    </div>
    <div class="tooltip-value" data-test="tooltip-ctr-fte-value">
      {{ ctr.totalFte | oneDecimal : true }}
    </div>
    <div class="tooltip-value" data-test="tooltip-ctr-registered-hours-value">
      TBD
    </div>
    <div class="tooltip-value" data-test="tooltip-ctr-duration-value">
      {{ ctr.duration | integer : true }}
    </div>
    <div class="tooltip-value" data-test="tooltip-ctr-start-date-value">
      {{ ctr.startDate | date }}
    </div>
    <div class="tooltip-value" data-test="tooltip-ctr-end-date-value">
      {{ ctr.endDate | date }}
    </div>
    <div class="tooltip-value" data-test="tooltip-ctr-entity-value">
      {{ entitiesNames }}
    </div>
  </div>
</div>
