import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserRole } from '@core/store/core.reducer';

import { ProjectsEffects } from './store/projects.effects';
import { reducer } from './store/projects.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('requestor-projects', (state, action) =>
      !action.payload ||
      !action.payload.context ||
      action.payload.context === UserRole.REQUESTOR
        ? reducer(state, action)
        : state
    ),
    StoreModule.forFeature('engineer-projects', (state, action) =>
      !action.payload ||
      !action.payload.context ||
      action.payload.context === UserRole.ENGINEER
        ? reducer(state, action)
        : state
    ),
    EffectsModule.forFeature([ProjectsEffects]),
  ],
})
export class ProjectsCollectionModule {}
