<mat-card
  appearance="outlined"
  *ngIf="data$ | async as data; else loading"
  class="form-card mdm-project-info"
>
  <mat-card-title>{{ title }}</mat-card-title>

  <mat-card-content>
    <div
      *ngIf="data.localProject?.length > 0 || data.groupProject?.length > 0; else empty"
      class="actions"
    >
      <mat-slide-toggle [formControl]="showNonEmptyFormControl">Show only fields containing data</mat-slide-toggle>
      <p class="muted">Turning this switch off shows all the fields, whether they contain data or are empty.</p>
    </div>

    <table
      class="info-table"
      *ngIf="data.localProject?.length > 0"
      mat-table
      [dataSource]="data.localProject"
    >
      <caption>Local project</caption>
      <ng-container matColumnDef="label">
        <th
          mat-cell
          *matCellDef="let element"
        >LP {{ element.label }}</th>
      </ng-container>
      <ng-container matColumnDef="value">
        <td
          mat-cell
          *matCellDef="let element"
        >{{ element.value ? element.value : '-' }}</td>
      </ng-container>

      <tr
        mat-row
        *matRowDef="let row; columns: ['label', 'value'];"
      ></tr>
    </table>

    <table
      class="info-table"
      *ngIf="data.groupProject?.length > 0"
      mat-table
      [dataSource]="data.groupProject"
    >
      <caption>Group project</caption>
      <ng-container matColumnDef="label">
        <th
          mat-cell
          *matCellDef="let element"
        >GP {{ element.label }}</th>
      </ng-container>
      <ng-container matColumnDef="value">
        <td
          mat-cell
          *matCellDef="let element"
        >{{ element.value ? element.value : '-' }}</td>
      </ng-container>

      <tr
        mat-row
        *matRowDef="let row; columns: ['label', 'value'];"
      ></tr>
    </table>
  </mat-card-content>
</mat-card>

<ng-template #loading>
  <mat-spinner
    class="center"
    color="primary"
    size="64"
  ></mat-spinner>
</ng-template>

<ng-template #empty>
  <div class="no-data">
    <mat-icon svgIcon="empty-state"></mat-icon>
    No MDM data available for this project
  </div>
</ng-template>
