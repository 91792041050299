import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    const resolvedReq = req.clone({
      url: req.url.replace('{apiUrl}', environment.apiUrl),
    });

    return next.handle(resolvedReq);
  }
}
