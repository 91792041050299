import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-table-cell-switch',
  templateUrl: './table-cell-switch.component.html',
  styleUrls: ['./table-cell-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TableCellSwitchComponent),
    },
  ],
})
export class TableCellSwitchComponent implements ControlValueAccessor {
  @Input() controlId: string;
  @Output() changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  value: boolean;
  disabled: boolean;
  onChange = (value) => {};

  onTouched = () => {};

  emitChangeEvent(value: boolean) {
    this.onChange(value);
    this.changeEvent.emit(value);
  }

  writeValue(value: boolean) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
