import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

import { selectSegmentProductsFactory } from '@collections/pxds/store/pxds.selectors';
import { Product } from '@models/product';

export interface ProductDialogData {
  product: Product;
  actionName: string;
}

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['../dialogs-style.scss'],
})
export class ProductDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;
  public products$ = this.store.select(
    selectSegmentProductsFactory(this.data.product.businessSegmentId)
  );
  public actionName = this.data.actionName;
  public valueChanged$;
  public initialValue;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProductDialogData,
    private formBuilder: UntypedFormBuilder,
    private store: Store
  ) {}

  public ngOnInit(): void {
    const product = this.data.product;
    this.formGroup = this.formBuilder.group({
      name: [
        product.name ? product.name : '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ],
        [this.duplicateFieldValidator('name', product.id)],
      ],
      shortName: [
        product.shortName ? product.shortName : '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20),
        ],
        [this.duplicateFieldValidator('shortName', product.id)],
      ],
      isActive: [product.id ? product.isActive : true],
      code: [
        product.code,
        [Validators.required, Validators.pattern('^[0-9]*$')],
      ],
    });
    this.initialValue = this.formGroup.value;

    this.valueChanged$ = this.formGroup.valueChanges.pipe(
      map(
        (newValue) =>
          JSON.stringify(newValue) !== JSON.stringify(this.initialValue)
      ),
      shareReplay(1)
    );

    this.formGroup.updateValueAndValidity();
  }

  public duplicateFieldValidator(
    fieldName: string,
    productId: number
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.products$.pipe(
        map((products) => {
          return products.some(
            (x) =>
              x[fieldName].toLowerCase() === control.value.toLowerCase() &&
              x.id !== productId
          )
            ? { duplicateName: { value: control.value } }
            : null;
        }),
        take(1)
      );
    };
  }
}
