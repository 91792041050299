<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="pxd">PxD</span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <ng-content></ng-content>

  <app-pxd-grid
    [businessSegmentId]="businessSegmentId"
    [selectedPxDs]="[selectedPxD]"
    [customData]="customData"
    [columnHeaderTemplate]="columnHeaderTemplate"
    [rowHeaderTemplate]="rowHeaderTemplate"
    [cellTemplate]="cellTemplate"
    (selectionChange)="selectedPxDChange.emit($event[0] || null)"
  >
  </app-pxd-grid>

  <app-pxd-cells-legend [legendItems]="legendItems"></app-pxd-cells-legend>
</mat-expansion-panel>
