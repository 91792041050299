import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_KEY,
  IProjectStatusesCollectionState,
} from './project-statuses.reducer';

export const selectProjectStatusesCollectionState =
  createFeatureSelector<IProjectStatusesCollectionState>(FEATURE_KEY);

export const selectProjectAvailableStatuses = createSelector(
  selectProjectStatusesCollectionState,
  ({ projectAvailableStatuses }) => projectAvailableStatuses
);
