import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_KEY,
  IMdmCollectionState,
  mdmEntityAdapter,
} from './mdm.reducer';

export const selectMdmCollectionState = createFeatureSelector<
  IMdmCollectionState
>(FEATURE_KEY);

const {
  selectAll,
  selectEntities,
} = mdmEntityAdapter.getSelectors(selectMdmCollectionState);

export const selectAllMdmProjectInfos = createSelector(
  selectAll,
  (v) => v
);

export const selectMdmProjectInfoMap = createSelector(
  selectEntities,
  (v) => v
);

export const selectMdmProjectInfoByMdmNumber = (mdmNumber: string) => createSelector(
  selectMdmProjectInfoMap,
  (v) => v[ mdmNumber ]
);
