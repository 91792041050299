import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest, defer, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { selectAllActiveBusinessSegments } from '@collections/business-segments/store/business-segments.selectors';
import { selectSegmentPxDsFactory } from '@collections/pxds/store/pxds.selectors';
import { AdminBlock } from '@models/admin-block';

import { BlockCtoType } from '@app/common/enums';
import { AdminActivitiesApiService } from '../admin-activities-api.service';
import { BlockDisplayNameValidator } from '../validators/block-display-name.validator';

const parseStringToNumber = map((v: string) => parseInt(v, 10));

@Component({
  selector: 'app-edit-block-form',
  templateUrl: './edit-block-form.component.html',
  styleUrls: ['./edit-block-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BlockDisplayNameValidator],
})
export class EditBlockFormComponent implements OnInit, OnDestroy {
  @Input() public originalCtrId: number;

  @Input() public originalBlockCode = '';

  @Input() public warnWhenMoving = true;

  @Input() set data(value: Partial<AdminBlock>) {
    this.formGroup.patchValue({
      ...value,
      ctrId: !value.ctrId ? 0 : value.ctrId,
    });
    this.displayNameValidator.setCtrId(value.ctrId);
    this.displayNameValidator.setBlockId(value.blockId);
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity({ emitEvent: true, onlySelf: true });
  }

  @Input() set canChangePath(value: boolean) {
    if (value) {
      this.formGroup.get('businessSegmentId').enable();
      this.formGroup.get('ctrId').enable({ onlySelf: false, emitEvent: true });
    } else {
      this.formGroup.get('businessSegmentId').disable();
      this.formGroup.get('ctrId').disable({ onlySelf: false, emitEvent: true });
    }
  }

  public businessSegmentsOptions$ = this.store.select(
    selectAllActiveBusinessSegments
  );

  public ctoTypesOptions$ = of(Object.values(BlockCtoType));

  private businessSegmentIdValue$ =
    this.createFormInputValue('businessSegmentId').pipe(parseStringToNumber);

  private ctrIdValue$ =
    this.createFormInputValue('ctrId').pipe(parseStringToNumber);

  private blockCodeValue$ = this.createFormInputValue('blockCode');

  public pxdOptions$ = this.businessSegmentIdValue$.pipe(
    switchMap((businessSegmentId) =>
      this.store.select(selectSegmentPxDsFactory(businessSegmentId))
    ),
    shareReplay(1)
  );

  public formGroup: UntypedFormGroup = this.formBuilder.group({
    blockId: [0],
    isActive: [true, [Validators.required]],
    businessSegmentId: [1, [Validators.required]],
    ctrId: [null, [Validators.required]],
    blockCode: [{ value: null, disabled: true }, [Validators.required]],
    blockDisplayName: [
      null,
      [Validators.required],
      [this.displayNameValidator],
    ],
    order: [],
    copyFrom: [],
    ctoType: [BlockCtoType.NA, [Validators.required]],
  });

  @Output() valid = this.formGroup.valueChanges.pipe(
    map(() => this.formGroup.valid)
  );

  @Output() dataChange = this.formGroup.valueChanges.pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    map(() => this.formGroup.getRawValue()),
    map((data) => ({ ...data, ctrId: !data.ctrId ? null : data.ctrId }))
  );

  public products = [];

  public disciplines = [];

  private subscriptions = new Subscription();

  public suggestedBlockCode$ = this.ctrIdValue$.pipe(
    distinctUntilChanged(),
    switchMap((ctrId) =>
      !!ctrId && (this.originalCtrId !== ctrId || !this.originalBlockCode)
        ? this.adminActivitiesApiService.getAvailableBlockCode(ctrId).pipe(
            map(({ code }) => code || ''),
            catchError(() => of(''))
          )
        : of(this.originalBlockCode)
    ),
    shareReplay(1)
  );

  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private adminActivitiesApiService: AdminActivitiesApiService,
    private displayNameValidator: BlockDisplayNameValidator
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([
        this.pxdOptions$,
        this.ctrIdValue$,
        this.businessSegmentIdValue$,
      ]).subscribe(([options, ctrId]) => {
        if (ctrId !== null && options.every((option) => option.id !== ctrId)) {
          this.formGroup.get('ctrId').setValue(null, { emitEvent: false });
        }
      })
    );

    this.subscriptions.add(
      this.ctrIdValue$.pipe(distinctUntilChanged()).subscribe((ctrId) => {
        this.displayNameValidator.setCtrId(ctrId);
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity({ emitEvent: true });
      })
    );

    this.subscriptions.add(
      this.suggestedBlockCode$
        .pipe(
          startWith(null),
          pairwise(),
          withLatestFrom(this.blockCodeValue$),
          map(([[previousSuggestedCode, suggestedBlockCode], blockCode]) =>
            !blockCode || blockCode === previousSuggestedCode
              ? suggestedBlockCode
              : blockCode
          )
        )
        .subscribe((blockCode) => {
          this.formGroup
            .get('blockCode')
            .setValue(blockCode, { emitEvent: true });
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private createFormInputValue(formControlName: string) {
    return defer(() =>
      this.formGroup
        .get(formControlName)
        .valueChanges.pipe(
          startWith(this.formGroup.get(formControlName).value),
          distinctUntilChanged()
        )
    ).pipe(shareReplay(1));
  }
}
