import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EventLogApiService } from '../event-log-api.service';

import {
  getEventLogAction,
  getEventLogFailureAction,
  getEventLogSuccessAction,
  postCommentAction,
  postCommentFailureAction,
  postCommentSuccessAction,
} from './event-log.actions';

@Injectable()
export class EventLogEffects {
  constructor(
    private actions$: Actions,
    private eventLogApiService: EventLogApiService
  ) {}

  public getEventLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEventLogAction),
      switchMap((action) =>
        this.eventLogApiService
          .getEventLog(action.payload.context, action.payload.id)
          .pipe(
            map((response) =>
              getEventLogSuccessAction({
                trigger: action,
                context: 'EventLogEffects::getEventLog$',
                payload: {
                  context: action.payload.context,
                  response,
                },
              })
            ),
            catchError(() =>
              of(
                getEventLogFailureAction({
                  trigger: action,
                  context: 'EventLogEffects::getEventLog$',
                  payload: {
                    context: action.payload.context,
                  },
                })
              )
            )
          )
      )
    )
  );

  public postComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postCommentAction),
      switchMap((action) =>
        this.eventLogApiService
          .postComment(
            action.payload.context,
            action.payload.id,
            action.payload
          )
          .pipe(
            map(() =>
              postCommentSuccessAction({
                trigger: action,
                context: 'EventLogEffects::postComment$',
                payload: {
                  context: action.payload.context,
                  id: action.payload.id,
                },
              })
            ),
            catchError(() =>
              of(
                postCommentFailureAction({
                  trigger: action,
                  context: 'EventLogEffects::postComment$',
                })
              )
            )
          )
      )
    )
  );

  public reloadEventLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postCommentSuccessAction),
      map((action) =>
        getEventLogAction({
          trigger: action,
          context: 'EventLogEffects::reloadEventLog$',
          payload: { context: action.payload.context, id: action.payload.id },
        })
      )
    )
  );
}
