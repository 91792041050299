import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { LOCALE_ID, NgModule } from '@angular/core';

/**
 * Setup custom number and date formatting.
 *
 * Number formatting info
 * @see https://docs.oracle.com/javase/tutorial/i18n/format/decimalFormat.html
 */
const localeCustomEn = [...localeEn];
localeCustomEn[0] = 'en-CUSTOM';
localeCustomEn[13] = [
  '.', // decimal separator
  '\u00A0', // grouping separator
  ';',
  '%',
  '+',
  '-',
  'E',
  '×',
  '‰',
  '∞',
  'NaN',
  ':',
];
localeCustomEn[14] = localeDe[14];

registerLocaleData(localeCustomEn);

@NgModule({
  providers: [{ provide: LOCALE_ID, useValue: localeCustomEn[0] }],
})
export class I18nModule {}
