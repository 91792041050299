import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Optional,
  Output,
  SkipSelf,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  getMinifiedPxDsAction,
  getProductsAndDisciplinesAction,
} from '@collections/pxds/store/pxds.actions';
import {
  selectSegmentDisciplinesFactory,
  selectSegmentProductsFactory,
  selectSegmentPxDsFactory,
} from '@collections/pxds/store/pxds.selectors';
import { Discipline } from '@models/discipline';
import { Product } from '@models/product';
import { IPxD, PxDCustomData, PxDIndex } from '@models/pxd';

import { PxDTableHelperService } from '../pxd-table-helper.service';

import { SelectionService } from './selection.service';

export enum PxdGritCellType {
  PRODCUT_HEADER = 1,
  DISCIPLINE_HEADER = 2,
  CUSTOM_COLUMN_HEADER = 4,
  CUSTOM_ROW_HEADER = 6,
  PXD = 3, // PRODCUT_HEADER + DISCIPLINE_HEADER
  CUSTOM_PRODUCT = 5, // PRODCUT_HEADER + CUSTOM_COLUMN_HEADER
  CUSTOM_DISCIPLINE = 8, // DISCIPLINE_HEADER + CUSTOM_ROW_HEADER
  CUSTOM_CELL = 10, // CUSTOM_COLUMN_HEADER + CUSTOM_ROW_HEADER
}

export interface PxDGridDimmensionConfig {
  /** productId, disciplineId, or custom column id */
  id: string | number;
  /** column header label */
  name: string;
  cellType: PxdGritCellType;
  /** value assigned to row/column */
  value: Product | Discipline | Record<number, string>;
  /** class applied to whole row/column */
  class?: string;
  /** classes applied to specific cells */
  classes?: Record<number, string>;
  selectable?: boolean;
}

export interface PxDGridCellData<T extends PxDCustomData = PxDCustomData>
  extends PxDIndex<number | string> {
  cellType?: PxdGritCellType;
  pxd: IPxD;
  data: T;
  selected: boolean;
  selectable: boolean;
  classes: string;
}

@Component({
  selector: 'app-pxd-grid',
  templateUrl: './pxd-grid.component.html',
  styleUrls: ['./pxd-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PxDTableHelperService,
    SelectionService,
    {
      provide: SelectionService,
      useFactory: (
        parentInjector: Injector,
        selectionService?: SelectionService<any>
      ) => {
        if (!selectionService) {
          const injector = Injector.create({
            providers: [{ provide: SelectionService }],
            parent: parentInjector,
          });
          selectionService = injector.get(SelectionService);
        }

        return selectionService;
      },
      deps: [Injector, [new Optional(), new SkipSelf(), SelectionService]],
    },
  ],
})
export class PxDGridComponent {
  public businessSegmentId$ = new ReplaySubject<number>();

  private customData$ = new BehaviorSubject<PxDCustomData[]>([]);

  private showInactive$ = new BehaviorSubject(true);

  @Input() selectable = true;

  @Input() set showInactive(value: boolean) {
    this.showInactive$.next(value);
  }

  private allCellsSelectable$ = new BehaviorSubject(true);

  /** When you want only subset of cells to be selectable set it to false.  */
  @Input() set allCellsSelectable(value: boolean) {
    this.allCellsSelectable$.next(value);
  }

  @Input() set businessSegmentId(businessSegmentId: number) {
    this.businessSegmentId$.next(businessSegmentId);
  }

  @Input() set selectedPxDs(value: PxDIndex<string | number>[]) {
    this.selectionService.setSelected(value.filter((v) => !!v));
  }

  @Input() customRows: PxDGridDimmensionConfig[] = [];

  @Input() customColumns: PxDGridDimmensionConfig[] = [];

  /**
   * Additional data that will be connected to pxd cells on data key.
   */
  @Input() set customData(data: PxDCustomData[]) {
    this.customData$.next(data);
  }

  @Input() columnHeaderTemplate: TemplateRef<any> = null;

  @Input() rowHeaderTemplate: TemplateRef<any> = null;

  @Input() cellTemplate: TemplateRef<any> = null;

  @Output() pxdClicked = new EventEmitter<{
    productId: number | string;
    disciplineId: number | string;
    cell: PxDGridCellData;
  }>();

  @Output() selectionChange = this.selectionService.selectionChange$;

  public hoveredCell: { rowIndex: number; columnIndex: number } = null;

  private loadBusinessSegment$ = this.businessSegmentId$.pipe(
    distinctUntilChanged(),
    tap((businessSegmentId) => {
      this.store.dispatch(
        getProductsAndDisciplinesAction({
          context: 'PxDGridComponent::loadBusinessSegment$',
          payload: { businessSegmentId },
        })
      );

      this.store.dispatch(
        getMinifiedPxDsAction({
          context: 'PxDGridComponent::loadBusinessSegment$',
          payload: { businessSegmentId },
        })
      );
    }),
    shareReplay(1)
  );

  private pxds$ = this.businessSegmentId$.pipe(
    switchMap((businessSegmentId) =>
      this.store.select(selectSegmentPxDsFactory(businessSegmentId))
    ),
    shareReplay(1)
  );

  private products$ = this.loadBusinessSegment$.pipe(
    switchMap((businessSegmentId) =>
      this.store.select(selectSegmentProductsFactory(businessSegmentId))
    ),
    shareReplay(1)
  );

  /**
   * products and customRows
   */
  private displayedRows$: Observable<
    PxDGridDimmensionConfig[]
  > = combineLatest([
    this.products$,
    this.showInactive$,
    this.customData$,
  ]).pipe(
    map(([products, showInactive, pxdsData]) =>
      products.filter(
        (product) =>
          showInactive ||
          product.isActive ||
          pxdsData
            .filter((pxd) => pxd.productId === product.id)
            .some((pxd) => pxd.forceShowContext)
      )
    ),
    map((products) => [
      ...products.map((product) => ({
        id: product.id,
        name: product.shortName || product.name,
        tooltip: product.name,
        value: product,
        selectable: true,
        cellType: PxdGritCellType.PRODCUT_HEADER,
      })),
      ...this.customRows.map((column) => ({
        ...column,
        cellType: PxdGritCellType.CUSTOM_ROW_HEADER,
      })),
    ]),
    shareReplay(1)
  );

  private disciplines$ = this.loadBusinessSegment$.pipe(
    switchMap((businessSegmentId) =>
      this.store.select(selectSegmentDisciplinesFactory(businessSegmentId))
    ),
    shareReplay(1)
  );

  /**
   * disciplines and customColumns
   */
  private displayedColumns$: Observable<
    PxDGridDimmensionConfig[]
  > = combineLatest([
    this.disciplines$,
    this.showInactive$,
    this.customData$,
  ]).pipe(
    map(([disciplines, showInactive, pxdsData]) =>
      disciplines.filter(
        (discipline) =>
          showInactive ||
          discipline.isActive ||
          pxdsData
            .filter((pxd) => pxd.disciplineId === discipline.id)
            .some((pxd) => pxd.forceShowContext)
      )
    ),
    map((disciplines) => [
      ...disciplines.map((discipline) => ({
        id: discipline.id,
        name: discipline.shortName || discipline.name,
        tooltip: discipline.name,
        value: discipline,
        selectable: true,
        cellType: PxdGritCellType.DISCIPLINE_HEADER,
      })),
      ...this.customColumns.map((column) => ({
        ...column,
        cellType: PxdGritCellType.CUSTOM_COLUMN_HEADER,
      })),
    ]),
    shareReplay(1)
  );

  /** collect data that will be pushed to pxd cells via pxd, data, classes properties */
  public gridData$: Observable<{
    columns: PxDGridDimmensionConfig[];
    rows: PxDGridDimmensionConfig[];
    data: Record<string | number, Record<string | number, PxDGridCellData>>;
  }> = combineLatest([
    this.pxds$,
    this.customData$,
    this.displayedRows$,
    this.displayedColumns$,
    this.allCellsSelectable$,
  ]).pipe(
    debounceTime(100),
    distinctUntilChanged(),
    map(([pxds, customData, rows, columns, allCellsSelectable]) => ({
      columns,
      rows,
      data: rows.reduce<Record<string | number, Record<string | number, PxDGridCellData>>>(
        (r, row) => ({
          ...r,
          [row.id]: columns.reduce((result, column) => {
            const pxd =
              pxds.find(
                (item) =>
                  item.productId === row.id && item.disciplineId === column.id
              ) || null;
            const data =
              customData.find(
                (item) =>
                  item.productId === row.id && item.disciplineId === column.id
              ) || null;

            return {
              ...result,
              [column.id]: {
                cellType: (row.cellType + column.cellType) as PxdGritCellType,
                pxd,
                data,
                selectable:
                  allCellsSelectable ||
                  (row.selectable &&
                    column.selectable &&
                    data &&
                    data.selectable),
                classes: [
                  row.class,
                  column.class,
                  row.classes ? row.classes[column.id] || null : null,
                  column.classes ? column.classes[row.id] || null : null,
                ]
                  .filter((v) => !!v)
                  .join(' '),
              } as PxDGridCellData,
            };
          }, {}),
        }),
        {}
      ),
    })),
    switchMap(({ columns, rows, data }) =>
      this.selectionService.isActive()
        ? this.selectionService.selectionChange$.pipe(
            startWith([], []),
            pairwise(),
            map(([previousSelection, selection]) => {
              previousSelection.forEach(
                ({ productId, disciplineId }) =>
                  (data[productId][disciplineId].selected = false)
              );
              selection.forEach(
                ({ productId, disciplineId }) =>
                  (data[productId][disciplineId].selected = true)
              );
              return { columns, rows, data };
            })
          )
        : of({ columns, rows, data })
    ),
    tap(() => {
      this.cdr.markForCheck();
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  @ViewChild('pxdTable') set pxdTableElement(element: ElementRef) {
    this.pxdTableHelperService.registerPxDTable(element, '', 'highlight');
  }

  constructor(
    private store: Store,
    private pxdTableHelperService: PxDTableHelperService,
    private selectionService: SelectionService<PxDIndex<string | number>>,
    private cdr: ChangeDetectorRef
  ) {}

  public onPxdCellSelect(
    productId: number | string,
    disciplineId: number | string,
    cell: PxDGridCellData
  ) {
    if (
      this.selectable &&
      cell.selectable &&
      this.selectionService.toggle({ productId, disciplineId })
    ) {
      this.pxdClicked.emit({
        productId,
        disciplineId,
        cell,
      });
    }
  }

  public onCellEnter(rowIndex: number, columnIndex: number) {
    this.pxdTableHelperService.markCells({
      rowIndex: rowIndex + 1,
      columnIndex: columnIndex + 1,
    });
  }

  public onCellOut(rowIndex: number, columnIndex: number) {
    this.pxdTableHelperService.unmarkCells({
      rowIndex: rowIndex + 1,
      columnIndex: columnIndex + 1,
    });
  }

  public trackById(index: number, { id }) {
    return id;
  }
}
