import { NgModule } from '@angular/core';

import { PxdGridModule } from '@common/pxd-grid/pxd-grid.module';
import {
  SelectionMode,
  selectionModeToken,
} from '@common/pxd-grid/selection.service';
import { SharedModule } from '@shared/shared.module';

import { PxdSelectorComponent } from './pxd-selector.component';

const components = [PxdSelectorComponent];

@NgModule({
  declarations: components,
  imports: [SharedModule, PxdGridModule],
  exports: components,
  providers: [
    {
      provide: selectionModeToken,
      useValue: SelectionMode.SINGLE_SELECTION,
    },
  ],
})
export class PxdSelectorModule {}
