<h2 mat-dialog-title data-test="edit-block-dialog-title">{{ title }}</h2>
<mat-dialog-content>
  <app-edit-block-form
    [(data)]="blockData"
    [canChangePath]="canChangePath"
    [originalCtrId]="originalCtrId"
    [originalBlockCode]="originalBlockCode"
    [warnWhenMoving]="!copyMode"
    (valid)="updateState($event)"
  ></app-edit-block-form>
</mat-dialog-content>
<div class="button-right" mat-dialog-actions [align]="'end'">
  <mat-checkbox
    *ngIf="copyMode"
    (change)="markCopyChildren($event.checked)"
    [checked]="!!blockData.copyFrom"
    matTooltip="Copy all scopes and activities of the old block to the new block"
    >Copy all children</mat-checkbox
  >

  <button
    [mat-dialog-close]="false"
    color="primary"
    data-test="edit-block-dialog-close-button"
    mat-button
  >
    Cancel
  </button>

  <button
    [disabled]="!canSave"
    [mat-dialog-close]="blockData"
    color="primary"
    data-test="edit-block-dialog-save-button"
    mat-button
  >
    {{ actionLabel }}
  </button>
</div>
