import { Injectable } from '@angular/core';
import { FileDto } from '@app/models/backendModel';

@Injectable({
  providedIn: 'root',
})
export class SaveBlobHelper {
  public Save(file: FileDto) {
    const aTag = document.createElement('a');
    document.body.appendChild(aTag);
    aTag.style.display = 'none';
    const binary = atob(file.stream);
    const array: number[] = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(array)], {
      type: file.contentType,
    });
    const url = window.URL.createObjectURL(blob);
    aTag.href = url;
    aTag.download = file.fileName;
    aTag.click();
    window.URL.revokeObjectURL(url);
  }
}
