import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, combineLatest, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  exhaustMap,
  filter,
  groupBy,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';
import { RouteDataService } from '@core/route-data.service';
import { UserRole } from '@core/store/core.reducer';
import {
  selectCurrentRouteUserRoleIsEngineer,
  selectCurrentRouteUserRoleIsRequestor,
} from '@core/store/core.selectors';

import { ProjectsApiService } from '../projects-api.service';

import { SnackBarNotificationService } from '@app/common/services/snackBarNotification.service';
import { MediatorService } from '@app/mediator.service';
import { UpdateProjectOwnerCommand } from '@app/models/backendModel';
import {
  getProjectAction,
  getProjectFailureAction,
  getProjectSuccessAction,
  patchProjectsListFiltersAction,
  resetProjectsListFiltersAction,
  searchProjectsAction,
  searchProjectsFailureAction,
  searchProjectsSuccessAction,
  updateCurrentlyViewedProjectAction,
  updateProjectOwnerAction,
  updateProjectOwnerSuccessAction,
} from './projects.actions';
import {
  selectProjectByIdFactory$,
  selectProjectsListFiltersFactory$,
} from './projects.selectors';

@Injectable()
export class ProjectsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private projectsApiService: ProjectsApiService,
    private routeDataService: RouteDataService,
    private mediator: MediatorService,
    private notificationService: SnackBarNotificationService
  ) {}

  public searchOnFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchProjectsListFiltersAction, resetProjectsListFiltersAction),
      map((action) =>
        searchProjectsAction({
          context: 'ProjectsEffects::searchOnFilterChange$',
          trigger: action,
          payload: { context: action.payload.context },
        })
      )
    )
  );

  public searchProjects$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(searchProjectsAction)),
      this.store.select(selectCurrentRouteUserRoleIsRequestor),
      this.store.select(selectCurrentRouteUserRoleIsEngineer),
    ]).pipe(
      filter(
        ([, isRequestorContext, isEngineerContext]) =>
          isRequestorContext || isEngineerContext
      ),
      exhaustMap(([action, isRequestorContext, isEngineerContext]) =>
        selectProjectsListFiltersFactory$(this.store).pipe(
          filter((v) => !!v),
          take(1),
          exhaustMap((filters) =>
            // TODO: double role
            (isRequestorContext && action.payload.context === UserRole.REQUESTOR
              ? this.projectsApiService.getRequestorProjects(filters)
              : isEngineerContext &&
                action.payload.context === UserRole.ENGINEER
              ? this.projectsApiService.getEngineerProjects(filters)
              : EMPTY
            ).pipe(
              map((projects) =>
                searchProjectsSuccessAction({
                  context: 'ProjectsEffects::searchRequestorProjects$',
                  trigger: action,
                  payload: { projects, context: action.payload.context },
                })
              ),
              catchError((error) =>
                of(
                  searchProjectsFailureAction({
                    context: 'ProjectsEffects::searchRequestorProjects$',
                    trigger: action,
                    payload: { error, context: action.payload.context },
                  })
                )
              )
            )
          )
        )
      )
    )
  );

  public getProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectAction),
      groupBy(
        (action) => action.payload.context + ':' + action.payload.projectId
      ),
      mergeMap((group$) =>
        group$.pipe(
          debounceTime(500),
          exhaustMap((action) =>
            this.projectsApiService.getProject(action.payload.projectId).pipe(
              withLatestFrom(
                selectProjectByIdFactory$(
                  this.store,
                  action.payload.projectId,
                  action.payload.context
                )
              ),
              map(([project, alreadyStoredProject]) =>
                getProjectSuccessAction({
                  context: 'ProjectsEffects::getProject$',
                  trigger: action,
                  // TODO: confirm that data coming from projects list and singular project endpoint are the same
                  payload: {
                    project: {
                      ...alreadyStoredProject,
                      // ignore null values as they overide already loaded data
                      ...Object.entries(project)
                        .filter(([key, value]) => value !== null)
                        .reduce(
                          (r, [key, value]) => ({ ...r, [key]: value }),
                          {}
                        ),
                    },
                    context: action.payload.context,
                  },
                })
              ),
              catchError((error) =>
                of(
                  getProjectFailureAction({
                    context: 'ProjectsEffects::getProject$',
                    trigger: action,
                    payload: { ...action.payload, error },
                  })
                )
              )
            )
          )
        )
      )
    )
  );

  public updateCurrentViewedProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCurrentlyViewedProjectAction),
      withLatestFrom(this.store.select(selectCurrentInitializedUser)),
      switchMap(([action, user]) =>
        this.routeDataService.activatedRoute$.pipe(
          map(({ params: { projectId } }) => projectId),
          filter((v) => !!v),
          mergeMap((projectId) =>
            [
              user.isRequestor
                ? getProjectAction({
                    context:
                      'ProjectsEffects::updateCurrentViewedProject$::REQUESTOR',
                    trigger: action,
                    payload: { projectId, context: UserRole.REQUESTOR },
                  })
                : null,
              user.isEngineer
                ? getProjectAction({
                    context:
                      'ProjectsEffects::updateCurrentViewedProject$::ENGINEER',
                    trigger: action,
                    payload: { projectId, context: UserRole.ENGINEER },
                  })
                : null,
            ].filter((v) => !!v)
          )
        )
      )
    )
  );

  public updateProjectOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectOwnerAction),
      switchMap((action) =>
        this.mediator
          .send<UpdateProjectOwnerCommand>(
            'UpdateProjectOwnerCommand',
            action.payload
          )
          .pipe(
            tap(() =>
              this.notificationService.showNotification(
                'Project Owner change successful'
              )
            ),
            concatMap(() => [
              updateProjectOwnerSuccessAction({ payload: action.payload }),
              updateCurrentlyViewedProjectAction(),
            ])
          )
      )
    )
  );
}
