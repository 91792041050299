import { createAction } from '@ngrx/store';

import {
  ProjectWithDetailsDto,
  UpdateProjectOwnerCommand,
} from '@app/models/backendModel';
import { UserRole } from '@core/store/core.reducer';
import { contextAwareProps } from '@core/utils/context-aware-props';
import { IPagedResponse } from '@models/pagination';
import { IGetSearchProjectsResponse } from '@models/project';
import { IProjectSearchParams } from '@models/projects';

export const patchProjectsListFiltersAction = createAction(
  '[Collection Projects] patch projects list search filters',
  contextAwareProps<{
    payload: { params: IProjectSearchParams; context: UserRole };
  }>()
);

export const resetProjectsListFiltersAction = createAction(
  '[Collection Projects] reset projects list search filters',
  contextAwareProps<{ payload: { context: UserRole } }>()
);

export const searchProjectsAction = createAction(
  '[Collection Projects Effect] search GET /projects/:context',
  contextAwareProps<{ payload: { context: UserRole } }>()
);

export const searchProjectsSuccessAction = createAction(
  '[Collection Projects Effect] search GET /projects/:context SUCCESS',
  contextAwareProps<{
    payload: {
      projects: IPagedResponse<IGetSearchProjectsResponse>;
      context: UserRole;
    };
  }>()
);

export const searchProjectsFailureAction = createAction(
  '[Collection Projects Effect] search GET /projects/:context FAILURE',
  contextAwareProps<{
    payload?: { error: any; context: UserRole };
  }>()
);

export const getProjectAction = createAction(
  '[Collection Projects Effect] GET /projects/:projectId',
  contextAwareProps<{
    payload: { projectId: number; context: UserRole };
  }>()
);

export const getProjectSuccessAction = createAction(
  '[Collection Projects Effect] GET /projects/:projectId SUCCESS',
  contextAwareProps<{
    payload: {
      project: ProjectWithDetailsDto;
      context: UserRole;
    };
  }>()
);

export const getProjectFailureAction = createAction(
  '[Collection Projects Effect] GET /projects/:projectId FAILURE',
  contextAwareProps<{
    payload: { projectId: number; context: UserRole; error: any };
  }>()
);

export const updateCurrentlyViewedProjectAction = createAction(
  '[SDK Call Effect] update currently viewed project',
  contextAwareProps()
);

export const updateProjectOwnerAction = createAction(
  '[Collection Projects Effect] UPDATE project owner',
  contextAwareProps<{
    payload: UpdateProjectOwnerCommand;
  }>()
);

export const updateProjectOwnerSuccessAction = createAction(
  '[Collection Projects Effect] UPDATE project owner SUCCESS',
  contextAwareProps<{
    payload: UpdateProjectOwnerCommand;
  }>()
);
