import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProjectsNamesEffects } from './store/projects-names.effects';
import { FEATURE_KEY, reducer } from './store/projects-names.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([ProjectsNamesEffects]),
  ],
})
export class ProjectsNamesCollectionModule {}
