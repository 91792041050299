import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EntitiesApiService } from '../entities-api.service';

import {
  getEntitiesAction,
  getEntitiesFailureAction,
  getEntitiesSuccessAction,
} from './entities.actions';

@Injectable()
export class EntitiesEffects {
  public getEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntitiesAction),
      switchMap((action) =>
        this.entitiesApiService.getEntities().pipe(
          map((payload) =>
            getEntitiesSuccessAction({
              trigger: action,
              context: 'EntitiesEffects::getEntities$',
              payload,
            })
          ),
          catchError(() =>
            of(
              getEntitiesFailureAction({
                trigger: action,
                context: 'EntitiesEffects::getEntities$',
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private entitiesApiService: EntitiesApiService
  ) {}
}
