import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { WebSocketService } from './web-socket.service';

@NgModule({
  declarations: [],
  imports: [],
})
export class WebSocketModule {
  public static forRoot(): ModuleWithProviders<WebSocketModule> {
    return {
      ngModule: WebSocketModule,
      providers: [
        WebSocketService,
        {
          provide: APP_INITIALIZER,
          useFactory: (webSocketService: WebSocketService) => () =>
            webSocketService.initiateSocketConnection(),
          deps: [WebSocketService],
          multi: true,
        },
      ],
    };
  }
}
