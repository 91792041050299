import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { MediatorService } from '@app/mediator.service';
import {
  CalendarizationDto,
  CalendarizationQuery,
  FteDistributionForMatrix,
  FtePeriod,
  GetWorkLoadForCtrQuery,
  GetWorkLoadForCtrResult,
  HoursDistributionForMatrix,
  HoursPeriod,
  UpdateStartDateCommand,
} from '@app/models/backendModel';
import { Store } from '@ngrx/store';
import { SnackBarNotificationService } from '../../common/services/snackBarNotification.service';
import { CalendarizationTableCTRsService } from '../calendarization-table-ctrs/services/calendarization-table-ctrs.service';
import { CalendarizationTableSharedService } from '../calendarization-table-shared/calendarization-table-shared.service';
import {
  CtrsCalendarizationActions,
  getCtrsCalendarizationAction,
  getCtrsCalendarizationFailureAction,
  getCtrsCalendarizationSuccessAction,
  updateScenarioCtrStartDatesAction,
  updateScenarioCtrStartDatesSuccessAction,
} from './ctrs-calendarization.actions';
import { CalendarizationForProject } from './ctrs-calendarization.reducer';
import { selectCtrCalendarizationByScenarioCtrId } from './ctrs-calendarization.selectors';

@Injectable()
export class CTRsCalendarizationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: SnackBarNotificationService,
    private readonly mediatorService: MediatorService,
    private readonly store: Store,
    private readonly calendarizationTableCTRsService: CalendarizationTableCTRsService,
    private readonly calendarizationTableSharedService: CalendarizationTableSharedService
  ) {}

  public getCalendarization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCtrsCalendarizationAction),
      switchMap((action) =>
        this.mediatorService
          .send<CalendarizationQuery, CalendarizationDto[]>(
            'CalendarizationQuery',
            { id: action.payload.scenarioId }
          )
          .pipe(
            map((payload) =>
              payload.map((x) => ({
                ...x,
                startDateAsDate:
                  this.calendarizationTableSharedService.parseDateWithoutTimeOffset(
                    x.startDate
                  ),
                endDateAsDate:
                  this.calendarizationTableSharedService.parseDateWithoutTimeOffset(
                    x.endDate
                  ),
              }))
            ),
            map((payload) =>
              payload.map((x) => ({
                ...x,
                totalHours:
                  this.calendarizationTableSharedService.getTotalHoursForCtr(
                    x.hoursDistributionForYears
                  ),
                totalFte:
                  this.calendarizationTableSharedService.getTotalFteForCtr(
                    x.fteDistributionForYears
                  ),
              }))
            ),
            map((payload) =>
              this.calendarizationTableCTRsService.fulfillTimePeriods(payload)
            ),
            map((payload) =>
              getCtrsCalendarizationSuccessAction({
                trigger: action,
                context: 'CalendarizationEffects::getCalendarization$',
                payload,
              })
            ),
            catchError(() =>
              of(
                getCtrsCalendarizationFailureAction({
                  trigger: action,
                  context: 'CalendarizationEffects::getCalendarization$',
                })
              )
            )
          )
      )
    )
  );

  public updateScenarioCtrStartDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateScenarioCtrStartDatesAction),
      switchMap((action) =>
        this.mediatorService
          .send<UpdateStartDateCommand>(
            'UpdateStartDateCommand',
            action.payload
          )
          .pipe(
            switchMap(() =>
              this.mediatorService.send<
                CalendarizationQuery,
                CalendarizationDto[]
              >('CalendarizationQuery', { id: action.payload.scenarioId })
            ),
            map((updatedData) =>
              updateScenarioCtrStartDatesSuccessAction({
                context: 'CalendarizationEffects::updateScenarioCtrStartDates$',
                updatedCtrsData: updatedData,
              })
            ),
            tap(() =>
              this.notificationService.showNotification(
                'Start dates updated successfully'
              )
            )
          )
      )
    )
  );

  public updateRolesCalendarizationSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(CtrsCalendarizationActions.reloadCalendarizationForScenarioCtr),
      switchMap((x) =>
        this.mediatorService
          .send<GetWorkLoadForCtrQuery, GetWorkLoadForCtrResult>(
            'GetWorkLoadForCtrQuery',
            { scenarioCtrId: x.scenarioCtrId }
          )
          .pipe(
            map((result) => ({
              scenarioCtrId: x.scenarioCtrId,
              hours: result.hours,
              fte: result.fte,
            }))
          )
      ),
      switchMap((x) =>
        this.store
          .select(selectCtrCalendarizationByScenarioCtrId(x.scenarioCtrId))
          .pipe(
            take(1),
            map((dataInStore) =>
              this.getDistributionWithEmptyPeriods(dataInStore, x.hours, x.fte)
            )
          )
      ),
      map((state) =>
        CtrsCalendarizationActions.reloadCalendarizationForScenarioCtrSuccess(
          state
        )
      )
    )
  );

  public getDistributionWithEmptyPeriods(
    dataInStore: CalendarizationForProject,
    hours: HoursDistributionForMatrix[],
    fte: FteDistributionForMatrix[]
  ): CalendarizationForProject {
    const fteUpdated = this.getFtesWithEmptyPeriods(dataInStore, fte);
    const hoursUpdated: HoursDistributionForMatrix[] =
      this.getHoursWithEmptyPeriods(dataInStore, hours);
    return {
      ...dataInStore,
      fteDistributionForYears: fteUpdated,
      hoursDistributionForYears: hoursUpdated,
    };
  }
  public getHoursWithEmptyPeriods(
    dataInStore: CalendarizationForProject,
    hours: HoursDistributionForMatrix[]
  ): HoursDistributionForMatrix[] {
    return dataInStore.hoursDistributionForYears.map((hoursInStore) => {
      const hoursFromDb = hours.find(
        (hours) => hours.index === hoursInStore.index
      );

      return (
        hoursFromDb || {
          index: hoursInStore.index,
          hoursPeriods: hoursInStore.hoursPeriods.map(
            (hoursPeriod) =>
              ({
                ...hoursPeriod,
                hoursInPeriod: 0,
              } as HoursPeriod)
          ),
        }
      );
    });
  }

  public getFtesWithEmptyPeriods(
    dataInStore: CalendarizationForProject,
    fte: FteDistributionForMatrix[]
  ): FteDistributionForMatrix[] {
    return dataInStore.fteDistributionForYears.map((fteInStore) => {
      const fteFromDb = fte.find((fte) => fte.index === fteInStore.index);
      return (
        fteFromDb || {
          index: fteInStore.index,
          ftePeriods: fteInStore.ftePeriods.map(
            (ftePeriod) =>
              ({
                ...ftePeriod,
                fteInPeriod: 0,
              } as FtePeriod)
          ),
        }
      );
    });
  }
}
