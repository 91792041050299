import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EquipAssignsEffects } from './store/equip-assigns.effects';
import { FEATURE_KEY, reducer } from './store/equip-assigns.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([EquipAssignsEffects]),
  ],
})
export class EquipAssignsCollectionModule {}
