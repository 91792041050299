<a
  [routerLink]="link"
  [queryParams]="{summary: true, focusOnTable: true}"
  class="cell"
  [ngClass]="backgroundClass"
  target="_blank"
  *ngIf="link"
>
  <ng-container *ngTemplateOutlet="valueTemplate; context: { showIcon: true}"></ng-container>
</a>
<div
  *ngIf="!link"
  class="cell"
  [ngClass]="backgroundClass"
>
  <ng-container *ngTemplateOutlet="valueTemplate; context: { showIcon: false}"></ng-container>
</div>

<ng-template
  #valueTemplate
  let-showIcon="showIcon"
>
  <div
    class="cell-content"
    [class.align-center]="!alignRight"
    [class.align-right]="alignRight"
    [class.bold]="bold"
  >
    <span *ngIf="contentIsNumeric">
      {{content | number: '1.0-0' | hideZero}}{{percentValue && content !== 0?'%':''}}
    </span>
    <span *ngIf="!contentIsNumeric">
      {{content}}
    </span>
    <app-ctr-status-icon
      *ngIf="showIcon && ctrStatus"
      [ctrStatus]="ctrStatus"
      data-test="scenario-comparison-ctr-status-icon"
    >
    </app-ctr-status-icon>
    <mat-icon *ngIf="showIcon">link</mat-icon>
  </div>
</ng-template>