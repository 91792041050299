import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CTRsCalendarizationEffects } from './ctrs-calendarization.effects';
import {
  CTRs_FEATURE_KEY,
  reducer as projectReducer,
} from './ctrs-calendarization.reducer';
import { RolesCalendarizationEffects } from './roles-calendarization.effects';
import { ROLES_FEATURE_KEY, reducer } from './roles-calendarization.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(ROLES_FEATURE_KEY, reducer),
    StoreModule.forFeature(CTRs_FEATURE_KEY, projectReducer),
    EffectsModule.forFeature([
      RolesCalendarizationEffects,
      CTRsCalendarizationEffects,
    ]),
  ],
})
export class CalendarizationStateModule {}
