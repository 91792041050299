import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IntegerPipe } from '@app/common/pipe/integer.pipe';
import { OneDecimalPipe } from '@app/common/pipe/one-decimal.pipe';
import { MonthData } from '../calendarization-table-shared.service';

export enum CalendarizationViewType {
  'CHART',
  'MATRIX',
  'FTE',
}
@Component({
  selector: 'app-calendarization-table-total-in-month',
  templateUrl: './calendarization-table-total-in-month.component.html',
  styleUrls: ['./calendarization-table-total-in-month.component.scss'],
  standalone: true,
  providers: [DecimalPipe],
  imports: [CommonModule, IntegerPipe, OneDecimalPipe],
})
export class CalendarizationTableTotalInMonthComponent {
  @Input() selectedView: CalendarizationViewType;
  @Input() month: MonthData;

  chartViewType = CalendarizationViewType;
}
