import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import {
  IGetScenarioResponse,
  IScenarioCTRGetScenarioResponsePart,
} from '@app/models/scenario';
import {
  FEATURE_KEY,
  IScenariosCollectionState,
  scenariosEntityAdapter,
} from './scenarios.reducer';

export const selectScenariosCollectionState =
  createFeatureSelector<IScenariosCollectionState>(FEATURE_KEY);

const { selectAll, selectEntities } = scenariosEntityAdapter.getSelectors(
  selectScenariosCollectionState
);

export const selectAllScenarios = createSelector(selectAll, (v) => v);

export const selectScenariosMap = createSelector(selectEntities, (v) => v);

export const selectScenarioByScenarioIdFactory = (scenarioId: number) =>
  createSelector(selectScenariosMap, (scenarios) =>
    scenarios ? scenarios[scenarioId] : null
  );

export const selectScenarioIsLoaded = createSelector(
  selectScenariosCollectionState,
  ({ loaded }) => loaded
);

export const selectScenarioCTRsFactory = (
  scenarioId: number
): MemoizedSelector<
  object,
  IScenarioCTRGetScenarioResponsePart[],
  (s1: IGetScenarioResponse) => IScenarioCTRGetScenarioResponsePart[]
> =>
  createSelector(selectScenarioByScenarioIdFactory(scenarioId), (scenario) =>
    scenario ? scenario.scenarioCTRs : []
  );
