import { NgModule } from '@angular/core';

import { I18nModule } from '@core/i18n/i18n.module';

import { CtrStatusPipe } from './ctr-status.pipe';
import { HideZeroPipe } from './hide-zero.pipe';
import { LinebreaksPipe } from './linebreaks.pipe';
import { SortingPipe } from './sorting.pipe';

const pipes = [CtrStatusPipe, HideZeroPipe, LinebreaksPipe, SortingPipe];

@NgModule({
  declarations: pipes,
  imports: [I18nModule],
  exports: pipes,
})
export class CommonPipesModule {}
