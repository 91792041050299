import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { IGetEntitiesResponseItem } from '@models/entities';

export const getEntitiesAction = createAction(
  '[Collection Entities] GET /entities',
  contextAwareProps()
);

export const getEntitiesSuccessAction = createAction(
  '[Collection Entities Effect] GET /entities SUCCESS',
  contextAwareProps<{ payload: IGetEntitiesResponseItem[] }>()
);

export const getEntitiesFailureAction = createAction(
  '[Collection Entities Effect] GET /entities FAILURE',
  contextAwareProps()
);
