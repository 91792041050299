import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { MdmProjectInfoComponent } from './mdm-project-info/mdm-project-info.component';

const components = [
  MdmProjectInfoComponent,
]

@NgModule({
  declarations: components,
  imports: [
    SharedModule,
    MaterialModule
  ],
  exports: components
})
export class MdmProjectInfoModule { }
